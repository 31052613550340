import React from "react";
import { Box, Button, Grid, Stack, Typography, Tabs, Tab } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PropTypes from "prop-types";
import "./ListService.css";
import { IndividualProfilePersona } from "../../../Components/Images/Images";
import StarsIcon from "@mui/icons-material/Stars";
import CheckIcon from "@mui/icons-material/Check";
const ReviewServicePost = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Box className="review-service-post-container">
      <Box className="top-view">
        <Box>
          <Typography className="font-36">Review Service Post</Typography>
        </Box>
        <Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box className="go-back-btn">
              <Button startIcon={<ArrowBackIcon />}>Go Back</Button>
            </Box>
            <Box className="continue-btn">
              <Button
                variant="contained"
                startIcon={<ArrowForwardIcon />}
                className="btn-rounded btn-large"
                style={{ background: "var(--golden-gradient)" }}
              >
                Continue
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box className="review-content">
        <Grid container spacing={2}>
          <Grid item xs={8} md={8}>
            <Box className="left-content">
              <Typography className="font-32">
                I will design animated HTML5 banner ads or Google banner ads
              </Typography>
              <Box></Box>
              <Box>
                <Typography className="font-24">About this service</Typography>
                <Typography className="font-14" mt={2}>
                  Looking to design animated HTML5 banner ads or Google banner
                  ads?
                </Typography>
                <Typography className="font-14" mt={2}>
                  Meet Thanuja M. Founder of E-Advertising, a reliable and
                  professional animated HTML5 web banner ads design firm,
                  located in Sri Lanka but delivering worldwide.
                </Typography>
                <Typography className="font-14" mt={2}>
                  Let us help you get your ad right to attract, engage visitors,
                  and ultimately generate more leads for your business.
                  Combining the art of designing with psychology customer
                  triggers, well ensure you a display ad that can take your paid
                  advertising campaigns to the next level.
                </Typography>
                <Typography className="font-14" mt={2}>
                  We design any kind of web display ad that supports IAB Display
                  Advertising Guideline. The following list is not limited but
                  including some of our HTML5 deliverables:
                </Typography>
                <ul>
                  <li className="font-14">Google Banner Ads</li>
                  <li className="font-14">Google Display Ads and Video 360</li>
                  <li className="font-14">Google Campaign Manager 360</li>
                  <li className="font-14">AMP for Google</li>
                  <li className="font-14">
                    AdRoll, Adform, LinkedIn, Xandr, Microsoft Advertising etc.
                  </li>
                  <li className="font-14">
                    Pharma banners with scrollable ISI
                  </li>
                  <li className="font-14">
                    Other display networks comply with IAB standards
                  </li>
                </ul>
                <Typography className="font-14" mt={2}>
                  Many of our buyers have seen record-breaking growth
                  immediately after signing up for our services. You too can be
                  the next satisfied buyer of ours, so wait no longer.
                </Typography>
                <Typography className="font-14" mt={2}>
                  Please feel free to contact us if you need any further
                  information. We will be pleased to answer any further queries.
                </Typography>
              </Box>
              <Box className="about-service-provider">
                <Typography className="font-24">
                  About the Service Provider
                </Typography>
                <Box className="about-service-content">
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Box className="left-div">
                        <img src={IndividualProfilePersona} alt="" />
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Box className="right-div">
                        <Typography className="font-22">
                          Helena Christensen
                        </Typography>
                        <Typography className="font-14 text-blueGray-300">
                          Full-Stack Developer
                        </Typography>
                        <Box className="profile-view">
                          <Box className="left-view">
                            <StarsIcon />
                            <Typography ml={2} className="font-12">
                              4.9
                            </Typography>
                          </Box>
                          <Box className="top-rates-btn">
                            <Stack spacing={2} direction="row">
                              <Button variant="contained" className="">
                                Top-Rated
                              </Button>
                              <Button variant="contained" className="">
                                100% Success
                              </Button>
                            </Stack>
                          </Box>
                        </Box>
                        <Box className="contact-me-btn">
                          <Button variant="outlined" className="">
                            Contact Me
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Box px={2} py={3}>
                    <Grid container spacing={2}>
                      <Grid md={3} sm={3}>
                        <Box>
                          <Typography className="font-22">Singapore</Typography>
                          <Typography className="font-12 text-blueGray-300">
                            Singapore, 7.15PM
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid md={3} sm={3}>
                        <Box>
                          <Typography className="font-22">1 hour</Typography>
                          <Typography className="font-12 text-blueGray-300">
                            Avg. response time
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid md={3} sm={3}>
                        <Box>
                          <Typography className="font-22">24 hours</Typography>
                          <Typography className="font-12 text-blueGray-300">
                            Last delivery
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid md={3} sm={3}>
                        <Box>
                          <Typography className="font-22">English</Typography>
                          <Typography className="font-12 text-blueGray-300">
                            Languages
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Typography className="font-18">
                      Senior frontend developer
                    </Typography>
                    <Typography className="font-14 text-blueGray-300">
                      Transitioning into the wonderful world of technology, I am
                      thrilled to embark on this new journey as a Software
                      Engineer. Stepping into this domain with an inquisitive
                      mindset as I conjoin my decade-plus experiences from
                      fashion, beauty and production industries.{" "}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box className="left-content">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Basic" {...a11yProps(0)} />
                    <Tab label="Standard" {...a11yProps(1)} />
                    <Tab label="Premium" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Box className="basic-step-first-container">
                    <Box className="top-view">
                      <Box>
                        <Typography className="font-16">
                          8 Animated Banner Ads
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="font-22">SGD 280.79</Typography>
                      </Box>
                    </Box>
                    <Box mt={2}>
                      <Typography className="font-14">
                        This package includes making 8 banners of different
                        sizes with the same design style.
                      </Typography>
                    </Box>

                    <Box mt={2} className="day-delivery">
                      <Typography className="font-12">
                        3 Days Delivery
                      </Typography>
                      <Typography className="font-12" ml={2}>
                        3 Revisions
                      </Typography>
                    </Box>

                    <Box class="basic-check-points">
                      <CheckIcon />
                      <Typography className="check-text font-14">
                        Includes source files
                      </Typography>
                    </Box>
                    <Box class="basic-check-points">
                      <CheckIcon />
                      <Typography className="check-text font-14">
                        Includes source files
                      </Typography>
                    </Box>
                    <Box class="basic-check-points">
                      <CheckIcon />
                      <Typography className="check-text font-14">
                        Includes source files
                      </Typography>
                    </Box>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  Item Two
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  Item Three
                </CustomTabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReviewServicePost;
