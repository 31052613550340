import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography, Button, TextField, IconButton, InputAdornment } from "@mui/material";
import { KeyboardArrowRight, Call, Create, AddLocation } from "@mui/icons-material";
import { NewLogo } from "../../../Components/Images/Images";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css";

export default function OwnerInformation(props) {
  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // Getting current proposal and progress data from parent
  var propData = getProposalData();
  let currentProgress = getProgress();

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: propData?.ownerInformation
  })

  // Setting Edit state of fields
  const [editData, setEditData] = useState({ lNameEdit: false, lPhoneEdit: false, lAddressEdit: false, lOrganizationName: false, lOrganizationPhone: false, lOrganizationAddress: false })

  // Getting data from local storage
  const dataFromLocalStorage = localStorage.getItem("user_data");
  const localData = JSON.parse(dataFromLocalStorage)

  /**
   * Handles the form submission, updates owner information, and proceeds to the next step or saves and exits.
   * @param {Object} data - Form data.
   * @param {Object} event - Event object.
   */
  const onSubmit = (data, event) => {
    const buttonText = event.nativeEvent.submitter.innerText;
    // Update proposal data with owner information
    propData.ownerInformation = data;
    // Update it to the main page
    updateProposalData(propData);
    // Update it to local
    if (buttonText.includes("Save and Exit")) {
      SaveAndExit();
    } else {
      handleNext();
    }
  }

  /**
   * Handles the Save and Exit action, saving the proposal.
   */
  const SaveAndExit = () => {
    saveProposal();
  }

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left">
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Is the asset owned by an individual or entity?
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box" component="form"
              onSubmit={handleSubmit(onSubmit)}>
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button id="my-button" className="btn-rounded btn-text-white btn-blue-600 saveAndExit" type="submit">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              {/*Owner information Form starts from here */}
              <Box className="questionnaire6 qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box className="auth-form" mt={4} >
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "32px",
                      color: "black",
                      marginBottom: "30px",
                    }}
                  >
                    Owner Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box className="form-group label-field">
                        <TextField
                          autoComplete="cc-name"
                          type="text"
                          id="outlined-required"
                          label="Owner's Name"
                          defaultValue={propData?.ownerInformation?.owner_name ? propData?.ownerInformation?.owner_name : `${localData?.first_name} ${localData?.last_name}`}
                          name='owner_name'
                          {...register("owner_name", {
                            required: "Owner Name is required.",
                          })}
                          error={Boolean(errors.owner_name)}
                          helperText={errors.owner_name?.message}
                          InputProps={{
                            readOnly: Boolean(!editData?.lNameEdit && (propData?.ownerInformation?.owner_name || localData?.first_name)),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setEditData({ ...editData, lNameEdit: true })}
                                  disabled={editData?.lNameEdit === true && true}
                                >
                                  <Create className="text-blue-600" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box className="form-group label-field">
                        <TextField
                          autoComplete="email"
                          defaultValue={localData?.email ? localData?.email : propData?.ownerInformation?.owner_email}
                          type="email"
                          id="outlined-required"
                          label="Email Address"
                          name='owner_email'
                          {...register("owner_email", {
                            required: "Owner Email is required.",
                          })}
                          error={Boolean(errors.owner_email)}
                          helperText={errors.owner_email?.message}
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className="form-group label-field">
                    <TextField
                      id="outlined-required"
                      label="Phone Number"
                      type="text"
                      pattern="[0-9]*"
                      name='owner_phone_number'
                      {...register("owner_phone_number", {
                        required: "Phone number is required.",
                        pattern: {
                          value: /^\d{4,15}$/,
                          message: "Please enter valid phone number in digits.",
                        },
                      })}
                      autoComplete="tel"  // this line for auto-fill
                      onInput={(event) => {
                        event.target.value = event.target.value
                          .replace(/\D/g, "")
                          .slice(0, 15);
                      }}
                      error={Boolean(errors.owner_phone_number)}
                      helperText={errors.owner_phone_number?.message}
                      inputProps={{ maxLength: 15 }}
                      InputProps={{
                        readOnly: Boolean(!editData?.lPhoneEdit && propData?.ownerInformation?.owner_phone_number),
                        startAdornment: (
                          <InputAdornment position="start">
                            <Call className="text-blue-600" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end" sx={{ display: propData?.ownerInformation?.owner_phone_number ? "" : 'none' }}>
                            <IconButton
                              onClick={() => setEditData({ ...editData, lPhoneEdit: true })}
                              disabled={editData?.lPhoneEdit === true && true}
                            >
                              <Create className="text-blue-600" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box className="form-group label-field" mb={10}>
                    <TextField
                      id="outlined-required"
                      label="Address"
                      type="text"
                      name='owner_address'
                      {...register("owner_address", {
                        required: "Address is required.",
                      })}
                      error={Boolean(errors.owner_address)}
                      helperText={errors.owner_address?.message}
                      autoComplete="address"  // this line for auto-fill
                      multiline
                      rows={3}
                      InputProps={{
                        readOnly: Boolean(!editData?.lAddressEdit && propData?.ownerInformation?.owner_address),
                        startAdornment: (
                          <InputAdornment position="start">
                            <AddLocation className="text-blue-600" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end" sx={{ display: propData?.ownerInformation?.owner_address ? "" : 'none' }}>
                            <IconButton
                              onClick={() => setEditData({ ...editData, lAddressEdit: true })}
                              disabled={editData?.lAdressEdit === true && true}
                            >
                              <Create className="text-blue-600" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  {propData?.owner_type === "Entity" &&
                    <>
                      <Box className="form-group label-field">
                        <TextField
                          autoComplete="organization"
                          id="outlined-required"
                          label="Organization's Name"
                          type="text"
                          name='organization_name'
                          {...register("organization_name", {
                            required: "Organization's Name is required.",
                          })}
                          InputLabelProps={{
                            shrink: true
                          }}
                          error={Boolean(errors.organization_name)}
                          helperText={errors.organization_name?.message}
                          InputProps={{
                            readOnly: Boolean(!editData?.lOrganizationName && propData?.ownerInformation?.organization_name),
                            endAdornment: (
                              <InputAdornment position="end" sx={{ display: propData?.ownerInformation?.organization_name ? "" : 'none' }}>
                                <IconButton
                                  onClick={() => setEditData({ ...editData, lOrganizationName: true })}
                                  disabled={editData?.lOrganizationName === true && true}
                                >
                                  <Create className="text-blue-600" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box className="form-group label-field">
                        <TextField
                          autoComplete="tel"
                          defaultValue={propData?.ownerInformation?.organization_phone_number}
                          id="outlined-required"
                          label="Organization Phone Number"
                          type="text"
                          pattern="[0-9]*"
                          name='organization_phone_number'
                          {...register("organization_phone_number", {
                            required: "Organization's Phone is required.",
                            pattern: {
                              value: /^\d{4,15}$/,
                              message: "Please enter valid phone number in digits.",
                            },
                          })}
                          onInput={(event) => {
                            event.target.value = event.target.value
                              .replace(/\D/g, "")
                              .slice(0, 15);
                          }}
                          error={Boolean(errors.organization_phone_number)}
                          helperText={errors.organization_phone_number?.message}
                          inputProps={{ maxLength: 15 }}
                          InputProps={{
                            readOnly: Boolean(!editData?.lOrganizationPhone && propData?.ownerInformation?.organization_phone_number),
                            startAdornment: (
                              <InputAdornment position="start">
                                <Call className="text-blue-600" />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end" sx={{ display: propData?.ownerInformation?.organization_phone_number ? "" : 'none' }}>
                                <IconButton
                                  onClick={() => setEditData({ ...editData, lOrganizationPhone: true })}
                                  disabled={editData?.lOrganizationPhone === true && true}
                                >
                                  <Create edge="end" className="text-blue-600" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box className="form-group label-field">
                        <TextField
                          autoComplete="address"
                          id="outlined-required"
                          label="Organization's Address"
                          type="text"
                          name='organization_address'
                          {...register("organization_address", {
                            required: "Organization's Address is required.",
                          })}
                          error={Boolean(errors.organization_address)}
                          helperText={errors.organization_address?.message}
                          multiline
                          rows={3}
                          InputProps={{
                            readOnly: Boolean(!editData?.lOrganizationAddress && propData?.ownerInformation?.organization_address),
                            startAdornment: (
                              <InputAdornment position="start">
                                <AddLocation className="text-blue-600" />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end" sx={{ display: propData?.ownerInformation?.organization_address ? "" : 'none' }}>
                                <IconButton
                                  onClick={() => setEditData({ ...editData, lOrganizationAddress: true })}
                                  disabled={editData?.lOrganizationAddress === true && true}
                                >
                                  <Create edge="end" className="text-blue-600" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </>
                  }
                </Box>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  mt={3}
                >
                  <Box textAlign={"left"} className="quests6-btn-box">
                    <Button className="btn-rounded back-btn" onClick={handleback}>Back</Button>
                  </Box>
                  <Box textAlign={"right"} className="quests-btn-box">
                    <Button
                      id="my-button"
                      endIcon={<KeyboardArrowRight />}
                      className="btn-rounded btn-text-white btn-blue-600"
                      type="submit"
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}
