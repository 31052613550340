import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { img1 } from "../../../Components/Images/Images";
const Gallery = () => {
  return (
    <Box className="list-service-gallery-container">
      <Box className="top-view">
        <Grid container spacing={2}>
          <Grid md={10}>
            <Box>
              <Typography className="font-36">Gallery</Typography>
              <Typography>
                Your project will display with an image or a video as its cover.
                You can upload up to 3 images/videos that represent your
                service. For best results with images, consider ones with a 4:3
                aspect ratio that are less than 10 MB and under 4000 px in width
                or height.
              </Typography>
            </Box>
          </Grid>
          <Grid md={2}>
            <Box className="upload-img">
              <Button
                variant="contained"
                endIcon={<AddCircleOutlineIcon />}
                className="btn-rounded btn-large "
              >
                Upload images or videos
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid md={6} item>
            <Box>
              <img src={img1} width="100%" />
            </Box>
          </Grid>
          <Grid md={6} item>
            <Box>
              <img src={img1} width="100%" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="btn-content-div">
        <Stack direction="row" spacing={2} alignItems="center">
          <Box className="go-back-btn">
            <Button startIcon={<ArrowBackIcon />}>Go Back</Button>
          </Box>
          <Box className="continue-btn">
            <Button
              variant="contained"
              startIcon={<ArrowForwardIcon />}
              className="btn-rounded btn-large"
              style={{ background: "var(--golden-gradient)" }}
            >
              Continue
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Gallery;
