// calculateEMI.js

import { readContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import convertToDecimal from "../Common/convertToDecimal";

/**
 * Calculate emi for the loan
 * @param {Number} loan_amount loan amount
 * @param {Number} loan_duration loan duration
 * @param {Number} loan_ior loan interest rate
 * @param {Number} loan_iop loan interest only period
 * @returns emi amount
 */
async function calculateEMI(loan_amount, loan_duration, loan_ior, loan_iop) {

    try {
        const emi = await readContract({
            address: CONTRACTS.CALCULATE_EMI,
            abi: CONTRACT_ABI[CONTRACTS.CALCULATE_EMI],
            // account: address,
            functionName: "calculateEMI",
            args: [
                convertToDecimal(loan_amount),
                Number(Math.round(loan_ior * 100)),
                Number(loan_duration),
                Number(loan_iop)
            ]
        })
        return emi;
    } catch (error) {
        console.error('Error calling calculate EMI:', error);
        throw error; // propagate the error
    }
}

export { calculateEMI };
