import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import SideBar from "./DashboardSideBar/SideBar";
import Header from "../../Layouts/Header/HeaderServiceProposer";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import PaymentsIcon from "@mui/icons-material/Payments";
import InsightsIcon from "@mui/icons-material/Insights";
import { SwapHorizontalCircle, Download, Upload } from "@mui/icons-material";
const DashboardWallet = () => {
  return (
    <Box className="dashboard-overview-main dashboard-post-project-container service-proposer-wallet">
      <Box className="dashboard-overview-right">
        <Box className="wallet-container">
          <Box className="wallet-heading">
            <Typography component="h4" className="title">
              Member ID: 213417283037
            </Typography>
            <Typography component="p" className="sub-title">
              Hi, John 👋🏼
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} className="">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box className="connect-wallet-div">
                  <Box className="wallet-div-left">
                    <AccountBalanceWalletIcon className="account-balance" />
                    <Typography component="p" className="title">
                      0x71C7656EC7ab88b098defB751B7401B5f6d8976F
                    </Typography>
                  </Box>
                  <Box className="wallet-div-right">
                    <Button>Connected</Button>
                  </Box>
                </Box>
                <Typography className="balance-text">
                  Balance: 4,890 RT
                </Typography>
                <Box className="your-rewards-container">
                  <Box className="top-view">
                    <Typography className="title" component="h4">
                      Your Rewards
                    </Typography>
                    <MoreHorizIcon />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <PaymentsIcon className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">4,890 RT</Box>
                        <Box className="payment-card-subtitle">
                          Wallet Balance
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <HttpsOutlinedIcon className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">2,000 RT</Box>
                        <Box className="payment-card-subtitle">
                          Total Staked
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <InsightsIcon className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">2,000 RT</Box>
                        <Box className="payment-card-subtitle">
                          Total Earnings
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <SwapHorizontalCircle className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">2,000 RT</Box>
                        <Box className="payment-card-subtitle">
                          Withdrawable
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="right-section">
                  <Typography>30-Day Wallet Statistics</Typography>
                  <Box className="list-data">
                    <Box className="icons">
                      <Download />
                    </Box>
                    <Typography ml={"12px"} fontWeight={"500"}>
                      In
                    </Typography>
                  </Box>
                  <Box>
                    <Box className="progress-data">
                      <Box className="progress" style={{ width: "50%" }} />
                      <Box
                        className="progress-empty"
                        style={{ width: `50%` }}
                      />
                    </Box>
                    <Box className="amount">
                      <Typography>21,500.00 RT</Typography>
                      <Typography>50%</Typography>
                    </Box>
                  </Box>
                  <Box className="list-data">
                    <Box className="icons">
                      <Upload />
                    </Box>
                    <Typography ml={"12px"} fontWeight={"500"}>
                      Out
                    </Typography>
                  </Box>
                  <Box>
                    <Box className="progress-data">
                      <Box className="progress" style={{ width: "24%" }} />
                      <Box
                        className="progress-empty"
                        style={{ width: `76%` }}
                      />
                    </Box>
                    <Box className="amount">
                      <Typography>5392.00 RT</Typography>
                      <Typography>24%</Typography>
                    </Box>
                  </Box>
                  <Box className="show-more-btn">
                    <Button
                      className="btn-rounded"
                      color="warning"
                      sx={{ backgroundColor: "#FFF8F3", maxWidth: "123px" }}
                    >
                      {false ? "Show Less" : "Show More"}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardWallet;
