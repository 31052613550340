import React,{useState,useEffect} from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { FiberNew, LocalFireDepartment } from "@mui/icons-material";
import { HomeImage } from "../../Components/Images/Images";
import Card from "../Components/MainCard/MainCard";
import Filter from "../Components/Filters/Filter";
import ServicesSearch from "../Components/ServicesSearch/ServicesSearch";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { GetApi } from "../../Api/Api";


const SearchProjectResults = () => {
  const navigate = useNavigate()

  const [servicesData,setServicesData] = useState([])
  const [categoryData, setCategoryData] = useState(null);

  useEffect(() => {
    // Api to get categoryData
    async function getServicesData() {
      try {
        const res = await GetApi("/services/getServices");
        setServicesData(res?.data?.data?.length ? res.data.data : []);
      } catch (error) {
        console.log(error);
      }
    }

    async function getCategoryData() {
      try {
        const res = await GetApi("/services/spCategory");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }

    getCategoryData()
    getServicesData();
  }, [])



  // const handleSearchResults = () => {
  //   navigate('/service-proposer/client-category-results')
  // }
  
  return (
    <Box>
      <Box className="home-exploreBanner-section ">

        <Box mb={3} pl={4}>
          <ServicesSearch showSearchBar={true} pageTitle={'client-category-results'} categoryData={categoryData} />
        </Box>

        {/* Filters */}
        <Box mb={2}>
          <Filter title={'Results'} />
        </Box>

        {/* Main-card -Top Rated*/}
        <Box className='' mx={1}>
          <Grid container spacing={1}>
            {servicesData?.map((card, index) => (
              <Grid key={index} item md={3} sm={6} xs={12}>
                <Card {...card} />
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* All */}
        <Box padding="44px 32px 59px" mx={-4}>
          <Grid container spacing={1}>
            {servicesData?.map((card, index) => (
              <Grid key={index} item md={3} sm={6} xs={12}>
                <Card {...card} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box >
  );
};
export default SearchProjectResults;
