// withdrawDICStake.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";

/**
 * Call the withdrawAmount method to transfer the parcel to investor and money to proposer
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function withdrawDICStake(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {

        if (!assetData?.assetDetails?.blockchain_id) {
            showSnackbar('Proposal id not found for the project', 'error');
            handleModalClose();
            handleActiveStep(-1)
            return
        }

        const { request: withdrawDICStake } = await prepareWriteContract({
            address: CONTRACTS.STAKE_CONTRACT,
            abi: CONTRACT_ABI[CONTRACTS.STAKE_CONTRACT],
            account: address,
            chainId: 6805,
            functionName: "withdrawStake",
            args: [
                assetData?.assetDetails?.blockchain_id // proposalId
            ]
        })

        const { hash } = await writeContract(withdrawDICStake)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(2)
                setTimeout(() => {
                    handleSuccess(assetData)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error in calling withdraw stake for dic:', error);
        throw error; // propagate the error
    }
}

export { withdrawDICStake };