import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Pagination,
  Grid,
  Stack, // Import Stack component from Material-UI
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";// Updated import for RemoveRedEyeIcon
import ProfileDescription from "../Components/ProfileDescription/ProfileDescription";
import WorkHistorySection from "../Components/WorkHistorySection/WorkHistorySection";
import PortfolioCard from "../Components/PortfolioCard/PortfolioCard";
import SkillChip from "../Components/Chips/SkillChip/SkillChip";
import RightProjectProfileSection from "../CompanyProfilePage/RightProjectProfileSection";
import { IndividualProfilePersona } from "../../Components/Images/Images";
import { GetApiParam } from "../../Api/Api";

// Define CustomTabPanel before using it in the JSX
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: 0, pr: 3, pt: 3, pb: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ServiceProviderProfile = () => {
  const [profileData, setProfileData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = React.useState(0);

  const itemsPerPage = 3; // Number of items per page


  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""

  useEffect(() => {
    async function getProfileData() {
      try {
        const res = await GetApiParam("/services/ProfileDetails/", { user_id: localData?.id ? localData?.id : "" });
        setProfileData(res?.data?.data ? res?.data?.data[0] : []);
      } catch (error) {
        console.log(error);
      }
    }
    getProfileData()
  }, [])




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function SingleSection({ children, sectionTitle }) {
    return (
      <Box p={"24px"}>
        <Typography variant="subtitle1" className="font-18 font-readexpro" ml={"16px"} mb={3}>
          {sectionTitle}
        </Typography>
        {children}
      </Box>
    );
  }

  return (
    <Box className="service-provider-my-profile-container" >
      <Grid container spacing={2}>
        <Grid item md={10}>
          <Box className="top-view">
            <Box className="tab-view">
              <Box>
                <Typography className="font-28">Your Profile</Typography>
              </Box>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="tabs-btn"
                >
                  <Tab
                    label="Preview Profile"
                    icon={<RemoveRedEyeIcon />}
                    className="tab-btn"
                    sx={{
                      borderRadius: value === 0 ? '50px' : '0', // Apply border radius only to the first tab
                      bgcolor: value === 0 ? '#398CD1' : 'inherit', // Apply background color conditionally
                      height: "15px",
                      color: value === 0 ? '#ffffff' : '#398CD1',

                    }}
                  />
                  <Tab label="All contracts" style={{ color: '#398CD1' }}
                  />
                </Tabs>
              </Box>
              <Box></Box>
            </Box>
            <Box></Box>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container >
          <Grid item xs={12} md={8}>
            <Box className="left-container">
              <Box className="pt-0">
                <CustomTabPanel value={value} index={0}>
                  <Box className="my-profile-box tab1">
                    <ProfileDescription
                      name={profileData ? `${profileData?.first_name ? profileData?.first_name : null} ${profileData?.last_name ? profileData?.last_name : null}` : null}
                      address={profileData?.address ? profileData?.address : null}
                      title={profileData && profileData?.title}
                      description={profileData && profileData?.description}
                      persona={profileData?.profilePicture?.length ? profileData?.profilePicture[0]?.path : IndividualProfilePersona}
                      hourlyrate={profileData && profileData?.hourly_rate}
                      from="serviceProviderProfile"
                    />
                  </Box>
                  <Box className="comapany-profile-skills">
                    <SingleSection sectionTitle="Work History">
                      <WorkHistorySection />
                    </SingleSection>
                  </Box>

                  {/* Portfolio section*/}
                  <Box className="comapany-profile-skills">
                    <Typography variant="subtitle1" className="font-18 font-readexpro" ml={"16px"} mb={3} pt={2}>
                      Portfolio
                    </Typography>
                    <Box mx={3}>
                      <Grid container spacing={1}>
                        {profileData?.portfolioData
                          ?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                          .map((item, index) => (
                            <Grid item key={index} md={4}>
                              <PortfolioCard
                                image={item.portfolioImages.path}
                                title={item.portfolio_title}
                                description={item.portfolio_description}
                                link={item.portfolio_link}
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Box>
                    <Box className="pagination-container">
                      <Pagination
                        count={Math.ceil(profileData?.portfolioData?.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                      />
                    </Box>
                  </Box>

                  {/* Skills Section Individual */}
                  <Box className="comapany-profile-skills">
                    <SingleSection sectionTitle="Skills">
                      <Box>
                        <Stack direction="row" spacing={2} className="skill-list-chips">
                          {profileData && profileData?.skillData?.map((item, index) => {
                            return <SkillChip key={index} label={item.skill_name} />;
                          })}
                        </Stack>
                      </Box>
                    </SingleSection>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  Item Two
                </CustomTabPanel>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="right-container">
              <RightProjectProfileSection profileData={profileData} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ServiceProviderProfile;
