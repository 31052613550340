import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useEffect, useState } from "react";
import { Box, Chip, Grid, Typography, Button } from "@mui/material";
import { FiberNew, LocalFireDepartment } from "@mui/icons-material";
import { HomeImage } from "../../Components/Images/Images";
import Card from "../Components/MainCard/MainCard";
import Filter from "../Components/Filters/Filter";
import ServicesSearch from "../Components/ServicesSearch/ServicesSearch";
import { useNavigate, useLocation } from "react-router-dom";
import { GetApi } from "../../Api/Api";

const CategoryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { title } = location.state || {};

  // Function to navigate to a specific route
  const [categoryData, setCategoryData] = useState(null);
  const [serviceData, setServicesData] = useState([])

  // Effect hook to fetch category data from an API when the component mounts
  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/services/spCategory");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }

    // Api to get categoryData
    async function getServicesData() {
      try {
        const res = await GetApi("/services/getServices");
        setServicesData(res?.data?.data?.length ? res.data.data : []);
      } catch (error) {
        console.log(error);
      }
    }
    getServicesData();
    getCategoryData()
  }, [])




  // const cardData = [
  //   {
  //     title: "Results-driven copy for your investment project",
  //     subtitle: "From $1,000",
  //     image: HomeImage,
  //     type_icon: <LocalFireDepartment />,
  //     type_label: "Popular",
  //     user_name: "Ajay Tarole",
  //     user_speciality: "Top Rated Service Provider",
  //     rating: 3.5,
  //     review: '1.185',
  //     user_profession: "Writing",
  //     user_sub_profession: "Sales & Marketing Copywriting",
  //   },
  //   {
  //     title: "Results-driven copy for your investment project",
  //     subtitle: "From $1,000",
  //     image: HomeImage,
  //     type_icon: <FiberNew />,
  //     type_label: "New",
  //     user_name: "Ajay Tarole",
  //     user_speciality: "Top Rated Service Provider",
  //     rating: 5.0,
  //     review: '0.67',
  //     user_profession: "Writing",
  //     user_sub_profession: "Sales & Marketing Copywriting",
  //   },
  //   {
  //     title: "Results-driven copy for your investment project",
  //     subtitle: "From $1,000",
  //     image: HomeImage,
  //     type_icon: <FiberNew />,
  //     type_label: "New",
  //     user_name: "Ajay Tarole",
  //     user_speciality: "Top Rated Service Provider",
  //     rating: 2.5,
  //     review: '3.185',
  //     user_profession: "Writing",
  //     user_sub_profession: "Sales & Marketing Copywriting",
  //   },
  //   {
  //     title: "Results-driven copy for your investment project",
  //     subtitle: "From $1,000",
  //     image: HomeImage,
  //     type_icon: <FiberNew />,
  //     type_label: "New",
  //     user_name: "Ajay Tarole",
  //     user_speciality: "Top Rated Service Provider",
  //     rating: 4.2,
  //     review: '1.585',
  //     user_profession: "Writing",
  //     user_sub_profession: "Sales & Marketing Copywriting",
  //   },
  // ];


  const handleSearchResults = () => {
    navigate('/service-proposer/client-category-results')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])
  return (
    <Box>
      <Box className="home-exploreBanner-section ">

        <Box className="container">
          <Box mb={3} px={'10px'} >
            <ServicesSearch showSearchBar={true} handleSearchResults={handleSearchResults} pageTitle={'Service Catalog'} categoryData={categoryData} />
          </Box>
        </Box>

        {/* Filters */}
        <Box mb={2}>
          <Filter title={title} />
        </Box>

        {/* Main-card -Top Rated*/}
        <Box className="container">
          <Box className='main-card bg' mx={-4} >
            <Box className='topRated' mb={2} display={'flex'} alignItems={'center'}>
              <Button
                className="top-rated-button"
                style={{ background: "var(--golden-gradient)" }}
              >
                Top Rated
              </Button>
              <Typography className="font-18 text-white" ml={{ xs: 0, md: '10px', sm: '10px' }}>Top-rated services you may like</Typography>
            </Box>
            <Grid container spacing={1}>
              {serviceData.map((card, index) => (
                <Grid key={index} item md={3} sm={6} xs={12}>
                  <Card {...card} />
                </Grid>
              ))}
            </Grid>
          </Box>


          {/* All */}
          <Box style={{ padding: "39px 20px" }} mx={-4}>
            <Grid container spacing={1}>
              {serviceData.map((card, index) => (
                <Grid key={index} item md={3} sm={6} xs={12}>
                  <Card {...card} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box >
  );
};
export default CategoryPage;
