// checkBalance.js

import { readContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";

/**
 * Fetch user wallet balance
 * @param {String} address user wallet address
 * @returns user wallet balance
 */
async function checkBalance(address, feeTokenType) {
    try {

        // let token_address = CONTRACTS.TOKEN;
        // if (feeTokenType && (feeTokenType === 'USDT' || feeTokenType === 'USDC')) {
        //     token_address = CONTRACTS.USDT;
        // }

        let token_address = getCurrencyTypeAddress(feeTokenType);

        const balanceOf = await readContract({
            address: token_address,
            abi: CONTRACT_ABI[token_address],
            account: address,
            functionName: "balanceOf",
            chainId: 6805,
            watch: true,
            args: [
                address // user wallet address
            ]
        });
        return Number(balanceOf);
    } catch (error) {
        console.error('Error in fetching wallet balance:', error);
        throw error; // propagate the error
    }
}

export { checkBalance };
