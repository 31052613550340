import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, Tooltip } from "@mui/material";
import { KeyboardArrowRight } from '@mui/icons-material';

// Import custom components for this page
import { NewLogo } from '../../../Components/Images/Images'
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css"

export default function AddAssetTitle(props) {
  // Destructuring props to extract necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // State to track whether the asset title length exceeds the character limit
  const [checkChar, checkCharLength] = useState(false);

  // Get current proposal and progress data from the parent component
  var propData = getProposalData();
  let currentProgress = getProgress();

  // Character limit for asset title
  const CHARACTER_LIMIT = 250;

  // State to manage the asset title, initialized with existing title if available
  const [asset_title, setAsset_title] = useState(propData?.asset_title ? propData?.asset_title : '');

  /**
   * Function to handle changes in the asset title input.
   * @param {String} name - The name of the input field.
   */
  const handleChange = name => event => {
    // Update character length status
    checkCharLength(true);

    // Set the asset title based on user input
    setAsset_title(event.target.value);
  };

  /**
   * Function to proceed to the next step.
   */
  const handleClick = () => {
    // Update proposal data with the new asset title
    propData.asset_title = asset_title;

    // Update proposal data in the main page state
    updateProposalData(propData);

    // Move to the next step
    handleNext();
  };

  /**
   * Function to save the current proposal and exit the current step/page.
   */
  const SaveAndExit = () => {
    // Update proposal data with the new asset title
    propData.asset_title = asset_title;

    // Update proposal data in the main page state
    updateProposalData(propData);

    // Save the proposal and exit
    saveProposal();
  };

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Let’s give your asset a name
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit} disabled={(asset_title?.length === 0) ? true : false} className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box className="quests-right-contents-type">
                  <Box className="heading-wrap">
                    <Typography component="h1" className="title">
                      Create your title
                    </Typography>
                  </Box>
                </Box>
                <TextField
                  id="assetTitle"
                  label="Title"
                  inputProps={{
                    maxLength: CHARACTER_LIMIT
                  }}
                  value={asset_title}
                  error={(asset_title.length === 0 && checkChar === true)}
                  helperText={(asset_title.length === 0 && checkChar === true) ?
                    "Asset title is required." : `${asset_title.length}/${CHARACTER_LIMIT}`}
                  onChange={handleChange()}
                  margin="normal"
                  variant="outlined"
                  multiline
                />
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleback}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className="quests-btn-box">
                    <Tooltip title={((asset_title?.length !== 0)) ? "" : "Please provide a title to proceed."}>
                      <span>
                        <Button
                          onClick={() => handleClick()}
                          endIcon={<KeyboardArrowRight />}
                          className='btn-rounded btn-text-white btn-blue-600'
                          disabled={(asset_title?.length === 0) ? true : false}
                        >
                          Next
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
