import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, CircularProgress, Tooltip } from "@mui/material";
import { KeyboardArrowRight, BorderAll, KeyboardReturn, PhotoSizeSelectLarge, MonetizationOn } from "@mui/icons-material";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import { GetApi, GetApiParam, assetDeleteApi } from "../../../Api/Api";
import STCardCompo from "../../../Components/Common/STCardCompo";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css";

const url = (
  <Link className="text-underline text-black" to="/dummy" target={"_blank"}>
    Learn more here
  </Link>
);

export default function SelectListingType(props) {
  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, getPrevAsset, updateAsset, saveProposal, getProgress, EditMode, updateAssetDeleted } = props;

  // Initialize state for listing type selected
  const [selected, changeSelection] = useState(propData?.investment_type_id ? propData?.investment_type_id : "");

  // Initialize state of listing type array
  const [assetData, setAssetData] = useState([{
    display_name: 'fraction',
    investment_type_id: 4,
    title: 'Fractionalize',
    subtitle: <>You are splitting up your asset such that multiple investors can purchase a percentage of it.<br /> {url}</>,
    image: <BorderAll className="text-blue-600" />
  },
  {
    display_name: 'loan',
    investment_type_id: 2,
    title: 'Collateralize',
    subtitle: <>  You are providing your asset as collateral for a loan. <br />{url}</>,
    image: <KeyboardReturn className="text-blue-600" />
  },
  {
    display_name: 'parcel',
    investment_type_id: 3,
    title: 'Parcel',
    subtitle: <>  You are visually dividing your asset so investors can select and purchase a single/multiple unique unit(s). <br />{url}</>,
    image: <PhotoSizeSelectLarge className="text-blue-600" />
  },
  {
    display_name: 'sale',
    investment_type_id: 1,
    title: 'Sale of an entire asset',
    subtitle: <> You are selling your entire asset to one single investor.<br />{url}</>,
    image: <MonetizationOn className="text-blue-600" />
  }]);

  // Initialize state for listing type name
  const [listingTypeDisplay, changeListingState] = useState({ 'loan': true, 'fraction': true, 'sale': true, 'parcel': true });

  // getting current proposal and progress data from parent
  var propData = getProposalData();
  var currentProgress = getProgress();

  /** 
   * Function to update parent component with listing type selected
  */
  function setSelected(data) {
    // define listing(s) id
    var selectedListingType = ""
    switch (data) {
      case 1:
        selectedListingType = "sale"
        break
      case 2:
        selectedListingType = "loan"
        break
      case 3:
        selectedListingType = "parcel"
        break
      case 4:
        selectedListingType = "fraction"
        break
      default:
        selectedListingType = "fraction"
        break
    }

    // update proposal data
    propData.selectedListingType = selectedListingType;
    propData.investment_type_id = data;
    // update it to main page
    updateProposalData(propData)
    changeSelection(data)
  }

  /** 
  * Selecting listing type(s) based on asset 
  */
  useState(() => {
    switch (propData.selectedCategory) {
      case 'art':
        listingTypeDisplay.fraction = false
        listingTypeDisplay.loan = false
        listingTypeDisplay.sale = true
        listingTypeDisplay.parcel = true
        break;
      case 'gold':
      case "car":
        listingTypeDisplay.fraction = false
        listingTypeDisplay.loan = true
        listingTypeDisplay.sale = false
        listingTypeDisplay.parcel = false
        break;
      case 'watch':
        listingTypeDisplay.fraction = false
        listingTypeDisplay.loan = true
        listingTypeDisplay.sale = false
        listingTypeDisplay.parcel = false
        break;
      case 'real-estate':
      case 'funds':
        listingTypeDisplay.fraction = true
        listingTypeDisplay.loan = false
        listingTypeDisplay.sale = false
        listingTypeDisplay.parcel = false
        break;
      default:
        break;
    }
    changeListingState(listingTypeDisplay);
  }, [])

  /**
   *  Function to delete the asset type in edit mode
   * @param {Integer} asset_id 
   * @returns boolean
   */
  async function deleteAsset(asset_id) {
    if (asset_id) {
      try {
        const res = await assetDeleteApi(`/proposer/assetDelete?asset_id=${asset_id}`);
        if (res.status) {
          updateAssetDeleted(true)
          propData.asset_id = ''
        }
        return res.status
      } catch (error) {
        console.log(error)
        return error
      }
    }
  }

  /**
   * Function to handle the "Next" and "Save and Exit" button clicks.
   * Checks for changes in the investment type and updates the proposal data accordingly.
   * Calls the 'handleNext', 'saveProposal', or 'updateAsset' functions based on the button clicked.
   * @param {Object} event - The event object from the button click.
   */
  const handleClick = (event) => {
    // Get the inner text of the clicked button
    const buttonText = event.currentTarget.textContent;
    // Get the previous asset type
    let prev = getPrevAsset()
    // Check if the current investment_type_id is different from the previous one and if there's a investment_type_id in propData
    if (prev?.investment_type_id !== propData?.investment_type_id && (propData?.investment_type_id)) {
      if (prev?.investment_type_id) {
        // setting to null
        let user_id = propData?.user_id;
        let category_id = propData?.category_id;
        let selectedCategory = propData?.selectedCategory
        let investment_type_id = propData?.investment_type_id
        let selectedListingType = propData?.selectedListingType
        let project_proposer = propData?.project_proposer

        let owner_id = ''

        // Check if you're in EditMode
        if (EditMode) {
          // If in EditMode, set the owner_id and delete the existing asset (if it exists)
          propData.owner_id = owner_id
          if (propData?.asset_id) {
            deleteAsset(propData?.asset_id)
          }
        }

        // Clear the existing data in propData
        let newObj = Object.keys(propData).forEach(key => propData[key] = null);
        updateProposalData(newObj)

        // Set the necessary data back in propData
        propData.user_id = user_id;
        propData.category_id = category_id;
        propData.selectedCategory = selectedCategory;
        propData.investment_type_id = investment_type_id;
        propData.selectedListingType = selectedListingType;
        propData.project_proposer = project_proposer;

        // If in EditMode, set the owner_id back in propData
        if (EditMode) {
          propData.owner_id = owner_id
        }

        // Update the proposal data
        updateProposalData(propData)
      }
      prev.investment_type_id = propData?.investment_type_id;
      updateAsset(prev);
    }
    // Check the inner text to determine which button was clicked
    if (buttonText.includes("Save and Exit")) {
      // Handle the Save and Exit button click
      saveProposal();
    } else {
      let stakeData = ''
      async function fetchRangeConfig() {
        try {
          // getting response from api
          const res = await GetApiParam(`/proposer/getStackAmount`, {
            category_id: propData?.category_id,
            investment_type_id: propData?.investment_type_id
          });
          // setting data
          if (res?.data?.data) {
            // setStakeAmtData(res.data.data)
            stakeData = res.data.data;
            propData.stackAmtData = stakeData;
          }
        } catch (error) {
          console.log(error)
        }
      }
      fetchRangeConfig();
      // Handle the Next button click
      handleNext();
    }
  }

  /**
  * Function to handle the "Back" button click.
  * Calls the 'handleback' function to navigate to the previous step.
  */
  const handleBack = () => {
    handleback();
  }

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left">
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Select your listing type
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={handleClick} disabled={
                    (selected !== "" || propData?.investment_type_id) ? false : true
                  } className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="questionnaire6 qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                {!assetData?.length && <Box textAlign={"center"}> <CircularProgress /></Box>}
                {/* rendering listing type array */}
                <Grid container spacing={1.5} direction='row'>
                  {(() => {
                    const deals = [];
                    for (let i = 0; i < assetData?.length; i++) {
                      deals.push(
                        <Grid item xs={12} sm={12} md={6}
                          key={assetData[i].display_name}
                          onClick={() => listingTypeDisplay[assetData[i].display_name] === false ? '' : setSelected(assetData[i].investment_type_id)}
                          className={listingTypeDisplay[assetData[i].display_name] === false ? "disabled" :
                            // className={displayListingType?.includes(assetData[i]?.investment_type_id) === false ? "disabled" :
                            propData?.investment_type_id === assetData[i].investment_type_id ? 'selected' : ''}
                        >
                          <STCardCompo image={assetData[i].image} key={1} title={assetData[i].title}
                            subtitle={assetData[i].subtitle}
                          />
                        </Grid>
                      );
                    }
                    return deals;
                  })()}
                </Grid>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn text-blue-600' onClick={handleBack}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className='quests-btn-box'>
                    {(selected !== "" || propData?.investment_type_id) ?
                      <Button onClick={handleClick} endIcon={<KeyboardArrowRight />} className='btn-rounded btn-text-white btn-blue-600'>
                        Next
                      </Button>
                      :
                      <Tooltip title="Select a listing type to continue." className="p-flow-next-btn-tooltip">
                        <span>
                          <Button disabled endIcon={<KeyboardArrowRight />} className='btn-rounded btn-text-white btn-blue-600'>
                            Next
                          </Button>
                        </span>
                      </Tooltip>
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}