import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import "./MyJobs.css";
const DetailsJob = () => {
  const TAX_RATE = 0.07;

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  function priceRow(qty, unit) {
    return qty * unit;
  }

  function createRow(item, qty, unit) {
    const price = priceRow(qty, unit);
    return { item, qty, unit, price };
  }

  function subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
  }

  const rows = [
    createRow("Auditing for Houston Project (Real Estate)", 1, "1 month"),
  ];

  const invoiceSubtotal = subtotal(rows);
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  const invoiceTotal = invoiceTaxes + invoiceSubtotal;
  return (
    <Box className="details-job-container">
      <Box className="details-job-main">
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} sm={6}>
            <Box className="left-section">
              <Typography className="font-16">
                Auditing for Houston Project
              </Typography>
              <Typography className="font-12 text-blueGray-300">
                Ordered from <span className="usman-text">Usman Abbasi</span> |
                Delivery date{" "}
                <span className="date-time-text">Apr 12, 9:45 PM</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <Box className="right-section">
              <Typography className="font-14 text-blueGray-300">
                TOTAL PRICE
              </Typography>
              <Typography className="text-golden font-22">
                SGD 125.86
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography className="font-16">Order Number</Typography>
          <Typography className="font-12 text-blueGray-300">
            #SP12746384864658468
          </Typography>
        </Box>
        <Box className="table-view-container">
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} sm={6}>
              <Box className="your-order-div">
                <Typography className="your-order-text font-16">
                  Your Order
                </Typography>
                <Typography className="text-blueGray-300 font-12" ml={2}>
                  Apr 8, 20
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <Typography className="text-golden font-12 text-underline view-invoice-text">
                View Invoice
              </Typography>
            </Grid>
          </Grid>

          <Box mt={1} className="item-table-main">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell>ITEM</TableCell>
                    <TableCell align="right">QTY</TableCell>
                    <TableCell align="right">DURATION</TableCell>
                    <TableCell align="right">PRICE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.desc}>
                      <TableCell>{row.item}</TableCell>
                      <TableCell align="right">{row.qty}</TableCell>
                      <TableCell align="right">{row.unit}</TableCell>
                      <TableCell align="right">
                        {ccyFormat(row.price)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow className="sub-total-div">
                    <TableCell>SUBTOTAL</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      {ccyFormat(invoiceSubtotal)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="sub-total-div">
                    <TableCell>SERVICE FEE</TableCell>
                    <TableCell></TableCell>

                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {ccyFormat(invoiceTaxes)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="sub-total-div">
                    <TableCell className="text-white">TOTAL</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right" className="text-white">
                      {ccyFormat(invoiceTotal)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box>
            <Typography className="text-blueGray-300 footer-text">
              If something appears to be missing or incorrect, please contact
              our <spap className="text-underline">Customer Support Specialists</spap>.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box></Box>
    </Box>
  );
};

export default DetailsJob;
