import {
  Box,
  Typography,
  Divider,
  Chip,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { CAPITAL_TYPE_ID, CAPITAL_TYPE_STATUS } from "../constants";
import { formatNumber } from "../Components/Common/USFormat";


const CapitalInfo = ({ capitalInfo }) => {
  // console.log(capitalInfo)
  const isXs = useMediaQuery('(max-width:600px)');

  return (
    <Box className="pd-sidebar-priceOverview" pt={0.8} pb={1.5}>
      <Divider />
      {capitalInfo?.map((val, ind) => {
        return (
          <>
            <Box
              mt={2.5}
              justifyContent={"space-between"}
              display={"flex"}
              alignItems={"center"}
            >
              <Box className="font-18">{val?.class ? `Class ${val?.class == 1 ? 'A' : 'B'}` : val?.capital_type?.capital_name}</Box>
              {
                val?.status === CAPITAL_TYPE_STATUS.FUNDED && (
                  <Box>
                    <Chip className="pc-chip-capitalinfo" label={"Funded"} />
                  </Box>
                )
              }
            </Box>
            <>
              <Stack direction={"row"} mb={2} mt={0.7} spacing={2} justifyContent={"space-between"}>
                {/* {val?.class ? null : */}
                <Stack spacing={0} >
                  <Typography className={isXs ? "price font-24" : "price font-24"}>
                    ${formatNumber(val?.total_raise ? val?.total_raise : 0)}
                  </Typography>
                  <Typography
                    className="title font-12 text-blueGray-400 text-capitalize"
                    mt={0.3}
                  >
                    Total Raise
                  </Typography>
                </Stack>
                {/* } */}
                {val?.capital_type?.id === CAPITAL_TYPE_ID.SENIOR_DEBT && (
                  <Stack spacing={0}>
                    <Typography className={isXs ? "price font-24" : "price font-24"}>
                      {val?.senior_duration} {val?.senior_duration > 1 ? 'Years' : 'Year'}
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Duration of Debt
                    </Typography>
                  </Stack>
                )}
                {val?.capital_type?.id === CAPITAL_TYPE_ID.JUNIOR_DEBT && (
                  <Stack spacing={0}>
                    <Typography className={isXs ? "price font-24" : "price font-24"}>
                      {val?.junior_duration} {val?.junior_duration > 1 ? 'Years' : 'Year'}
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Duration of Debt
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <Stack spacing={0} mb={2}>
                  <Typography className="price font-24">
                    {val?.rate_of_return}%
                  </Typography>
                  <Typography
                    className="title font-12 text-blueGray-400 text-capitalize"
                    mt={0.3}
                  >
                    {val?.class ? "Hurdle Rate" : "Interest Rate"}
                  </Typography>
                </Stack>
                <Stack spacing={0} mb={2}>
                  <Typography className="price font-24">
                    ${formatNumber(val?.minimum_investment ? val?.minimum_investment : 0)}
                  </Typography>
                  <Typography
                    className="title font-12 text-blueGray-400 text-capitalize"
                    mt={0.3}
                  >
                    Min. Investment
                  </Typography>
                </Stack>
                {val?.class ? null :
                  val?.capital_type?.id !== CAPITAL_TYPE_ID.EQUITY && (
                    <Stack spacing={0} mb={2}>
                      <Typography className="price font-24">
                        {val?.capital_type?.id === CAPITAL_TYPE_ID.SENIOR_DEBT ? val?.iop_senior : val?.iop_junior} Months
                      </Typography>
                      <Typography
                        className="title font-12 text-blueGray-400 text-capitalize"
                        mt={0.3}
                      >
                        Interest-only Period
                      </Typography>
                    </Stack>
                  )}
                {val?.capital_type?.id === CAPITAL_TYPE_ID.EQUITY && (
                  <Stack spacing={0} mb={2}>
                    <Typography className="price font-24">
                      {val?.equity}%
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Equity
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Divider />
            </>
          </>
        );
      })}
    </Box>
  );
};

export default CapitalInfo;


//prop types
CapitalInfo.propTypes = {
  capitalInfo: PropTypes.arrayOf(PropTypes.object),
};