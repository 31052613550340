import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Pagination,
  Button,
} from "@mui/material";
import ProfileDescription from "../Components/ProfileDescription/ProfileDescription";
import PortfolioCard from "../Components/PortfolioCard/PortfolioCard";
import SkillChip from "../Components/Chips/SkillChip/SkillChip";
import OnboardingRightProfile from "./OnboardingRightProfile";
import { ServiceProviderLogo } from "../../Components/Images/Images";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { IndividualProfilePersona } from "../../Components/Images/Images";
import { postApi } from "../../Api/Api";

const OnboardingReviewPage = ({
  onFinish,
  onBack,
  sharedData,
  sharedDataStepTwo,
  sharedDataStepThree,
  previousData,
  ProgileImage,
}) => {
  /**
   * Getting userId from the  local storage
   */
  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : "";
  const navigate = useNavigate();
  const [reviewPageData, setReviewPageData] = useState({
    first_name: sharedData?.firstName,
    last_name: sharedData?.lastName,
    display_name: `${sharedData?.firstName} ${sharedData?.lastName}`,
    location: sharedData?.location,
    title: sharedDataStepTwo?.title ? sharedDataStepTwo.title : "",
    user_id: localData ? localData.id : "",
    description: sharedData?.description,
    profile_picture: sharedData?.profileImage,
    sp_category_id: sharedDataStepTwo?.selectedCategory,
    hourly_rate: sharedDataStepThree?.hourlyRate
      ? sharedDataStepThree?.hourlyRate
      : "",
    service_fee: sharedDataStepThree?.serviceFee
      ? sharedDataStepThree?.serviceFee
      : "",
    estimated_fee: sharedDataStepThree?.youWillGet
      ? sharedDataStepThree?.youWillGet
      : "",
    main_service: sharedDataStepThree?.mainServices
      ? sharedDataStepThree?.mainServices
      : "",
    availability: sharedDataStepThree?.availability
      ? sharedDataStepThree?.availability
      : "",
    documents: sharedDataStepTwo?.Documents,
    skillData: sharedDataStepTwo?.selectedSkills,
    language: sharedDataStepTwo?.selectedLanguages,
    associatedOrganization: sharedDataStepTwo.associated_organization,
    educationDetails: sharedDataStepTwo?.educationDetails,
    portfolio: sharedDataStepTwo?.details,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Number of items per page

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  function SingleSection({ children, sectionTitle }) {
    return (
      <Box p={"24px"}>
        <Typography
          variant="subtitle1"
          className="font-18 font-readexpro"
          ml={"16px"}
          mb={3}
        >
          {sectionTitle}
        </Typography>
        {children}
      </Box>
    );
  }

  const skills = [
    {
      name: "Email Marketing",
    },
    {
      name: "Data Entry",
    },
    {
      name: "Lead Generation",
    },
    {
      name: "Google Suite",
    },
    {
      name: "Development",
    },
  ];

  const handleContinue = async () => {
    if (reviewPageData) {
      /**
       * Calling Api's
       */

      let formData = new FormData();
      formData.append("first_name", reviewPageData?.first_name);
      formData.append("last_name", reviewPageData?.last_name);
      formData.append("display_name", reviewPageData?.display_name);
      formData.append("location", reviewPageData?.location);
      formData.append(
        "title",
        reviewPageData?.title ? reviewPageData.title : ""
      );
      formData.append("user_id", localData ? localData.id : "");
      formData.append("description", reviewPageData?.description);
      formData.append("profile_picture", reviewPageData?.profile_picture);
      formData.append("sp_category_id", reviewPageData?.sp_category_id);
      formData.append(
        "hourly_rate",
        reviewPageData?.hourly_rate ? reviewPageData?.hourly_rate : ""
      );
      formData.append(
        "service_fee",
        reviewPageData?.service_fee ? reviewPageData?.service_fee : ""
      );
      formData.append(
        "estimated_fee",
        reviewPageData?.estimated_fee ? reviewPageData?.estimated_fee : ""
      );
      formData.append(
        "main_service",
        reviewPageData?.main_service ? reviewPageData?.main_service : ""
      );
      formData.append(
        "availability",
        reviewPageData?.availability ? reviewPageData?.availability : ""
      );

      // Append documents here
      reviewPageData?.documents &&
        reviewPageData?.documents?.forEach((val) => {
          formData.append("upload_document", val);
        });

      // Append Skill Ids as an array
      const skillIds = reviewPageData?.skillData?.map((skill) => skill.id);
      formData.append(
        "skill_ids",
        reviewPageData?.skillData?.length > 0
          ? JSON.stringify(skillIds)
          : sharedDataStepTwo?.stepSkills
      );

      // Appeng Language as an array
      const languageData = reviewPageData?.language?.map((language, ind) => {
        return {
          language: language.name,
          proficiency: language.proficiency,
        };
      });

      formData.append("language", JSON.stringify(languageData));

      // Append Associated Organization Here
      const associated = reviewPageData?.associatedOrganization?.map(
        (assosiateddata, ind) => {
          return {
            associated_organization: assosiateddata,
          };
        }
      );

      formData.append("associated_organization", JSON.stringify(associated));

      try {
        // Define the data for your POST requests
        const requestEducationDetailData = reviewPageData?.educationDetails;
        // Create a new FormData object for portfolio
        const portfolioFormData = new FormData();

        // Append portfolio data to the new FormData object
        reviewPageData?.portfolio?.forEach((portfolioData, index) => {
          portfolioFormData.append(
            `title${index}`,
            portfolioData.portfolio_title
          );
          portfolioFormData.append(
            `image${index}`,
            portfolioData.portfolio_image
          );

          // appending userId into zero index only
          if (index === 0) {
            portfolioFormData.append(`user_id`, portfolioData.user_id);
          }
        });

        // Define your API endpoints
        const apiUrlEducation = "/services/education";
        const apiUrlPortfolio = "/services/portfolio";
        const apiUrlCreateProfile = "/services/createProfile";

        // Define your axios POST requests
        const request1 = await postApi(
          apiUrlEducation,
          requestEducationDetailData
        );
        const request2 = await postApi(apiUrlPortfolio, portfolioFormData);
        const request3 = await postApi(apiUrlCreateProfile, formData);

        // Call both requests simultaneously
        const [
          responseEducation,
          responseWorkExperience,
          responceCreateProfile,
        ] = await Promise.all([request1, request2, request3]);

        // Handle responses here
        if (
          responceCreateProfile?.data?.code === 200 &&
          responseEducation?.data?.code === 200 &&
          responseWorkExperience?.data?.code === 200
        ) {
          navigate("/service-provider");
          // Call onFinish or any other function to handle the form submission
          onFinish();
        }
      } catch (error) {
        // Handle errors here
        console.error("Error:", error);
      }
    }
  };

  return (
    <Box className="onboarding-review-page-container">
      {/* <Box className="logo-onboarding-one">
        <Link to="/">
          <img src={ServiceProviderLogo} alt="Logo" />
        </Link>
      </Box> */}
      <Box className="review-profile-top">
        <Grid container spacing={2}>
          <Grid xs={12} md={8} sm={12}>
            <Box>
              <Typography className="font-36">Review Profile</Typography>
              <Typography className="font-14">
                Check your details and click create profile to publish your
                profile. You can edit any details in your profile page.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4} sm={12}>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box className="go-back-btn">
                  <Button startIcon={<ArrowBackIcon />} onClick={onBack}>
                    Go Back
                  </Button>
                </Box>
                <Box className="continue-btn">
                  <Button
                    variant="contained"
                    startIcon={<ArrowForwardIcon />}
                    className="btn-rounded btn-large"
                    style={{ background: "var(--golden-gradient)" }}
                    onClick={() => {
                      handleContinue();
                    }}
                  >
                    Create Profile
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} sm={12}>
            <Box>
              <Box className="my-profile-box">
                <ProfileDescription
                  name={
                    reviewPageData
                      ? `${reviewPageData?.first_name
                        ? reviewPageData?.first_name
                        : null
                      } ${reviewPageData?.last_name
                        ? reviewPageData?.last_name
                        : null
                      }`
                      : null
                  }
                  address={
                    reviewPageData?.location ? reviewPageData?.location : null
                  }
                  title={reviewPageData && reviewPageData?.title}
                  description={reviewPageData && reviewPageData?.description}
                  persona={
                    reviewPageData?.profile_picture
                      ? reviewPageData?.profile_picture
                      : IndividualProfilePersona
                  }
                  hourlyrate={reviewPageData && reviewPageData?.hourly_rate}
                  from="reviewProfile"
                />
              </Box>

              {/* Portfolio section*/}
              <Box className="comapany-profile-skills">
                <Typography
                  variant="subtitle1"
                  className="font-18 font-readexpro"
                  ml={"16px"}
                  mb={3}
                  pt={2}
                >
                  Portfolio
                </Typography>
                <Box mx={3}>
                  <Grid container spacing={1}>
                    {reviewPageData?.portfolio
                      ?.slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage
                      )
                      .map((item, index) => (
                        <Grid item key={index} md={4}>
                          <PortfolioCard
                            image={item.portfolio_image}
                            title={item.portfolio_title}
                          // description={item.portfolio_description}
                          // link={item.portfolio_link}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
                <Box className="pagination-container">
                  <Pagination
                    count={Math.ceil(
                      reviewPageData?.portfolio?.length / itemsPerPage
                    )}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                  />
                </Box>
              </Box>

              {/* Skills Section Individual */}
              <Box className="comapany-profile-skills">
                <SingleSection sectionTitle="Skills">
                  <Grid container spacing={2} mt={1}>
                    {reviewPageData?.skillData?.map((item, index) => {
                      return (
                        <Grid xs="auto" ml={2}>
                          {" "}
                          <SkillChip key={index} label={item.skill_name} />{" "}
                        </Grid>
                      );
                    })}
                  </Grid>
                </SingleSection>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sm={12}>
            <Box className="right-section">
              <OnboardingRightProfile reviewPageData={reviewPageData} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OnboardingReviewPage;
