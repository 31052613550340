import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, InputAdornment, CircularProgress } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Import custom components for this page
import { NewLogo } from '../../../Components/Images/Images'
import ParcelEditCard from "./Components/ParcelEditCard";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import { SplitImage } from "./Components/SplitImage";
import DrawGridImage from "./Components/DrawGridImage";
import "./Components/Questionnaire.css"


export default function EditParcelPrices(props) {
  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress, EditMode } = props;

  // Get current proposal and progress data from the parent component
  var propData = getProposalData();
  let currentProgress = getProgress();

  const [data, setData] = useState([]);
  const [parcelDataError, setParcelDataError] = useState({ message: '', status: false });

  /**
   * Function to set error
   * @param {String} err 
   */
  const handleParcelDataError = (err) => {
    setParcelDataError(err);
  }

  /**
   * Function to check integer and two decimal value
   * @param {Number} value 
   */
  const decimalFormatter = (value) => {
    // Check if the value has more than two decimal places
    if (Number.isInteger(value)) {
      // If the value is an integer or has at most two decimal places, return it as is
      return value;
    } else {
      // If the value has more than two decimal places, apply toFixed(2)
      return value.toFixed(2);
    }
  }

  // initializing data object 
  useEffect(() => {
    if (propData?.parcelsDetails?.length === 0) {
      let _makeData = [];
      for (let i = 0; i < propData?.number_of_parcel; i++) {
        _makeData.push({ parcel_id: '', id: i + 1, image: '', image_view: '', data: '' })

      }
      // makeData.current = _makeData
      setData(_makeData)
    } else {
      setData(propData?.parcelsDetails)
    }
  }, [])

  const [isParcelCreated, setIsParcelCreated] = useState(propData?.isParcelCreated ? propData?.isParcelCreated : false);
  const [parcelPrice, setParcelPrice] = useState(0.0);

  // converting base64 Image to blob type image
  const contentType = 'image/png';
  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  var parcelImages = [];
  // creating parcel
  useEffect(() => {
    async function ChangeParcel() {
      parcelImages = await SplitImage(propData?.asset_coverphoto_view, propData?.number_of_rows, propData?.
        number_of_columns)
      if (parcelImages.length > 0) {
        setIsParcelCreated(true)
        propData.cover_photo_changed = false;
        parcelImages?.forEach((image, index) => {
          const base64 = image.split(',')[1]
          const blob = b64toBlob(base64, contentType);
          const blobUrl = URL.createObjectURL(blob);
          setParcelImage(blob, index + 1)

          setParcelImageView(blobUrl, index + 1)
          setParcelData(propData?.minimun_investment_per_parcel, index + 1)

        })
      }
    }
    if (propData?.parcelsDetails?.length === 0) {
      ChangeParcel()
    }
    // Call the asynchronous function
  }, [propData?.parcelsDetails])

  /**
   * Updating data object with price of parcel
   * @param {Number} price
   * @param {Integer} id
   */
  const setParcelData = (price, id) => {
    setData(pre => {
      if (pre?.length) {
        let result = [...pre];
        result[id - 1].data = parseFloat(price);
        return result;
      }
      return pre
    })
  }

  /**
   * Updating data object with image of parcel
   * @param {String} image
   * @param {Integer} id
   */
  const setParcelImage = (image, id) => {
    setData(pre => {
      if (pre?.length) {
        let result = [...pre];
        result[id - 1].image = image;
        return result;
      }
      return pre
    })
  }

  /**
  * Updating data object with image of parcel for ui only
  * @param {String} view
  * @param {Integer} id
  */
  const setParcelImageView = (view, id) => {
    setData(pre => {
      if (pre?.length) {
        let result = [...pre];
        result[id - 1].image_view = view;
        return result;
      }
      return pre
    })
  }

  /**
  * Updating proposal object
  */
  const handleClick = () => {
    // finding smallest price from all parcels price
    const newMinimumInvestmentPerParcel = data.reduce((min, current) => (current.data < min.data ? current : min), data[0]);

    propData.parcelsDetails = data;
    propData.isParcelCreated = isParcelCreated;
    if (newMinimumInvestmentPerParcel.data) {
      // updating minimum investment per parcel
      propData.minimun_investment_per_parcel = newMinimumInvestmentPerParcel.data
    }
    updateProposalData(propData)
    handleNext();
  }

  /**
   * Handle save and exit button
   */
  const SaveAndExit = () => {
    // finding smallest price from all parcels price
    const newMinimumInvestmentPerParcel = data.reduce((min, current) => (current.data < min.data ? current : min), data[0]);

    propData.parcelsDetails = data;
    propData.isParcelCreated = isParcelCreated;
    if (newMinimumInvestmentPerParcel.data) {
      // updating minimum investment per parcel
      propData.minimun_investment_per_parcel = newMinimumInvestmentPerParcel.data
    }
    updateProposalData(propData)
    saveProposal();
  }

  /**
  * Handle  back button action
  */
  const handleBack = () => {
    handleback();
  }

  useEffect(() => {
    if (data?.length && data[0].image) {
      let flag = false;
      let prices = 0
      data?.forEach((value) => {
        prices += value.data
        if (value.data == '' || value.data == 0) {
          flag = true
        }
      })
      if (!flag) {
        setParcelDataError({ message: '', status: 'false' })
      }
      if (prices) {
        setParcelPrice(parseFloat(prices).toFixed(2))
      }
    }
  }, [data]);

  const newMinimum = parseFloat(propData?.parcel_total_price / (propData?.number_of_parcel)).toFixed(2);
  const newPrice = decimalFormatter(newMinimum * (propData?.number_of_parcel));

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  You can edit individual parcel prices here
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit}
                    disabled={(Boolean(parseFloat(propData?.parcel_total_price) != parseFloat(parcelPrice)) || parcelDataError.status === true) ? true : false}
                    className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box my={4}>
                  <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                    <Grid item md={5} sm={12} xs={4}>
                      <Box>
                        {/* <ParcelEditCard image={NFTsIC} edit={false} editPrice={false} /> */}
                        <DrawGridImage title={true} artImage={((typeof propData?.asset_coverphoto == "string") && propData?.asset_coverphoto_view?.startsWith("proposer/uploads/")) ? `${process.env.REACT_APP_IMAGE_URL}${propData?.asset_coverphoto_view}` : propData?.asset_coverphoto_view} propData={propData} drawGrid={true} setParcelImage={setParcelImage} setParcelImageView={setParcelImageView} />
                      </Box>
                    </Grid>
                    <Grid item md={7} sm={12} xs={8}>
                      <Box className="" my={4}>
                        <TextField hiddenLabel placeholder="amount" variant='filled'
                          // defaultValue={"$3825.10 USD"}
                          // helperText="Balance: $3825.10 USD"
                          defaultValue={`$${parcelPrice > 0 ? parcelPrice : newPrice}`}
                          helperText={`Sale Price: $${propData?.parcel_total_price} USD`}
                          value={`$${parcelPrice}`}
                          disabled
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><Box height={'32px'} width={'32px'} component={'img'} src={NewLogo} /></InputAdornment>,
                            endAdornment: <InputAdornment position="end">
                              {/* <Button className="rc-tertiary-btn-blue" style={{ padding: '0px' }} variant="filled" >Reset</Button> */}
                            </InputAdornment>,
                          }} sx={{ input: { color: '#94A3B8 !important' } }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container spacing={{ xs: 1, sm: 2 }}>
                  {(() => {
                    let ArrLength = data?.length;
                    const deals = [];
                    for (let i = 0; i < (ArrLength > 100 ? 100 : ArrLength); i++) {
                      deals.push(
                        <Grid item xs={6} sm={12} md={4} lg={4}>
                          <Box>
                            <ParcelEditCard edit={false} editPrice={true} parcelId={i + 1} setParcelData={setParcelData} setParcelImage={setParcelImage}
                              artImage={(data[i]?.image_view?.startsWith("proposer/uploads/")) ? `${process.env.REACT_APP_IMAGE_URL}${data[i]?.image_view}` : data[i]?.image_view} propData={propData} setParcelImageView={setParcelImageView} handleParcelDataError={handleParcelDataError} />
                          </Box>
                        </Grid>
                      );
                    }
                    return deals;
                  })()}
                </Grid>
                <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center', marginTop: 2.5 }}>{parcelDataError.status === true ? parcelDataError.message : Boolean((parseFloat(propData?.parcel_total_price)) != (parseFloat(parcelPrice)) && (data?.length)) ? "Total parcel price must be equal to the sale price." : ''}</Typography>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleBack}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className='quests-btn-box'>
                    <Button onClick={handleClick} endIcon={<KeyboardArrowRightIcon />}
                      // className='btn-rounded quests-btn'
                      className="btn-rounded btn-text-white btn-blue-600"
                      disabled={(Boolean(parseFloat(propData?.parcel_total_price) != parseFloat(parcelPrice)) || parcelDataError.status === true) ? true : false}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}






