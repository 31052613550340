import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const Requirements = () => {
  return (
    <Box className="list-service-requirement-container">
      <Box className="top-view">
        <Box>
          <Typography className="font-36">
            Requirements for the client
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            endIcon={<AddCircleOutlineIcon />}
            className="btn "
          >
            Send
          </Button>
        </Box>
      </Box>
      <Box className="requirment-container">
        <Grid container spacing={2}>
          <Grid item xs={4} md={4}>
            <Box>
              <Typography className="font-22">
                Tell the client what you need to get started
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8} md={8}>
            <Box>
              <TextField
                id="description"
                name="description"
                variant="outlined"
                placeholder="Already have a description? Paste it here!"
                // value={formData.description}
                // onChange={handleInputChange}
                className="onboarding-input-text"
                multiline // Enable multiline
                rows={4} // Set the number of rows to display
                // error={Boolean(errors.description)}
                // helperText={errors.description}
                style={{ minHeight: "80px" }}
              />
            </Box>
            <Box className="mandatory-reuirement-checkbox" mt={3}>
              <FormControlLabel
                value="end"
                control={<Checkbox />}
                label="Mandatory Requirement"
                labelPlacement="end"
              />
            </Box>
            <Box className="mandatory-reuirement-checkbox">
              <Button variant="contained" className="btn-rounded btn-blue-800 ">Add</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="btn-content-div">
      <Stack direction="row" spacing={2} alignItems="center">
        <Box className="go-back-btn">
          <Button startIcon={<ArrowBackIcon />}>Go Back</Button>
        </Box>
        <Box className="continue-btn">
          <Button
            variant="contained"
            startIcon={<ArrowForwardIcon />}
            className="btn-rounded btn-large"
            style={{ background: "var(--golden-gradient)" }}
          >
            Continue
          </Button>
        </Box>
      </Stack>
    </Box>
    </Box>
  );
};

export default Requirements;
