import Documents from "../Documents";
import AssetDetails from "../AssetDetails";
import PriceOverview from "../PriceOverview";
import Offers from "../Offers";
import GallerySlider from "../GallerySlider";
import Description from "../Description";
import OwnerInformation from "../OwnerInformation";
import InvestorButtons from "../InvestorButtons";
import BaseDetailsAsset from "./BaseDetailsClass";
import Templates from "../Templates";

export default class Art extends BaseDetailsAsset {
  rightPanel = [];
  leftPanel = [];
  isParcel = false;


  constructor(_data) {

    super(_data);
    if (!_data) {
      throw new Error(
        "Custom error: Art data is required in Art Details class"
      );
    }

    this.assetDetails = [
      {
        title: "Investment Type",
        value: _data?.investment_type?.investment_name ?? "",
      },
      {
        title: "Art Type",
        value: _data.asset_sub_category_info?.title ?? "",
      },
      {
        title: "Art Title",
        value: _data.asset_title ?? "",
      },
      {
        title: "Artist",
        value: _data.artsData[0]?.artist ?? "",
      },
      {
        title: "Year",
        value: _data.artsData[0]?.year ?? ""
      },
      {
        title: "Medium",
        value: _data.artsData[0]?.medium ?? ""
      },
      {
        title: "Owned By",
        value: _data.owner_information?.owner_type ?? ""
      },
    ];

    this.docs = _data.asset_document;
    this.description_document = _data.description_document
    this.asset_signing_document = _data.asset_signing_document
    this.selected_templates = _data?.template_data ? _data?.template_data[0]?.template_ids : []
    this.investment_type_id = _data.investment_type_id
    this.galleryImages = _data?.asset_gallery;
    this.is_onboard = _data?.is_onboard
    this.nft_id = _data?.nft_id
    this.is_direct_buy = _data?.is_direct_buy
    this.is_parcel_sold = false
    this.member_id = _data?.user_details?.member_id

    if (_data?.parcelData && _data?.parcelData?.length) {
      if (_data?.parcelData?.some((parcel) => parcel.is_sold)) {
        this.is_parcel_sold = true
      }
    }

    if (_data.description_document?.length > 0) {
      const documentOnly = _data.description_document.filter(item => item.images && (item.images.type === "mp4"));
      if (documentOnly?.length > 0) {
        this.galleryImages = _data?.asset_gallery.concat(documentOnly);
      }
    }
    this.isParcel = _data?.investment_type?.investment_name === "Parcelling" ? true : false;
    this.coverphoto = _data?.asset_coverphoto;
    this.descriptionData = {
      title: _data?.artsData[0]?.art_name,
      subtitle: _data?.artsData[0]?.art_address,
      investmentType: _data?.investment_type?.investment_name,
      assetType: _data?.assets_category?.title,
      assetSubtype: _data?.asset_sub_category_info?.title,
      description: _data?.asset_description,
      image: _data?.asset_coverphoto?.path ? _data?.asset_coverphoto?.path : "",
    };

    this.parcelCardsData = _data?.parcelData;
    this.setLeftPanelComponents();
    this.setRightPanelComponents();
  }

  setLeftPanelComponents = () => {
    this.leftPanel.push(<GallerySlider images={this.galleryImages} coverPhoto={this.coverphoto} isOnboard={this.is_onboard} nft_id={this.nft_id} isParcel={this.isParcel} isSale={this.isSale} />);
    this.leftPanel.push(
      <Description
        description={this.descriptionData.description}
        investmentType={this.descriptionData.investmentType}
        title={this.descriptionData.title}
        subtitle={this.descriptionData.subtitle}
        assetType={this.descriptionData.assetType}
        assetSubtype={this.descriptionData.assetSubtype}
        parcelCardsData={this.parcelCardsData}
        userId={this.user_id}
        assetId={this.asset_Id}
      />
    );
  };

  setRightPanelComponents = () => {
    if (!this.isOwner) { // only show below code block components if user is not owner
      this.rightPanel.push(<OwnerInformation owner={this.ownerInfo} totalProject={this.totalProjects} member_id={this.member_id} />);
    } else {
      if (!this.isParcel) { this.rightPanel.push(<Offers asset_Id={this.asset_Id} is_direct_buy={this.is_direct_buy} />); }
      else {
        this.rightPanel.push(<Offers asset_Id={this.asset_Id} is_parcel={true} is_parcel_sold={this.is_parcel_sold} />);
      }
    }
    this.rightPanel.push(<PriceOverview prices={this.prices} isParcel={this.isParcel} isSale={this.isSale} assetId={this.asset_Id} btnTitle={this.descriptionData.title} listingType={this.descriptionData.investmentType} isOfferAccepted={this.isOfferAccepted} isOnboard={this.is_onboard} isOwner={this.isOwner} />);
    // if (!this.isOwner) this.rightPanel.push(<InvestorButtons assetId={this.asset_Id} btnTitle={this.descriptionData.title} listingType={this.descriptionData.investmentType} isOfferAccepted={this.isOfferAccepted} isOnboard={this.is_onboard} />);
    this.rightPanel.push(<AssetDetails details={this.assetDetails} editable={this.isOwner} isParcel={this.isParcel} isSale={this.isSale} />);
    this.rightPanel.push(<Documents documentsData={this.docs} descriptionData={this.description_document} ownerId={this.ownerInfo} assetId={this.asset_Id} editable={this.isOwner} projectStatus={this.projectStatus} section_title="Asset Documents" document_name="asset_document" investment_type_id={this.investment_type_id} isParcel={this.isParcel} isSale={this.isSale} />);
    // this.rightPanel.push(<Documents documentsData={this.asset_signing_document} descriptionData={[]} ownerId={this.ownerInfo} assetId={this.asset_Id} editable={this.isOwner} projectStatus={this.projectStatus} section_title="Agreement Documents" document_name="asset_signing_document" investment_type_id={this.investment_type_id} selected_templates={this.selected_templates} isParcel={this.isParcel} isSale={this.isSale} />);
    this.rightPanel.push(<Templates documentsData={this.asset_signing_document} descriptionData={[]} ownerId={this.ownerInfo} assetId={this.asset_Id} editable={this.isOwner} projectStatus={this.projectStatus} investment_type_id={this.investment_type_id} selected_templates={this.selected_templates} isParcel={this.isParcel} isSale={this.isSale} />);
  };

}
