// withdrawAmountSPVDebt.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CAPITAL_TYPE_ID } from "../../constants";

/**
 * Call the withdrawAmount method to transfer the parcel to investor and money to proposer
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function withdrawAmountSPVDebt(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {

        const { request: withdrawalAmount } = await prepareWriteContract({
            address: CONTRACTS.SPV_DEBT_OFFER_ADDRESS,
            abi: CONTRACT_ABI[CONTRACTS.SPV_DEBT_OFFER_ADDRESS],
            account: address,
            chainId: 6805,
            functionName: "withdrawalAmount",
            args: [
                assetData?.listing_id, // listing id / ipfs code id Integer
                assetData?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1
            ]
        })

        const { hash } = await writeContract(withdrawalAmount)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(2)
                setTimeout(() => {
                    handleSuccess(assetData)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error in calling withdraw amount spv debt:', error);
        throw error; // propagate the error
    }
}

export { withdrawAmountSPVDebt };