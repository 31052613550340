import React, { useState, useEffect } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ListCard from "../Components/ListCard/ListCard";
import { User } from "../../Components/Images/Images";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import "./CompanyProfile.css";
import { Assignment } from "@mui/icons-material";
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';



function RightProjectProfileSection({ profileData }) {

  const [profileVal, setProfileVal] = useState(profileData)
  const [language, setLanuage] = useState([])
  const [organizationVal, setOrganizationVal] = useState([])
  const [experienceDetails, setExperienceDetail] = useState([])

  /**
 * Setting Languages for the Left side Bar section
 */
  useEffect(() => {
    if (profileData) {
      const profileLanguage = profileData?.language ? JSON.parse(profileData?.language) : null;

      // Map languages and proficiencies, then join them with "|"
      const languageSelected = profileLanguage && profileLanguage.map((val) => {
        return `${val?.language}:${val?.proficiency}`;
      })

      setLanuage(languageSelected);


      // Setting associated Organization
      const associatedOrganization = profileData?.associated_organization ? JSON.parse(profileData?.associated_organization) : null;

      // Map languages and proficiencies, then join them with "|"
      const organization = associatedOrganization && associatedOrganization.map((val) => {

        return `${val?.associated_organization}`;
      }).join(" | ");

      setOrganizationVal(associatedOrganization);

      // Filter experienceData to get the object where currentlyWorking is true
      const currentExperience = profileData && profileData?.experienceProfile
        ?.filter(experience => experience.currentlyWorking);
      setExperienceDetail(currentExperience);
    }
  }, [profileData]);


  return (
    <Box p={3} className="right-project-profile">
      <Box className="top-view border-top">
        <Box>
          <Typography className="font-32">0</Typography>
          <Typography className="font-14 text-blueGray-400 ">Total Jobs</Typography>
        </Box>
        <Box>
          <Typography className="font-32">0,000</Typography>
          <Typography className="font-14 text-blueGray-400 ">Total Hours</Typography>
        </Box>
      </Box>

      <Box className="top-view" mt={2}>
        <Box>
          <Typography className="font-12 title">Availability</Typography>
          <Typography className="font-16">{profileData && profileData?.availability === "open" ? "open to offer" : profileData?.availability}</Typography>
        </Box>
        <Box>
          <EditIcon />
        </Box>
      </Box>
      <Box className="top-view" mt={2}>
        <Box>
          <Typography className="font-12 title">Languages</Typography>
          <Typography className="font-16">{language ? language : null}</Typography>
        </Box>
        <Box>
          <EditIcon />
        </Box>
      </Box>
      <Box className="top-view" mt={2}>
        <Box>
          <Typography className="font-16">Education</Typography>
        </Box>
        <Box>
          <EditIcon />
        </Box>
      </Box>
      <Box mt={2} ml={2}>

        {profileData?.educationProfile && profileData?.educationProfile?.length > 0 ? (
          profileData?.educationProfile.map((val, index) => {
            const startDate = new Date(val.startDate);
            const endDate = new Date(val.endDate);

            const startYear = startDate.getFullYear();
            const endYear = endDate.getFullYear();

            return (
              <Box key={index}>
                <Typography variant="subtitle2" className="font-12" mb={2}>
                  {val.school}
                </Typography>
                <Typography variant="subtitle1" className="font-12 text-blueGray-500" mb={2}>
                  {val.fieldOfStudy} <br />
                  {startYear}-{endYear}
                </Typography>
              </Box>
            );
          })
        ) : (
          <Typography variant="subtitle1" className="font-12">
            No education details available.
          </Typography>
        )}
      </Box>

      {organizationVal?.length ? (
        <>
          <Box className="top-view" mt={2}>
            <Box>
              <Typography className="font-16">Associated with</Typography>
            </Box>
          </Box>

          <Box ml={-2} mt={1}>
            {organizationVal?.length > 0 ? organizationVal?.map((val, ind) => {
              return <ListCard key={ind} listIcon={<ApartmentOutlinedIcon />} bgColor={'#fff'} iconColor="#398CD1" type={'icon'} title={val?.associated_organization} subtitle1={""} subtitle1Color='#398CD1' />
            })
              : "No Orgainzation Available"
            }
         </Box>
        </>
      ) : null}

      {profileData?.documents?.length ? (
        <>
          <Box className="top-view" mt={2}>
            <Box>
              <Typography className="font-16">Documents</Typography>
            </Box>
          </Box>
          <Box ml={-2} mt={1}>
            {profileData?.documents?.length > 0 ? profileData?.documents.map((val, ind) => {
              return <ListCard key={ind} listIcon={<Assignment />} bgColor={'#fff'} iconColor="#398CD1" type={'icon'} title={val.original_name} subtitle1={val.title} subtitle1Color='#398CD1' />
            })
              : "No Documents Available"
            }
          </Box>

        </>
      ) : null}
    </Box>
  );
}

export default RightProjectProfileSection;

function Section({ children, title, icon }) {
  return (
    <Box>
      <Stack direction="row" justifyContent={"space-between"}>
        <Typography
          variant="subtitle1"
          fontWeight={"500"}
          className="font-16"
          mb={2}
        >
          {title}
        </Typography>
        {icon ? <InfoIcon className="info-icon" /> : null}
      </Stack>
      <Box p={2}>{children}</Box>
    </Box>
  );
}
