import { Avatar, Box, Chip, Stack, Typography } from "@mui/material";
import React from "react";
import "./ProfileCard.css";
import StarsIcon from '@mui/icons-material/Stars';
import {
  ServiceProviderPhoto,
  UserBorder,
  UserPhotos,
  NFT
} from "../../../Components/Images/Images";

const ProfileCard = (props) => {
  return (
    <Box style={{ backgroundColor: "var(--blue-900)", borderRadius: "24px" ,padding:"20px"}}>
    
     <Box className="user-img-wrap">
        <Box className="user-img-div">
            <img src={UserPhotos} alt="" />
        </Box>
     </Box>
      <Box className="profile-card-content">
        <Typography className="profile-name">
          {props?.profile_name
            ? props?.profile_name
            : `${props.first_name}  ${props.last_name}`}
        </Typography>
        <Typography className="profile-profession">
          {props?.profile_profession
            ? props?.profile_profession
            : props.title && props.title}
        </Typography>
        <Typography className="profile-nation">
          {props?.profile_nation ? props?.profile_nation : props.address}
        </Typography>
        {/* <Typography className="united-text">{props.address ? props.address :props.address }</Typography>  */}
      </Box>
      <Box direction="row" display={"flex"} justifyContent={"center"} alignItems="center">
        <Box direction="row" display={"flex"} alignItems="center">
        <StarsIcon/>
        <Typography ml={2}>4.9</Typography>
        </Box>
        <Box direction="row" ml={4}>
          <Typography>$100/hr</Typography>
        </Box>
      </Box>
      <Box className="pc-chip-wrap" pb={3} mt={3}>
        <Stack
          direction="row"
          flexWrap={"wrap"}
          display={"flex"}
          spacing={1}
          justifyContent={"center"}
        >
          <Chip
            className="pc-chip"
            label={props?.rated ? props?.rated : "Top-Rated"}
          />
          <Chip
            className="pc-chip"
            label={props?.success ? props?.success : "100% Success"}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default ProfileCard;
