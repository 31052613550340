import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import { Box, Tab, Tabs, Grid, Typography, InputBase } from "@mui/material";

// import custom components
import PendingDocumentsPanel from "./PendingDocumentsPanel";
import ReportsPanel from "./ReportsPanel";
import "../Transactions/Transactions.css";
import { Search } from "@mui/icons-material";

/**
 * TabPanel functional component that conditionally renders its children based on the active tab.
 * @param {Object} props - The properties passed to the component.
 * @param {ReactNode} props.children - The content to be rendered within the TabPanel.
 * @param {number} props.value - The current active tab index.
 * @param {number} props.index - The index of the specific TabPanel.
 * @param {Object} other - Additional properties spread onto the component.
 * @returns {JSX.Element} - The rendered TabPanel component.
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

/**
 * Helper function to generate accessibility properties for tabs.
 * @param {number} index - The index of the tab.
 * @returns {Object} - Accessibility properties for the tab.
 */
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function Documents() {
  // Importing 'useTheme' hook from the Material-UI library
  const theme = useTheme();

  // State to manage the current tab
  const [value, setValue] = useState(0);

  /**
   * Function to handle changes in the tab, triggered by the TabPanel
   * @param {Number} newValue - index value of tab panel
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * Function to handle changes in the index, used for tab navigation
   * @param {Number} index
   */
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Box className="document-main-div">
        <Grid className="transactions-list-head" item xs={10} sm={10} md={10}>
          <Box className="my-document-container">
            <Box className="heading-left">
              <Typography component={"h6"} ml={2}>
                My Documents
              </Typography>
            </Box>
            <Box className="filters-group-wrap">
              {/* Search bar filter */}
              <Box className="filterSearch-form">
                <Box component={"span"} className="search-icon">
                  <Search />
                </Box>
                <InputBase
                  // ref={searchBarRef}
                  className="filterSearch"
                  placeholder={"Search documents"}
                  value=""
                  inputProps={{ "aria-label": "search in transactions" }}
                />
              </Box>
            </Box>
          </Box>
          <Box className="transactions-list-head-inside">
            <Box className="tab-div">
              <Tabs
                onChange={handleChange}
                value={value}
                aria-label="Tabs where each tab needs to be selected manually"
                sx={{ overflowX: "auto" }}
              >
                <Tab
                  label="Completed Documents"
                  className={
                    value === 0
                      ? "tabs transactions-active-button"
                      : " tabs transactions-inactive-button"
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label="Reports"
                  className={
                    value === 1
                      ? "tabs transactions-active-button"
                      : " tabs transactions-inactive-button"
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  label="Tax Documents"
                  className={
                    value === 2
                      ? "tabs transactions-active-button"
                      : " tabs transactions-inactive-button"
                  }
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0}>
                <PendingDocumentsPanel />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ReportsPanel />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ReportsPanel />
              </TabPanel>
            </SwipeableViews>
          </Box>
        </Grid>
      </Box>
    </>
  );
}
