import React, { useState } from 'react'
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from '@mui/material';
import Header from "../Header/HeaderServiceProposer";
import SideBar from '../../ServiceProvider/ClientDashboard/DashboardSideBar/SideBar';
import '../../ServiceProvider/ServiceProvider.css'

function LayoutServiceProposerDashboard() {


   // State variable for the sidebar open state
   const [sidebarOpen, setSidebarOpen] = useState(false);

   // Function to handle the change in the sidebar state
   const handleSidebarChange = (newState) => {
      // Handle the state change in the sidebar
      setSidebarOpen(newState);
   };


   return (
      <>
         <Box className='main-layout serviceprovider'>
            <Box className="dashborad-header serviceprovider" >
               <Header />
            </Box>
            <Box className='main-page' display={'flex'} pt={10}  >
               <SideBar handleSidebarChange={handleSidebarChange} />
               <Box className='main-contant serviceprovider'>
                  <Outlet context={sidebarOpen} />
               </Box>
            </Box>
         </Box>
      </>
   )
}

export default LayoutServiceProposerDashboard