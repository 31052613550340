import React, { useState } from "react";
import { Box, Typography, Button, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { Upload } from "@mui/icons-material";
import "./AddPortfolioPopup.css"

const AddPortfolioPopup = ({ open, handleClose, onSave }) => {
    const [formValues, setFormValues] = useState({
        title: "",
        image: null,
    });
    const [errors, setErrors] = useState({
        title: "",
        image: "",
    });

    const [AdditonalDetailsLocal, setAdditionalDetailsLocal] = useState([]); // state to store
    const localData = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : "";

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image") {
            setFormValues({
                ...formValues,
                [name]: files[0],
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
        setErrors({
            ...errors,
            [name]: "",
        });
    };

    const validateForm = () => {
        let isValid = true;
        // Your validation logic goes here
        // For example:
        if (formValues.title.trim() === "") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: "Title is required",
            }));
            isValid = false;
        }
        if (!formValues.image) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                image: "Image is required",
            }));
            isValid = false;
        }
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            // Update the educationDetailsLocal state with the new form values directly
            setAdditionalDetailsLocal([...AdditonalDetailsLocal, formValues]);

            // Call the onSave function passed from the parent component
            const formDataWithUserId = {
                ...formValues,
                user_id: localData && localData.id,
            };
            console.log("formDataWithUserId...",formDataWithUserId)
            // onSave(formDataWithUserId);

            onSave({ portfolio_title: formValues.title, portfolio_image: formValues.image , user_id : localData && localData.id,});
            setFormValues({
                title: "",
                image: null,
            });
            handleClose();
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-main"
        >
            <Box className="popup-main">
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Box className="close-icon-main cursor-pointer">
                            <CloseIcon  onClick={handleClose} />
                        </Box>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            mt={2}
                            className="heading font-32"
                            sx={{ textAlign: "center" }}
                        >
                            Add Portfolio
                        </Typography>
                        <Box mt={3}>
                            <TextField
                                name="title"
                                value={formValues.title}
                                onChange={handleChange}
                                className="first-name"
                                label="Portfolio Title"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                placeholder="E.g. Copywriter"
                                error={!!errors.title}
                                helperText={errors.title}
                            />
                        </Box>
                        <Box mt={3}>
                            <Box className="upload-section">
                                <input
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="upload-button"
                                    name="image"
                                    type="file"
                                    onChange={handleChange}
                                />
                               
                                <Box  mt={2}>
                                {formValues.image && (
                                    <img
                                        src={URL.createObjectURL(formValues.image)}
                                        alt="Uploaded"
                                        className="uploaded-image"
                                    />
                                )}
                                </Box>
                                <label htmlFor="upload-button" className="upload-img-btn">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<Upload />}
                                        className="upload-img"
                                    >
                                        Upload Image
                                    </Button>
                                </label>
                                <br/>
                                {errors.image && (
                                    <Typography variant="caption" className="font 12" color="error">
                                        {errors.image}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ textAlign: "center" }} mt={3}>
                            <Button
                                type="submit"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                sx={{
                                    background: "var(--golden-gradient)",
                                    color: "var(--blueGray-900)",
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>

            </Box>
        </Modal>
    );
};

export default AddPortfolioPopup;
