import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Modal,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./AddEducationPopup.css";

const AddEducationPopup = ({ open, handleClose, onSave }) => {
  const [formData, setFormData] = useState({
    school: "",
    degree: "",
    fieldOfStudy: "",
    startDate: "",
    endDate: "",
    description: "",
  });
  const [textCount, setTextCount] = useState(["5000"]);
  const [errors, setErrors] = useState({});
  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : "";

  useEffect(() => {
    setErrors("");
  }, [handleClose]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

    let updatedValue = value;
    if (name === "description") {
      // Check if description length is within the limit
      if (value.length > 5000) {
        updatedValue = value.substring(0, 5000); // Trim the description to 5000 characters
      }
      setTextCount(5000 - updatedValue.length); // Update character count
    }
    setFormData({
      ...formData,
      [name]: updatedValue,
    });

    // Remove error when user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};

    // Validate school field
    if (!formData.school.trim()) {
      errors.school = "School is required";
    }

    // Validate degree field (if required)
    // For example, if degree is required:
    if (!formData.degree.trim()) {
      errors.degree = "Degree is required";
    }

    // Validate fieldOfStudy field (if required)
    // For example, if fieldOfStudy is required:
    if (!formData.fieldOfStudy.trim()) {
      errors.fieldOfStudy = "Field of study is required";
    }

    // Validate start date field
    if (!formData.startDate) {
      errors.startDate = "Start date is required";
    }

    // Validate end date field
    if (!formData.endDate) {
      errors.endDate = "End date is required";
    }

    // Validate description field (if required)
    // For example, if description is required:
    if (!formData.description.trim()) {
      errors.description = "Description is required";
    }

    if (formData?.description?.length < 500) {
      errors.description =
        "Please enter a description of at least 500 characters.";
    }

    // Set errors state with validation results
    setErrors(errors);

    // If there are no errors, proceed with onSave and handleClose
    if (Object.keys(errors).length === 0) {
      // Add user_id to formData
      const formDataWithUserId = {
        ...formData,
        user_id: localData && localData.id,
      };
      onSave(formDataWithUserId); // Call onSave with formData
      handleClose(); // Close the modal
    }
  };

  return (
    <Box className="add-education-popup-main">
      <Button onClick={handleClose}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-main"
      >
        <Box
          id="education-scroller-wrapper"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            borderRadius: "24px",
            width: "900px",
            padding: "52px",

            background: "var(--blueGray-900)",
            boxShadow:
              "0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)",
            // Media query for small devices
            "@media (max-width: 600px)": {
              width: "350px", // Set a different height for small screens
            },
            // Additional media queries for other screen sizes if needed
            "@media (min-width: 601px) and (max-width: 900px)": {
              // Custom styles for devices between 601px and 900px width
              width: "580px",
            },
            "@media (min-width: 901px) and (max-width: 1200px)": {
              // Custom styles for devices between 901px and 1200px width
              width: "750px",
              maxHeight: "300px",
              overflow: "auto",
            },
            // Specify the maximum width for large screens
            "@media (min-width: 1201px)": {
              maxWidth: "1043px",
              maxHeight: "525px",
              overflow: "auto",
            },
          }}
        >
          <Box className="close-icon-main cursor-pointer">
            <CloseIcon  onClick={handleClose} />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mt={2}
            className="heading font-32"
            sx={{ textAlign: "center" }}
          >
            Add Education History
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box mt={3}>
              <TextField
                id="school"
                name="school"
                label="School*"
                variant="outlined"
                fullWidth
                value={formData.school}
                onChange={handleChange}
                error={!!errors.school}
                helperText={errors.school ? errors.school : ""}
              />
            </Box>
            <Box mt={3}>
              <TextField
                id="degree"
                name="degree"
                label="Degree"
                variant="outlined"
                fullWidth
                value={formData.degree}
                onChange={handleChange}
                error={!!errors.degree}
                helperText={errors.degree ? errors.degree : ""}
              />
            </Box>
            <Box mt={3}>
              <TextField
                id="fieldOfStudy"
                name="fieldOfStudy"
                label="Field to Study"
                variant="outlined"
                fullWidth
                value={formData.fieldOfStudy}
                onChange={handleChange}
                error={!!errors.fieldOfStudy}
                helperText={errors.fieldOfStudy ? errors.fieldOfStudy : ""}
              />
            </Box>
            <Typography className="font-18" mt={2}>
              Dates Attended
            </Typography>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box className="DatePicker SPAddEducation">
                    <TextField
                      id="startDate"
                      name="startDate"
                      label="From"
                      type="date"
                      value={formData.startDate}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: formData.endDate,
                        placeholder: "Select one from the dropdown below",
                      }}
                      error={!!errors.startDate}
                      helperText={errors.startDate ? errors.startDate : ""}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className="DatePicker SPAddEducation">
                    <TextField
                      id="endDate"
                      name="endDate"
                      label="To (or expected graduation year)"
                      type="date"
                      value={formData.endDate}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: formData.startDate,
                        placeholder: "Select one from the dropdown below",
                      }}
                      error={!!errors.endDate}
                      helperText={errors.endDate ? errors.endDate : ""}
                      sx={{ height: "100%" }} // Set height for consistency
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Typography className="font-18" mt={2}>
              Description
            </Typography>
            <Box mt={2}>
              <TextField
                id="description"
                name="description"
                label="Description"
                variant="outlined"
                placeholder="Describe your studies, awards, etc."
                fullWidth
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange}
                style={{ minHeight: "80px" }}
                error={!!errors.description}
                helperText={errors.description ? errors.description : ""}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {!errors.description ? (
                <Typography
                  paddingLeft={"32px"}
                  paddingTop={"3px"}
                  className="font-12"
                >
                  {" "}
                  Enter at least 500 characters
                </Typography>
              ) : (
                <Typography
                  paddingLeft={"32px"}
                  paddingTop={"3px"}
                  className="font-12"
                ></Typography>
              )}
              <Typography
                paddingRight={"24px"}
                paddingTop={"3px"}
                className="font-12"
              >
                {" "}
                {textCount} characters left
              </Typography>
            </Box>
            <Box mt={3} className="btn-container">
              <Stack spacing={2} direction="row" justifyContent="center">
                {/* <Button variant="outlined" className='cancel-btn' onClick={handleClose}>Cancel</Button> */}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "var(--golden-gradient)",
                    color: "var(--blueGray-900)",
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddEducationPopup;
