import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./ServiceProviderDashboard.css";
import { Grid, Typography, Stack ,IconButton} from "@mui/material";
import SkillChip from "../Components/Chips/SkillChip/SkillChip";
import CloseIcon from "@mui/icons-material/Close";
const SavedRequestDrawer = ({ open ,onClose }) => {
  const [previousStepData, setPreviousStepData] = useState([]);
  const [openDrawer ,setOpenDrawer]= useState()


  const [state, setState] = useState({
    right: false,
  });

  useEffect(() => {
    // setState({ ...state, [anchor]: open });
    if(open){
      setOpenDrawer(open)
    }
  }, [open]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 650,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="save-req-draw"
      id="scroller-wrapper"
    >
      <Box className="top-view">
        <Box>
    
          <IconButton
            aria-label="delete"
            variant="contained"
            className="btn-golden btn-rounded btn-large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Button
            variant="contained"
            startIcon={<RemoveRedEyeIcon />}
            className="btn-golden btn-rounded btn-large"
          >
            View Full Request
          </Button>
        </Box>
      </Box>

      <Box className="real-estate-container">
        <Typography className="font-28 text-white">
          Real Estate Audit for Houston Project
        </Typography>
        <Typography className="font-14 text-white">
          Search more Auditing service requests | Posted 21 hours ago
        </Typography>
        <Button
          className="btn-blue-500 btn-rounded btn-large submit-app-btn"
          variant="contained"
          startIcon={<ArrowForwardIcon />}
        >
          Submit application for this request
        </Button>
        <Typography className="text-white font-14" mt={2}>
          Stiamo cercando un montatore video e foto che parli fluentemente
          italiano per unirsi al nostro team di allenatori di fitness. Sarai
          fondamentale nella definizione dell'identità visiva del nostro
          marchio.Competenze RichiesteAdobe Premiere ProCapcutAdobe After
          Effects (preferibile)Adobe PhotoshopAdobe LightroomUlteriori
          competenze software sono un plus.Dettagli PartnershipMiremo a una
          relazione a lungo termine. La remunerazione è fissa e scalabile,
          basata sulle tue prestazioni e contributi.ResponsabilitàMontare video
          brevi e lunghi, e fotoMigliorare il nostro stile graficoVari compiti
          di editing aggiuntiviNota: Apprezziamo molto i membri del team che
          possono portare nuove prospettive al nostro progetto e sono
          interessati al suo successo. Coloro che contribuiscono attivamente
          saranno ricompensati sia finanziariamente che con opportunità di
          avanzamento di carriera.Consegne Iniziali4 reels/TikToks/shorts
          settimanali2 foto modificate settimanali1 video lungo settimanale
          (vedi nota)Nota:Le consegne sopra indicate servono come requisiti di
          base generali per questo periodo iniziale. Valorizziamo la
          flessibilità e l'adattabilità. Queste liste di compiti possono essere
          adattate in base alle esigenze variabili del progetto. Ad esempio, un
          video lungo potrebbe sostituire un determinato numero di reels o
          viceversa. State certi che, in caso di un significativo aumento del
          carico di lavoro, vi sarà un adeguamento appropriato della
          remunerazione.Nota Aggiuntiva sui Video Lunghi:Sebbene attualmente non
          stiamo producendo video lunghi, saranno una parte integrante della
          nostra strategia di contenuto in futuro. L'inclusione dei video lunghi
          nell'elenco è intesa per impostare le aspettative per i lavori futuri.
          Al momento, stiamo offrendo una remunerazione che tiene conto di
          questa futura responsabilità, beneficiandovi effettivamente nel breve
          termine per compiti futuri.
        </Typography>
      </Box>
      <Box className="categoryCard" mt={2}>
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={4}>
            <Box className="category-card">
              {previousStepData.length && (
                <img
                  className="Icon"
                  src={`${process.env.REACT_APP_IMAGE_URL}${
                    previousStepData &&
                    previousStepData?.sp_media_tables[0]?.path
                  }`}
                />
              )}
              <Box pt={3}>
                <Typography className="Heading font-24">
                  ${previousStepData && previousStepData?.budget_to}
                </Typography>
                <Typography className="SubHeading  font-18">
                  Fixed-prices
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={4}>
            <Box className="category-card">
              {previousStepData.length && (
                <img
                  className="Icon"
                  src={`${process.env.REACT_APP_IMAGE_URL}${
                    previousStepData &&
                    previousStepData?.sp_media_tables[0]?.path
                  }`}
                />
              )}
              <Box pt={3}>
                <Typography className="Heading font-24">
                  {previousStepData && previousStepData?.level_of_experience}
                </Typography>
                <Typography className="SubHeading  font-18">
                  Experience Level
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={4}>
            <Box className="category-card">
              {previousStepData.length && (
                <img
                  className="Icon"
                  src={`${process.env.REACT_APP_IMAGE_URL}${
                    previousStepData &&
                    previousStepData?.sp_media_tables[0]?.path
                  }`}
                />
              )}
              <Box pt={3}>
                <Typography className="Heading font-24">Ongoing</Typography>
                <Typography className="SubHeading  font-18">
                  Project Type
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="comapany-profile-skills" mt={3}>
        <Typography component="h4" className="title font-18">
          Skills & Expertise
        </Typography>

        <Box mt={2}>
          <Box className="skill-section">
         
          
            <Grid container spacing={2} mt={1}>
            
            {previousStepData?.skillData?.map((item, index) => {
                  return <Grid xs="auto" ml={2} > <SkillChip key={index} label={item.skill_name} />  </Grid>;
                })}
               
            </Grid>
         
          </Box>
        </Box>
      </Box>
    </Box>
  );
  return (
    <Box className="save-request-drawer-main">
      <div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
            <Drawer
              anchor={anchor}
              open={openDrawer}
              onClose={onClose}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </Box>
  );
};

export default SavedRequestDrawer;
