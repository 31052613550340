import React, { useEffect, useState, useMemo } from 'react'
import { GetApi, postApi, GetApiParam } from '../../Api/Api';
import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, Typography, Chip, Button } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Bookmarks from '@mui/icons-material/Bookmarks';
import { Link, useNavigate } from 'react-router-dom';
import SavedRequestDrawer from './SavedRequestDrawer';

const SavedRequests = () => {

  const [projectServiceDetails, setProjectServiceDetails] = useState([]);
  const [expandedStates, setExpandedStates] = useState([]);
  const [bookmark, setBookmark] = useState() // for Bookmark
  const [drawerState, setDrawerState] = useState(false)

  const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""
  const navigate = useNavigate()

  useEffect(() => {
    if (localData?.id) {
      // Api to get InvestmentTypeData
      async function getBookmarkData() {
        try {
          const res = await GetApi(`/services/getBookmarkProjects?user_id=${localData.id}`);
          setBookmark(res?.data?.data ? res?.data?.data : [])
          setProjectServiceDetails(res?.data?.data ? res?.data?.data : [])

        } catch (error) {
          console.log(error);
        }
      }
      getBookmarkData()
    }
  }, [])

  // functionality for Bookmark
  const bookmarkVal = (asset_id) => {
    return bookmark?.some((item) => item.asset_id === asset_id && item.status === 1);
  }



  // const serviceProviderData = async()=>{
  //   try {

  //     const projectRes = await GetApi("/services/getBookmarkProjects");
  //       setProjectServiceDetails(projectRes?.data?.data || []);

  //       // Initialize expanded state for each card
  //       setExpandedStates(new Array(projectRes?.data?.data.length).fill(false));
  //     } catch (error) {
  //       console.log(error);
  //     }
  // } 

  // useEffect(()=>{
  //   serviceProviderData()
  // },[])

  const handleViewMore = (ind) => {
    setExpandedStates(prevStates => {
      const newStates = [...prevStates];
      newStates[ind] = true;
      return newStates;
    });
  };

  const handleViewLess = (ind) => {
    setExpandedStates(prevStates => {
      const newStates = [...prevStates];
      newStates[ind] = false;
      return newStates;
    });
  };

  /**
   * Onclick function  to change the bookmark condition
   * @param {*} item 
   */
  const handleClickBookmark = async (item) => {
    const payloadData = {
      "project_id": item.id,
      "user_id": localData.id,
      "status": item.is_bookmark ? 0 : 1
    }

    if (localData.id) {
      const bookmarkResponce = await postApi("/services/isBookmark", payloadData)

      //  if(bookmarkResponce?.data?.code === 200){
      //   serviceProviderData()
      //  }
    }

  }


  const handleDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };


  return (
    <Box>
      <Grid container spacing={2}>
        <Box>
          <Typography className='font-28' ml={2}></Typography>
        </Box>
        <Grid item xs={12} md={12}>
          <Box className="brower-request-right">
            <Grid container spacing={2}>
              {projectServiceDetails?.map((serviceVal, ind) => (
                <Grid item xs={6} key={ind}>
                  <Box className="card-box">
                    <Box className="top-header">
                      <Box className="top-left">
                        <Box className="pc-chip-wrep-browse">
                          <Chip className="pc-chip" label={"New"} />
                        </Box>
                        <Box className="top-sub-left">
                          <Typography className='font-16' >{serviceVal?.project_title}</Typography>
                          <Typography className='font-12'>Fixed-price | Posted 21 hours ago</Typography>
                        </Box>
                      </Box>
                      {serviceVal && serviceVal?.status ?
                        <Box className="top-right">
                          <Bookmarks className='bookmark-icon' onClick={() => handleClickBookmark(serviceVal)} />
                        </Box>
                        : <Box className="top-right">
                          <BookmarkBorderIcon className='bookmark-icon' onClick={() => handleClickBookmark(serviceVal)} />
                        </Box>
                      }
                    </Box>
                    <Box className="card-body">
                      <Box className="card-body-top">
                        <Box className="left-section">
                          <Typography className='title font-12'>{serviceVal?.budget === "Project Budget" ? "Fixed-price" : serviceVal?.budget}</Typography>
                          <Typography component="p" className='sub-title font-16'>${serviceVal?.budget_from}</Typography>
                        </Box>
                        <Box className="right-section">
                          <Typography component="h4" className='title font-12'>Experience Level</Typography>
                          <Typography component="p" className='sub-title font-16'>{serviceVal?.level_of_experience}</Typography>
                        </Box>
                      </Box>

                      <Box className="card-body-discription">
                        {expandedStates[ind] ? (
                          <>
                            <Typography component="p" className='title font-14'>{serviceVal?.describe_services}</Typography>
                            <Link onClick={() => handleViewLess(ind)}>
                              <Typography variant="h6" sx={{
                                color: '#398CD1', fontSize: '14px', fontWeight: 700, textDecoration: "underline",
                                textDecorationColor: "#398CD1"
                              }} mt={1}>
                                View Less
                              </Typography>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Typography component="p" className='title font-14' sx={{
                              mt: '12px', color: '#FFFFFF', overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "2",
                              WebkitBoxOrient: "vertical",
                            }}>{serviceVal?.describe_services}</Typography>
                            <Link onClick={() => handleViewMore(ind)}>
                              <Typography variant="h6" sx={{
                                color: '#398CD1', fontSize: '14px', fontWeight: 700, textDecoration: "underline",
                                textDecorationColor: "#398CD1"
                              }} mt={1}>
                                View More
                              </Typography>
                            </Link>
                          </>
                        )}
                      </Box>


                      <Box className="button-list">
                        {serviceVal?.skillData?.map((skillVal, index) => (
                          <Button key={index}>
                            {skillVal.skill_name}
                          </Button>
                        ))}
                      </Box>
                      <Box className="see-more-btn">
                        <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleDrawer()} >
                          See more
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {drawerState ? <Box>
        <SavedRequestDrawer open={drawerState} onClose={handleCloseDrawer} />
      </Box> : null}
    </Box>
  )
}

export default SavedRequests