import React, { useEffect, useState } from 'react'
import ProfileDescription from '../Components/ProfileDescription/ProfileDescription'
import ServiceAccordian from '../Components/ServicesAccordian/ServiceAccordian'
import { Box, Grid, Stack, Typography } from '@mui/material'
import PortfolioCard from '../Components/PortfolioCard/PortfolioCard'
import SkillChip from '../Components/Chips/SkillChip/SkillChip'
import { HomeImage, IndividualProfilePersona } from '../../Components/Images/Images'
import WorkHistorySection from '../Components/WorkHistorySection/WorkHistorySection'
import Cards from '../Components/MainCard/MainCard'
import { LocalFireDepartment } from '@mui/icons-material'
import { GetApi } from '../../Api/Api'
import MainCard from '../Components/MainCard/MainCard'
import Pagination from '@mui/material/Pagination';


function LeftPersonProfileSection({ profileData }) {

    const [userIndividualProfile, setUserIndividualProfile] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 3; // Number of items per page


    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (profileData)
            setUserIndividualProfile(profileData)
    }, [profileData])

    console.log("profileData...", profileData)

    return (
        <>
            <Box >
                {/* Description paper */}
                <Box className="comapany-profile-skills main-div">
                    <ProfileDescription
                        name={userIndividualProfile ? `${userIndividualProfile?.first_name ? userIndividualProfile?.first_name : null} ${userIndividualProfile?.last_name ? userIndividualProfile?.last_name : null}` : null}
                        address={userIndividualProfile?.address ? userIndividualProfile?.address : null}
                        title={userIndividualProfile && userIndividualProfile?.title}
                        description={userIndividualProfile && userIndividualProfile?.description}
                        persona={userIndividualProfile && userIndividualProfile?.profilePicture
                            ?.length > 0 ? userIndividualProfile?.profilePicture[0]?.path : IndividualProfilePersona}
                        hourlyrate={userIndividualProfile && userIndividualProfile?.hourly_rate}
                        user_id={userIndividualProfile && userIndividualProfile?.user_id}
                    />
                </Box>

                <Box className="comapany-profile-skills">
                    {/* Services tab company */}
                    <SingleSection sectionTitle="Services">
                        <ServicesSection />
                    </SingleSection>
                </Box>

                {/* Work History */}
                <Box className="comapany-profile-skills">
                    <SingleSection sectionTitle="Work History">
                        <WorkHistorySection />
                    </SingleSection>
                </Box>

                {/* Portfolio section*/}
                <Box className="comapany-profile-skills">
                    <Typography variant="subtitle1" className="font-18 font-readexpro" ml={"16px"} mb={3} pt={2}>
                        Portfolio
                    </Typography>
                    <Box mx={3}>
                        <Grid container spacing={1}>
                            {profileData?.portfolioData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item, index) => (
                                <Grid item key={index} md={4} >
                                    <PortfolioCard
                                        image={item.portfolioImages.path}
                                        title={item.portfolio_title}
                                        description={item.portfolio_description}
                                        link={item.portfolio_link}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box className="pagination-container">
                        <Pagination
                            count={Math.ceil(profileData?.portfolioData?.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                        />
                    </Box>
                </Box>
                {/* Skills Section Individual */}
                <Box className="comapany-profile-skills">
                    <SingleSection sectionTitle="Skills">
                        <Box >
                            <Stack direction="row" spacing={2}>
                                {
                                    profileData?.skillData
                                        ?.length ? userIndividualProfile?.skillData?.map((item, index) => {
                                            return (
                                                <SkillChip key={index} label={item.skill_name} />
                                            )
                                        })
                                        :
                                        "No Skill Available"
                                }
                            </Stack>
                        </Box>
                    </SingleSection>
                </Box>

            </Box>
        </>
    )
}

export default LeftPersonProfileSection

function SingleSection({ children, sectionTitle }) {
    return (
        <Box p={'24px'}>
            <Typography variant="subtitle1" className="font-18 font-readexpro" ml={'16px'} mb={3}>{sectionTitle}</Typography>
            {children}
        </Box>
    )
}

function ServicesSection() {
    const [serviceData, setServicesData] = useState([]);

    useEffect(() => {
        // Api to get categoryData
        async function getServicesData() {
            try {
                const res = await GetApi("/services/getServices");
                setServicesData(res?.data?.data?.length ? res.data.data : []);
            } catch (error) {
                console.log(error);
            }
        }
        getServicesData();
    }, [])

    return (
        <Box margin={'10px 0px 0px 16px'}>
            <Grid container spacing={2}>
                {
                    <Grid container spacing={1}>
                        {serviceData?.slice(0, 3).map((card, index) => (
                            <Grid key={index} item md={4} sm={6} xs={12}>
                                <MainCard {...card} bgColor='var(--blue-800)' />
                            </Grid>
                        ))}
                    </Grid>
                }
            </Grid>
        </Box>
    )
}
