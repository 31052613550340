import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

// Import custom components for this page
import { Stripe, Ethereum, NewLogo, } from "../../../Components/Images/Images";
import FiatCryptoCompo from "../../../Components/Common/FiatCryptoCompo";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css";

export default function SelectPaymentType(props) {
  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // Getting current proposal and progress data from the parent component
  var propData = getProposalData();
  let currentProgress = getProgress();

  // Initialize state for the selected payment gateway
  const [selected, changeSelection] = useState("");

  /**
   * Function to set the selected payment gateway in the proposal data.
   * @param {string} data - The selected payment gateway data (crypto or fiat).
   */
  function setSelected(data) {
    // Update proposal data with the selected payment gateway
    propData.preferred_payment_gateway = data;
    // Update it to the main page
    updateProposalData(propData);
    // Update it locally
    changeSelection(data);
  }

  /**
   * Function to handle the "Save and Exit" action.
   * Calls the 'saveProposal' function to save the current proposal data.
   */
  const SaveAndExit = () => {
    saveProposal();
  };

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left">
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  How are you getting paid?
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button
                    onClick={SaveAndExit}
                    className="btn-rounded btn-text-white btn-blue-600"
                    disabled={
                      selected !== "" || propData?.preferred_payment_gateway
                        ? false
                        : true
                    }
                  >
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="qsn-middle-content" sx={{ pt: 4, mb: 5 }} >
                <Box className="quests-right-contents-type">
                  <Box className="heading-wrap">
                    <Typography component="h1" className="title">
                      {propData?.selectedListingType !== "loan"
                        ? "Enter your payment details"
                        : "Make sure your wallet address is correct"}
                    </Typography>
                    <Typography component="p" className="sub-title">
                      {propData?.selectedListingType !== "loan"
                        ? "We accept investments in both fiat and crypto."
                        : "We only accept collateralization in crypto."}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2} className="PaymentFiatCrypto">
                  {false && (
                    <Box
                      my={2}
                      onClick={() => setSelected("fiat")}
                      className={
                        propData?.preferred_payment_gateway === "fiat"
                          ? "selected"
                          : "NSelected"
                      }
                    >
                      <FiatCryptoCompo
                        title="Fiat Payment"
                        subtitle="Get paid directly to your bank account via wire transfer"
                        image={Stripe}
                      />
                    </Box>
                  )}
                  <Box
                    my={2}
                    onClick={() => setSelected("crypto")}
                    className={
                      propData?.preferred_payment_gateway === "crypto"
                        ? "selected"
                        : "NSelected"
                    }
                  >
                    <FiatCryptoCompo
                      title="Crypto Payment"
                      subtitle="Get paid directly to your crypto wallet"
                      image={Ethereum}
                    />
                  </Box>
                </Box>
              </Box>
              <Box mt={2}>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  mt={3}
                >
                  <Box textAlign={"left"} className="quests6-btn-box">
                    <Button
                      className="btn-rounded back-btn"
                      onClick={handleback}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className="quests-btn-box">
                    <Button
                      disabled={
                        selected !== "" || propData?.preferred_payment_gateway
                          ? false
                          : true
                      }
                      onClick={handleNext}
                      endIcon={<KeyboardArrowRightIcon />}
                      className="btn-rounded btn-text-white btn-blue-600"
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
