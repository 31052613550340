import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Box, Grid, Typography, Button, TextField, Stack, IconButton, Avatar } from "@mui/material";
import { KeyboardArrowRight, Upload, VideoLibrary, Cancel, Delete, TextFormat } from '@mui/icons-material';
import MediumEditor from "react-medium-editor";
import "medium-editor/dist/css/medium-editor.css";
import "medium-editor/dist/css/themes/default.css";
import purify from "dompurify";

// Import custom components for this page
import LinearProgressCompo from "./Components/LinearProgressCompo";
import { useSnackbar } from '../../../Contexts/SnackbarContext'
import { CircleCheckIcon, NewLogo, listDownIcon, listUpIcon, unorderedlist, orderedlist, anchor } from '../../../Components/Images/Images'
import "./Components/Questionnaire.css"
import "./Components/AddAssetDescription.css"

export default function AddAssetDescription(props) {
  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress, EditMode } = props;

  // Get current proposal and progress data from the parent component
  let propData = getProposalData()
  let currentProgress = getProgress();

  const { showSnackbar } = useSnackbar();

  const [selectedSec, setSelectedSec] = useState(0)
  // to open section in edited mode
  const [editTitle, setEditTitle] = useState(false);
  // all section data
  const [data, setData] = useState(propData?.asset_description ? propData?.asset_description : []);
  // for new title
  const [addTitle, setAddTitle] = useState("");
  // for new description
  const [addDescription, setAddDescription] = useState("");
  const editorRef = useRef(null);

  const [descriptionLength, setDescriptionLength] = useState(0);

  // set asset new title
  var AssetTitle = ""
  switch (propData?.selectedCategory) {
    case "real-estate":
    case "energy":
    case "collections":
    case "funds":
      AssetTitle = propData?.asset_title
      break
    case "art":
      AssetTitle = propData?.artProjectDetails?.art_name
      break
    case "gold":
      AssetTitle = propData?.goldInformation?.gold_title
      break
    case "watch":
      AssetTitle = propData?.watchInformation?.watch_model
      break
    default:
      AssetTitle = propData?.asset_title
      break
  }

  // upload image section --start
  const [uploadedDocs, setUploadedDocs] = useState(propData?.description_document ? propData?.description_document : [])
  const onDrop = useCallback(acceptedFiles => {
    const pdfFilesArray = acceptedFiles.filter(file => file.type === 'application/pdf');
    let dataDetail = [...uploadedDocs, ...pdfFilesArray]
    setUploadedDocs(dataDetail);
  }, [uploadedDocs])

  const { getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [".pdf"],
    },
    onDrop
  });
  const { getRootProps: getRootProps2, open: open2, getInputProps: getInputProps2, } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      // "image/jpeg": [".jpeg", ".jpg", ".png"],
      "video/mp4": [".mp4"],
    },
    onDrop: (acceptedFiles) => {
      // Filter only images of maximum size 12 MB and videos of maximum size 50 MB

      const imageAndVideoFiles = acceptedFiles.filter(file => (
        (file.type.startsWith("image/") && file.size <= 12000000) || (file.type.startsWith("video/") && file.size <= 50000000)
      ));
      if (imageAndVideoFiles.length !== acceptedFiles.length) {
        showSnackbar("Please upload an image with a maximum size of 12 MB and video with a maximum size of 50 MB.", 'error')
      }
      if (imageAndVideoFiles?.length) {
        let dataDetail = [...uploadedDocs, ...imageAndVideoFiles]
        setUploadedDocs(dataDetail);
      }
    }
  });

  const [deletedDoc, setDeletedDoc] = useState(propData?.description_document_ids ? propData?.description_document_ids : [])
  const [docFiles, setDocFiles] = useState(propData?.description_document_file ? propData?.description_document_file : [])

  /**
   * Function to handle delete image
   * @param {File} file 
   */
  const handleDeleteImage = (file) => {
    if (EditMode && file) {
      const newDocFile = docFiles.filter((docFile) => {
        if (docFile.images == file) {
          setDeletedDoc([...deletedDoc, docFile.id])
        }
        return docFile.images !== file
      })
      setDocFiles(newDocFile);
    }
    const newUploadedImages = uploadedDocs.filter((image) => image.path !== file.path);
    setUploadedDocs(newUploadedImages);
  };

  // rendering uploaded docs
  const files = uploadedDocs?.map((file) => (
    <Box className="showUploadedDocuments" >
      <Box key={file.path} className="showUploadedDocuments-wrap">
        <Box className="fileName">
          {file?.path?.includes("proposer/uploads/") || file?.path?.includes("https://race25-live-uploads.s3.eu-central-2.amazonaws.com/") || file?.path?.includes("https://race25-uploads-docs.s3.eu-central-2.amazonaws.com/") || file?.path?.includes('https://local-racetest-images.s3.eu-central-2.amazonaws.com/') ? file.original_name : file.name}
        </Box>
        <Cancel onClick={() => handleDeleteImage(file)} className="removeIcon" />
      </Box>
    </Box>
  ))
  // upload image section --end

  /**
  * Function to handling new description
  * @param {htmlContent} newContent 
  */
  const handleAddChangeDesc = (newContent) => {
    var htmlContent = newContent;
    let maxTextLength = 500;

    const parsedText = htmlContent.replace(/<[^>]+>|&nbsp;|&amp;/g, ''); // Remove HTML tags

    if (parsedText.length <= maxTextLength) {
      setAddDescription(htmlContent);
      setDescriptionLength(parsedText.length);
    } else {
      const editorElement = document.getElementById('medium-editor-element-id');
      if (editorElement) {
        editorElement.textContent = ''; // Clear the content in the editor directly
      }
      const trimmedText = parsedText.substring(0, 500)
      const trimmedHtml = trimmedText === parsedText ? htmlContent : `${trimmedText}`;
      const sanitizedHTML = purify.sanitize(trimmedHtml);
      editorElement.textContent = sanitizedHTML; // add the content in the editor directly
      setAddDescription(trimmedHtml);
      setDescriptionLength(500);
    }
  };

  /**
   * Function to handle keys enter at the time of add description
   * @param {Event} event 
   */
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (event.shiftKey) {
        // Add a new line to addDescription
        let descWithNewLine = addDescription + "\n";
        setAddDescription(descWithNewLine);
        // event.preventDefault();
      } else {
        // Call addData function
        AddData();
      }
    }
    else if (event.keyCode !== 8 && event.keyCode !== 46 && event.keyCode !== 37 && event.keyCode !== 38 && event.keyCode !== 39 && event.keyCode !== 40 || event.keyCode === 17) {
      if (descriptionLength >= 500) {
        event.preventDefault(); // Prevent further text input when maximum length is reached
      }
    }
  };

  /**
   * Function to handling edited description
   * @param {htmlContent} newContent 
   */
  const handleEditorChangeDesc = (i) => (newContent) => {

    var htmlContent = newContent;
    let maxTextLength = 500;

    const parsedText = htmlContent.replace(/<[^>]+>|&nbsp;|&amp;/g, ''); // Remove HTML tags
    if (parsedText.length === 0) {
      const newState = data.map((obj, index) => {
        if (index === i) { return { ...obj, description: "<p><br></p>" }; }
        return obj;
      });
      setData(newState);// Update the edited content as the user makes changes
    }
    else if (newContent !== "<p><br></p>") {
      if (parsedText.length <= maxTextLength) {
        const newState = data.map((obj, index) => {
          if (index === i) { return { ...obj, description: newContent }; }
          return obj;
        });
        setData(newState);// Update the edited content as the user makes changes
      } else {
        const editorElement = document.getElementById('medium-editor-element-id-edit');
        if (editorElement) {
          editorElement.textContent = ''; // Clear the content in the editor directly
        }
        const trimmedText = parsedText.substring(0, 500)
        const trimmedHtml = trimmedText === parsedText ? htmlContent : `${trimmedText}`;
        const sanitizedHTML = purify.sanitize(trimmedHtml);
        editorElement.textContent = sanitizedHTML; // add the content in the editor directly
        const newState = data.map((obj, index) => {
          if (index === i) { return { ...obj, description: trimmedHtml }; }
          return obj;
        });
        setData(newState);// Update the edited content as the user makes changes
      }
    }
  };

  /**
   * Function to handle clear
   */
  const handleClear = () => {
    setAddDescription("")
    const editorElement = document.getElementById('medium-editor-element-id');
    if (editorElement) {
      editorElement.textContent = ''; // Clear the content in the editor directly
    }
    setDescriptionLength(0)
  }

  /**
   * Function to handle delete
   */
  const handleDelete = (i) => {
    const newState = data.filter((obj, index) => index != i);
    setData(newState);
  }

  /**
  * Function to handle down
  */
  const handleDown = (i) => {
    if (data.length - 1 > i) {
      const newData = [...data];
      let temp1 = newData[i];
      newData[i] = newData[i + 1];
      newData[i + 1] = temp1;
      setData(newData);
    }
  }

  /**
   * Function to handle up
   */
  const handleUp = (i) => {
    if (0 < i) {
      const newData = [...data];
      let temp1 = newData[i];
      newData[i] = newData[i - 1];
      newData[i - 1] = temp1;
      setData(newData);
    }
  }

  // function for rendering all section data (description)
  const renderSection = () => {
    let renderObj = []
    for (let i = 0; i < data?.length; i++) {
      if ((data[i]?.title || data[i]?.description)) {
        renderObj.push(
          <Box className="list-item" key={i}>
            <Box className="editable-form" onClick={() => setEditTitle(i)}  >
              <Box className={editTitle === i ? "form-group title-field" : "form-group title-field read-only"} sx={{ paddingLeft: '16px' }}>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  // defaultValue={data[i]?.title}
                  // InputProps={{
                  //   readOnly: editTitle === i ? false : true,
                  // }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      AddData();
                    }
                    else if (event.keyCode !== 8 &&
                      event.keyCode !== 46 &&
                      event.keyCode !== 37 && event.keyCode !== 38 && event.keyCode !== 39 && event.keyCode !== 40 || event.keyCode === 17) {
                      if (descriptionLength >= 500) {
                        event.preventDefault(); // Prevent further text input when maximum length is reached
                      }
                    }
                  }}
                  value={data[i]?.title}
                  onChange={(e) => {
                    const newState = data.map((obj, index) => {
                      if (index === i) {
                        return { ...obj, title: purify.sanitize(e.target.value) };
                      }
                      return obj;
                    });
                    setData(newState);
                  }
                  }
                // error={data[i]?.title ? false : true}
                // helperText={data[i]?.title ? "" : "Title is required"}
                />
              </Box>
              <Box className={editTitle === i ? "form-group description-field" : "form-group description-field read-only"}>
                <MediumEditor
                  dangerouslySetInnerHTML={{ __html: purify.sanitize(data[i]?.description) }}
                  id="medium-editor-element-id-edit"
                  text={purify.sanitize(data[i]?.description)}
                  onChange={handleEditorChangeDesc(i)}
                  options={{
                    toolbar: {
                      buttons: [
                        "bold",
                        "italic",
                        "underline",
                        "unorderedlist",
                        "orderedlist",
                        "anchor"
                      ]
                    },
                    // placeholder: { text: "Section Title..." }
                  }}
                  style={{ flex: "1", color: "black", }}
                />
              </Box>
            </Box>
            <Box className="action-btn-wrap">
              <IconButton className='action-btn delete' onClick={() => handleDelete(i)}>
                <Delete />
              </IconButton>
              <IconButton className='action-btn line-down' onClick={() => handleDown(i)}>
                <Avatar variant='square' src={listDownIcon} alt='Down Icon' sx={{ width: '24px', height: '24px' }}></Avatar>
              </IconButton>
              <IconButton className='action-btn line-up' onClick={() => handleUp(i)}>
                <Avatar variant='square' src={listUpIcon} alt='Down Icon' sx={{ width: '24px', height: '24px' }}></Avatar>
              </IconButton>
            </Box>
          </Box>)
      }
    }
    return renderObj
  }

  /**
   * Function to adding/creating new section
   */
  const AddData = () => {
    if (addTitle.length && addDescription.length && addDescription != "<p><br></p>" && addDescription != "" && addDescription != "<p></p") {
      let dataDetail = [...data, ...[{ title: addTitle, description: addDescription }]]
      setData(dataDetail);
      setAddTitle("");
      setAddDescription("")
      setSelectedSec(0)
      handleClear();
      const editorElement = document.getElementById('medium-editor-element-id');
      if (editorElement) {
        editorElement.textContent = ''; // Clear the content in the editor directly
      }
    }
  }

  /**
   * Function to handle next
   */
  const handleClick = () => {
    if ((data?.length > 0 && data?.every((desc) => desc.title && desc.description != "<p><br></p>")) === true) {
      propData.asset_description = data
      propData.description_document = uploadedDocs
      if (EditMode) {
        propData.description_document_ids = deletedDoc;
      }
      updateProposalData(propData)
      handleNext();
    } else {
      showSnackbar('Asset Description is required to continue.', 'error')
    }
  }

  /**
  * Function to handle save and exit
  */
  const SaveAndExit = () => {
    if ((data?.length > 0 && data?.every((desc) => desc.title && desc.description != "<p><br></p>")) === true) {
      propData.asset_description = data
      propData.description_document = uploadedDocs
      if (EditMode) {
        propData.description_document_ids = deletedDoc;
      }
      updateProposalData(propData)
      saveProposal();
    } else {
      showSnackbar('Asset Description is required to continue.', 'error')
    }
  }

  // changing icon of medium-editor toolbar
  useEffect(() => {
    const updateIcon = (buttonElement, iconSource) => {
      if (buttonElement) {
        buttonElement.textContent = ''; // Clear existing content
        const imgElement = document.createElement('img');
        imgElement.src = iconSource;
        imgElement.alt = 'list';
        buttonElement.appendChild(imgElement);
      }
    };

    const unorderedListButtons = document.querySelectorAll('.medium-editor-action-unorderedlist');
    const orderedListButtons = document.querySelectorAll('.medium-editor-action-orderedlist');
    const linkButtons = document.querySelectorAll('.medium-editor-action-anchor');

    // Update icons for unordered list buttons
    unorderedListButtons.forEach((button) => updateIcon(button, unorderedlist));

    // Update icons for ordered list buttons
    orderedListButtons.forEach((button) => updateIcon(button, orderedlist));

    // Update icons for link buttons
    linkButtons.forEach((button) => updateIcon(button, anchor));
  }, [selectedSec, editTitle]);

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Time to add your description
                </Typography>
                <br />
                <Typography
                  component="h3"
                  sx={{
                    textAlign: "center",
                    color: "#ffffff ",
                    textTransform: "uppercase",
                    fontWeight: "600px",
                    fontFamily: "Rubik",
                    fontSize: "18px"

                  }}
                >
                  If you have any brochures oR info document, you can <br />
                  Upload them here as well
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right  quests-right-contents-box" sx={{ overflow: 'hidden' }}>
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit}
                    className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className='DescriptionPage qsn-middle-content' sx={{ pt: 4, mb: 5 }}>
                <Box>
                  <Typography component="h1" className="title" color={"black"} fontSize={"32px"}>
                    {AssetTitle}
                  </Typography>
                </Box>
                <OverlayScrollbarsComponent defer
                  style={{ width: '100%', minHeight: '350px', maxHeight: '52vh' }}
                >
                  <Box sx={{ width: '97%' }}>
                    <Box mb={4}>
                      {/* rendering all section */}
                      <Box className="shophouse-value-list" mt={2}>
                        {
                          renderSection()
                        }
                      </Box>
                      <Box className="shophouse-form" mt={2}>
                        {selectedSec === 1 &&
                          <>
                            <Box className="form-group title-field" mb={2}>
                              <TextField id="standard-basic" label="Section Title" value={addTitle} onChange={(e) => setAddTitle(purify.sanitize(e.target.value))} variant="filled" />
                            </Box>
                            <Box className="form-group description-field" mb={1.5}>
                              <Box className="input-group" position={'relative'}>
                                <MediumEditor
                                  ref={editorRef}
                                  id="medium-editor-element-id"
                                  text={addDescription}
                                  onChange={handleAddChangeDesc}
                                  onKeyDown={handleKeyDown}
                                  options={{
                                    toolbar: {
                                      buttons: [
                                        "bold",
                                        "italic",
                                        "underline",
                                        "unorderedlist",
                                        "orderedlist",
                                        "anchor"
                                      ]
                                    },
                                    placeholder: { text: "Paragraph..." },
                                    paste: { forcePlainText: true },
                                  }}
                                  style={{ flex: "1", color: "black", borderBottom: "2px solid #CBD5E1" }}
                                />
                                {(descriptionLength > 0) && <IconButton className='close-btn' onClick={handleClear}>
                                  <Cancel />
                                </IconButton>}
                              </Box>
                              <Stack direction="row" spacing={1} justifyContent={'space-between'} className="form-msg">
                                {(addTitle.length && addDescription.length && addDescription != "<p><br></p>" && addDescription != "" && addDescription != "<p></p" && descriptionLength > 0) ? <Typography>“Enter” to save this section</Typography> : <Typography>{""}</Typography>}
                                <Typography>{descriptionLength} / 500 </Typography>
                              </Stack>
                            </Box>
                            <Box className="btn-wrap" textAlign={'right'}>
                              <Button className="btn-rounded" disabled={(addTitle.length && addDescription.length && addDescription != "<p><br></p>" && addDescription != "" && addDescription != "<p></p" && descriptionLength > 0) ? false : true} sx={{ bgcolor: '#AEDAFF !important' }} onClick={() => AddData()}>Save Section</Button>
                            </Box>
                          </>
                        }
                      </Box>
                    </Box>
                    <Box mt={2}>
                      {files != "" ? (
                        <Box mt={3}>
                          {files?.map(files => {
                            return (<Box display={"flex"} alignItems={"center"} mt={1} >
                              <Avatar
                                src={CircleCheckIcon}
                                alt="Icon"
                                sx={{ width: 24, height: 24 }}
                              />
                              <Box
                                className="dD-file-name text-black"
                                ml={1}
                              >
                                {files}
                              </Box>
                            </Box>
                            )
                          })
                          }
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box my={4} >
                      <Grid container spacing={{ xs: 1, sm: 2, md: 2 }} >
                        <Grid item md={4} sm={6} xs={12}>
                          <Box className={selectedSec === 1 ? 'ppDescPage-card active' : "ppDescPage-card"} onClick={() => { setSelectedSec(1) }} sx={{ cursor: 'pointer' }}>
                            <Box className="ppDescPage-card-icon-box">
                              <Box className='ppDescPage-card-icon'><TextFormat /></Box>
                            </Box>
                            <Box className='ppDescPage-card-title'>Add Text Section</Box>
                          </Box>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <Box  {...getRootProps2({
                            className: selectedSec === 2 ? 'ppDescPage-card active' : 'ppDescPage-card',
                            onClick: () => { setSelectedSec(2); open2(); },
                          })}
                            sx={{ cursor: 'pointer' }}
                          >
                            <input {...getInputProps2()} />
                            <Box className="ppDescPage-card-icon-box">
                              <Box className='ppDescPage-card-icon'><VideoLibrary /></Box>
                            </Box>
                            <Box className='ppDescPage-card-title'>Upload Videos</Box>
                          </Box>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <Box         {...getRootProps({
                            className: selectedSec === 3 ? 'ppDescPage-card active' : 'ppDescPage-card',
                            onClick: () => { setSelectedSec(3); open(); },
                          })}
                            sx={{ cursor: 'pointer' }}
                          >
                            <input {...getInputProps()} />
                            <Box className="ppDescPage-card-icon-box">
                              <Box className='ppDescPage-card-icon' sx={{ cursor: 'pointer' }}><Upload /></Box>
                            </Box>
                            <Box className='ppDescPage-card-title' >Upload Docs</Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </OverlayScrollbarsComponent>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleback}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className='quests-btn-box'>
                    <Button
                      onClick={() => handleClick()} endIcon={<KeyboardArrowRight />}
                      className="btn-rounded btn-text-white btn-blue-600"
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}




