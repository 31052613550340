import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Avatar } from '@mui/material'

export default function OwnerInformation({ owner, totalProject, member_id }) {
    return (
        <>
            <Box className="pd-sidebar-ownerInfo rc-project-owner-details">
                <Box className="ownerInfo-wrap" display={'flex'} justifyContent={'center'} pb={1.5}>
                    <Box textAlign={'center'}>
                        <Box className="owner-img" display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Avatar sx={{ width: 64, height: 64 }} alt="User image" src={""} />
                        </Box>
                        <Typography className='title font-22' mt={0.6}>{member_id}</Typography>
                        <Typography className='sub-title font-14 text-blueGray-400' mt={0.2}>{totalProject ? totalProject : "2"} Projects</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

OwnerInformation.propTypes = {
    owner: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired
    }),
    totalProject: PropTypes.number.isRequired
}