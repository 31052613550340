import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Typography, Box, Avatar, Button, Paper, Chip } from '@mui/material';
import { avatarOne } from '../../Components/Images/Images';
import { Add, Bookmark } from '@mui/icons-material';
import RatingCompo from '../Components/RatingCompo/RatingCompo';
import ServicesSearch from '../Components/ServicesSearch/ServicesSearch';
import Filter from '../Components/Filters/Filter';
import { GetApi } from '../../Api/Api';
import "./SearchServiceProviderResults.css"
import { Link } from 'react-router-dom';

const SearchServiceProviderResults = () => {

    const [categoryData, setCategoryData] = useState(null);
    const [serviceProvider, setServiceProvider] = useState([])

    const buttonTexts = ['Top-Rated', '$10k + Earned', '100% Job Success'];
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 200)
    })

    useEffect(() => {
        // Api to get categoryData
        async function getCategoryData() {
            try {
                const res = await GetApi("/services/spCategory");
                setCategoryData(res?.data?.data);
            } catch (error) {
                console.log(error);
            }
        }

        // Api to get Service Provider
        async function getServiceProviders() {
            try {
                const res = await GetApi("/services/getProfiles");
                setServiceProvider(res?.data?.data?.length ? res.data.data : []);
            } catch (error) {
                console.log(error);
            }
        }
        getServiceProviders();
        getCategoryData();
    }, [])

    /**
     * Search Function for the providers
     * @param {*} searchInput 
     */
    const handleSearchResults = (searchInput) => {
        const filteredData = serviceProvider?.filter((item) =>
            item?.services?.some((data) =>
                data?.services_title?.toLowerCase().includes(searchInput.toLowerCase())
            )
        );
        setServiceProvider(filteredData);
    }

    return (
        <>
            <Box>

                <Box px={'14px'}>
                    <Box className="container">
                        <Box mt={0} >
                            <ServicesSearch categoryData={categoryData} showSearchBar={true} pageTitle={'Service Catalog'} handleSearchResults={handleSearchResults} />
                        </Box>
                    </Box>

                    {/* Filters */}
                    <Box mb={2} pt={4}>
                        <Filter title={"Service Provider"} />
                        <Box className="container">
                            <Typography >{serviceProvider?.length} Service Providers</Typography>
                        </Box>
                    </Box>
                    <Box className="container">
                        {serviceProvider && serviceProvider.length > 0 && serviceProvider?.map((val, ind) => {
                            return (
                                <>
                                    <Box style={{ backgroundColor: "var(--blue-900)", padding: '16px', borderRadius: '24px' }} mb={2}>
                                        <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                                            <RatingCompo rating={4.91} review={632} />
                                            <Box sx={{ backgroundColor: '#398CD1', borderRadius: "12px", color: 'white', padding: '10px', display: 'flex', alignItems: 'center' }}>
                                                <Bookmark />
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} >
                                            <Box className="">
                                                <Avatar
                                                    src={avatarOne}
                                                    alt={"girl"}
                                                />
                                            </Box>
                                            <Box ml={2}>
                                                <Typography variant='h6' sx={{ fontWeight: 500, color: '#FFFFFF' }} className="ctUImage-title">
                                                    {`${val.first_name} ${val.last_name}`}
                                                </Typography>
                                                <Typography className="ctUImage-subtitle" sx={{ fontWeight: 400, color: '#FFFFFF' }} mt={1}>
                                               { val?.skillData?.map(skill => skill?.skill_name).join(' | ') }
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box >
                                            <Typography mt={2} variant='h6' sx={{ fontWeight: 400 }}>{`$${val?.hourly_rate ? val?.hourly_rate : 0}/hr`}</Typography>
                                            <Typography sx={{
                                                mt: '12px', color: '#FFFFFF', overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: "2",
                                                WebkitBoxOrient: "vertical",
                                            }}>{val?.description}
                                            </Typography>
                                            <Link to={`/service-proposer/profile/individual/${val?.id}`} state={{user_id : val.user_id}}>
                                                <Typography variant="h6" sx={{
                                                    color: '#398CD1', fontSize: '14px', fontWeight: 700, textDecoration: "underline",
                                                    textDecorationColor: "#398CD1"
                                                }} mt={1}>
                                                    View More
                                                </Typography>
                                            </Link>
                                            <Stack spacing={2} mt={1} direction="row" >
                                                {buttonTexts.map((text, index) => (
                                                    // <Button className='btn-round' key={index} sx={{ backgroundColor: '#000000', color: '#ffffff', borderRadius: '8px' }}>
                                                    //     <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                    //         {text}
                                                    //     </Typography>
                                                    // </Button>

                                                    <Box className="pc-chip-wrap" pb={3}  >
                                                        <Stack direction="row" flexWrap={'wrap'} display={"flex"} spacing={1} justifyContent={'center'}>
                                                            <Chip className=" service-provider-result" label={text} />
                                                        </Stack>
                                                    </Box>
                                                ))}
                                            </Stack>
                                            <Button className="btn-rounded" style={{ background: "var(--golden-gradient)" }} sx={{ marginBottom: '10px', borderRadius: "40px", fontSize: "14px", fontWeight: 500 }} variant="contained" startIcon={<Add />}>
                                                Hire
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default SearchServiceProviderResults