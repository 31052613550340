import React from 'react'
import './WorkHistorySection.css'
import { Box, Tab, Tabs, styled } from '@mui/material';
import PropTypes from 'prop-types';
import WorkComponent from '../WorkComponent/WorkComponent';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={3}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: '#398CD1',
        borderRadius: '3px 3px 0 0',
        height: '3px'
    },
    '& .MuiTab-root': {
        textTransform: 'none',
        color: '#000000',
        fontWeight: 500,
        fontFamily: 'Rubik',
        fontSize: '14px',
        lineHeight: '20px',
        minWidth: 0,
        minHeight: 0,
        '&.Mui-selected': {
            color: '#398CD1'
        },
        '&:hover': {
            color: '#398CD1'
        }

    }
}));


function WorkHistorySection() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%',}}>

            <CustomTabs value={value} onChange={handleChange} aria-label="basic tabs example"   TabIndicatorProps={{
                            style: { background: "var(--golden-gradient)" }, // Add your selected color here
                        }}>
                <Tab label={`Completed Projects (${2})`} {...a11yProps(0)} className={value==0?'text-golden':'custom-white'} />
                <Tab label={`In Progress (${3})`} {...a11yProps(1)} className={value==1?'text-golden':'custom-white'}  />
            </CustomTabs>
            <CustomTabPanel value={value} index={0}>
                <CompletedProjects />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <InProgressProjects />
            </CustomTabPanel>
        </Box>
    )
}

export default WorkHistorySection


function CompletedProjects() {
    return (
        <Box sx={{ width: '100%' }}>
            {
                [1, 2, 3].map((item, index) => {
                    return (
                        <Box key={index} mb={2} className="work-box">
                            <WorkComponent workEndDate={new Date()} />
                        </Box>
                    )
                })
            }
        </Box>
    )
}


function InProgressProjects() {
    return (
        <Box sx={{ width: '100%' }}>
            {
                [1, 2].map((item, index) => {
                    return (
                        <Box key={index} mb={2} className="work-box">
                            <WorkComponent  />
                        </Box>
                    )
                })
            }
        </Box>
    )
}