import { Box, Button, Chip, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import "../Investor.css"
import { useLocation } from 'react-router-dom';
import ConnectWalletInvestor from '../../../Web3/ConnectWalletInvestor';
import { update } from '../../../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux';
import { Check } from '@mui/icons-material';


export default function PaymentMethod({ assetCategoryName, currencyOption, setFeeTokenType }) {
    // Importing necessary hooks and functions
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.auth.user);

    // Determine whether to show the fiat tab based on the asset category
    // const showFiat = assetCategoryName === "gold" || assetCategoryName === "watch" ? false : true;
    const showFiat = false;

    // State variable for controlling the selected tab value
    const [value, setValue] = React.useState(showFiat == true ? 0 : 1);

    // state to hold payment currency type
    const [selectedOption, setSelectedOption] = useState('USDT');

    /**
    * Handle change in payment type
    * @param {Event} event 
    */
    const handleOptionChange = (event) => { setSelectedOption(event.target.value); setFeeTokenType(event.target.value); };

    /**
     * Function to handle tab changes
     * @param {*} event 
     * @param {*} newValue 
     */
    const handleTabChange = (event, newValue) => { setValue(newValue); }

    // useEffect to update the selected tab value based on the asset category and showFiat variable
    useEffect(() => {
        setValue(showFiat == true ? 0 : 1)
    }, [assetCategoryName])


    //Wallet code
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);

    /**
     * @description opens wallet connection dialog
     */
    const handleClickOpen = () => {
        setWalletDialogOpen(true);
    };

    /**
     *  @description closes wallet connection dialog
     * @param {string} [value] 
     */
    const handleCloseDialog = (value) => {
        setWalletDialogOpen(false);
    };

    /**
     * @description gets called when a wallet is connected successfully 
     * @param {*} acc 
     */
    const walletLogin = async (acc) => {
        dispatch(update({ wallet_address: acc }));
    }
    //Wallet code ends


    return (
        <>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }} className='rc-basic-container'>
                <Typography variant='h6' className='font-16' mb={1.5}>Payment Method</Typography>
                <Typography className='rc-small-secondary-text' variant='body2'>Manage payment methods connected to your account</Typography>
                <Box sx={{ mt: 1 }} >
                    <Tabs className='rc-payment-tab-wrapper' value={value} onChange={handleTabChange} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} variant="fullWidth" >
                        {false &&
                            <Tab className='rc-payment-tab' label="Fiat" value={0} {...a11yProps(0)} disableRipple />
                        }
                        <Tab className='rc-payment-tab' label="Crypto" value={1} {...a11yProps(1)} disableRipple />
                    </Tabs>
                </Box>
                <Box ml={-1}>
                    <TabPanel value={value} index={0}>
                        <Typography mb={4} className='rc-small-secondary-text' gutterBottom>Pay using a bank account or your credit card:</Typography>
                        <Button className='btn-rounded btn-text-white btn-blue-500' size='small' variant='contained' >Connect to Payment Provider</Button>
                        <Typography mt={3} className='rc-small-secondary-text' gutterBottom>RACE does not process or store your bank account or credit card information.</Typography>

                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {/* Pay using RACE, BTC, ETH, USDT or USDC: */}
                        {currencyOption ?
                            <Box className='investor-select-currency-type'>
                                <Typography className='pay-using font-14'>
                                    Pay using
                                </Typography>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="payment-method"
                                        name="payment-method"
                                        defaultValue="USDT"
                                        row
                                        className='currency-options'
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                    >
                                        {/* <FormControlLabel
                                            value="RACE"
                                            control={<Radio />}
                                            label="RACE Token"
                                        /> */}
                                        <FormControlLabel
                                            value="USDT"
                                            control={<Radio />}
                                            label="USDT"
                                        />
                                        <FormControlLabel
                                            value="USDC"
                                            control={<Radio />}
                                            label="USDC"
                                        />
                                        <FormControlLabel
                                            value="DAI"
                                            control={<Radio />}
                                            label="DAI"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            :
                            <Typography mb={4} className='rc-small-secondary-text' gutterBottom>Pay using RACE, BTC, ETH, USDT or USDC:
                            </Typography>
                        }
                        {
                            userData?.wallet_address && userData.wallet_address !== "" ?
                                <Chip icon={<Check />} label={`Wallet : ${userData?.wallet_address}`} color="success" />
                                :
                                <Button onClick={handleClickOpen} className='btn-rounded btn-text-white btn-blue-500 ' size='small' variant='contained' >Connect to your Wallet</Button>

                        }
                        <Typography mt={3} className='rc-small-secondary-text' gutterBottom>RACE does not process or store your wallet information.</Typography>
                    </TabPanel>
                </Box>
            </Paper>
            <ConnectWalletInvestor open={walletDialogOpen} handleCloseDialog={handleCloseDialog} handleConnectedSuccess={walletLogin} askConfirmation={true} />
        </>
    )
}



function a11yProps(index) {
    return {
        id: `horizontal-tab-${index}`,
        "aria-controls": `horizontal-tabpanel-${index}`
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number,
    value: PropTypes.number,
};

PaymentMethod.propTypes = {
    /**
     * @description asset category name is the `url` field of the api asset category
     */
    assetCategoryName: PropTypes.string.isRequired,
};
