import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  Modal,
  Typography,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FileViewer from "react-file-viewer";
import { deleteApi, GetApi } from "../../Api/Api";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { ArrowDropDown } from "@mui/icons-material";

export default function Documents({
  documentsData,
  descriptionData,
  ownerId,
  assetId,
  editable,
  section_title,
}) {
  const [showMore, setShowMore] = useState(false);

  // Merge the two arrays
  let mergedArray = documentsData?.length ? documentsData : [];
  if (descriptionData?.length > 0) {
    const documentOnly = descriptionData.filter(
      (item) =>
        item?.images &&
        item?.type === "document" &&
        item?.images?.type !== "mp4"
    );
    if (documentOnly?.length > 0) {
      mergedArray = mergedArray.concat(documentOnly);
    }
  }

  const [view, setView] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState(mergedArray);

  const viewDoc = async (documentdata) => {
    setOpenModal(true);
    setView(true);
    if (documentdata?.images.path) {
      // Get the sanitized file name
      // const fileName = `${process.env.REACT_APP_IMAGE_URL}${documentdata.images.path ? documentdata.images.path : ""}`;
      // setFile(fileName);
      const fileName = await fetch(
        `${process.env.REACT_APP_IMAGE_URL}${
          documentdata.images.path ? documentdata.images.path : ""
        }`
      );

      const templateBuffer = await fileName.arrayBuffer();
      const templateBlob = new Blob([templateBuffer], {
        type: "application/pdf",
      });
      const templateUrl = URL.createObjectURL(templateBlob);
      setFile(templateUrl);
    }
    if (
      documentdata?.images.type === "document" ||
      documentdata?.images.type === "doc"
    ) {
      setType("pdf");
    } else {
      setType(documentdata?.images.type);
    }
  };

  useEffect(() => {
    setData(mergedArray);
  }, [documentsData, descriptionData]);

  const handleClose = () => setOpenModal(false);

  const handleView = () => {
    setView(!view);
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  // handle show more button
  const handleShowMore = () => {
    setShowMore(true);
  };

  return (
    <>
      <Box className="pd-sidebar-documents" mb={3}>
        <Stack
          className="pd-sidebar-heading"
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className="title" component={"h4"}>
            {section_title}
          </Typography>
        </Stack>

        {/* Dynamically rendring documents with viewer */}

        {data && data.length > 0 ? (
          <>
            {data?.map((val, ind) => {
              if (ind < 5 || showMore) {
                return (
                  // <Card key={ind} className="pd-card">
                  <Card className="pd-action-card" key={ind}>
                    <CardHeader
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          className="bg-blue-900"
                          variant="square"
                          sx={{
                            width: "48px",
                            height: "48px",
                            borderRadius: "12px",
                          }}
                        >
                          <AssignmentIcon />
                        </Avatar>
                      }
                      action={
                        <>
                          <Box className="btn-wrap">
                            <Stack
                              direction="row"
                              spacing={1.5}
                              alignItems={"center"}
                            >
                              <IconButton
                                aria-label="View"
                                size="large"
                                className="square-icon-btn"
                                onClick={() => viewDoc(val)}
                              >
                                <RemoveRedEyeIcon />
                              </IconButton>
                            </Stack>
                          </Box>
                        </>
                      }
                      onClick={() => viewDoc(val)}
                      title={val.type}
                      subheader={
                        <Tooltip title={val.images.original_name}>
                          <span>{val.images.original_name}</span>
                        </Tooltip>
                      }
                    />
                  </Card>
                );
              } else {
                return null;
              }
            })}

            {showMore === false && data?.length > 5 && (
              <Stack flexDirection={"row"} mt={2} justifyContent={"flex-end"}>
                <Stack flexDirection={"row"} alignItems="center">
                  <Typography>More</Typography>
                </Stack>
                <Box sx={{ cursor: "pointer" }} onClick={handleShowMore}>
                  <ArrowDropDown />
                </Box>
              </Stack>
            )}
          </>
        ) : (
          <Typography textAlign={"center"}>
            No documents availiable to view
          </Typography>
        )}
      </Box>

      <Grid>
        {/* {view &&
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
            <Box sx={styleModal}>
              <OverlayScrollbarsComponent defer
                style={{ width: '100%', height: '100%' }}
              >
                <Box className="raj">
                  {<FileViewer fileType={type} filePath={file} />}
                </Box>
              </OverlayScrollbarsComponent>
            </Box>
          </Modal>
        } */}
        {view && type && file && (
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
            <Box sx={styleModal}>
              {/* <iframe
                title="PDF Viewer"
                src={file}
                style={{ width: "100%", height: "100%" }}
                className="pdf-viewer-modal"
              ></iframe> */}
              <object
                data={file}
                type="application/pdf"
                style={{ width: "100%", height: "100%" }}
              >
                <p style={{ textAlign: "center" }}>
                  Your browser does not support PDFs.{" "}
                  <a
                    href={file}
                    target="_blank"
                    style={{ color: "lightgreen" }}
                  >
                    Download the PDF
                  </a>
                  .
                </p>
              </object>
            </Box>
          </Modal>
        )}
      </Grid>
    </>
  );
}
