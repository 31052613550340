import { Box, Tab, Tabs, Typography, styled } from "@mui/material";
import React from "react";
import "./ManageOrder.css";
const ManageOrder = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const CustomTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: 'var(--golden-gradient)',
        borderRadius: '3px 3px 0 0',
        height: '3px'
    },
    '& .MuiTab-root': {
        textTransform: 'none',
        color: '#000000',
        fontWeight: 500,
        fontFamily: 'Rubik',
        fontSize: '14px',
        lineHeight: '20px',
        minWidth: 0,
        minHeight: 0,
        '&.Mui-selected': {
            color: '#398CD1'
        },
        '&:hover': {
            color: '#398CD1'
        }
    }
}));
  return (
    <Box>
      <Typography className="font-28">Manage Orders</Typography>
      <Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <CustomTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: { background: "var(--golden-gradient)" }, // Add your selected color here
            }}
            >
              <Tab
                label="Active"
                {...a11yProps(0)}
                className={value == 0 ? "text-golden" : "custom-white"}
              />
              <Tab
                label="Missing Details"
                {...a11yProps(1)}
                className={value == 1 ? "text-golden" : "custom-white"}
              />
              <Tab
                label="Delivered"
                {...a11yProps(2)}
                className={value == 2 ? "text-golden" : "custom-white"}
              />
              <Tab
                label="Completed"
                {...a11yProps(2)}
                className={value == 3 ? "text-golden" : "custom-white"}
              />
              <Tab
                label="Cancelled"
                {...a11yProps(2)}
                className={value == 4 ? "text-golden" : "custom-white"}
              />
              <Tab
                label="All"
                {...a11yProps(2)}
                className={value == 5 ? "text-golden" : "custom-white"}
              />
            </CustomTabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            Item One
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            Item Two
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            Item Three
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            Item four
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            Item five
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            Item six
          </CustomTabPanel>
        </Box>
      </Box>
      <Box mt={3} className="manage-order-table">
      <Typography className="font-22">Active Orders</Typography>
      </Box>
    </Box>
  );
};

export default ManageOrder;
