import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Box, Button, ListItemIcon, Badge, Stack, Avatar, Menu, MenuItem, IconButton, Typography, Divider, Tabs, Tab, } from "@mui/material";
import { User, NewLogo, Profile } from "../../Components/Images/Images";
import { AccountBalanceWallet, Notifications, ArrowDropDownRounded, Logout, Settings, AccountCircle } from "@mui/icons-material";
import { useAccount, useDisconnect, useNetwork, useBalance } from "wagmi";
import { update, logout } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../../Contexts/SocketContext";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { GetApi, updateApi } from "../../Api/Api";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import "./Header.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


const Header = () => {
  // Select user data from the Redux store
  const { chains, chain } = useNetwork();
  const { data, isSuccess, status } = useBalance({
    address: useAccount().address,
  });

  const userData = useSelector((state) => state.auth.user);
  const localData = JSON.parse(localStorage.getItem("user_data"))
  // Get the socket instance using the custom hook
  const socket = useSocket();
  const { showSnackbar } = useSnackbar();

  // Get the dispatch function from the Redux store
  const dispatch = useDispatch();

  // Get the account and disconnect function from the useAccount and useDisconnect custom hooks
  const account = useAccount();
  const { disconnect } = useDisconnect();

  // Get the navigate function from the useNavigate custom hook
  const navigate = useNavigate();

  // State variables for anchor element and its open state
  const [notifications, setNotifications] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [value, setValue] = useState(0);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Event handler to open the anchor element
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
    setValue(0);
  };

  // Event handler to close the anchor element
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
    setValue(-1);
  };


  async function getNotification() {
    try {
      if (localData?.id) {
        const res = await GetApi(`/user/getNotification?id=${localData.id}`);
        if (res?.data?.data && res?.data?.data?.data) {
          setNotifications(res.data.data.data);
          setUnreadNotifications(res?.data?.data?.unreadCount);
        }
      } else {
        showSnackbar()
      }
    } catch (error) {
      console.log(error);
    }
  }
  // Function to calculate relative time
  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    return formattedDate
  }
  useEffect(() => {
    // Api to get getNotification
    const timer = setTimeout(() => {
      getNotification();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [])

  // new-notification received then call
  socket.on("new-notification", (data) => {
    getNotification();
  });

  // Function to scroll to the top of the page
  function topFunction() {
    document.documentElement.scrollTop = 0;
  }

  /**
   * function to handle logout functionality
   */
  const handleLogout = () => {
    handleClose();
    if (account.isConnected) {
      //disconnect wallet if connected
      disconnect();
    }
    dispatch(logout());
    socket.emit("remove-user", userData?.id);
    navigate("/");

    // Reload the page after a short delay (e.g., 500 milliseconds)
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  //Wallet connection code start
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setWalletDialogOpen(true);
  };

  const handleCloseDialog = (value) => {
    setWalletDialogOpen(false);
  };

  /**
   * gets called when a wallet is connect successfully
   * @param {*} acc
   */
  const walletLogin = async (acc) => {
    // setOpenModal(true); //ask for confirmation
    dispatch(update({ wallet_address: acc?.toLowerCase() }));
    // setUserData({ ...userData, wallet_address: acc });
  };

  const handleWalletDisconnect = () => {
    disconnect();
    handleClose1();
    showSnackbar('Wallet disconnected', 'success');
  }
  //Wallet connection code end

  /**
   * Function to update notification is_read status
   * @param {Object} value 
   */
  const handleNotificationsUpdate = (value) => {
    async function updateNotification() {
      try {
        const res = await updateApi(`/user/updateNotification/${value.id}`);
        if (res?.data?.code === 200) {
          // getting updated data
          getNotification();
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (!value.is_read) {
      updateNotification();
    }
  }


  // code for restricting Routes for the Categories
  const notificationPath = (val) => {
    switch (val.type) {
      case "Offer Cancel":
        return `/project-details/${val.asset_id}`;
      case "Offer":
        return `/project-details/${val.asset_id}`;
      case "Project Update":
        return `/project-details/${val.asset_id}`;
      case "Terms":
        return `/project-details-inreview/${val.asset_id}`;
      default:
        return "";
    }
  }

  const handleNotificationView = (val) => {
    navigate(`${notificationPath(val)}`, { state: { from: "notification", proState: "notification" } })
    setAnchorEl2(null);
  }

  return (
    <>
      <Box className="main-header-wrap">
        <Box className="container">
          <Grid container spacing={2} className="main-header">
            <Grid item xs={2} className="left">
              <Box display={"flex"} alignItems={"center"}>
                <Box className="logo">
                  <Link onClick={topFunction} to="/">
                    <img src={NewLogo} alt="Logo" />
                  </Link>
                </Box>
                <Stack direction="row" spacing={4} className="headerLeft-menu" sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Box className="menu-item">
                    <Link to="/dummy">Invest</Link>
                  </Box>
                  <Box className="menu-item">
                    <Link to="/dummy">Learn</Link>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={10} className="right">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Box sx={{ display: { xs: 'none', md: 'flex', sm: 'flex' } }}>
                  <Link
                    onClick={topFunction}
                    to="/project-proposer"
                    className="btn-rounded btn-golden"
                  >
                    Raise Capital{" "}
                  </Link>
                </Box>
                {account?.address ? (<>
                  <Button className="wallet-btn" sx={{ ml: 1.5 }}
                    id="fade-button"
                    aria-controls={open1 ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={handleClick1}
                  >
                    <Box component={"span"} className="icon">
                      <AccountBalanceWallet />
                    </Box>
                    <Box component={"span"} className="text">
                      {account?.address?.slice(0, 6) +
                        "..." +
                        account?.address?.slice(-4)
                      }
                    </Box>
                    <Avatar
                      alt="Token icon"
                      src={Profile}
                      sx={{ width: 18, height: 18 }}
                    />
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    anchorEl={anchorEl1}
                    open={open1}
                    onClose={handleClose1}
                    // TransitionComponent={Fade}
                    className="dropdown-menu"
                  >
                    <Button onClick={() => { handleWalletDisconnect() }} className="" style={{ width: '186px', display: 'flex', gap: '20px', color: 'white' }}>
                      <Logout fontSize="small" />
                      Disconnect
                    </Button>

                  </Menu>
                </>
                ) : (
                  <Button
                    onClick={handleClickOpen}
                    className="wallet-btn"
                    sx={{ ml: 1.5 }}
                  >
                    <Box component={"span"} className="icon">
                      <AccountBalanceWallet />
                    </Box>
                    &nbsp; Connect Wallet
                  </Button>
                )}

                {/* <Box className="notification" sx={{ ml: { xs: 1, md: 3 } }}
                >
                  <Badge
                    // badgeContent={3}
                    color="primary"
                  >
                    <Notifications />
                  </Badge>
                </Box> */}
                <Box className="menu-item">
                  <IconButton
                    className="notification"
                    sx={{ ml: { xs: 1, md: 3 } }}
                    id="fade-button"
                    aria-controls={open2 ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={handleClick2}
                  // onMouseOver={handleClick2}
                  >
                    {unreadNotifications ?
                      <Badge
                        badgeContent={unreadNotifications}
                        color="primary"
                      >
                        <Notifications />
                      </Badge>
                      : <Notifications />
                    }
                  </IconButton>
                  {notifications?.length ?
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorEl={anchorEl2}
                      open={open2}
                      onClose={handleClose2}
                      // onMouseLeave={handleClose2}
                      className="mega-menu InvesterHeader-dropdown notifications"
                      keepMounted
                    >
                      <OverlayScrollbarsComponent
                        options={{
                          scrollbars: {
                            autoHide: "scroll",
                          },
                        }}
                        style={{ width: 358, maxHeight: 280, marginRight: '5px' }}
                      >
                        <Box
                          className='header-notifications'
                          sx={{ width: "358px" }}
                        >
                          <Tabs
                            value={value}
                            variant="scrollable"
                            onChange={handleChange}
                            scrollButtons={false}
                            aria-label="scrollable prevent tabs example"
                            className="not-tabs"
                          >
                            <Tab label="Notifications" className="tab-title" />
                          </Tabs>
                          <Divider thickness={2} className="divider" />
                          <TabPanel value={value} index={0}>
                            {notifications && notifications?.map((val, ind) => {
                              return (
                                <Box key={`notification ${ind + 1}`} className='notification-item' onClick={() => handleNotificationsUpdate(val)}>
                                  <Box>
                                    <Typography variant="body1" className="title" >
                                      <span onClick={() => handleNotificationView(val)}>{val.message}</span>
                                    </Typography>
                                    <Typography variant="caption" className="time">
                                      {formatRelativeTime(val.createdAt)}
                                    </Typography>
                                  </Box>
                                  {!val?.is_read ? <Box>
                                    <span className="unread-icon"></span>
                                  </Box>
                                    : null}
                                </Box>
                              );
                            })}
                          </TabPanel>
                        </Box>
                      </OverlayScrollbarsComponent>
                    </Menu>
                    : null}
                </Box>
                <Box className="userStatus" sx={{ ml: { xs: 2, md: 3.5 } }} >
                  <Avatar
                    alt="Token icon"
                    src={
                      userData?.persona_picture
                        ? userData?.persona_picture
                        : User
                    }
                    sx={{ width: 40, height: 40 }}
                  />
                  <Badge className="insideBadge"></Badge>
                </Box>
                <Box className="profileMenu-wrap" ml={1.5}>
                  <Button
                    id="fade-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className="profileMenu-btn"
                  >
                    <ArrowDropDownRounded />
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    // TransitionComponent={Fade}
                    className="dropdown-menu"
                  >
                    <MenuItem onClick={() => {
                      handleClose();
                      navigate("/user/projects");
                    }}>
                      <ListItemIcon>
                        <AccountCircle fontSize="small" />
                      </ListItemIcon>
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate("/user/portfolio");
                      }}
                    >
                      <ListItemIcon>
                        <AccountCircle fontSize="small" />
                      </ListItemIcon>
                      My account
                    </MenuItem>
                    <MenuItem onClick={
                      () => {
                        handleClose();
                        navigate("/user/settings");
                      }
                    }>
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      Settings
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box >
      <ConnectWalletInvestor
        open={walletDialogOpen}
        handleCloseDialog={handleCloseDialog}
        handleConnectedSuccess={walletLogin}
      // askConfirmation={!localData?.wallet_address ? true : localData?.wallet_address?.toLocaleLowerCase() != account?.address?.toLocaleLowerCase() ? true : false}
      />
    </>
  );
};

export default Header;
