import React from 'react'
import { Box, Button,TextField } from '@mui/material'
import SideBar from '../DashboardSideBar/SideBar'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import computerIcon from "../../../Assets/Images/service-provider/o-list.png";
import questionMarkIcon from "../../../Assets/Images/service-provider/question-mark.png";
import rightArrow from "../../../Assets/Images/service-provider/right-arrow.png";
import imgBg from "../../../Assets/Images/service-provider/img-card.png";
import downloadIcon from "../../../Assets/Images/service-provider/download.png";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import PostProject from "../PostProjectComponent/PostProject";
import Header from '../../../Layouts/Header/HeaderServiceProposer';

const DashboardMyOrder = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    const rows = [
        createData('Frozen yoghurt', 'Auditing for Houston Project (Real Estate)', 1, '1 month', 'SGD $125.86'),

    ];
    return (
        <Box className="dashboard-overview-main dashboard-post-project-container">
            
                
                <Box className="dashboard-overview-right">
                    <Box className="my-order-container">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <Box className="my-order-left-container">
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                <Tab label="Activity" {...a11yProps(0)} />
                                                <Tab label="Details" {...a11yProps(1)} />
                                                <Tab label="Requirements" {...a11yProps(2)} />
                                                <Tab label="Delivery" {...a11yProps(3)} />
                                            </Tabs>
                                        </Box>
                                        <CustomTabPanel value={value} index={0}>
                                            <Box className="my-order-history">
                                                <Box className="date-btn">
                                                    <Button variant='contained'>Apr 8</Button>
                                                </Box>
                                                <Box className="order-history-container">
                                                    <Box className="order-history-left">
                                                        <img src={computerIcon} alt='' />
                                                    </Box>
                                                    <Box className="order-history-right">
                                                        <Typography component="h5" className='title'>You placed the order</Typography>
                                                        <Typography component="p" className='sub-title'>Apr 8, 9:45PM</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="order-history-container">
                                                    <Box className="order-history-left">
                                                        <img src={computerIcon} alt='' />
                                                    </Box>
                                                    <Box className="order-history-right">
                                                        <Typography component="h5" className='title'>You placed the order</Typography>
                                                        <Typography component="p" className='sub-title'>Apr 8, 9:45PM</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="order-history-container">
                                                    <Box className="order-history-left">
                                                        <img src={computerIcon} alt='' />
                                                    </Box>
                                                    <Box className="order-history-right">
                                                        <Typography component="h5" className='title'>You placed the order</Typography>
                                                        <Typography component="p" className='sub-title'>Apr 8, 9:45PM</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="order-history-container border-bottom">
                                                    <Box className="order-history-left">
                                                        <img src={computerIcon} alt='' />
                                                    </Box>
                                                    <Box className="order-history-right">
                                                        <Typography component="h5" className='title'>You placed the order</Typography>
                                                        <Typography component="p" className='sub-title'>Apr 8, 9:45PM</Typography>
                                                        <Box className="msg-section">
                                                            <Typography component="h5" className='title'>Message</Typography>
                                                            <Typography component="p" className='sub-title'>This helps your project post stand out to the right service providers. It’s the first thing they will see, so make it stick!</Typography>
                                                        </Box>
                                                        <Box className="img-box">
                                                            <Box className="img-box-div">
                                                                <img src={imgBg} alt='' />
                                                            </Box>
                                                            <Box className="img-box-bottom">
                                                                <Box>
                                                                    <Typography>Title</Typography>
                                                                    <Typography>100MB</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <img src={downloadIcon} alt='' />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </CustomTabPanel>
                                        <CustomTabPanel value={value} index={1}>
                                            <Box className="my-order-detail-main">
                                                <Box className="top-view">
                                                    <Box className="top-left">
                                                        <Typography className='title' component="h4">Auditing for Houston Project</Typography>
                                                        <Typography className='sub-title' component="p">Ordered from <span>Usman Abbasi </span>| Delivery date Apr 12, 9:45 PM</Typography>
                                                    </Box>
                                                    <Box className="top-right">
                                                        <Typography className='total-title' component="">TOTAL PRICE</Typography>
                                                        <Typography className='total-price' component="">SGD 125.86</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="top-order-detail">
                                                    <Typography className='order-title'>Order Number</Typography>
                                                    <Typography className='order-no'>#SP12746384864658468</Typography>
                                                </Box>
                                                <Box className="table-main">
                                                    <Box className="top-view">
                                                        <Box className="top-left">
                                                            <Typography component="h4" className='title'>Your Order</Typography>
                                                            <Typography component="h5" className='sub-title'>Apr 8, 20</Typography>
                                                        </Box>
                                                        <Box className="top-right">
                                                            <Typography component="h6" className='title'>View Invoice</Typography>
                                                        </Box>
                                                    </Box>
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell>ITEM</StyledTableCell>
                                                                    <StyledTableCell align="right">QTY</StyledTableCell>
                                                                    <StyledTableCell align="right">DURATION</StyledTableCell>
                                                                    <StyledTableCell align="right">PRICE</StyledTableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rows.map((row) => (
                                                                    <StyledTableRow key={row.name}>

                                                                        <StyledTableCell align="right">{row.calories}</StyledTableCell>
                                                                        <StyledTableCell align="right">{row.fat}</StyledTableCell>
                                                                        <StyledTableCell align="right">{row.carbs}</StyledTableCell>
                                                                        <StyledTableCell align="right">{row.protein}</StyledTableCell>
                                                                    </StyledTableRow>
                                                                ))}
                                                            </TableBody>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell>SUBTOTAL</StyledTableCell>
                                                                    <StyledTableCell align="right"></StyledTableCell>
                                                                    <StyledTableCell align="right"></StyledTableCell>
                                                                    <StyledTableCell align="right">SGD $125.86</StyledTableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell>SERVICE FEE</StyledTableCell>
                                                                    <StyledTableCell align="right"></StyledTableCell>
                                                                    <StyledTableCell align="right"></StyledTableCell>
                                                                    <StyledTableCell align="right">SGD 6.00</StyledTableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell>TOTAL</StyledTableCell>
                                                                    <StyledTableCell align="right"></StyledTableCell>
                                                                    <StyledTableCell align="right"></StyledTableCell>
                                                                    <StyledTableCell align="right">SGD $125.86</StyledTableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                        </Table>
                                                    </TableContainer>
                                                    <Box className="bottom-box">
                                                        <Typography component="p" className='title'>If something appears to be missing or incorrect, please contact our Customer Support Specialists.</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </CustomTabPanel>
                                        <CustomTabPanel value={value} index={2}>
                                        <PostProject />
                                        </CustomTabPanel>
                                        <CustomTabPanel value={value} index={3}>
                                            Item four
                                        </CustomTabPanel>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box class="my-order-right-container">
                                        <Box className="top-container">
                                            <Box className="order-detail-heading">
                                                <Typography component="h4" className='title'>Order Details</Typography>
                                            </Box>
                                            <Box></Box>
                                        </Box>
                                        <Box className="img-box-container">
                                            <Box className="left-container">
                                                <img src={computerIcon} alt='' />
                                            </Box>
                                            <Box className="right-container">
                                                <Typography component="h5" className='title'>
                                                    Auditing for Houston Project
                                                </Typography>
                                                <Typography component="p" className='sub-title'>Completed</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="order-history-details">
                                            <Box className="left-container">
                                                <Typography className='title-left'>Ordered from</Typography>
                                            </Box>
                                            <Box className="right-container">
                                                <Typography className='title-right'>Usman Abbasi</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="order-history-details">
                                            <Box className="left-container">
                                                <Typography className='title-left'>Delivery Date</Typography>
                                            </Box>
                                            <Box className="right-container">
                                                <Typography className='title-right'>April 12, 9:45PM</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="order-history-details">
                                            <Box className="left-container">
                                                <Typography className='title-left'>Total Price</Typography>
                                            </Box>
                                            <Box className="right-container">
                                                <Typography className='title-right'>SGD 125.86</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="order-history-details">
                                            <Box className="left-container">
                                                <Typography className='title-left'>Order Number</Typography>
                                            </Box>
                                            <Box className="right-container">
                                                <Typography className='title-right'>#ON123475967</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="support-container">
                                            <Typography component="h5" className='title'>Support</Typography>
                                            <Box className="faq-box">
                                                <Box className="first-box">
                                                    <img src={questionMarkIcon} alt='' />
                                                </Box>
                                                <Box className="middle-box">
                                                    <Typography component="h4" className='title'>FAQs</Typography>
                                                    <Typography component="h5" className='sub-title'>Have a question?</Typography>
                                                </Box>
                                                <Box className="last-box">
                                                    <img src={rightArrow} alt='' />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>

                    </Box>
                </Box>
            </Box>
       
    )
}

export default DashboardMyOrder