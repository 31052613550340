// buyParcel.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import convertToDecimal from "../Common/convertToDecimal";
import convertToSixDecimal from "../Common/convertToSixDecimal";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Call buyParcel method to create transaction for full payment
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Number} stakeAmt amount to stake
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function buyParcel(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // set active step to 2
        handleActiveStep(2);

        // let token_address = CONTRACTS.TOKEN;
        // let amount = convertToDecimal(assetData?.amount)
        // if (assetData?.feeTokenType && (assetData?.feeTokenType === 'USDT' || assetData?.feeTokenType === 'USDC')) {
        //     token_address = CONTRACTS.USDT;
        //     amount = convertToSixDecimal(assetData?.amount)
        // }

        let token_address = getCurrencyTypeAddress(assetData?.feeTokenType);
        let amount = getCurrencyTypeDecimal(assetData?.feeTokenType, assetData?.amount);

        const { request: buyParcel } = await prepareWriteContract({
            address: CONTRACTS.ART_PARCEL,
            abi: CONTRACT_ABI[CONTRACTS.ART_PARCEL],
            account: address,
            chainId: 6805,
            functionName: "buyParcel",
            args: [
                assetData?.listing_id, // asset listing id
                assetData?.parcel_ids, // parcel ids array
                amount, // sum of selected parcel price amount
                token_address // fee token
            ]
        })

        const { hash } = await writeContract(buyParcel);

        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data, assetData?.amount, assetData?.asset_id, assetData,)
                    handleModalClose();
                    handleActiveStep(-1)
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error calling buy parcel:', error);
        throw error; // propagate the error
    }
}

export { buyParcel };