import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import io from 'socket.io-client';
import { updateMsgUnreadCount } from '../features/auth/chatSlice';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(io(process.env.REACT_APP_CHAT_URL));
    const dispatch = useDispatch();


    useEffect(() => {
        if (socket) {
            let user = JSON.parse(localStorage.getItem('user_data'));
            if (user) {
                socket.emit("setup", user?.id);
                socket.emit("refresh-unread", user?.id);
            }

            socket.on("unread", (data) => {
                dispatch(updateMsgUnreadCount(data.data));
            });

            socket.on("join-chat-call", (data) => {
                socket.emit("join-chat", data);
                // dispatch(updateMsgUnreadCount(data.data));
            });

            socket.on('message-recieved', (data) => {
                let user = JSON.parse(localStorage.getItem('user_data'));
                // this refreshes the unread count whenever message is received from the socket in any chat
                socket.emit("refresh-unread", user?.id);
            })
        }

        return () => {
            socket.disconnect();
        };
    }, [socket])



    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => {
    return useContext(SocketContext);
};
