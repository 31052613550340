import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const Pricing = () => {
  return (
    <Box className="list-service-pricing-container">
      <Box className="top-view">
        <Box>
          <Typography className="font-36">Price & Scope</Typography>
        </Box>
        <Box>
          <FormControlLabel
            value="start"
            control={<Switch color="primary" />}
            label="3 tiers"
            labelPlacement="start"
          />
        </Box>
      </Box>
      <Typography className="font-18">
        Customize your service with 1 or 3 pricing tiers
      </Typography>
      <Box className="pricing-container">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box>
              <Typography>Custom Title</Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  id="outlined-multiline-static"
                  label="Multiline"
                  multiline
                  rows={2}
                  defaultValue="Default Value"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-multiline-static"
                  label="Multiline"
                  multiline
                  rows={2}
                  defaultValue="Default Value"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-multiline-static"
                  label="Multiline"
                  multiline
                  rows={2}
                  defaultValue="Default Value"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box className="" mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box>
                <Typography>Custom Description</Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Multiline"
                    multiline
                    rows={4}
                    defaultValue="Default Value"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Multiline"
                    multiline
                    rows={4}
                    defaultValue="Default Value"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Multiline"
                    multiline
                    rows={4}
                    defaultValue="Default Value"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="pricing-container">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box>
              <Typography>Delivery days</Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box>
                <Typography className="font-14">Number of Revisions</Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className="pricing-container">
        <Typography className="font-18">Service Tier Options</Typography>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box>
                <Typography className="font-14">
                  Include Additional Services included in each tier
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="pricing-container">
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box>
                <Typography className="font-14">Service Price</Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="btn-content-div">
      <Stack direction="row" spacing={2} alignItems="center">
                    <Box className='go-back-btn'>
                        <Button startIcon={<ArrowBackIcon />} >
                            Go Back
                        </Button>
                    </Box>
                    <Box className="continue-btn">
                        <Button
                            variant="contained"
                            startIcon={<ArrowForwardIcon />}
                            className='btn-rounded btn-large'
                            style={{ background: "var(--golden-gradient)" }}
                           
                        >
                            Continue
                        </Button>
                    </Box>
                </Stack>
      </Box>
    </Box>
  );
};

export default Pricing;
