import React from 'react';
import { Box, Stack, Rating } from '@mui/material';
import PropTypes from 'prop-types';
import "./RatingCompo.css";

const RatingCompo = (props) => {
    return (
        <Stack direction="row" alignItems={'center'} flexWrap="wrap" spacing={1} className='rating-wrap'>
            <Box className="value" component={'span'}>{props?.rating}</Box>
            <Rating name="read-only" value={props?.rating} readOnly precision={0.5} />
            {
                props?.review && <Box className="review" component={'span'}>{`${props?.review}`} Reviews</Box>
            }
        </Stack>
    )
}

export default RatingCompo

//prop types
RatingCompo.propTypes = {
    rating: PropTypes.number.isRequired,
    review: PropTypes.number
}

//default props
RatingCompo.defaultProps = {
    rating: 0,
}