// storeCallData.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";

/**
 * Call the storeCallData method for DIC to complete vote
 * @param {String} address user wallet address
 * @param {Number} proposalId asset's blockchain id
 * @param {Boolean} dicVoteType approve or reject
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function storeCallData(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {

    try {
        let compilanceAddress = assetData?.data?.map((item) => item.compilance)
        let functionData = assetData?.data?.map((item) => item.result);
        const { request: storeCallData } = await prepareWriteContract({
            address: CONTRACTS.DIC_PROPOSAL,
            abi: CONTRACT_ABI[CONTRACTS.DIC_PROPOSAL],
            account: address,
            chainId: 6805,
            functionName: "storeCallData",
            args: [
                assetData?.proposalId, // asset's blockchain id
                {
                    compilanceAddress,
                    functionData
                }
            ]
        })

        const { hash } = await writeContract(storeCallData)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                handleActiveStep(2);
                showSnackbar("Transaction Successful", 'success')
                setTimeout(() => {
                    handleSuccess()
                    handleModalClose();
                    handleActiveStep(-1)

                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error calling store call data:', error);
        throw error; // propagate the error
    }
}

export { storeCallData };