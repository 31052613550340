import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
import { FileUpload, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, Grid, Typography, Stack, IconButton, Tooltip } from '@mui/material'
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
// Import custom components for this page
import { NewLogo } from '../../../Components/Images/Images'
import { useSnackbar } from '../../../Contexts/SnackbarContext';
import LinearProgressCompo from './Components/LinearProgressCompo';
import DropDownAssetPhotos from './Components/DropDownAssetPhotos';
import "./Components/Questionnaire.css"

export default function AddAssetPhotos(props) {
  // Destructuring props to extract necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, EditMode, saveProposal, getProgress } = props;

  // Get current proposal and progress data from the parent component
  let propdata = getProposalData();
  let currentProgress = getProgress();
  const { showSnackbar } = useSnackbar()

  // Initialize arrays for different image-related operations
  let viewArray = []
  let selectArray = []
  let editArray = []
  // Check if there is a cover photo in the proposal data then updated all the arrays
  if (propdata?.asset_coverphoto) {
    for (let i = 0; i < propdata?.gellary?.length; i++) {
      viewArray.push(propdata?.gellary_view[i])
      // only for edit mode
      if (EditMode) {
        editArray.push(propdata?.gallery_photo_id[i])
        if (typeof propdata?.gellary[i] === "string") {
          selectArray.push(propdata?.gellary[i])
        }
        else {
          selectArray.push(propdata?.gellary[i])
        }
      } else {
        selectArray.push(propdata?.gellary[i])
      }
    }
  }

  // Initialize states for image management
  const [selected, setSelected] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState((viewArray?.length) ? viewArray : [])
  const [uploadedImage, setUploadedImage] = useState(selectArray?.length ? selectArray : [])
  const [galleryEdited, setGalleryEdited] = useState(editArray?.length ? editArray : [])
  const [deletedPhoto, setDeletedPhoto] = useState(propdata?.deleted_photo_id ? propdata?.deleted_photo_id : [])

  // Function to handle file drop
  const onDrop = useCallback(acceptedFiles => {
    const acceptedFiles1 = acceptedFiles?.filter((images) => {
      return images.size <= 12000000
    })
    if (acceptedFiles1.length !== acceptedFiles.length) {
      showSnackbar("Please upload an image with a maximum size of 12 MB.", 'error')
    }
    let dataDetail = [...uploadedImage, ...acceptedFiles1]
    setUploadedImage(dataDetail);
    let data = acceptedFiles1.map(file => URL.createObjectURL(file))
    let dataDetailView = [...selectedFiles, ...data]
    setSelectedFiles(dataDetailView);
  }, [selectedFiles])

  // Configuration for file dropzone
  let { getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [".jpeg", ".jpg", ".png"],
    },
    // maxSize: 12 * 1024 * 1024, // 12MB in bytes
    uploadMultiple: true,
    onDrop
  });

  // Function to handle the "Next" button click
  const handleClick = async () => {
    if (uploadedImage[selected] != propdata?.asset_coverphoto) {
      propdata.cover_photo_changed = true;
      propdata.parcelsDetails = []
      propdata.isParcelCreated = true;
    }
    propdata.asset_coverphoto = uploadedImage[selected];
    if (selectedFiles[selected]?.startsWith('proposer/uploads')) {
      const response = await fetch(`${process.env.REACT_APP_IMAGE_URL}${selectedFiles[selected]}`);
      if (response) {
        const blob = await response.blob(); // converting to blob
        const blobUrl = URL.createObjectURL(blob); // converting to object url
        propdata.asset_coverphoto_view = blobUrl;
      }
    } else {
      propdata.asset_coverphoto_view = selectedFiles[selected];
    }

    propdata.gellary = uploadedImage;
    propdata.gellary_view = selectedFiles;
    if (EditMode) {
      propdata.gallery_photo_id = galleryEdited;
      propdata.deleted_photo_id = deletedPhoto;
    }
    // update it to main page
    updateProposalData(propdata)
    handleNext()
  }

  // Function to handle the "Save and Exit" button click
  const SaveAndExit = async () => {
    if (uploadedImage[selected] != propdata?.asset_coverphoto) {
      propdata.cover_photo_changed = true;
      propdata.parcelsDetails = []
      propdata.isParcelCreated = true;
    }
    propdata.asset_coverphoto = uploadedImage[selected];
    if (selectedFiles[selected]?.startsWith('proposer/uploads')) {
      const response = await fetch(`${process.env.REACT_APP_IMAGE_URL}${selectedFiles[selected]}`);
      if (response) {
        const blob = await response.blob(); // converting to blob
        const blobUrl = URL.createObjectURL(blob); // converting to object url
        propdata.asset_coverphoto_view = blobUrl;
      }
    } else {
      propdata.asset_coverphoto_view = selectedFiles[selected];
    }

    propdata.gellary = uploadedImage;
    propdata.gellary_view = selectedFiles;
    if (EditMode) {
      propdata.gallery_photo_id = galleryEdited;
      propdata.deleted_photo_id = deletedPhoto;
    }
    // update it to main page
    updateProposalData(propdata)
    saveProposal();
  }

  // Function to handle going back
  const handleBack = async () => {
    handleback()
  }

  // Function to set the cover photo
  const SetCoverPhoto = (id) => {
    if (id > 0) {
      setSelected(0);
      // updating for frontend
      const newSelectedFiles = [...selectedFiles];
      let temp1 = newSelectedFiles[id];
      newSelectedFiles[id] = newSelectedFiles[0];
      newSelectedFiles[0] = temp1;
      setSelectedFiles(newSelectedFiles);

      // updating for backend
      let newUploadedImage = [...uploadedImage]
      let temp2 = newUploadedImage[id];
      newUploadedImage[id] = newUploadedImage[0];
      newUploadedImage[0] = temp2;
      setUploadedImage(newUploadedImage);

      if (EditMode) {
        // updating for backend
        let newGalleryEdited = [...galleryEdited]
        let temp3 = newGalleryEdited[id];
        newGalleryEdited[id] = newGalleryEdited[0];
        newGalleryEdited[0] = temp3;
        setGalleryEdited(newGalleryEdited);
      }
    }
  }

  // Function to remove an image
  const RemoveImage = (id) => {
    // gallery view
    setSelectedFiles(selectedFiles.filter((el, index) => {
      return index != id;
    }))
    // gallery
    setUploadedImage(uploadedImage.filter((el, index) => {
      return index != id;
    }))
    if (EditMode && galleryEdited[id]) {
      setDeletedPhoto([...deletedPhoto, galleryEdited[id]])
      setGalleryEdited(galleryEdited.filter((el, index) => {
        return index != id;
      }))
    }
  }

  //  Function to handle next 
  const MoveNext = (id) => {
    // && id > 0
    if (selectedFiles?.length - 1 > id) {
      // updating for frontend
      const newSelectedFiles = [...selectedFiles];
      let temp1 = newSelectedFiles[id];
      newSelectedFiles[id] = newSelectedFiles[id + 1];
      newSelectedFiles[id + 1] = temp1;
      setSelectedFiles(newSelectedFiles);

      // updating for backend
      let newUploadedImage = [...uploadedImage]
      let temp2 = newUploadedImage[id];
      newUploadedImage[id] = newUploadedImage[id + 1];
      newUploadedImage[id + 1] = temp2;
      setUploadedImage(newUploadedImage);

      if (EditMode) {
        // updating for backend
        let newGalleryEdited = [...galleryEdited]
        let temp3 = newGalleryEdited[id];
        newGalleryEdited[id] = newGalleryEdited[id + 1];
        newGalleryEdited[id + 1] = temp3;
        setGalleryEdited(newGalleryEdited);
      }
    }
  }

  //  Function to handle  previous
  const MovePrevious = (id) => {
    if (1 < id) {
      // updating for frontend
      const newSelectedFiles = [...selectedFiles];
      let temp1 = newSelectedFiles[id];
      newSelectedFiles[id] = newSelectedFiles[id - 1];
      newSelectedFiles[id - 1] = temp1;
      setSelectedFiles(newSelectedFiles);

      // updating for backend
      let newUploadedImage = [...uploadedImage]
      let temp2 = newUploadedImage[id];
      newUploadedImage[id] = newUploadedImage[id - 1];
      newUploadedImage[id - 1] = temp2;
      setUploadedImage(newUploadedImage);

      if (EditMode) {
        // updating for backend
        let newGalleryEdited = [...galleryEdited]
        let temp3 = newGalleryEdited[id];
        newGalleryEdited[id] = newGalleryEdited[id - 1];
        newGalleryEdited[id - 1] = temp3;
        setGalleryEdited(newGalleryEdited);
      }
    }
  }

  // handle drag end
  const handleDragEnd = (e, newIndex) => {
    e.preventDefault();
    // updating for frontend
    const newSelectedFiles = [...selectedFiles];
    let temp1 = newSelectedFiles[newIndex];
    newSelectedFiles[newIndex] = newSelectedFiles[draggedIndex];
    newSelectedFiles[draggedIndex] = temp1;
    setSelectedFiles(newSelectedFiles);

    // updating for backend
    let newUploadedImage = [...uploadedImage]
    let temp2 = newUploadedImage[newIndex];
    newUploadedImage[newIndex] = newUploadedImage[draggedIndex];
    newUploadedImage[draggedIndex] = temp2;
    setUploadedImage(newUploadedImage);

    if (EditMode) {
      // updating for backend
      let newGalleryEdited = [...galleryEdited]
      let temp3 = newGalleryEdited[newIndex];
      newGalleryEdited[newIndex] = newGalleryEdited[draggedIndex];
      newGalleryEdited[draggedIndex] = temp3;
      setGalleryEdited(newGalleryEdited);
    }
  };

  let draggedIndex = null;

  // handle drag start
  const handleDragStart = (e, index) => {
    draggedIndex = index;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.target.parentNode);
  };


  return (

    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Next, let’s add some photos of your asset
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right  quests-right-contents-box" >
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit} disabled={(uploadedImage[0]) ? false : true} className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box sx={{ padding: '' }}>
                <Box className="questionnaire5 qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                  <>
                    <Stack direction="row" mb={2} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography variant="h4" color={'black'}>Drag to reorder</Typography>
                      <Tooltip title="Upload Photos">
                        <Box
                          {...getRootProps({
                            className: "dropzone",
                          })}
                        >
                          <input {...getInputProps()} />
                          <IconButton sx={{
                            ":hover": {
                              bgcolor: " var(--blue-600)"

                            }
                          }} className='rc-fab-button' onClick={open}><FileUpload /></IconButton>
                        </Box>
                      </Tooltip>
                    </Stack>
                    {/* sx={{ background: isCoverPhoto == true ? "green !important" : '' }} */}
                    <OverlayScrollbarsComponent defer
                      style={{ width: '100%', minHeight: '340px', maxHeight: '52vh' }}
                    >
                      <Box sx={{ position: 'relative', width: '97%' }}>
                        {selectedFiles[0] &&
                          <> <Button className='btn-rounded btn-text-white btn-blue-600 rc-set-cover-btn'>Cover photo</Button>
                            <Box className='rc-single-add-photo-img' draggable="false" component={'img'} src={(selectedFiles[selected] && selectedFiles[selected]?.startsWith("proposer/uploads/")) ? `${process.env.REACT_APP_IMAGE_URL}${selectedFiles[selected]}` : selectedFiles[selected]} />
                          </>
                        }
                      </Box>
                      <Box sx={{ width: '97%' }}>
                        <Grid container spacing={{ xs: 1, sm: 1, md: 1 }}>
                          {(() => {
                            const deals = [];
                            for (let i = 0; i < selectedFiles?.length; i++) {
                              // if (selected != i) {
                              deals.push(
                                <Grid item md={6} sm={12} xs={12} key={i}
                                // onClick={() => setSelected(i)}
                                >
                                  <Box
                                    className='rc-single-add-photo-card'
                                    sx={{ position: 'relative' }}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, i)}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDrop={(e) => handleDragEnd(e, i)}>
                                    <Box className="ASPDropDown">
                                      <DropDownAssetPhotos SetCoverPhoto={SetCoverPhoto} RemoveImage={RemoveImage} MoveNext={MoveNext} MovePrevious={MovePrevious} ImageId={i} Conditions={selected === i ? true : false} />
                                    </Box>
                                    <Box className='rc-single-add-photo-img' component={'img'} src={selectedFiles[i].startsWith("proposer/uploads/") ? `${process.env.REACT_APP_IMAGE_URL}${selectedFiles[i]}` : selectedFiles[i]} />
                                  </Box>
                                </Grid>
                              );
                              // }
                            }
                            return deals;
                          })()}
                        </Grid>
                      </Box>
                    </OverlayScrollbarsComponent>
                  </>
                </Box>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleBack}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} >
                    <Tooltip title={(uploadedImage[0]) ? "" : "Please upload some photos to proceed."} className="p-flow-next-btn-tooltip">
                      <span>
                        <Button
                          disabled={(uploadedImage[0]) ? false : true}
                          onClick={handleClick} endIcon={<KeyboardArrowRight />}
                          className="btn-rounded btn-text-white btn-blue-600"
                        >
                          Next
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box >
  )
}
