import React from "react";
import { Chip, Box, Avatar, Typography, Badge, Rating, Stack, } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { SaveDeal } from "../../../Components/Images/Images";
import { BookmarkBorder } from "@mui/icons-material";
import { FiberNew, LocalFireDepartment } from "@mui/icons-material";
import "./MainCard.css";

const MainCard = (props) => {

  return (
    <>
      <Box className="product-commonCard" sx={{ background: props?.bgColor ? props?.bgColor : '' }}>
        <Box className="pc-card-body" position={"relative"}>
          <Box className="pc-bookmark" display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <BookmarkBorder />
          </Box>
          <Box className='type-chip-icon'>
            <Chip icon={<LocalFireDepartment />} label={"Popular"} />
          </Box>
          <Box className="pc-thumbnail-wrap">
            <Box className="pc-slide" sx={{ cursor: "pointer" }}>
              <img src={`${process.env.REACT_APP_IMAGE_URL}${props?.coverphoto?.path}`} alt="Slide Image" />
            </Box>
          </Box>
          <Typography component={"h5"} className="pc-title text-white">
            {props.services_title ? props.services_title : "No Title"}
          </Typography>
          <Typography className="pc-subtitle text-white">
            {props.budget_from ? `From ${props.budget_from}` : "No Range"}
          </Typography>

          <Box className="pc-chip-wrap" >
            <Stack direction="row" flexWrap={'wrap'} display={"flex"} spacing={1}>
              <Chip className="pc-chip" label={props?.skillData ? props?.skillData[0]?.skill_name : null} />
              <Chip className="pc-chip" label={props?.serviceCategory?.title} />
            </Stack>
          </Box>
          <Box className="pc-user-wrap" display={"flex"} alignItems={"center"} mt={3}>
            <Box className="user-thumbnail" display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <Badge badgeContent={<DoneIcon />} anchorOrigin={{ vertical: "bottom", horizontal: "right", }}>
                <Avatar className="user-avatar" alt="User" src={props.ownerImage ?? SaveDeal} />
              </Badge>
            </Box>
            <Box className="user-details" ml={1.8}>
              <Typography className="user-title text-white">
                {props?.userData ? `${props?.userData?.first_name}  ${props?.userData?.last_name}` : null}
              </Typography>
              <Typography className="user-subtitle">
                {"Top Rated Service Provider"}
              </Typography>
            </Box>
          </Box>
          <Stack direction="row" flexWrap="wrap" spacing={1} className='rating-wrap text-golden'>
            <Box className="value" component={'span'}>{3.0}</Box>
            <Rating name="read-only" value={3} readOnly precision={0.5} />
            <Box className="review" component={'span'}>{`${1.185}`} Reviews</Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default MainCard;
