import React, { useState } from "react";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  Typography,
  Box,
  styled,
  Drawer,
  IconButton,
  List,
  Badge,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  Assignment,
  AttachMoney,
  Bookmarks,
  ChevronLeft,
  ChevronRight,
  HomeWork,
  Settings,
  Message,
} from "@mui/icons-material";

import RoomServiceRoundedIcon from "@mui/icons-material/RoomServiceRounded";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import DescriptionIcon from "@mui/icons-material/Description";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MessageIcon from "@mui/icons-material/Message";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  padding: "12px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  padding: "12px",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  [theme.breakpoints.up("xs")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    marginTop: "64px",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: "#032744",
      borderRadius: "0px 16px 16px 0px",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "#032744",
      borderRadius: "0px 16px 16px 0px",
    },
  }),
}));

const ServiceProviderSideBar = ({ handleSidebarChange }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const lastSegment = window.location.href.split("/").pop();

  const { totalUnreadCount } = useSelector((state) => state.serviceChat);

  const handleDrawerOpen = () => {
    setOpen(true);
    handleSidebarChange(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleSidebarChange(false);
  };

  const handleClick = () => {
    navigate("/dummy");
  };

  return (
    <CustomDrawer className="left-sidebar" variant="permanent" open={open}>
      {open ? (
        <Box className="leftSidebar-toggle-wrap">
          <IconButton
            className="leftSidebar-toggle"
            onClick={handleDrawerClose}
          >
            <ChevronLeft />
          </IconButton>
        </Box>
      ) : (
        <Box className="leftSidebar-toggle-wrap">
          <IconButton className="leftSidebar-toggle" onClick={handleDrawerOpen}>
            <ChevronRight />
          </IconButton>
        </Box>
      )}
      <Box className="service-provider-sidebar-head">
        {open ? (
          <>
            <Box className="provide-service-btn" mb={2}>
              <Button
                variant="contained"
                className="btn btn-rounded btn-golden btn-large"
              >
                <RoomServiceRoundedIcon />
                <span className="btn-text ">Find Service</span>
              </Button>
            </Box>
            <Divider className="divider" />
          </>
        ) : (
          <>
            <Divider className="divider-closed" />
          </>
        )}
      </Box>
      <List>
        <Box className="service-provider-sidebar-menu">
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-provider-dashboard/overview"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "Workspace" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <WorkOutlinedIcon
                    style={{ marginRight: open ? "10px" : "0px" }}
                  />
                </Box>{" "}
              </Tooltip>
              {open && "Workspace"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-provider-dashboard/profile"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "My Profile" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <FactCheckIcon
                    style={{ marginRight: open ? "10px" : "0px" }}
                  />
                </Box>{" "}
              </Tooltip>
              {open && "My Profile"}
            </NavLink>
          </Box>

          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-provider-dashboard/my-jobs"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "My Jobs" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <DescriptionIcon
                    style={{ marginRight: open ? "10px" : "0px" }}
                  />
                </Box>{" "}
              </Tooltip>
              {open && "My Jobs"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-provider-dashboard/savedrequest"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "Saved Requests" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <BookmarksIcon
                    style={{ marginRight: open ? "10px" : "0px" }}
                  />
                </Box>{" "}
              </Tooltip>
              {open && "Saved Requests"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="" exact className={`nav-link`}>
              <Tooltip
                title={open === false ? "Wallet" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <AttachMoney style={{ marginRight: open ? "10px" : "0px" }} />
                </Box>{" "}
              </Tooltip>
              {open && "Wallet"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-provider-dashboard/chats"
              exact
              className={`nav-link`}
              style={{}}
            >
              <Tooltip
                title={open === false ? "Messages" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon" style={{}}>
                  {/* // forcefully 0 unread messages make badge  invisible when sidebar is expanded */}
                  <Badge
                    badgeContent={open ? 0 : totalUnreadCount}
                    color="error"
                  >
                    <Message style={{ marginRight: open ? "10px" : "0px" }} />
                  </Badge>
                </Box>{" "}
              </Tooltip>
              {open && "Messages"}
              <Badge
                badgeContent={open ? totalUnreadCount : 0}
                color="error"
                sx={{
                  transition: "opacity 0.5s ease-in",
                  opacity: open ? 1 : 0,
                  float: "right",
                  mt: "10px",
                }}
              />
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="" exact className={`nav-link`}>
              <Tooltip
                title={open === false ? "Documents" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <Assignment style={{ marginRight: open ? "10px" : "0px" }} />
                </Box>{" "}
              </Tooltip>
              {open && "Documents"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="" exact className={`nav-link`}>
              <Tooltip
                title={open === false ? "Settings" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <Settings style={{ marginRight: open ? "10px" : "0px" }} />
                </Box>{" "}
              </Tooltip>
              {open && "Settings"}
            </NavLink>
          </Box>
        </Box>
      </List>
    </CustomDrawer>
  );
};

export default ServiceProviderSideBar;
