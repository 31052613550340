import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { NewLogo } from "../../../Components/Images/Images";
import { Close } from "@mui/icons-material";
import { Box, TextField, Button, Typography, Avatar, Modal, Backdrop, Autocomplete, Tooltip, Checkbox } from "@mui/material";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import TransactionProgressModal from "../../../Components/SmartContract/TransactionProgressModal";
import { SET_OF_RULES } from "../../../constants";
import CountryCodes from "../../../Data/countries.json";
import { getRulesData } from "../../../Components/SmartContract/getRulesData";
import NumericFormatCustom from "../../../Components/Common/NumericFormatCustom";
import { GetApi } from "../../../Api/Api";
import "./SetStackRts.css";

const WhiteAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-clearIndicator": {
    color: "white",
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: "white",
  },
  "& .MuiInputBase-input": {
    color: "white", // Change the input text color to white
  },
});

//country code file
const country_codes = CountryCodes;

const style = {
  position: "relative",
  boxShadow: 24,
  borderRadius: "24px",
  width: "900px",
  height: "550px",
  // Media query for small devices
  "@media (max-width: 600px)": {
    width: "350px", // Set a different height for small screens
  },
  // Additional media queries for other screen sizes if needed
  "@media (min-width: 601px) and (max-width: 900px)": {
    // Custom styles for devices between 601px and 900px width
    width: "580px",
  },

  "@media (min-width: 901px) and (max-width: 1200px)": {
    // Custom styles for devices between 901px and 1200px width
    width: "750px",
  },

  // Specify the maximum width for large screens
  "@media (min-width: 1201px)": {
    maxWidth: "1043px",
    maxHeight: "687px",
  },
};

export default function SetRulesModal(props) {
  const { showSnackbar } = useSnackbar();

  const [allowCountries, setAllowCountries] = useState(
    props?.allowedCountries ? props?.allowedCountries : []
  );
  const [disAllowCountries, setDisAllowCountries] = useState(
    props?.disAllowedCountries ? props?.disAllowedCountries : []
  );
  const [restrictCountries, setRestrictCountries] = useState([]);
  const [unRestrictCountries, setUnRestrictCountries] = useState([]);

  const [allUser, setAllUser] = useState([]);
  const [allowUser, setAllowUser] = useState([]);
  const [disAllowUser, setDisAllowUser] = useState([]);

  const [filteredDisallowCountry, setFilteredDisallowCountry] = useState([]);
  const [filteredAllowCountry, setFilteredAllowCountry] = useState([]);
  const [filteredUnRestrictCountry, setFilteredUnRestrictCountry] = useState(
    []
  );
  const [filteredRestrictCountry, setFilteredRestrictCountry] = useState([]);
  const [filteredAllowUser, setFilteredAllowUser] = useState([]);
  const [filteredDisAllowUser, setFilteredDisAllowUser] = useState([]);
  const [maxBalance, setMaxBalance] = useState(0);
  const [supplyLimit, setSupplyLimit] = useState(0);

  const [checked, setChecked] = useState({
    allowCountry: false,
    disAllowCountry: false,
    restrictCountry: false,
    unRestrictCountry: false,
    allowUser: false,
    disAllowUser: false,
    maxBalance: false,
    supplyLimit: false,
  });

  const [storeData, setStoreData] = useState(
    props?.storeData ? props?.storeData : ""
  );
  const [openTransactionModal, setOpenTransactionModal] = useState(false);

  const handleSubmit = async () => {
    let data = await getRulesData(
      allowCountries,
      disAllowCountries,
      restrictCountries,
      unRestrictCountries,
      allowUser,
      disAllowUser,
      maxBalance,
      supplyLimit
    );

    if (data?.length) {
      let updateData = data?.map((item, index) => {
        let ans = item;
        switch (index) {
          case 0:
            ans = { ...item, compilance: SET_OF_RULES.batchAllowCountry };
            break;
          case 1:
            ans = { ...item, compilance: SET_OF_RULES.batchDisAllowCountry };
            break;
          case 2:
            ans = { ...item, compilance: SET_OF_RULES.batchRestricCountry };
            break;
          case 3:
            ans = { ...item, compilance: SET_OF_RULES.batchUnRestricCountry };
            break;
          case 4:
            ans = { ...item, compilance: SET_OF_RULES.batchAllowUser };
            break;
          case 5:
            ans = { ...item, compilance: SET_OF_RULES.batchDisallowUsers };
            break;
          case 6:
            ans = { ...item, compilance: SET_OF_RULES.setMaxBalance };
            break;
          case 7:
            ans = { ...item, compilance: SET_OF_RULES.setSupplyLimit };
            break;
        }
        return ans;
      });
      let filterData = [];
      if (checked.allowCountry) {
        filterData.push(updateData[0]);
      }
      if (checked.disAllowCountry) {
        filterData.push(updateData[1]);
      }
      if (checked.restrictCountry) {
        filterData.push(updateData[2]);
      }
      if (checked.unRestrictCountry) {
        filterData.push(updateData[3]);
      }
      if (checked.allowUser) {
        filterData.push(updateData[4]);
      }
      if (checked.disAllowUser) {
        filterData.push(updateData[5]);
      }
      if (checked.maxBalance) {
        filterData.push(updateData[6]);
      }
      if (checked.supplyLimit) {
        filterData.push(updateData[7]);
      }

      setStoreData({
        asset_id: props?.assetId,
        data: filterData,
        proposalId: props?.proposalId,
      });
      setOpenTransactionModal(true);
    }
  };

  const confirmStake = () => {
    props?.setIsSetRules(allowCountries, disAllowCountries);
    props?.handleCloseCountry();
  };

  const handleModalClose = () => {
    setOpenTransactionModal(false);
  };

  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/admin/getAppuser");
        if (res?.data?.data && res?.data?.data?.length) {
          let user = res.data?.data;
          let filterUser = user?.filter((item) => item.wallet_address);
          setAllUser(filterUser);
          setFilteredAllowUser(filterUser);
          setFilteredDisAllowUser(filterUser);
        } else {
          showSnackbar("Error in fetching user data", "error");
        }
      } catch (error) {
        showSnackbar(error, "error");
      }
    }
    getCategoryData();
  }, []);

  useEffect(() => {
    const filteredData = country_codes.filter(
      (item) => allowCountries.includes(item) === false
    );
    setFilteredDisallowCountry(filteredData);
  }, [allowCountries]);

  useEffect(() => {
    const filteredData = country_codes.filter(
      (item) => disAllowCountries.includes(item) === false
    );
    setFilteredAllowCountry(filteredData);
  }, [disAllowCountries]);

  useEffect(() => {
    const filteredData = country_codes.filter(
      (item) => restrictCountries.includes(item) === false
    );
    setFilteredUnRestrictCountry(filteredData);
  }, [restrictCountries]);

  useEffect(() => {
    const filteredData = country_codes.filter(
      (item) => unRestrictCountries.includes(item) === false
    );
    setFilteredRestrictCountry(filteredData);
  }, [unRestrictCountries]);

  useEffect(() => {
    const filteredData = allUser.filter(
      (item) => allowUser.includes(item) === false
    );
    setFilteredDisAllowUser(filteredData);
  }, [allowUser]);

  useEffect(() => {
    const filteredData = allUser.filter(
      (item) => disAllowUser.includes(item) === false
    );
    setFilteredAllowUser(filteredData);
  }, [disAllowUser]);

  return (
    <>
      {props.openAllowCountry ? (
        <Modal
          open={props.openAllowCountry}
          onClose={props.handleCloseCountry}
          className="kyc-modal"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          BackdropComponent={Backdrop}
          BackdropProps={{ open: false }}
        >
          <Box className="set-stack-rts" sx={style}>
            <Box className="p-headContent">
              <Box className="logo">
                <Avatar
                  alt="Logo"
                  src={NewLogo}
                  variant="square"
                  sx={{ width: 39, height: 90 }}
                />
              </Box>
              <Close className="close-btn" onClick={props.handleCloseCountry} />
            </Box>
            <Box className="allow-country-modal" mt={1} id="scroller-wrapper">
              <Box
                className="modalContentDone"
                py={{ md: "2px", sm: "0px" }}
                px={{ md: 18, sm: 2 }}
              >
                <Box className="select-country-section">
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "300px",
                      }}
                    >
                      <Checkbox
                        style={{ marginTop: "14px", color: "#fff" }}
                        checked={checked.allowCountry}
                        onChange={(event) =>
                          setChecked({
                            ...checked,
                            allowCountry: event.target.checked,
                          })
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <Typography className="font-18" mt={2}>
                        Select Allow Country
                      </Typography>
                    </Box>
                    {checked.allowCountry ? (
                      <WhiteAutocomplete
                        multiple
                        id="country-input"
                        options={filteredAllowCountry}
                        getOptionLabel={(option) => option.country}
                        value={allowCountries}
                        onChange={(e, newValue) => setAllowCountries(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select countries"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "white" },
                            }} // Input text color
                          />
                        )}
                        style={{ width: "300px", marginTop: "16px" }}
                        renderTags={(value, getTagProps) => (
                          <div>
                            {value.length > 2 ? (
                              <Tooltip
                                title={
                                  <Box>
                                    {allowCountries.length > 0 && (
                                      <span>
                                        <span>
                                          {allowCountries
                                            .map((country) => country.country)
                                            .join(", ")}
                                        </span>
                                      </span>
                                    )}
                                  </Box>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Button className="selected-country-btn">
                                  {`${value.length} Countries Selected`}
                                </Button>
                              </Tooltip>
                            ) : (
                              value.map((option, index) => (
                                <span
                                  key={index}
                                  {...getTagProps({ index })}
                                  style={{ color: "white" }}
                                >
                                  {option.country}
                                  {index < value.length - 1 && ", "}
                                </span>
                              ))
                            )}
                          </div>
                        )}
                      />
                    ) : null}
                  </Box>
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "300px",
                      }}
                    >
                      <Checkbox
                        style={{ marginTop: "14px", color: "#fff" }}
                        checked={checked.disAllowCountry}
                        onChange={(event) =>
                          setChecked({
                            ...checked,
                            disAllowCountry: event.target.checked,
                          })
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <Typography className="font-18" mt={2}>
                        Select Dis Allow Country
                      </Typography>
                    </Box>
                    {checked.disAllowCountry ? (
                      <WhiteAutocomplete
                        multiple
                        id="country-input"
                        options={filteredDisallowCountry}
                        getOptionLabel={(option) => option.country}
                        value={disAllowCountries}
                        onChange={(e, newValue) =>
                          setDisAllowCountries(newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select countries"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "white" },
                            }} // Input text color
                          />
                        )}
                        style={{ width: "300px", marginTop: "16px" }}
                        renderTags={(value, getTagProps) => (
                          <div>
                            {value.length > 2 ? (
                              <Tooltip
                                title={
                                  <Box>
                                    {disAllowCountries.length > 0 && (
                                      <span>
                                        <span>
                                          {disAllowCountries
                                            .map((country) => country.country)
                                            .join(", ")}
                                        </span>
                                      </span>
                                    )}
                                  </Box>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Button className="selected-country-btn">
                                  {`${value.length} Countries Selected`}
                                </Button>
                              </Tooltip>
                            ) : (
                              value.map((option, index) => (
                                <span
                                  key={index}
                                  {...getTagProps({ index })}
                                  style={{ color: "white" }}
                                >
                                  {option.country}
                                  {index < value.length - 1 && ", "}
                                </span>
                              ))
                            )}
                          </div>
                        )}
                      />
                    ) : null}
                  </Box>
                </Box>
                <Box className="select-country-section">
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "300px",
                      }}
                    >
                      <Checkbox
                        style={{ marginTop: "14px", color: "#fff" }}
                        checked={checked.restrictCountry}
                        onChange={(event) =>
                          setChecked({
                            ...checked,
                            restrictCountry: event.target.checked,
                          })
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <Typography className="font-18" mt={2}>
                        Select Restrict Country
                      </Typography>
                    </Box>
                    {checked.restrictCountry ? (
                      <WhiteAutocomplete
                        multiple
                        id="country-input"
                        options={filteredRestrictCountry}
                        getOptionLabel={(option) => option.country}
                        value={restrictCountries}
                        onChange={(e, newValue) =>
                          setRestrictCountries(newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select countries"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "white" },
                            }} // Input text color
                          />
                        )}
                        style={{ width: "300px", marginTop: "16px" }}
                        renderTags={(value, getTagProps) => (
                          <div>
                            {value.length > 2 ? (
                              <Tooltip
                                title={
                                  <Box>
                                    {restrictCountries.length > 0 && (
                                      <span>
                                        <span>
                                          {restrictCountries
                                            .map((country) => country.country)
                                            .join(", ")}
                                        </span>
                                      </span>
                                    )}
                                  </Box>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Button className="selected-country-btn">
                                  {`${value.length} Countries Selected`}
                                </Button>
                              </Tooltip>
                            ) : (
                              value.map((option, index) => (
                                <span
                                  key={index}
                                  {...getTagProps({ index })}
                                  style={{ color: "white" }}
                                >
                                  {option.country}
                                  {index < value.length - 1 && ", "}
                                </span>
                              ))
                            )}
                          </div>
                        )}
                      />
                    ) : null}
                  </Box>
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "300px",
                      }}
                    >
                      <Checkbox
                        style={{ marginTop: "14px", color: "#fff" }}
                        checked={checked.unRestrictCountry}
                        onChange={(event) =>
                          setChecked({
                            ...checked,
                            unRestrictCountry: event.target.checked,
                          })
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <Typography className="font-18" mt={2}>
                        Select Un Restrict Country
                      </Typography>
                    </Box>
                    {checked.unRestrictCountry ? (
                      <WhiteAutocomplete
                        multiple
                        id="country-input"
                        options={filteredUnRestrictCountry}
                        getOptionLabel={(option) => option.country}
                        value={unRestrictCountries}
                        onChange={(e, newValue) =>
                          setUnRestrictCountries(newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select countries"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "white" },
                            }} // Input text color
                          />
                        )}
                        style={{ width: "300px", marginTop: "16px" }}
                        renderTags={(value, getTagProps) => (
                          <div>
                            {value.length > 2 ? (
                              <Tooltip
                                title={
                                  <Box>
                                    {unRestrictCountries.length > 0 && (
                                      <span>
                                        <span>
                                          {unRestrictCountries
                                            .map((country) => country.country)
                                            .join(", ")}
                                        </span>
                                      </span>
                                    )}
                                  </Box>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Button className="selected-country-btn">
                                  {`${value.length} Countries Selected`}
                                </Button>
                              </Tooltip>
                            ) : (
                              value.map((option, index) => (
                                <span
                                  key={index}
                                  {...getTagProps({ index })}
                                  style={{ color: "white" }}
                                >
                                  {option.country}
                                  {index < value.length - 1 && ", "}
                                </span>
                              ))
                            )}
                          </div>
                        )}
                      />
                    ) : null}
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "300px",
                      }}
                    >
                      <Checkbox
                        style={{ marginTop: "14px", color: "#fff" }}
                        checked={checked.allowUser}
                        onChange={(event) =>
                          setChecked({
                            ...checked,
                            allowUser: event.target.checked,
                          })
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <Typography className="font-18" mt={2}>
                        Select Allow User
                      </Typography>
                    </Box>
                    {checked.allowUser ? (
                      <WhiteAutocomplete
                        multiple
                        id="country-input"
                        options={filteredAllowUser}
                        getOptionLabel={(option) => option.wallet_address}
                        value={allowUser}
                        onChange={(e, newValue) => setAllowUser(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Allow User"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "white" },
                            }} // Input text color
                          />
                        )}
                        style={{ width: "450px", marginTop: "16px" }}
                        renderTags={(value, getTagProps) => (
                          <div>
                            {value.length > 0 ? (
                              <Tooltip
                                title={
                                  <Box>
                                    {allowUser.length > 0 && (
                                      <span>
                                        <span>
                                          {allowUser
                                            .map(
                                              (country) =>
                                                country.wallet_address
                                            )
                                            .join(", ")}
                                        </span>
                                      </span>
                                    )}
                                  </Box>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Button className="selected-country-btn">
                                  {`${value.length} User Selected`}
                                </Button>
                              </Tooltip>
                            ) : (
                              value.map((option, index) => (
                                <span
                                  key={index}
                                  {...getTagProps({ index })}
                                  style={{ color: "white" }}
                                >
                                  {option.wallet_address}
                                  {index < value.length - 1 && ", "}
                                </span>
                              ))
                            )}
                          </div>
                        )}
                      />
                    ) : null}
                  </Box>
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "300px",
                      }}
                    >
                      <Checkbox
                        style={{ marginTop: "14px", color: "#fff" }}
                        checked={checked.disAllowCountry}
                        onChange={(event) =>
                          setChecked({
                            ...checked,
                            disAllowCountry: event.target.checked,
                          })
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <Typography className="font-18" mt={2}>
                        Select Dis Allow User
                      </Typography>
                    </Box>
                    {checked.disAllowCountry ? (
                      <WhiteAutocomplete
                        multiple
                        id="country-input"
                        options={filteredDisAllowUser}
                        getOptionLabel={(option) => option.wallet_address}
                        value={disAllowUser}
                        onChange={(e, newValue) => setDisAllowUser(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select countries"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "white" },
                            }} // Input text color
                          />
                        )}
                        style={{ width: "450px", marginTop: "16px" }}
                        renderTags={(value, getTagProps) => (
                          <div>
                            {value.length > 0 ? (
                              <Tooltip
                                title={
                                  <Box>
                                    {disAllowUser.length > 0 && (
                                      <span>
                                        <span>
                                          {disAllowUser
                                            .map(
                                              (country) =>
                                                country.wallet_address
                                            )
                                            .join(", ")}
                                        </span>
                                      </span>
                                    )}
                                  </Box>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Button className="selected-country-btn">
                                  {`${value.length} User Selected`}
                                </Button>
                              </Tooltip>
                            ) : (
                              value.map((option, index) => (
                                <span
                                  key={index}
                                  {...getTagProps({ index })}
                                  style={{ color: "white" }}
                                >
                                  {option.wallet_address}
                                  {index < value.length - 1 && ", "}
                                </span>
                              ))
                            )}
                          </div>
                        )}
                      />
                    ) : null}
                  </Box>
                </Box>
                <Box className="select-country-section">
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "300px",
                      }}
                    >
                      <Checkbox
                        style={{ marginTop: "14px", color: "#fff" }}
                        checked={checked.maxBalance}
                        onChange={(event) =>
                          setChecked({
                            ...checked,
                            maxBalance: event.target.checked,
                          })
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <Typography className="font-18" mt={2}>
                        Set Max Balance
                      </Typography>
                    </Box>
                    {checked.maxBalance ? (
                      <>
                        <TextField
                          id="country-input"
                          className="max-balance"
                          placeholder="Enter Max Balance"
                          variant="outlined"
                          value={maxBalance}
                          onChange={(e) => setMaxBalance(e.target.value)}
                          style={{ width: "300px", marginTop: "16px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                            style: { color: "white" },
                          }}
                          onKeyDown={(event) => {
                            if (
                              event?.key === "-" ||
                              event?.key === "+" ||
                              event?.key === "."
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <Typography
                          className="font-14"
                          mt={1}
                          style={{ color: "red" }}
                        >
                          {maxBalance == ""
                            ? "Max Balance is required"
                            : maxBalance == 0
                              ? "Max Balance can not be 0"
                              : ""}
                        </Typography>
                      </>
                    ) : null}
                  </Box>

                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "300px",
                      }}
                    >
                      <Checkbox
                        style={{ marginTop: "14px", color: "#fff" }}
                        checked={checked.supplyLimit}
                        onChange={(event) =>
                          setChecked({
                            ...checked,
                            supplyLimit: event.target.checked,
                          })
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <Typography className="font-18" mt={2}>
                        Set Supply Limit
                      </Typography>
                    </Box>
                    {checked.supplyLimit ? (
                      <>
                        <TextField
                          id="country-input"
                          className="max-balance"
                          placeholder="Enter Supply Limit"
                          variant="outlined"
                          value={supplyLimit}
                          onChange={(e) => setSupplyLimit(e.target.value)}
                          style={{ width: "300px", marginTop: "16px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                            style: { color: "white" },
                          }}
                          onKeyDown={(event) => {
                            if (
                              event?.key === "-" ||
                              event?.key === "+" ||
                              event?.key === "."
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <Typography
                          className="font-14"
                          mt={1}
                          style={{ color: "red" }}
                        >
                          {supplyLimit == ""
                            ? "Supply Limit is required"
                            : supplyLimit == 0
                              ? "Supply Limit can not be 0"
                              : ""}
                        </Typography>
                      </>
                    ) : null}
                  </Box>
                </Box>
                <Box style={{ width: "200px" }} mt={1}>
                  <Button
                    disabled={
                      checked.allowCountry ||
                        checked.disAllowCountry ||
                        checked.restrictCountry ||
                        checked.unRestrictCountry ||
                        checked.allowUser ||
                        checked.disAllowUser ||
                        checked.maxBalance ||
                        checked.supplyLimit
                        ? false
                        : true
                    }
                    className="wallet-button"
                    sx={{ background: "#1771BD !important" }}
                    onClick={handleSubmit}
                  >
                    <Box className="btn-icon">Submit</Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : null}

      {/* Transaction progress modal for dic suggestions(lead) */}
      {openTransactionModal ? (
        <TransactionProgressModal
          identity={"store-call-data"}
          confirmStake={confirmStake}
          propData={storeData}
          handleModalClose={handleModalClose}
          openTransactionModal={openTransactionModal}
        />
      ) : null}
    </>
  );
}
