import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Slider,
  Stack,
  Avatar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputBase,
  Paper,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import "./ClientDashboard.css";
import Grid from "@mui/material/Grid";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { CircleCheckIcon } from "../../Components/Images/Images";
import { Cancel, Upload } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { GetApi, deleteApi } from "../../Api/Api";
import { Search } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import RaceProjectCard from "./ServiceProviderProfilePage/RaceProjectCard/RaceProjectCard";
import SlickSlider from "./ServiceProviderProfilePage/SlickSlider/SlickSlider";
const DashboardPostProject = () => {
  const [step1Title, setStep1Title] = useState("");
  const [step2Skills, setStep2Skills] = useState([]);
  const [step3ProjectSize, setStep3ProjectSize] = useState("");
  const [step3ProjectTime, setStep3ProjectTime] = useState(20);
  const [step3ExperienceLevel, setStep3ExperienceLevel] = useState("");
  const [step4HourlyRateFrom, setStep4HourlyRateFrom] = useState("");
  const [step4HourlyRateTo, setStep4HourlyRateTo] = useState("");
  const [step4InputType, setStep4InputType] = useState("hourlyRate"); // State to track input type
  const [step4ProjectBudgetFrom, setStep4ProjectBudgetFrom] = useState("");
  const [step4ProjectBudgetTo, setStep4ProjectBudgetTo] = useState("");
  const [step5Description, setStep5Description] = useState("");
  const [textCount, setTextCount] = useState(["5000"]);
  const [isUploadedDocument, setIsUploadedDocument] = useState(false);
  const [uploadedImage, setUploadedImage] = useState([]);
  const [deletedDoc, setDeletedDoc] = useState([]);
  const [docFiles, setDocFiles] = useState([]);
  const [errors, setErrors] = useState({});

  const [categories, setCategories] = useState([]);
  const [categoryName, setcategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategorySkillData, setSelectedCategorySkillData] = useState(
    []
  );
  const [selectedSkills, setSelectedSkills] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  var userData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : ""; //Getting  user data from local storage

  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/services/spCategory");
        setCategories(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    getCategoryData();
  }, []);

  useEffect(() => {
    const previewVal =
      location?.state?.form === "PostProject"
        ? location?.state?.previewData?.data?.data
        : location?.state?.previewData;
    const selectedCategoryObject = categories?.find(
      (category) => category.id === previewVal?.CategoryId
    );
    if (location.state) {
      setStep1Title(previewVal.Title);
      setStep2Skills(
        selectedCategoryObject?.skillData.map((skill) => {
          return skill.skill_name;
        })
      );
      setcategoryName(previewVal?.Category);
      setSelectedSkills(selectedCategoryObject?.skillData);
      setStep3ProjectSize(previewVal.ProjectSize);
      setStep3ProjectTime(previewVal.ProjectTime);
      setStep3ExperienceLevel(previewVal.ExperienceLevel);
      setStep4HourlyRateFrom(previewVal.HourlyRateFrom);
      setStep4HourlyRateTo(previewVal.HourlyRateTo);
      setStep4InputType(
        previewVal?.projectBudget === "Project Budget"
          ? "projectBudget"
          : "hourlyRate"
      );
      setStep4ProjectBudgetFrom(previewVal.ProjectBudgetFrom);
      setStep4ProjectBudgetTo(previewVal.ProjectBudgetTo);
      setStep5Description(previewVal.Description);
      setUploadedImage(previewVal.Document);
      setSelectedCategory(previewVal.CategoryId);
    }
  }, [location, categories]);

  /**
   * FUnction to select Multiple Skills
   * @param {*} skill
   */
  const handleSkillSelection = (skill) => {
    const skillId = skill.id;
    const skillName = skill.skill_name;

    // Check if the skill is already selected
    if (!selectedSkills.some((selectedSkill) => selectedSkill.id === skillId)) {
      setSelectedSkills([
        ...selectedSkills,
        { id: skillId, skill_name: skillName },
      ]);
      setStep2Skills((prevSkills) => {
        const newSkills = prevSkills
          ? `${prevSkills} ${skillName} ,`
          : skillName;
        return newSkills;
      });
      // setStep2Skills([...step2Skills, { id: skillId, name: skillName }]);
    }
  };

  /**
   * Function to extract skill data from category and handlechange.
   * @param {*} event
   */
  const handleCategoryChange = async (event) => {
    const categoryTitle = event.target.value;
    setSelectedCategory(categoryTitle);
    setStep2Skills([]);
    setSelectedSkills([]);
    // Find the selected category object
    const selectedCategoryObject = categories?.find(
      (category) => category.id === categoryTitle
    );

    // Extract skillData from the selected category object
    if (selectedCategoryObject) {
      setSelectedCategorySkillData(selectedCategoryObject.skillData);
      setcategoryName(selectedCategoryObject.title);
    }
  };

  // handle uploaded docs
  const onDrop = useCallback(
    (acceptedFiles) => {
      const pdfFilesArray = acceptedFiles.filter(
        (file) => file.type === "application/pdf"
      );
      let dataDetail = [...uploadedImage, ...pdfFilesArray];
      setUploadedImage(dataDetail);
    },
    [uploadedImage]
  );

  const { getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { "image/jpeg": [".pdf"] },
    onDrop,
  });

  // handle deleted docs
  const handleDeleteImage = async (file) => {
    if (file) {
      const newDocFile = docFiles.filter((docFile) => {
        setDeletedDoc([...deletedDoc, docFile.id]);
        return docFile.image !== file;
      });
      setDocFiles(newDocFile);
    }
    const newUploadedImages = uploadedImage.filter(
      (image) => image.path !== file.path
    );
    setUploadedImage(newUploadedImages);

    /**
     * Calling Api to delete Document from backend
     */
    if (location.state.form === "MyPorjectPosting" && userData) {
      try {
        const res = await deleteApi(
          `/services/spMediaDelete?user_id=${userData.id}&image_id=${file.id}`
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  // preparing to show uploaded docs
  const files = uploadedImage?.map((file) => (
    <Box className="showUploadedDocuments">
      <Box
        key={file.path}
        className="showUploadedDocuments-wrap"
        display={"flex"}
        alignItems={"center"}
      >
        <Box className="fileName" mr={1}>
          {file?.path?.includes("services/uploads/")
            ? file.original_name
            : file.name}
        </Box>
        <Cancel
          onClick={() => handleDeleteImage(file)}
          className="removeIcon"
        />
      </Box>
    </Box>
  ));

  const isUploadDocuments = location.state
    ? files?.length > 0
    : files?.length > 0;

  /**
   * Validation function for the form Fields
   * @returns
   */
  const validate = () => {
    let errors = {};
    if (!step1Title) {
      errors.step1Title = "Title is required";
    } else {
      errors.step1Title = ""; // Clear the error if the field is not empty
    }
    if (!step2Skills) {
      errors.step2Skills = "Skills are required";
    } else {
      errors.step2Skills = ""; // Clear the error if the field is not empty
    }
    if (!step3ProjectSize) {
      errors.step3ProjectSize = "Project size is required";
    } else {
      errors.step3ProjectSize = ""; // Clear the error if the field is not empty
    }
    if (!step5Description) {
      errors.step5Description = "Description is required";
    } else {
      errors.step5Description = ""; // Clear the error if the field is not empty
    }
    setErrors(errors);
    return Object.keys(errors).every((key) => errors[key] === "");
  };

  const handleReviewProject = async () => {
    const isValid = validate();
    const formData = {
      Title: step1Title,
      Skills: selectedSkills,
      ProjectSize: step3ProjectSize,
      ProjectTime: step3ProjectTime,
      ExperienceLevel: step3ExperienceLevel,
      HourlyRateFrom: step4HourlyRateFrom,
      HourlyRateTo: step4HourlyRateTo,
      ProjectBudgetFrom: step4ProjectBudgetFrom,
      ProjectBudgetTo: step4ProjectBudgetTo,
      projectBudget: step4InputType,
      Description: step5Description,
      Category: categoryName,
      CategoryId: selectedCategory,
      Document: uploadedImage,
      from:
        location?.state?.form === "MyPorjectPosting" ? location.state.form : "",
      projectId:
        location?.state?.form === "MyPorjectPosting"
          ? location?.state.project_id
          : "",
    };

    if (isValid) {
      // Proceed with reviewing the project
      navigate("/service-proposer-dashboard/reviewproject", {
        state: { data: formData, form: "DashboardPostProject" },
      });
    }
  };

  const handleStep3ProjectSizeChange = (event) => {
    setStep3ProjectSize(event);
  };
  const SearchBox = () => (
    <Box className="sp-search-box">
      <Search className="icon" />
      <InputBase placeholder={"Search Services"} className="input" />
    </Box>
  );
  const handleStep3ExperienceLevelChange = (event) => {
    setStep3ExperienceLevel(event);
  };

  /**
   * Setting the lable for the slider
   * @param {*} value
   * @returns
   */
  function valueLabelFormat(value) {
    const units = "Month";
    return `${value} ${units}`;
  }

  const handleChangeDiscription = (e) => {
    // setStep5Description(e.target.value)
    const textData = e.target.value;
    if (textData.length < 5000) {
      setStep5Description(e.target.value);
    }
    setTextCount(5000 - textData.length);
  };

  return (
    <Box className="dashboard-overview-right service-proposer-postproject">
      <Box className="post-project-heading">
        <Typography component="h4" className="title">
          Post A Project Request
        </Typography>
      </Box>
      <Box className="post-project-container">
        {/* Step 1 */}
        <Box className="card-container">
          <Box className="left-section">
            <Typography component="p" className="font-16">
              Step 1
            </Typography>
            <Typography component="p" className="font-22">
              Start with a strong title.
            </Typography>
            <Typography component="p" className="font-12 text-blueGray-300">
              This helps your project post stand out to the right service
              providers. It’s the first thing they will see, so make it stick!
            </Typography>
          </Box>
          <Box className="right-section">
            <Box className="input-box">
              <TextField
                id="outlined-basic"
                label="Write a title for your project post"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                placeholder="E.g. Auditor needed for real estate project"
                value={step1Title}
                onChange={(e) => setStep1Title(e.target.value)}
                // error={!!errors.step1Title && !step1Title}
                // helperText={errors.step1Title}
              />
            </Box>
          </Box>
        </Box>

        {/* Category Step */}
        <Box className="card-container" mt={2}>
          <Box className="left-section">
            <Typography component="p" className="font-16">
              Step 2
            </Typography>
            <Typography component="p" className="font-22">
              Select project category.
            </Typography>
            <Typography component="p" className="font-16">
              Your skill set will reflect according to the category selected.
            </Typography>
          </Box>
          <Box className="right-section">
            <Box className="select-box">
              <FormControl>
                <InputLabel id="select-label">Select Category</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  fullWidth
                  // id="outlined-basic"
                  label="Select Category"
                  labelId="select-label"
                  placeholder="Select Category"
                >
                  {categories?.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>

        {/* Step 3 */}
        <Box className="card-container" mt={2}>
          <Box className="left-section">
            <Typography component="p" className="font-16">
              Step 3
            </Typography>
            <Typography component="p" className="font-22">
              What are the main skills required for your project?
            </Typography>
          </Box>
          <Box className="right-section">
            <Box className="input-box">
              <TextField
                id="outlined-basic"
                label="Search skills or add your own"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                placeholder="E.g. Auditor needed for real estate project"
                value={step2Skills}
                onChange={(e) => setStep2Skills(e.target.value)}
              />
            </Box>
            <Box className="button-div">
              {selectedCategorySkillData &&
                selectedCategorySkillData.map((skill) => (
                  <Button
                    key={skill.id}
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => handleSkillSelection(skill)}
                  >
                    {skill.skill_name}
                  </Button>
                ))}
            </Box>
          </Box>
        </Box>

        {/* Step 4 */}
        <Box className="card-container" mt={2}>
          <Box className="left-section">
            <Typography component="p" className="font-16">
              Step 4
            </Typography>
            <Typography component="p" className="font-22">
              Estimate the scope for your work.
            </Typography>
            <Typography component="p" className="font-12 text-blueGray-300">
              Consider the size of your project and the time it will take.
            </Typography>
          </Box>
          <Box className="right-section">
            <Box className="skill-container">
              <Box className="title-box">
                <Typography component="h4" className="title">
                  Project Size
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={4} sm={12}>
                    <Box
                      className={`card-main ${
                        step3ProjectSize === "Small" ? "selected" : ""
                      }`}
                      onClick={() => handleStep3ProjectSizeChange("Small")}
                    >
                      <Typography component="h4" className="title">
                        Small
                      </Typography>
                      <Typography component="p" className="sub-title">
                        Straightforward tasks
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4} sm={12}>
                    <Box
                      className={`card-main ${
                        step3ProjectSize === "Medium" ? "selected" : ""
                      }`}
                      onClick={() => handleStep3ProjectSizeChange("Medium")}
                    >
                      <Typography component="h4" className="title">
                        Medium
                      </Typography>
                      <Typography component="p" className="sub-title">
                        Well-defined projects
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4} sm={12}>
                    <Box
                      className={`card-main ${
                        step3ProjectSize === "Large" ? "selected" : ""
                      }`}
                      onClick={() => handleStep3ProjectSizeChange("Large")}
                    >
                      <Typography component="h4" className="title">
                        Large
                      </Typography>
                      <Typography component="p" className="sub-title">
                        Longer-term or complex work
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                <Typography component="h4" className="title">
                  How long will your work take?
                </Typography>
                <Box sx={{ width: "100%" }} mt={3} mb={2}>
                  <Slider
                    // defaultValue={50}
                    value={step3ProjectTime}
                    valueLabelFormat={valueLabelFormat}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    className="post-project-slider"
                    onChange={(e, newValue) => setStep3ProjectTime(newValue)}
                  />
                </Box>
              </Box>
              <Box className="title-box">
                <Typography component="h4" className="title font-16">
                  Level of experience required
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={4} sm={12}>
                    <Box
                      className={`card-main ${
                        step3ExperienceLevel === "Entry" ? "selected" : ""
                      }`}
                      onClick={() => handleStep3ExperienceLevelChange("Entry")}
                    >
                      <Typography component="h4" className="title font-18">
                        Entry
                      </Typography>
                      <Typography component="p" className="sub-title font-16">
                        Relatively new to this field
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4} sm={12}>
                    <Box
                      className={`card-main ${
                        step3ExperienceLevel === "Intermediate"
                          ? "selected"
                          : ""
                      }`}
                      onClick={() =>
                        handleStep3ExperienceLevelChange("Intermediate")
                      }
                    >
                      <Typography
                        component="h4"
                        className="title font-18 text-wrap"
                      >
                        Intermediate
                      </Typography>
                      <Typography component="p" className="sub-title font-16">
                        Substantial coverage in field
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4} sm={12}>
                    <Box
                      className={`card-main ${
                        step3ExperienceLevel === "Expert" ? "selected" : ""
                      }`}
                      onClick={() => handleStep3ExperienceLevelChange("Expert")}
                    >
                      <Typography component="h4" className="title font-18">
                        Expert
                      </Typography>
                      <Typography component="p" className="sub-title font-16 ">
                        Comprehensive expertise in field
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Step 5 */}
        <Box className="card-container" mt={2}>
          <Box className="left-section">
            <Typography component="p" className="font-16">
              Step 5
            </Typography>
            <Typography component="p" className="font-22">
              Tell us about your budget.
            </Typography>
            <Typography component="p" className="font-12 text-blueGray-300">
              This will help you reach talents within your range.
            </Typography>
          </Box>
          <Box className="right-section">
            <Box className="skill-container">
              <Box className="title-box">
                <Typography component="h4" className="title">
                  Budget
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} sm={12}>
                    <Box
                      className={`card-main ${
                        step4InputType === "hourlyRate" ? "selected" : ""
                      }`}
                      onClick={() => setStep4InputType("hourlyRate")}
                    >
                      <Typography component="h4" className="title">
                        Hourly Rate
                      </Typography>
                      <Typography component="p" className="sub-title">
                        Pay by hour
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={6} sm={12}>
                    <Box
                      className={`card-main ${
                        step4InputType === "projectBudget" ? "selected" : ""
                      }`}
                      onClick={() => setStep4InputType("projectBudget")}
                    >
                      <Typography component="h4" className="title">
                        Project Budget
                      </Typography>
                      <Typography component="p" className="sub-title">
                        Budget for the entire project
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={3}>
                  {step4InputType === "hourlyRate" ? (
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <Box className="input-box">
                          <TextField
                            id="outlined-basic"
                            label="From"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            placeholder="/hr"
                            value={step4HourlyRateFrom}
                            onChange={(e) =>
                              setStep4HourlyRateFrom(e.target.value)
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Box className="input-box">
                          <TextField
                            id="outlined-basic"
                            label="To"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            placeholder="/hr"
                            value={step4HourlyRateTo}
                            onChange={(e) =>
                              setStep4HourlyRateTo(e.target.value)
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : step4InputType === "projectBudget" ? (
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <Box className="input-box">
                          <TextField
                            id="outlined-basic"
                            label="From"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            placeholder="From"
                            value={"$" + step4ProjectBudgetFrom} // Concatenating "$" with input value
                            onChange={(e) =>
                              setStep4ProjectBudgetFrom(
                                e.target.value.substring(1)
                              )
                            } // Removing "$" before setting state
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Box className="input-box">
                          <TextField
                            id="outlined-basic"
                            label="To"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            placeholder="To"
                            value={"$" + step4ProjectBudgetTo}
                            onChange={(e) =>
                              setStep4ProjectBudgetTo(
                                e.target.value.substring(1)
                              )
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Step 6 */}
        <Box className="card-container" mt={2}>
          <Box className="left-section">
            <Typography component="p" className="font-16">
              Step 6
            </Typography>
            <Typography component="p" className="font-22">
              Describe the project
            </Typography>
            <Typography component="p" className="font-12 text-blueGray-300">
              Make sure you include:
            </Typography>
            <ul>
              <li className="font-12 text-blueGray-300">
                Clear expectations about your tasks or deliverables
              </li>
              <li className="font-12 text-blueGray-300">
                Skills required for your work
              </li>
              <li className="font-12 text-blueGray-300">
                Details about how you or your team like to work
              </li>
            </ul>
          </Box>
          <Box className="right-section">
            <Box className="skill-container">
              <Box className="title-box">
                <Typography component="h4" className="title">
                  Describe what you need
                </Typography>
              </Box>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "99%" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-multiline-flexible"
                  placeholder="Already have a description? Paste it here!"
                  multiline
                  maxRows={10}
                  rows={4}
                  value={step5Description}
                  onChange={(e) => handleChangeDiscription(e)}

                  // error={!!errors.step5Description && !step5Description}
                  // helperText={errors.step5Description}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography paddingLeft={"32px"} className="font-12">
                    {" "}
                    Enter at least 500 characters
                  </Typography>
                  <Typography paddingRight={"24px"} className="font-12">
                    {" "}
                    {textCount} characters left
                  </Typography>
                </Box>
              </Box>
              <Box className="attach-file-btn" mt={2}>
                <Box
                  {...getRootProps({
                    className: "dropzone",
                  })}
                >
                  <input {...getInputProps()} />
                  <Stack direction="row" spacing={0} flexWrap={"wrap"}>
                    <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 3 }}>
                      <Button
                        className="btn-rounded btn-blue-600 btn-text-white"
                        onClick={open}
                        startIcon={<Upload />}
                        sx={{ color: "white" }}
                      >
                        Attach Files
                      </Button>
                    </Box>
                  </Stack>
                </Box>
                {files !== "" ? (
                  <Box
                    sx={{
                      paddingBottom: "8px",
                      overflow: "hidden",
                    }}
                  >
                    {files?.map((fileName, ind) => {
                      return (
                        <>
                          {" "}
                          <Box
                            alignItems={"center"}
                            mt={1}
                            mb={2}
                            key={ind}
                            sx={{ display: "flex" }}
                          >
                            <Avatar
                              src={CircleCheckIcon}
                              alt="Icon"
                              sx={{ width: 24, height: 24 }}
                            />
                            <Box
                              className="dD-file-name text-black"
                              ml={1}
                              sx={{ color: "#fff" }}
                            >
                              {fileName}
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                    {isUploadedDocument && !isUploadDocuments && (
                      <Typography variant="body2" style={{ color: "#d32f2f" }}>
                        Upload document is required
                      </Typography>
                    )}
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Step 7 */}
        <Box className="card-main-view" mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} sm={6}>
              <Typography className="font-16 text-blueGray-300">
                Step 6 (Optional)
              </Typography>
              <Typography className="font-22 ">
                Link to existing RACE project
              </Typography>
            </Grid>
            <Grid item xs={8} md={8} sm={6}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  borderRadius: "30px",
                  background: "transparent",
                  color: "fff",
                  border: "1px solid #94A3B8",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1,}}
                  className="search-input"
                  placeholder="Search for your investment Projects"
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <IconButton
                  type="button"
                  sx={{
                    p: "10px",
                    background: "var(--golden-gradient)",
                    color: "#05131B",
                  }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} sm={6}></Grid>
          </Grid>
          <Box mt={3} p={2}>
            <SlickSlider />
          </Box>
        </Box>

        {/* Review Project Button */}
        <Box className="review-project-btn">
          <Button
            variant="outlined"
            startIcon={<RemoveRedEyeIcon />}
            onClick={handleReviewProject}
          >
            Review Project
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardPostProject;
