import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Box, styled, Drawer, IconButton, List, Badge, Tooltip } from "@mui/material";
import PieChartIcon from "@mui/icons-material/PieChart";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import MessageIcon from "@mui/icons-material/Message";
import Divider from "@mui/material/Divider";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import "./Sidebar.css";
import { AccountBalanceWallet, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useSelector } from "react-redux";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  padding: '12px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  padding: '12px',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  [theme.breakpoints.up('xs')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const CustomDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
      marginTop: '64px',
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        backgroundColor: "#032744",
        borderRadius: "0px 16px 16px 0px",
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        backgroundColor: "#032744",
        borderRadius: "0px 16px 16px 0px",
      },
    }),
  }),
);


const SidebarDic = ({ handleSidebarChange }) => {

  const { totalUnreadCount } = useSelector((state) => state.chat);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/dummy");
  };
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
    handleSidebarChange(true)
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleSidebarChange(false)
  };

  //getting current route
  // const location = useLocation();
  // let pathName = location.pathname.replace('/', '')
  // handling route change
  // const [tab, setTab] = useState({
  //    portfolio: pathName === 'portfolio' ? true : false,
  //    projects: pathName === 'projects' ? true : false,
  //    saved_deals: pathName === 'saved-deals' ? true : false,
  //    transactions: pathName === 'transactions' ? true : false,
  //    messages: pathName === 'chats' ? true : false,
  //    documents: pathName === 'documents' ? true : false,
  //    setting: pathName === 'settings' ? true : false,
  // })

  // Checking segment for the Active class for link
  const lastSegment = window.location.href.split("/").pop();

  return (
    <>
      <CustomDrawer className="left-sidebar dic-sidebar" variant="permanent" open={open}>
        {
          open ?
            <Box className="leftSidebar-toggle-wrap">
              <IconButton className="leftSidebar-toggle" onClick={handleDrawerClose} >
                <ChevronLeft />
              </IconButton>
            </Box>
            :
            <Box className="leftSidebar-toggle-wrap">
              <IconButton className="leftSidebar-toggle" onClick={handleDrawerOpen} >
                <ChevronRight />
              </IconButton>
            </Box>
        }

        <Box className="sidebar-head">
          {open ?
            <>
              <Button className="helpCenter-btn btn-rounded btn-yellow-60 btn-large" startIcon={<HelpCenterIcon />} onClick={handleClick} >
                {'Help Center'}
              </Button>
              <Divider className="divider" />
            </>
            :
            <>
              <Box className="helpCenter-btn-closed" onClick={handleClick} sx={{ padding: "10px", marginTop: '10px', width: '40px !important' }}>
                < HelpCenterIcon />
              </ Box>
              <Divider className="divider-closed" />
            </>
          }
        </Box>

        <List>
          {/* <Box className="sidebar-menu-wrap"> */}
          {/* <Scrollbars className="ctm-scroll"> */}
          <Box className="sidebar-menu">

            <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
              <NavLink to="/dic/overview" exact="true" className={`nav-link ${lastSegment === "overview" ? 'active' : ''}`} >
                <Tooltip title={open === false ? 'Overview' : ''} arrow placement="right">
                  <Box component={"span"} className="icon">
                    <PieChartIcon style={{ marginRight: open ? '10px' : "0px" }} />
                  </Box>
                </Tooltip>
                {open && <span>{" "}Overview</span>}
              </NavLink>
            </Box>

            <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
              <NavLink to="/dic/project" exact="true" className={`nav-link ${lastSegment === "project" ? 'active' : ''}`}>
                <Tooltip title={open === false ? 'Projects' : ''} arrow placement="right">
                  <Box component={"span"} className="icon">
                    <HomeWorkIcon style={{ marginRight: open ? '10px' : "0px" }} />
                  </Box>
                </Tooltip>
                {open && <span>{" "}Projects</span>}
              </NavLink>
            </Box>
            <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
              <NavLink to="Saveddealdic" exact="true" className={`nav-link ${lastSegment === "Saveddealdic" ? 'active' : ''}`}>
                <Tooltip title={open === false ? 'Saved Deals' : ''} arrow placement="right">
                  <Box component={"span"} className="icon">
                    <BookmarksIcon style={{ marginRight: open ? '10px' : "0px" }} />
                  </Box>
                </Tooltip>
                {open && <span>{" "}Saved Deals</span>}
              </NavLink>
            </Box>
            <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
              <NavLink to="/dic/chats" exact="true" className={`nav-link ${lastSegment === "chats" ? 'active' : ''}`} >
                <Tooltip title={open === false ? 'Messages' : ''} arrow placement="right">
                  <Box component={"span"} className="icon">
                    <Badge badgeContent={open ? 0 : totalUnreadCount} color='error'>
                      <MessageIcon style={{ marginRight: open ? '10px' : "0px" }} />
                    </Badge>
                  </Box>
                </Tooltip>
                {open && <span>{" "}Messages</span>}
                <Badge badgeContent={open ? totalUnreadCount : 0} color='error' sx={{ transition: 'opacity 0.5s ease-in', opacity: open ? 1 : 0, float: 'right', mt: '10px' }} />
              </NavLink>
            </Box>
            <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
              <NavLink to="/dic/wallet" exact="true" className={`nav-link ${lastSegment === "wallet" ? 'active' : ''}`} >
                <Tooltip title={open === false ? 'Wallet' : ''} arrow placement="right">
                  <Box component={"span"} className="icon">
                    <AccountBalanceWallet style={{ marginRight: open ? '10px' : "0px" }} />
                  </Box>
                </Tooltip>
                {open && <span>{" "}Wallet</span>}
              </NavLink>
            </Box>
            {/* <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
              <Box component={"span"} className="icon">
                <AccountBalanceWalletIcon style={{ marginRight: open ? '10px' : "0px" }} />
              </Box>{" "}
              {open && 'Wallet'}
            </Box>
            <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
              <Box component={"span"} className="icon">
                <SettingsIcon style={{ marginRight: open ? '10px' : "0px" }} />
              </Box>{" "}
              {open && 'Settings'}
            </Box> */}
          </Box>
          {/* </Scrollbars> */}
          {/* </Box> */}
        </List>
      </CustomDrawer>
    </>
  );
};

export default SidebarDic;
