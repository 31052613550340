import React from 'react'
import './WorkComponent.css'
import { Box, Stack, Typography } from '@mui/material';
import RatingCompo from '../RatingCompo/RatingCompo';
import PropTypes from 'prop-types';

// locale date options for date formatting e.g Aug 3, 2021
const dateLocaleOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
}


function WorkComponent(props) {

    return (
        <Box>
            <Box display={'flex'} spa alignItems={'center'} >
                <RatingCompo rating={props.workRating} />
                <Typography ml={2} variant='subtitle2'  sx={{color:'#9CA3AF'}}>
                    {new Date().toLocaleDateString(undefined, dateLocaleOptions)}
                    &nbsp;-&nbsp;
                    {props.workEndDate ? props.workEndDate.toLocaleDateString(undefined, dateLocaleOptions) : 'Present'}
                </Typography>
            </Box>
            <Box >
                <Typography variant='h6' sx={{ fontWeight: 400, color: '#fff' }}>{props.workTitle}</Typography>
                <Typography sx={{ mt: 1, color: '#9CA3AF' }}>{props.workDescription}</Typography>
            </Box>
            <Stack spacing={3} ml={1} mt={2} direction="row" >
                <Typography variant='subtitle2' sx={{ color: '#fff' }} >$1,320.00</Typography>
                <Typography variant='subtitle2' sx={{ color: '#fff' }} >${props.workPrice}/hr</Typography>
                <Typography variant='subtitle2' sx={{ color: '#fff' }} >{props.workPrice} hours</Typography>
            </Stack>

        </Box>
    )
}

export default WorkComponent

//prop types
WorkComponent.propTypes = {
    workTitle: PropTypes.string.isRequired,
    workDescription: PropTypes.string.isRequired,
    workRating: PropTypes.number.isRequired,
    workReview: PropTypes.number,
    workPrice: PropTypes.number.isRequired,
    workHours: PropTypes.number.isRequired,
    workStartDate: PropTypes.instanceOf(Date),
    workEndDate: PropTypes.instanceOf(Date),
}

//default props
WorkComponent.defaultProps = {
    workTitle: 'Fixes and new functions of the web app',
    workDescription: '“Outstanding experience. The service provided exceeded my expectations. Quick delivery and excellent communication. Highly recommended!"',
    workRating: 3.6,
    workReview: 632,
    workPrice: 3.50,
    workHours: 440,
    workStartDate: new Date(),
    workEndDate: null
}