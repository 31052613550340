import React, { useState, useEffect } from "react";
import "./OnboardingReviewPage.css";
import { Box, Grid, Typography, Stack, Pagination } from "@mui/material";
import { CHInformatics } from "../../Components/Images/Images"
import { Assignment } from "@mui/icons-material";
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import ListCard from "../Components/ListCard/ListCard";

const OnboardingRightProfile = ({ reviewPageData }) => {
  const [language, setLanuage] = useState([]);
  const [organizationVal, setOrganizationVal] = useState([]);

  /**
   * Setting Languages for the Left side Bar section
   */
  useEffect(() => {
    if (reviewPageData) {
      // Map languages and proficiencies, then join them with "|"
      const languageSelected =
        reviewPageData?.language?.length > 0 &&
        reviewPageData?.language
          ?.map((val) => {
            return `${val?.name}:${val?.proficiency}`;
          })
          .join(" | ");

      setLanuage(languageSelected);

      // Setting associated Organization

      // Map languages and proficiencies, then join them with "|"
      const organization =
        reviewPageData?.associatedOrganization?.length &&
        reviewPageData?.associatedOrganization
          ?.map((val) => {
            return `${val}`;
          })
          .join(" | ");

      setOrganizationVal(organization);
    }
  }, [reviewPageData]);

  return (
    <Box p={3} className="right-project-profile">
      <Box className="top-view border-top">
        <Box>
          <Typography className="font-32">0</Typography>
          <Typography className="font-14 sub-title">Total Jobs</Typography>
        </Box>
        <Box>
          <Typography className="font-32">0,000</Typography>
          <Typography className="font-14 sub-title">Total Hours</Typography>
        </Box>
      </Box>

      <Box className="top-view" mt={2}>
        <Box>
          <Typography className="font-12" sx={{ fontWeight: '500' }}>Avaibility</Typography>
        </Box>
        <Box>
          <Typography className="font-12 sub-title">
            {reviewPageData && reviewPageData?.availability === "open"
              ? "open to offer"
              : reviewPageData?.availability}
          </Typography>
        </Box>
      </Box>
      <Box className="top-view" mt={2}>
        <Box>
          <Typography className="font-12 " sx={{ fontWeight: '500' }}>Languages</Typography>
        </Box>
        <Box>
          <Typography className="font-12 sub-title">
            {language ? language : null}
          </Typography>
        </Box>
      </Box>
      <Box className="" mt={2}>
        <Box>
          <Typography className="font-12" sx={{ fontWeight: '500' }}>Education</Typography>
        </Box>
        <Box mt={2} ml={2}>
          {reviewPageData?.educationDetails &&
            reviewPageData?.educationDetails?.length > 0 ? (
            reviewPageData?.educationDetails?.map((val, index) => {
              const startDate = new Date(val.startDate);
              const endDate = new Date(val.endDate);

              const startYear = startDate.getFullYear();
              const endYear = endDate.getFullYear();

              return (
                <Box key={index}>
                  <Typography
                    variant="subtitle2"
                    className="font-12 text-white "
                    mb={2}
                  >
                    {val.school}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    className="font-12 text-blueGray-400"
                    mb={2}
                  >
                    {val.fieldOfStudy} <br />
                    {startYear}-{endYear}
                  </Typography>

                </Box>
              );
            })
          ) : (
            <Typography
              variant="subtitle1"
              className="font-12 text-blueGray-500"
            >
              No education details available.
            </Typography>
          )}
        </Box>
      </Box>

      {reviewPageData?.associatedOrganization?.length ? (
        <>
          <Box className="top-view" mt={2}>
            <Box>
              <Typography className="font-16">Associated with</Typography>
            </Box>
          </Box>

          <Box ml={-2} mt={1}>
            {reviewPageData?.associatedOrganization?.length > 0 ? reviewPageData?.associatedOrganization?.map((val, ind) => {
              return <ListCard key={ind} listIcon={<ApartmentOutlinedIcon />} bgColor={'#fff'} iconColor="#398CD1" type={'icon'} title={val} subtitle1={""} subtitle1Color='#398CD1' />
            })
              : "No Orgainzation Available"
            }
          </Box>
        </>
      ) : null}
      

      {reviewPageData?.documents?.length ? (
        <>
          <Box className="top-view" mt={2}>
            <Box>
              <Typography className="font-16">Documents</Typography>
            </Box>
          </Box>
          <Box ml={-2} mt={1}>
            {reviewPageData?.documents?.length > 0 ? reviewPageData?.documents.map((val, ind) => {
              return <ListCard key={ind} listIcon={<Assignment />} bgColor={'#fff'} iconColor="#398CD1" type={'icon'} title={val.path} subtitle1={val.title} subtitle1Color='#398CD1' />
            })
              : "No Documents Available"
            }
          </Box>

        </>
      ) : null}
    </Box>
  );
};

export default OnboardingRightProfile;
