import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { postApi } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import ProjectDetailFactory from "./ProjectDetailFactory";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ProjectDetails.css";

const ProjectDetailsPage = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar()
  const location = useLocation();
  const [currentImage, setCurrentImage] = useState("");
  const [overviewData, setOverviewData] = useState();
  const readOnly = "";
  // Navigating on my-project page on click of arrow.
  const backPage = () => {
    navigate(-1, {
      state: {
        from: "projectDetails",
      },
    });
  };

  /**
   * Check if it is overview page
   */
  const view = location?.pathname?.includes("/dic/project-details-view/");
  const view1 = location?.pathname?.includes("/dic/project-details/")

  const { assetId } = useParams();
  const [projectDetailsData, setProjectDetailsData] = useState({});
  useEffect(() => {
    async function getOverviewData() {
      try {
        const localData = JSON.parse(localStorage.getItem("user_data_dic"));
        if (localData?.id) {
          const overviewPayload = {
            asset_id: parseInt(assetId),
          };
          if (view || view1) {
            overviewPayload.committee_member_id = localData?.id
          }
          const res = await postApi("/proposer/assetsDetails", overviewPayload);
          if (res?.data?.data && res?.data?.data[0]) {
            // Inserting readOnly status into response to check whether it comes from overViewPage or not.
            res.data.data[0].readOnly = view;
            setProjectDetailsData(
              ProjectDetailFactory(
                res?.data?.data[0]?.category_id,
                res?.data?.data[0]
              )
            );
            setOverviewData(res.data.data[0]);
            setCurrentImage(
              `${process.env.REACT_APP_IMAGE_URL}${res.data.data[0]?.asset_coverphoto?.path}`
            );
          } else if (res?.data?.code === 201) {
            showSnackbar(res?.data?.message, 'error')
            setTimeout(() => {
              navigate('/dic/overview')
            }, 1000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    setTimeout(() => {
      getOverviewData();
    }, 100);
  }, [assetId]);

  return (
    <div className="main-layout">
      <Grid>
        <Box className="arrowHeader rightArrowSign">
          <Box className="container">
            <Box sx={{ cursor: "pointer", color: "white" }}>
              <ArrowBackIcon onClick={() => backPage()} />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Box className="pdPage-main projectDetails-page" pb={6.2} mb={0}>
        <Box className="container">
          <Box className="projectDetails-wrap">
            <Grid container spacing={2}>
              {/* this template id for left panel of project details page */}
              <Grid item sm={12} md={8} xs={12}>
                {projectDetailsData?.leftPanel?.length ?
                  <Box className="projectDetails-left">
                    <Box className="projectDetails-left-inner">
                      {
                        // left panel is loaded here
                        projectDetailsData.leftPanel &&
                        projectDetailsData.leftPanel.map((component, index) => {
                          return <Box key={index}>{component}</Box>;
                        })
                      }
                    </Box>
                  </Box>
                  : null}
              </Grid>

              {/* this template id for right panel of project details page */}
              <Grid item sm={12} md={4} xs={12}>
                {projectDetailsData.rightPanel?.length ?
                  <Box className="projectDetails-right">
                    <Box className="pdPage-rs">
                      {
                        // Right Panel is loaded here
                        (projectDetailsData.rightPanel || []).map((component) => {
                          return component;
                        })
                      }
                    </Box>
                  </Box> : null}
              </Grid>
            </Grid>
            {/* Start help button */}
            {/* <Button
              className="squareHelp-btn btn-rounded btn-yellow-100 btn-large"
              onClick={handleClick}
            >
              <Avatar
                alt="Icon"
                src={squareQuestionsMark}
                sx={{ width: "36px", height: "36px" }}
              ></Avatar>
            </Button> */}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ProjectDetailsPage;
