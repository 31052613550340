import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NewLogo } from "../../../Components/Images/Images";
import { Box, Grid, Typography, Button, TextField, CardActionArea, Paper, Stack, Tooltip } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import SliderCard from '../../../Components/Common/SliderCard'
import CropSquareIcon from '@mui/icons-material/CropSquare';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import { postApi } from "../../../Api/Api";
import { useForm } from "react-hook-form";
import "./Components/Questionnaire.css";

export default function SelectAssetSubType(props) {
  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // Getting current proposal and progress data from parent
  var propdata = getProposalData();
  let currentProgress = getProgress();

  // Initialize State variables
  const [selected, changeSelection] = useState(propdata?.asset_sub_category ? propdata?.asset_sub_category : "");
  const [selectedShape, setSelectedShape] = useState(propdata?.art_regular_shape == 0 ? 0 : 1);
  const [subCategoryData, setSubCategoryData] = useState("");

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      others_title: ((propdata?.asset_sub_category_url === "other") && propdata?.other_category) ? propdata?.other_category : null
    }
  });

  /** 
  * Function to set sub category id and url on asset sub category selected
  */
  function setSelected(data) {
    // update proposal data
    propdata.asset_sub_category = data?.id;
    propdata.asset_sub_category_url = data?.url;
    if (data?.url !== "other") {
      propdata.other_category = ""
    }
    // update it to main page
    updateProposalData(propdata)

    // update it to local
    changeSelection(data?.id);
  }

  /**
   * Handles the form submission.
   * Determines the selected category (art or other), updates the proposal data accordingly, and triggers the next step or Save and Exit based on the button clicked.
   * @param {Object} data - The form data.
   * @param {Object} event - The event object from the form submission.
   */
  const onSubmit = (data, event) => {
    // Get the inner text of the clicked button
    const buttonText = event.nativeEvent.submitter.innerText;

    // Check the selected category
    if (propdata?.selectedCategory === "art") {
      // Update the art_regular_shape in propdata
      propdata.art_regular_shape = selectedShape;
      // Update the proposal data
      updateProposalData(propdata);
    } else {
      // Update the other_category in propdata
      propdata.other_category = data?.others_title;
      // Update the proposal data
      updateProposalData(propdata);
    }

    // Check the button clicked to determine the next action
    if (buttonText.includes("Save and Exit")) {
      // Handle Save and Exit
      SaveAndExit();
    } else {
      // Handle Next button click
      handleNext();
    }
  }

  // Fetching sub categories data for asset type selected from server
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await postApi("/proposer/getSubcategory/", { "category_id": propdata?.category_id });
        setSubCategoryData(res?.data?.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData();
  }, [propdata?.category_id])

  // Handle save and exit
  const SaveAndExit = () => {
    saveProposal();
  }

  // This function will render only for other sub category type
  const htmlRender = () => {
    if (propdata?.selectedCategory === "energy") {
      return (
        <>
          <Typography variant="h4" color={'black'} gutterBottom>Energy type not above?</Typography>
          <Typography variant="body1" color={'black'} mb={3}>Fill in your category and we will review it</Typography>
          {(propdata?.asset_sub_category_url) ?
            <TextField id="outlined-basic" label="Other Energy Project" variant="outlined" name='others_title'
              {...register("others_title", {
                required: "Other Energy Project Title is required.",
              })}
              error={Boolean(errors.others_title)}
              helperText={errors.others_title?.message} />
            :
            <TextField disabled id="outlined-basic" label="Other Energy Project" variant="outlined" />
          }
        </>
      )
    } else if (propdata?.selectedCategory === "real-estate") {
      return (
        <>
          <Typography variant="h4" color={'black'} gutterBottom>Real Estate type not above</Typography>
          <Typography variant="body1" color={'black'} mb={3}>Fill in your real estate category and we will review it</Typography>
          {(propdata?.asset_sub_category_url === "other") ?
            <TextField id="outlined-basic" label="Other Real Estate" variant="outlined"
              name='others_title'
              {...register("others_title", {
                required: "Other Real Estate Title is required.",
              })}
              error={Boolean(errors.others_title)}
              helperText={errors.others_title?.message}
            />
            :
            <TextField disabled id="outlined-basic" label="Other Real Estate" variant="outlined" />
          }
        </>
      )
    } else if (propdata?.selectedCategory === "art") {
      return (
        <>
          <Box sx={{ color: 'black', fontSize: "32px" }} pt={2}>Is your art piece in a regular shape?</Box>
          <Box sx={{ color: 'black', fontSize: "18px" }} pt={1}>If your art piece is not in a rectangular or square format, please select no.</Box>
          <Grid container spacing={2} mb={2} mt={1.5}>
            <Grid item xs={6} >
              <CardActionArea onClick={() => { setSelectedShape(1) }}>
                <Paper elevation={selectedShape === 1 ? 3 : 0} style={{ padding: '10px', borderRadius: "12px" }} >
                  <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                    <Box className='rc-art-shape-icon'>
                      <CropSquareIcon sx={{ color: "#b37a56" }} />
                    </Box>
                    <Typography variant="subtitle2" className="text-black" >Yes</Typography>
                  </Stack>
                </Paper>
              </CardActionArea>
            </Grid>
            <Grid item xs={6} >
              <CardActionArea onClick={() => { setSelectedShape(0) }} >
                <Paper elevation={selectedShape === 0 ? 3 : 0} style={{ padding: '10px', borderRadius: "12px" }}>
                  <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                    <Box className='rc-art-shape-icon'>
                      <BubbleChartIcon sx={{ color: "#b37a56" }} />
                    </Box>
                    <Typography variant="subtitle2" className="text-black" >No</Typography>
                  </Stack>
                </Paper>
              </CardActionArea>
            </Grid>
          </Grid>
        </>
      )
    } else if (propdata?.selectedCategory === "collections") {
      return (
        <>
          <Typography variant="h4" color={'black'} gutterBottom>Collection not above?</Typography>
          <Typography variant="body1" color={'black'} mb={3}>Fill in your collection category and we will review it</Typography>
          {(propdata?.asset_sub_category_url) ?
            <TextField id="outlined-basic" label="Other Collections" variant="outlined" name='others_title'
              {...register("others_title", {
                required: "Owner Collections Title is required.",
              })}
              error={Boolean(errors.others_title)}
              helperText={errors.others_title?.message} /> :
            <TextField disabled id="outlined-basic" label="Other Collections" variant="outlined" />
          }
        </>
      )
    }

  }

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left">
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Which of the following best describes your asset?
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box" component="form" onSubmit={handleSubmit(onSubmit)}>
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button className="btn-rounded btn-text-white btn-blue-600 " type="submit">
                    Save and Exit
                  </Button>
                </Box>
              </Box>

              {propdata?.selectedCategory === "art" ?
                <Box className="questionnaire5 qsn-middle-content" sx={{ pt: 4, mb: 5 }} p={{ md: 9.5, sm: 2 }}>
                  <Box className={propdata?.selectedCategory === "equity" ? "Fund-subCategory" : "CategoryBox"}>
                    <Grid container spacing={2} mb={2}>
                      {(() => {
                        const deals = [];
                        for (let i = 0; i < subCategoryData?.length; i++) {
                          deals.push(
                            <React.Fragment key={i}>
                              {propdata?.selectedCategory === "equity" ?
                                <Grid item md={6} sm={12} xs={6} key={i}
                                  onClick={() => setSelected(subCategoryData[i])}
                                  className={propdata?.asset_sub_category === subCategoryData[i]?.id ? "selected" : ""}
                                >
                                  <Box className="card">
                                    <Typography className="crd-txt">{subCategoryData[i].title}</Typography>
                                  </Box>
                                </Grid>
                                :
                                <Grid item xs={6} sm={6} md={propdata?.selectedCategory === "art" ? 6 : 4} key={i}
                                  onClick={() => setSelected(subCategoryData[i])}
                                  className={propdata?.asset_sub_category === subCategoryData[i]?.id ? "selected" : ""}
                                >
                                  <SliderCard height={'100%'} image={`${process.env.REACT_APP_IMAGE_URL}${subCategoryData[i]?.category_image?.path}`} title={subCategoryData[i]?.title} color="black" />
                                </Grid>
                              }
                            </React.Fragment >
                          );
                        }
                        return deals;
                      })()}
                    </Grid>
                    <Box width={propdata?.selectedCategory === "art" ? '100%' : '80%'} marginX={'auto'}>
                      {(propdata?.asset_sub_category_url === "other" || propdata?.selectedCategory === "art") && htmlRender()}
                    </Box>
                  </Box>
                </Box>
                : <Box className="questionnaire5 qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                  <Box className={propdata?.selectedCategory === "equity" ? "Fund-subCategory" : "CategoryBox"}>
                    <Grid container spacing={2} mb={2}>
                      {(() => {
                        const deals = [];
                        for (let i = 0; i < subCategoryData?.length; i++) {
                          deals.push(
                            <React.Fragment key={i}>
                              {propdata?.selectedCategory === "equity" ?
                                <Grid item md={6} sm={12} xs={6} key={i}
                                  onClick={() => setSelected(subCategoryData[i])}
                                  className={propdata?.asset_sub_category === subCategoryData[i]?.id ? "selected" : ""}
                                >
                                  <Box className="card">
                                    <Typography className="crd-txt">{subCategoryData[i].title}</Typography>
                                  </Box>
                                </Grid>
                                :
                                <Grid item xs={6} sm={6} md={propdata?.selectedCategory === "art" ? 6 : 4} key={i}
                                  onClick={() => setSelected(subCategoryData[i])}
                                  className={propdata?.asset_sub_category === subCategoryData[i]?.id ? "selected" : ""}
                                >
                                  <SliderCard height={'100%'} image={`${process.env.REACT_APP_IMAGE_URL}${subCategoryData[i]?.category_image?.path}`} title={subCategoryData[i]?.title} color="black" />
                                </Grid>
                              }
                            </React.Fragment >
                          );
                        }
                        return deals;
                      })()}
                    </Grid>
                    <Box width={propdata?.selectedCategory === "art" ? '100%' : '80%'} marginX={'auto'}>
                      {(propdata?.asset_sub_category_url === "other" || propdata?.selectedCategory === "art") && htmlRender()}
                    </Box>
                  </Box>
                </Box>}

              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  mt={3}
                >
                  <Box textAlign={"left"} className="quests6-btn-box">
                    <Button
                      className="btn-rounded back-btn"
                      onClick={handleback}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className="quests-btn-box">
                    {(selected !== "" || propdata?.asset_sub_category) ?
                      // (selected == "Others" || selected == "Other") ?
                      ((propdata?.asset_sub_category_url || propdata?.other_category) || propdata?.selectedCategory === "art") ?
                        <Button
                          endIcon={<KeyboardArrowRightIcon />}
                          className='btn-rounded btn-text-white btn-blue-600'
                          type="submit"
                        >
                          Next
                        </Button>
                        :
                        <Button
                          onClick={handleNext}
                          endIcon={<KeyboardArrowRightIcon />}
                          className='btn-rounded btn-text-white btn-blue-600'
                        >
                          Next
                        </Button>
                      :
                      <Tooltip title="Select an asset subtype to continue.">
                        <span>
                          <Button disabled endIcon={<KeyboardArrowRightIcon />} className='btn-rounded quests-btn btn-text-white btn-blue-600'>
                            Next
                          </Button>
                        </span>
                      </Tooltip>
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
