import React, { useCallback, useEffect, useState, useRef } from "react";
import { Avatar, Box, Card, CardHeader, Grid, Modal, Typography, Stack, IconButton, useMediaQuery, Backdrop } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FileViewer from "react-file-viewer";
import { deleteApi, GetApi, postApi } from "../Api/Api";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useSnackbar } from "../Contexts/SnackbarContext";
import { useDropzone } from "react-dropzone";
import { ArrowDropDown, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetApiParam, updateApi } from '../Api/Api';
import DropdownBox from '../InvestorOnly/Component/DropdownBox'
import { updateStep } from "../features/auth/tooltipSlice";
import { CATEGORY_TYPE_ID } from "../constants";

export default function Documents({ documentsData, descriptionData, ownerId, assetId, editable, projectStatus, section_title, document_name, investment_type_id, selected_templates, isLoan, isParcel, isSale, isFraction, isFundFraction }) {
  const { showSnackbar } = useSnackbar();
  const [showMore, setShowMore] = useState(false);
  const [tolPosition, setTolPosition] = useState(0)
  const isXs = useMediaQuery('(max-width:600px)');
  const step = useSelector((state) => {
    if (isLoan) {
      return state.tooltipSlice.loan_step_number
    } else if (isParcel) {
      return state.tooltipSlice.parcel_step_number
    } else if (isSale) {
      return state.tooltipSlice.sale_step_number
    } else if (isFraction) {
      return state.tooltipSlice.re_step_number
    } else if (isFundFraction) {
      return state.tooltipSlice.funds_step_number
    }
  }
  );
  const isActive = useSelector((state) => state.tooltipSlice.active_how_it_work);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // const localData =
  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : ""; //Getting  user data from local storage

  // Merge the two arrays
  let mergedArray = documentsData?.length ? documentsData : [];
  if (descriptionData?.length > 0) {
    const documentOnly = descriptionData.filter(item => item?.images && item?.type === "document" && item?.images?.type !== 'mp4');
    if (documentOnly?.length > 0) {
      mergedArray = mergedArray.concat(documentOnly);
    }
  }

  const [view, setView] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState(mergedArray);

  /**
   * onClick function for viewing document
    @param {} documentData
   */
  const viewDoc = async (documentData) => {
    setOpenModal(true);
    setView(true);
    if (documentData?.images.path) {
      const fileName = await fetch(`${process.env.REACT_APP_IMAGE_URL}${documentData.images.path ? documentData.images.path : ""
        }`);

      const templateBuffer = await fileName.arrayBuffer();
      const templateBlob = new Blob([templateBuffer], { type: 'application/pdf' });
      const templateUrl = URL.createObjectURL(templateBlob);
      setFile(templateUrl);
      // setFile(fileName);
    }
    if (
      documentData?.images.type === "document" ||
      documentData?.images.type === "doc"
    ) {
      setType("pdf");
    } else {
      setType(documentData?.images.type);
    }
  };

  useEffect(() => {
    setData(mergedArray);
  }, [documentsData, descriptionData]);

  useEffect(() => {
  }, [data])

  // Delete functionality for the document
  const handleDelete = async (deleteVal) => {
    const res = await deleteApi(`/proposer/assetResources/${deleteVal.id}`);
    if (res?.data.code === 200) {
      showSnackbar("Document deleted successfully", "success");
      try {
        const res = await GetApi(`/proposer/getAssetDoc/${assetId}`);
        if (res.data.data) {
          let signingDoc = res?.data?.data?.asset_document;
          if (document_name === 'asset_signing_document') {
            if (signingDoc?.length) {
              signingDoc = signingDoc?.filter((items) => items.title === 'asset_signing_document')
              setData(signingDoc);
            } else {
              setData([])
            }
          } else {
            if (signingDoc?.length) {
              signingDoc = signingDoc?.filter((items) => items.title !== 'asset_signing_document' && items?.images && items?.type === "document" && items?.images?.type !== 'mp4')
              setData(signingDoc);
            } else {
              setData([])
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  /**
   * Onclose function for Modal
   * @returns void
   */
  const handleClose = () => {
    setOpenModal(false);
    setView(false);
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  // handle uploaded docs
  const onDrop = useCallback(
    async (acceptedFiles) => {
      /**
       * Applying filter for pdf fileType only.
       */
      const pdfFilesArray = acceptedFiles.filter(
        (file) => file.type === "application/pdf"
      );

      /**
       * Uploading documents trough looping (using promise.all)
       */
      const uploadPromises = pdfFilesArray.map(async (file, index) => {
        const uploadDocData = new FormData();
        uploadDocData.append(document_name, file);
        uploadDocData.append("asset_id", assetId);
        uploadDocData.append("user_id", localData?.id);
        uploadDocData.append('type', 'document_updated')
        try {
          return postApi(`/proposer/assetResources/`, uploadDocData);
        } catch (error) {
          console.error("Error uploading document or fetching data:", error);
        }
      });

      /**
       * After full filling all the promise data calling api
       */
      try {
        const results = await Promise.all(uploadPromises);
        if (results) {
          const res = await GetApi(`/proposer/getAssetDoc/${assetId}`);
          // Assuming that successfulResults is an array of data from successful uploads
          /**
           * Setting data into data variable for documents
           */
          showSnackbar("Document added successfully", "success");
          if (res.data.data) {
            let signingDoc = res?.data?.data?.asset_document;
            if (document_name === 'asset_signing_document') {
              if (signingDoc?.length) {
                signingDoc = signingDoc?.filter((items) => items.title === 'asset_signing_document')
                setData(signingDoc);
              } else {
                setData([])
              }
            } else {
              if (signingDoc?.length) {
                signingDoc = signingDoc?.filter((items) => items.title !== 'asset_signing_document' && items?.images && items?.type === "document" && items?.images?.type !== 'mp4')
                setData(signingDoc);
              } else {
                setData([])
              }
            }
          }
        }
      } catch (error) {
        console.error("Error in at least one upload or fetching data:", error);
      }
    },
    [data]
  );

  /**
   * Uploading pdf file document using useDropzone
   */
  const { getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [".pdf"],
    },
    onDrop,
  });

  // Helper function to truncate a string and add an ellipsis if it's too long
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  // handle show more button
  const handleShowMore = () => {
    setShowMore(true);
  }

  const handleDocumentUpload = () => {
    const data = {
      "investment_type_id": investment_type_id,
      "asset_signing_document": documentsData,
      "selected_templates": selected_templates,
      "assetId": assetId,
      // Add more data as needed
    };
    if (isLoan) {
      data.category_id = CATEGORY_TYPE_ID.WATCH
    } else if (isParcel) {
      data.category_id = CATEGORY_TYPE_ID.ART
    } else if (isSale) {
      data.category_id = CATEGORY_TYPE_ID.ART
    } else if (isFraction) {
      data.category_id = CATEGORY_TYPE_ID.REAL_ESTATE
    } else if (isFundFraction) {
      data.category_id = CATEGORY_TYPE_ID.FUNDS
    }

    navigate('/upload-agreement-documents', { state: data })
  }


  useEffect(() => {
    if (tolPosition > 14 && isLoan) {
      setLoading(false)
    } else if (tolPosition > 10 && isParcel) {
      setLoading(false)
    } else if (tolPosition > 9 && isSale) {
      setLoading(false)
    } else if (tolPosition > 12 && isFraction) {
      setLoading(false)
    } else if (tolPosition > 10 && isFundFraction) {
      setLoading(false)
    }
  }, [tolPosition])

  useEffect(() => {
    async function getStep() {
      try {
        const data = await GetApiParam("/user/currentTooltipPosition", { "user_id": localData.id });
        if (data?.data?.data && isActive) {
          if (data.data?.data?.loan_step_number == 14 && isLoan) {
            setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
            setLoading(true)
            scrollToPosition("scroll-to-document");
          } else if ((data.data?.data?.parcel_step_number == 10) && isParcel) {
            setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
            setLoading(true)
            scrollToPosition("scroll-to-document");
          }
          else if ((data.data?.data?.sale_step_number == 9) && isSale) {
            setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
            setLoading(true)
            scrollToPosition("scroll-to-document");
          }
          else if ((data.data?.data?.re_step_number == 12) && isFraction) {
            setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
            setLoading(true)
            scrollToPosition("scroll-to-document");
          } else if ((data.data?.data?.funds_step_number == 10) && isFundFraction) {
            setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
            setLoading(true)
            scrollToPosition("scroll-to-document");
          }
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    // Call getStep initially
    const timer = setTimeout(() => {
      if (localData && localData?.id) {
        getStep();
      }
    }, 100);
    return () => clearTimeout(timer)
  }, [step, isActive])

  async function setStep(tolPositionData) {
    try {
      let body = { "loan_step_number": tolPositionData };
      if (isParcel) {
        body = { "parcel_step_number": tolPositionData };
      } else if (isSale) {
        body = { "sale_step_number": tolPositionData };
      } else if (isFraction) {
        body = { "re_step_number": tolPositionData };
      } else if (isFundFraction) {
        body = { "funds_step_number": tolPositionData };
      }
      const data = await updateApi("/user/updateUser/" + localData?.id, body);
      if (data?.data?.data) {
        if (data.data?.data?.loan_step_number == 14 && isLoan) {
          setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
          setLoading(true)
        } else if ((data.data?.data?.parcel_step_number == 10) && isParcel) {
          setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
          setLoading(true)
        }
        else if ((data.data?.data?.sale_step_number == 9) && isSale) {
          setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
          setLoading(true)
        }
        else if ((data.data?.data?.re_step_number == 12) && isFraction) {
          setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
          setLoading(true)
        } else if ((data.data?.data?.funds_step_number == 10) && isFundFraction) {
          setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
          setLoading(true)
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const scrollToPosition = (findId) => {
    setTimeout(() => {
      const element = document.getElementById(`${findId}`);
      if (element) {
        const offset = 0;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }, 200);
  }

  const handleTolPosition = () => {
    setTolPosition(pre => pre + 1)
    setStep(tolPosition + 1)
    if (isLoan)
      dispatch(updateStep({ key: 'loan_step_number', value: tolPosition + 1 }));
    if (isSale)
      dispatch(updateStep({ key: 'sale_step_number', value: tolPosition + 1 }));
    if (isParcel)
      dispatch(updateStep({ key: 'parcel_step_number', value: tolPosition + 1 }));
    if (isFraction)
      dispatch(updateStep({ key: 're_step_number', value: tolPosition + 1 }));
    if (isFundFraction)
      dispatch(updateStep({ key: 'funds_step_number', value: tolPosition + 1 }));

    scrollToPosition("scroll-to-template");
  }


  return (
    <>
      {loading && document_name !== "asset_signing_document" ?
        <Box className="pd-sidebar-documents" id="scroll-to-document" mb={3} sx={{
          zIndex: 10, position: 'relative', backgroundColor: "#06487E",
          color: "white", borderRadius: "8px", zIndex: "3",
          padding: '4px'
        }}>
          <DropdownBox render={
            <Box >
              <Stack
                className="pd-sidebar-heading"
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography className="title" component={"h4"}>
                  {section_title}
                </Typography>

                {/* Conditionally rendering upload functionality for proposer only*/}
                {(editable && localData?.id && !projectStatus && section_title !== "Agreement Documents") ? (
                  <Box
                    {...getRootProps({
                      className: "dropzone",
                    })}
                  >
                    <input {...getInputProps()} />
                    <Box className="action">
                      <IconButton aria-label="Uplaod" size="large">
                        <UploadIcon className="text-white" onClick={open} />
                      </IconButton>
                    </Box>
                  </Box>
                ) : (editable && localData?.id && !projectStatus && section_title === "Agreement Documents") && (
                  <Box>
                    <Box className="action">
                      <IconButton aria-label="Uplaod" size="large">
                        <Edit className="text-white" onClick={handleDocumentUpload} />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Stack>

              {/* Dynamically rendering documents with viewer  */}

              {data && data.length > 0 ? (<>
                {
                  data?.map((val, ind) => {
                    if (ind < 5 || showMore) {
                      const subheaderText = val?.images?.original_name
                        ? val.images.original_name
                        : val.name;
                      const truncatedSubheader = truncateString(subheaderText, 22); // You can change 30 to your desired length
                      return (
                        <Card className="pd-action-card" key={ind}>
                          <CardHeader
                            avatar={
                              <Avatar
                                aria-label="recipe"
                                className="bg-blue-900"
                                variant="square"
                                sx={{
                                  width: "48px",
                                  height: "48px",
                                  borderRadius: "12px",
                                }}
                              >
                                <AssignmentIcon />
                              </Avatar>
                            }
                            action={
                              <>
                                <Box className="btn-wrap">
                                  <Stack
                                    direction="row"
                                    spacing={1.5}
                                    alignItems={"center"}
                                  >
                                    {editable && localData?.id && !projectStatus && section_title !== "Agreement Documents" ?
                                      <IconButton
                                        aria-label="Edit"
                                        size="large"
                                        className="square-icon-btn"
                                        onClick={() => handleDelete(val)}
                                      >
                                        <DeleteIcon />
                                      </IconButton> : ""
                                    }
                                    <IconButton
                                      aria-label="View"
                                      size="large"
                                      className="square-icon-btn"
                                      onClick={() => viewDoc(val)}
                                    >
                                      <RemoveRedEyeIcon />
                                    </IconButton>
                                  </Stack>
                                </Box>
                              </>
                            }
                            title={val.type}
                            subheader={truncatedSubheader}
                          />
                        </Card>
                      );
                    } else {
                      return null
                    }
                  })
                }
                {(showMore === false && data?.length > 5) &&
                  <Stack flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
                    <Stack flexDirection={'row'} pt={0.2} >
                      <Typography>More</Typography>
                    </Stack>
                    <Box sx={{ cursor: 'pointer' }} onClick={handleShowMore}>
                      <ArrowDropDown />
                    </Box>
                  </Stack>
                }
              </>
              ) : (
                <Typography textAlign={"center"}>
                  No documents available to view
                </Typography>
              )}

            </Box>
          }
            setState={handleTolPosition} description={"The agreements for the deal are available for preview, RACE strongly advises to read all agreements before making an offer"} position={"left"}
          />
        </Box>
        :

        <Box className="pd-sidebar-documents" id="scroll-to-document" mb={3}>
          <Stack
            className="pd-sidebar-heading"
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className="title" component={"h4"}>
              {section_title}
            </Typography>

            {/* Conditionally rendering upload functionality for proposer only*/}
            {(editable && localData?.id && !projectStatus && section_title !== "Agreement Documents") ? (
              <Box
                {...getRootProps({
                  className: "dropzone",
                })}
              >
                <input {...getInputProps()} />
                <Box className="action">
                  <IconButton aria-label="Uplaod" size="large">
                    <UploadIcon className="text-white" onClick={open} />
                  </IconButton>
                </Box>
              </Box>
            ) : (editable && localData?.id && !projectStatus && section_title === "Agreement Documents") && (
              <Box>
                <Box className="action">
                  <IconButton aria-label="Uplaod" size="large">
                    <Edit className="text-white" onClick={handleDocumentUpload} />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Stack>

          {/* Dynamically rendering documents with viewer  */}

          {data && data.length > 0 ? (<>
            {
              data?.map((val, ind) => {
                if (ind < 5 || showMore) {
                  const subheaderText = val?.images?.original_name
                    ? val.images.original_name
                    : val.name;
                  const truncatedSubheader = truncateString(subheaderText, 22); // You can change 30 to your desired length
                  return (
                    <Card className="pd-action-card" key={ind}>
                      <CardHeader
                        avatar={
                          <Avatar
                            aria-label="recipe"
                            className="bg-blue-900"
                            variant="square"
                            sx={{
                              width: "48px",
                              height: "48px",
                              borderRadius: "12px",
                            }}
                          >
                            <AssignmentIcon />
                          </Avatar>
                        }
                        action={
                          <>
                            <Box className="btn-wrap">
                              <Stack
                                direction="row"
                                spacing={1.5}
                                alignItems={"center"}
                              >
                                {editable && localData?.id && !projectStatus && section_title !== "Agreement Documents" ?
                                  <IconButton
                                    aria-label="Edit"
                                    size="large"
                                    className="square-icon-btn"
                                    onClick={() => handleDelete(val)}
                                  >
                                    <DeleteIcon />
                                  </IconButton> : ""
                                }
                                <IconButton
                                  aria-label="View"
                                  size="large"
                                  className="square-icon-btn"
                                  onClick={() => viewDoc(val)}
                                >
                                  <RemoveRedEyeIcon />
                                </IconButton>
                              </Stack>
                            </Box>
                          </>
                        }
                        title={val.type}
                        subheader={truncatedSubheader}
                      />
                    </Card>
                  );
                } else {
                  return null
                }
              })
            }
            {(showMore === false && data?.length > 5) &&
              <Stack flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
                <Stack flexDirection={'row'} pt={0.2} >
                  <Typography>More</Typography>
                </Stack>
                <Box sx={{ cursor: 'pointer' }} onClick={handleShowMore}>
                  <ArrowDropDown />
                </Box>
              </Stack>
            }
          </>
          ) : (
            <Typography textAlign={"center"}>
              No documents available to view
            </Typography>
          )}
        </Box>
      }

      <Grid>
        {/* {view && (
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
            <Box sx={styleModal}>
              <OverlayScrollbarsComponent
                defer
                style={{ width: "100%", height: "100%" }}
              >
                <Box className="raj">
                  {<FileViewer fileType={type} filePath={file} />}
                </Box>
              </OverlayScrollbarsComponent>
            </Box>
          </Modal>
        )} */}
        {(view && !loading && type && file) && (
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
            <Box sx={styleModal}>
              {/* <iframe
                title="PDF Viewer"
                src={file}
                style={{ width: "100%", height: "100%" }}
                className="pdf-viewer-modal"
              ></iframe> */}
              <object
                data={file}
                type="application/pdf"
                style={{ width: '100%', height: '100%' }}
              >
                <p style={{ textAlign: 'center' }}>
                  Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                </p>
              </object>
            </Box>
          </Modal>
        )}
      </Grid>
      {loading &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => 2 }}
          open={loading}
        >
        </Backdrop>
      }
    </>
  );
}
