import { Typography } from "@mui/material";
import { CAPITAL_TYPE_ID } from "../../../constants";
import { formatNumber } from "../../../Components/Common/USFormat";

export default class BaseDetailsAsset {

    prices = {};
    data = {};
    isLoan = false;
    isParcel = false;
    isFraction = false;
    asset_Id = null;
    user_id = null;
    totalProjects = null;
    offersData = null;
    result = null;
    readOnly = false
    filterChecklist = (checklist, investmentId) => {
        // Find the item with the specified investment_id
        const investmentItem = checklist?.find(item => item.investment_type_id === investmentId);
        return investmentItem?.asset_checklist ? investmentItem?.asset_checklist : [];
    }
    getExtension = (date) => {
        // Find the item with the specified investment_id
        const val = parseInt(date) % 10;
        let result = ""
        switch (val) {
            case 1:
                result = "st"
                break
            case 2:
                result = "nd"
                break
            case 3:
                result = "rd"
                break
            default:
                result = "th"
                break
        }
        return result
    }

    constructor(_data, _readOnly) {
        if (!_data) {
            throw new Error(
                "Custom error: data is required in Base Details class"
            );
        }
        this.data = _data;
        this.readOnly = _data.readOnly;
        this.ownerInfo = _data.user_details;
        this.asset_Id = _data.id;
        this.reviewList = this.filterChecklist(this.checkList, _data.investment_type_id)
        this.user_id = _data.user_id
        this.totalProjects = _data.totalProject
        // checking if user is owner
        let u = localStorage.getItem("user_data");
        let user = JSON.parse(u);
        this.isOwner = user?.id === _data?.user_id;
        this.capitalInfo = _data.capital_info

        // Determine the string for months (singular or plural)
        const loanDurationMonths =
            _data?.loan_duration_month > 1
                ? `${_data?.loan_duration_month} Months`
                : _data?.loan_duration_month == 1 ? `${_data?.loan_duration_month} Month` : '';

        const loanDurationFrom = _data?.suggestReviewData?.length ? formatNumber(parseFloat((_data?.suggestReviewData[0]?.loan_duration_from))) : 1;

        const loanRoiFrom = _data?.suggestReviewData?.length ? formatNumber(parseFloat(_data?.suggestReviewData[0]?.loan_roi_from)) : '1';



        const loan_interest_op =
            _data?.loan_iop > 1
                ? `${_data?.loan_iop} Months`
                : `${_data?.loan_iop} Month`;

        this.loan_interest_op = `${loan_interest_op}`
        this.result = `${loanDurationMonths}`
        this.loanDurationFrom = `${loanDurationFrom}`
        this.loanRoiFrom = `${loanRoiFrom}`
        this.setPrices(this.data?.investment_type?.investment_name);

    }

    setPrices(type) {
        switch (type) {
            case "Parcelling":
                this.isParcel = true;
                Object.assign(this.prices, {
                    'Sale Price': `$${formatNumber(this.data?.parcel_total_price ? this.data?.parcel_total_price : 0)}`,
                    'Number of Parcels': this.data?.number_of_parcel,
                    'Minimum Investment': `$${formatNumber(this.data?.minimun_investment_per_parcel ? this.data?.minimun_investment_per_parcel : 0)}`,
                });
                break;
            case "Sale":
                Object.assign(this.prices, {
                    'Sale Price': `$${formatNumber(this.data?.sale_price ? this.data?.sale_price : 0)}`,
                    'Deposit Amount': `$${formatNumber(this.data?.sale_deposit_amount ? this.data?.sale_deposit_amount : 0)}`,
                });
                break;
            case "Loan":
                this.isLoan = true;
                Object.assign(this.prices, {
                    'Total Raise Amount': `$${formatNumber(this.data?.loan_amount ? this.data?.loan_amount : 0)}`,
                    'Preferred Interest Rate Range': `${this.data?.is_publish ? formatNumber(this.loanRoiFrom) + " - " : ""} ${this.data?.loan_roi} % APR`,
                    'Preferred Loan Duration Range': `${this.data?.is_publish ? this.loanDurationFrom + " - " : ''} ${this.result}`,
                    'Interest Only period': this.loan_interest_op ? this.loan_interest_op : 0,
                    'Minimum Investment': `$${formatNumber(this.data?.loan_minimum_investment ? parseFloat(this.data?.loan_minimum_investment) : 0)}`,
                    'EMI Date': <Typography className='font-22 emi-date'>
                        <span className='day'>{this.data?.loan_repay_day ? this.data?.loan_repay_day : 1}</span>
                        <span className='extension-base'>{this.getExtension(this.data?.loan_repay_day ? this.data?.loan_repay_day : 1)}</span>{" "}
                        <span className='month'>of Month</span>
                    </Typography>
                    // `${this.data?.loan_repay_day}${this.getExtension(this.data?.loan_repay_day)} of Month`
                });
                break;
            case "Fractionalize":
                this.isFraction = true
                Object.assign(this.prices, {
                    'Total Raise': `$${formatNumber(this.data?.fractionalize_total_price ? this.data?.fractionalize_total_price : 0)}`,
                    'Total Project Value': `$${formatNumber(this.data?.fractionalize_project_size ? this.data?.fractionalize_project_size : 0)}`,
                    'Project Duration': `${this.data?.fractionalize_duration_of_project
                        } Years`,

                    // 'Miniumum Investment': `$${this.data?.fractionalize_minimum_investment ? this.data?.fractionalize_minimum_investment : "100"}`,
                });
                if (this.capitalInfo?.some((item) => item?.capital_type?.id === CAPITAL_TYPE_ID.SENIOR_DEBT || item?.capital_type?.id === CAPITAL_TYPE_ID.JUNIOR_DEBT)) {
                    Object.assign(this.prices, {
                        'EMI Date': <Typography className='font-22 emi-date'>
                            <span className='day'>{this.data?.loan_repay_day ? this.data?.loan_repay_day : 1}</span>
                            <span className='extension-base'>{this.getExtension(this.data?.loan_repay_day ? this.data?.loan_repay_day : 1)}</span>{" "}
                            <span className='month'>of Month</span>
                        </Typography>
                    });
                }
                break;
            default:
                break;
        }
    }

}