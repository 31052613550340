import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import "./RaceProjectCard.css";
import homeImg from "../../../../Assets/Images/homeImg.png";
import { LocalFireDepartment } from "@mui/icons-material";
const RaceProjectCard = () => {
  return (
    <Box className="race-project-card-container">
      <Box className="card-img-box">
        <Box className="img-div">
          <img src={homeImg} />
        </Box>
        <Box className="card-chip">
          <Chip icon={<LocalFireDepartment />} label={"Popular"} />
        </Box>
      </Box>
      <Box mt={3}>
        <Typography className="font-16">
          Results-driven copy for your investment project
        </Typography>
        <Typography className="font-12 text-blueGray-300">
          From $1,000
        </Typography>
      </Box>
    </Box>
  );
};

export default RaceProjectCard;
