import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Box, Button, ListItemIcon, Drawer, Badge, List, Stack, ListItem, ListItemButton, Avatar, Menu, MenuItem, IconButton, Typography, Divider, Tabs, Tab, Backdrop } from "@mui/material";
import { LogoInvestor } from "../../Components/Images/Images";
import { Logout, AccountCircle, AccountBalanceWallet, Notifications, ArrowDropDownRounded } from "@mui/icons-material";
import { useAccount, useDisconnect } from "wagmi";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import { update, logout } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../../Contexts/SocketContext";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { GetApi, GetApiParam, updateApi } from "../../Api/Api";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { updateStep } from "../../features/auth/tooltipSlice";
import MenuIcon from '@mui/icons-material/Menu';
import DropdownBox from "../../InvestorOnly/Component/DropdownBox";
import "./Header.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Header = () => {

  const userData = useSelector((state) => state.auth.user);
  const localData = JSON.parse(localStorage.getItem("user_data"));

  // Get the socket instance using the custom hook
  const socket = useSocket();
  const { showSnackbar } = useSnackbar();

  // Get the dispatch function from the Redux store
  const dispatch = useDispatch();
  const location = useLocation();

  // Get the account and disconnect function from the useAccount and useDisconnect custom hooks
  const account = useAccount();
  const { disconnect } = useDisconnect();

  // Get the navigate function from the useNavigate custom hook
  const navigate = useNavigate();

  // State variables for anchor element and its open state
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [tolPosition, setTolPosition] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tolPosition >= 5) {
      setLoading(false);
    }
  }, [tolPosition]);


  useEffect(() => {
    async function getStep() {
      try {
        const data = await GetApiParam("/user/currentTooltipPosition", {
          user_id: localData.id,
        });
        if (data?.data?.data) {
          if (
            (data.data?.data?.loan_step_number < 5 &&
              data.data?.data?.loan_step_number > 0) ||
            data.data?.data?.loan_step_number == null
          ) {
            setLoading(true);
          }
          setTolPosition(
            data.data?.data?.loan_step_number == null
              ? 1
              : data.data.data.loan_step_number
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    setTimeout(() => {
      getStep();
    }, 100);
  }, []);

  async function setStep(tolPositionData) {
    try {
      const data = await updateApi("/user/updateUser/" + localData?.id, {
        loan_step_number: tolPositionData,
        sale_step_number: tolPositionData,
        parcel_step_number: tolPositionData,
        re_step_number: tolPositionData,
        funds_step_number: tolPositionData,
      });
      if (data?.data?.data) {
        setTolPosition(
          data.data?.data?.loan_step_number == null
            ? 1
            : data.data.data.loan_step_number
        );
        if (
          data.data?.data?.loan_step_number < 5 &&
          data.data?.data?.loan_step_number > 0
        ) {
          setLoading(true);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [value, setValue] = useState(0);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Event handler to open the anchor element
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
    setValue(0);
  };

  // Event handler to close the anchor element
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
    setValue(-1);
  };

  async function getNotification() {
    try {
      if (localData?.id) {
        const res = await GetApi(`/user/getNotification?id=${localData.id}`);
        if (res?.data?.data && res?.data?.data?.data) {
          setNotifications(res.data.data.data);
          setUnreadNotifications(res?.data?.data?.unreadCount);
        }
      } else {
        showSnackbar();
      }
    } catch (error) {
      console.log(error);
    }
  }
  // Function to calculate relative time
  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedDate;
  };
  useEffect(() => {
    // Api to get getNotification
    const timer = setTimeout(() => {
      getNotification();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // new-notification received then call
  socket.on("new-notification", (data) => {
    getNotification();
  });

  // Function to scroll to the top of the page
  function topFunction() {
    document.documentElement.scrollTop = 0;
  }

  /**
   * function to handle logout functionality
   */
  const handleLogout = () => {
    handleClose();
    if (account.isConnected) {
      //disconnect wallet if connected
      disconnect();
    }
    dispatch(logout());
    socket.emit("remove-user", userData?.id);
    navigate("/");

    // Reload the page after a short delay (e.g., 500 milliseconds)
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  //Wallet connection code start
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setWalletDialogOpen(true);
  };

  const handleCloseDialog = (value) => {
    setWalletDialogOpen(false);
  };

  /**
   * gets called when a wallet is connect successfully
   * @param {*} acc
   */
  const walletLogin = async (acc) => {
    // setOpenModal(true); //ask for confirmation
    dispatch(update({ wallet_address: acc?.toLowerCase() }));
    // setUserData({ ...userData, wallet_address: acc });
  };

  const handleWalletDisconnect = () => {
    disconnect();
    handleClose1();
    showSnackbar("Wallet disconnected", "success");
  };
  //Wallet connection code end

  /**
   * Function to update notification is_read status
   * @param {Object} value
   */
  const handleNotificationsUpdate = (value) => {
    async function updateNotification() {
      try {
        const res = await updateApi(`/user/updateNotification/${value.id}`);
        if (res?.data?.code === 200) {
          // getting updated data
          getNotification();
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (!value.is_read) {
      updateNotification();
    }
  };

  // code for restricting Routes for the Categories
  const notificationPath = (val) => {
    console.log("val...", val.type)
    switch (val.type) {
      case "Offer Cancel":
        return `/project-details/${val.asset_id}`;
      case "Offer":
        return `/user/transactions`;
      case "Project-detail":
        return `/project-details/${val.asset_id}`;
      case "Project Update":
        return `/project-details/${val.asset_id}`;
      case "Terms":
        return `/project-details-inreview/${val.asset_id}`;
      case "Terms ":
        return `/project-details-inreview/${val.asset_id}`;
      default:
        return "";
    }
  };

  const handleNotificationView = (val) => {
    navigate(`${notificationPath(val)}`, {
      state: { from: "notification", proState: "notification" },
    });
    setAnchorEl2(null);
  };

  function scrollTop() {
    document.documentElement.scrollTop = 0;
  }
  const handleTolPosition = () => {
    setTolPosition((pre) => pre + 1);
    setStep(tolPosition + 1);
    dispatch(updateStep({ key: "loan_step_number", value: tolPosition + 1 }));
  };

  // Function to get the first letter of a string
  const getFirstLetter = (str) => {
    return str.charAt(0);
  };
  const [menuOpen, setMenuOpen] = React.useState(false);

  const toggleDrawer = (newMenuOpen) => () => {
    setMenuOpen(newMenuOpen);
  };


  // Effect to handle popstate event for browser back button
  useEffect(() => {
    const handlePopstate = () => {
      // Always navigate to overview page when popstate event occurs
      if (location.pathname === '/') {
        navigate("/", { replace: true })
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);


  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>

        <ListItem >
          <ListItemButton>
            {tolPosition === 1 ? (
              <DropdownBox
                render={<Box >Home</Box>}
                setState={handleTolPosition}
                description={"Return to the main Homepage"}
              />
            ) : (
              <Link to="/" onClick={scrollTop} >
                Home
              </Link>
            )}
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            {tolPosition === 2 ? (
              <DropdownBox
                render={<Box>Dashboard</Box>}
                setState={handleTolPosition}
                description={
                  "View your investment portfolio and explore tokenized assets"
                }
              />
            ) : (
              <Link to="/user/portfolio" >Dashboard</Link>
            )}
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            {tolPosition === 3 ? (
              <DropdownBox
                render={<Box >About</Box>}
                setState={handleTolPosition}
                description={"Learn more about RACE and our mission."}
              />
            ) : (
              <Link to="https://www.race.foundation/" target="_blank" >
                About
              </Link>
            )}
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <Link to="project-proposer" >Propose a Project</Link>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <Box className="investor-only-menu">
            {account?.address ? (
              <>
                <Button
                  className="wallet-btn"
                  sx={{ ml: 1.5 }}
                  id="fade-button"
                  aria-controls={open1 ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? "true" : undefined}
                  onClick={handleClick1}
                >
                  <Box component={"span"} className="icon">
                    <AccountBalanceWallet />
                  </Box>
                  <Box component={"span"} className="text">
                    {account?.address?.slice(0, 6) +
                      "..." +
                      account?.address?.slice(-4)}
                  </Box>
                  {/* <Avatar
                      alt="Token icon"
                      src={Profile}
                      sx={{ width: 18, height: 18 }}
                    /> */}
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  anchorEl={anchorEl1}
                  open={open1}
                  onClose={handleClose1}
                  // TransitionComponent={Fade}
                  className="dropdown-menu"
                >
                  <Button
                    onClick={() => {
                      handleWalletDisconnect();
                    }}
                    className=""
                    style={{
                      width: "186px",
                      display: "flex",
                      gap: "20px",
                      color: "white",
                    }}
                  >
                    <Logout fontSize="small" />
                    Disconnect
                  </Button>
                </Menu>
              </>
            ) : (
              <Button
                onClick={handleClickOpen}
                className="wallet-btn"
              >
                <Box component={"span"} className="icon">
                  <AccountBalanceWallet />
                </Box>
                &nbsp; Connect Wallet
              </Button>
            )}
          </Box>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <>
      <Box className="main-header-investor-only">
        <Box className="container">
          <Box className="investor-only-item">
            <Box display={"flex"} alignItems={"center"}>
              <Box className="investor-only-logo">
                <Link to="/" onClick={scrollTop}>
                  <Avatar variant="square" src={LogoInvestor} alt="Logo" />
                </Link>
              </Box>
              <Stack
                direction="row"
                spacing={4}
                className="headerLeft-menu"
                sx={{
                  display: {
                    xs: "none", // Display as flex for small screens
                    md: "flex", // Display as flex for medium screens and above
                  },
                  flexDirection: {
                    xs: "unset", // Change direction to unset for small screens
                    md: "row", // Keep direction as row for medium screens and above
                  },
                  padding: "16px",
                  marginLeft: { xs: "2px", md: "32px" },
                }}
              >
                <Box className="menu-item">
                  {tolPosition === 1 ? (
                    <DropdownBox
                      render={<Box>Home</Box>}
                      setState={handleTolPosition}
                      description={"Return to the main Homepage"}
                    />
                  ) : (
                    <Link to="/" onClick={scrollTop}>
                      Home
                    </Link>
                  )}
                </Box>
                <Box className="menu-item">
                  {tolPosition === 2 ? (
                    <DropdownBox
                      render={<Box>Dashboard</Box>}
                      setState={handleTolPosition}
                      description={
                        "View your investment portfolio and explore tokenized assets"
                      }
                    />
                  ) : (
                    <Link to="/user/portfolio">Dashboard</Link>
                  )}
                </Box>
                <Box className="menu-item">
                  {tolPosition === 3 ? (
                    <DropdownBox
                      render={<Box>About</Box>}
                      setState={handleTolPosition}
                      description={"Learn more about RACE and our mission."}
                    />
                  ) : (
                    <Link to="https://www.race.foundation/" target="_blank">
                      About
                    </Link>
                  )}
                </Box>
                {/* remove code before live */}
                {/* <Box className="menu-item">
                  <Link to="/project-proposer">Propose a Project</Link>
                </Box> */}
              </Stack>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              {account?.address ? (
                <>
                  <Button
                    className="wallet-btn"
                    sx={{ ml: 1.5 }}
                    id="fade-button"
                    aria-controls={open1 ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={handleClick1}
                  >
                    <Box component={"span"} className="icon">
                      <AccountBalanceWallet />
                    </Box>
                    <Box component={"span"} className="text">
                      {account?.address?.slice(0, 6) +
                        "..." +
                        account?.address?.slice(-4)}
                    </Box>
                    {/* <Avatar
                      alt="Token icon"
                      src={Profile}
                      sx={{ width: 18, height: 18 }}
                    /> */}
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    anchorEl={anchorEl1}
                    open={open1}
                    onClose={handleClose1}
                    // TransitionComponent={Fade}
                    className="dropdown-menu"
                  >
                    <Button
                      onClick={() => {
                        handleWalletDisconnect();
                      }}
                      className=""
                      style={{
                        width: "186px",
                        display: "flex",
                        gap: "20px",
                        color: "white",
                      }}
                    >
                      <Logout fontSize="small" />
                      Disconnect
                    </Button>
                  </Menu>
                </>
              ) : (
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Button
                    onClick={handleClickOpen}
                    className="wallet-btn"
                    sx={{ ml: 1.5 }}
                  >
                    <Box component={"span"} className="icon">
                      <AccountBalanceWallet />
                    </Box>
                    &nbsp; Connect Wallet
                  </Button>
                </Box>
              )}
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Button onClick={toggleDrawer(true)} sx={{ minWidth: 'auto', color: '#ffffff' }}>
                  <MenuIcon />
                </Button>
                <Drawer open={menuOpen} onClose={toggleDrawer(false)}>
                  {DrawerList}
                </Drawer>
              </Box>
              <Box className="menu-item">
                <IconButton
                  className="notification"
                  sx={{ ml: { xs: 1, md: 3 } }}
                  id="fade-button"
                  aria-controls={open2 ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={handleClick2}
                // onMouseOver={handleClick2}
                >
                  {unreadNotifications ? (
                    <Badge badgeContent={unreadNotifications} color="primary">
                      <Notifications />
                    </Badge>
                  ) : (
                    <Notifications />
                  )}
                </IconButton>
                {notifications?.length ? (
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorEl={anchorEl2}
                    open={open2}
                    onClose={handleClose2}
                    // onMouseLeave={handleClose2}
                    className="mega-menu InvesterHeader-dropdown notifications"
                    keepMounted
                  >
                    <OverlayScrollbarsComponent
                      options={{
                        scrollbars: {
                          autoHide: "scroll",
                        },
                      }}
                      style={{ width: 358, maxHeight: 280, marginRight: "5px" }}
                    >
                      <Box
                        className="header-notifications"
                        sx={{ width: "358px" }}
                      >
                        <Tabs
                          value={value}
                          variant="scrollable"
                          onChange={handleChange}
                          scrollButtons={false}
                          aria-label="scrollable prevent tabs example"
                          className="not-tabs"
                        >
                          <Tab label="Notifications" className="tab-title" />
                        </Tabs>
                        <Divider thickness={2} className="divider" />
                        <TabPanel value={value} index={0}>
                          {notifications &&
                            notifications?.map((val, ind) => {
                              return (
                                <Box
                                  key={`notification ${ind + 1}`}
                                  className="notification-item"
                                  onClick={() => handleNotificationsUpdate(val)}
                                >
                                  <Box>
                                    <Typography
                                      variant="body1"
                                      className="title"
                                      onClick={() =>
                                        handleNotificationView(val)
                                      }
                                    >
                                      <span
                                      >
                                        {val.message}
                                      </span>
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      className="time"
                                    >
                                      {formatRelativeTime(val.createdAt)}
                                    </Typography>
                                  </Box>
                                  {!val?.is_read ? (
                                    <Box>
                                      <span className="unread-icon"></span>
                                    </Box>
                                  ) : null}
                                </Box>
                              );
                            })}
                        </TabPanel>
                      </Box>
                    </OverlayScrollbarsComponent>
                  </Menu>
                ) : null}
              </Box>
              {tolPosition === 4 ? (
                <DropdownBox
                  render={
                    <Box className="userStatus" sx={{ ml: { xs: 2, md: 3.5 } }}>
                      {userData && userData?.persona_picture ? (
                        <Avatar
                          alt="Token icon"
                          src={
                            userData?.persona_picture ??
                            userData?.persona_picture
                          }
                          sx={{ width: 40, height: 40 }}
                        />
                      ) : (
                        <Avatar className={"avatar"} alt="Remy Sharp">
                          {getFirstLetter(
                            userData?.first_name ? userData?.first_name : ""
                          )}
                          {getFirstLetter(
                            userData?.last_name ? userData?.last_name : ""
                          )}
                        </Avatar>
                      )}
                      <Badge className="insideBadge"></Badge>
                    </Box>
                  }
                  setState={handleTolPosition}
                  description={
                    "Access your profile settings and account information."
                  }
                />
              ) : (
                <Box className="userStatus" sx={{ ml: { xs: 2, md: 3.5 } }}>
                  {userData && userData?.persona_picture ? (
                    <Avatar
                      alt="Token icon"
                      src={
                        userData?.persona_picture ?? userData?.persona_picture
                      }
                      sx={{ width: 40, height: 40 }}
                    />
                  ) : (
                    <Avatar className={"avatar avtar-custom"} style={{ backgroundColor: 'white', color: 'black' }} alt="Remy Sharp">
                      {getFirstLetter(
                        userData?.first_name ? userData?.first_name : ""
                      )}
                      {getFirstLetter(
                        userData?.last_name ? userData?.last_name : ""
                      )}
                    </Avatar>
                  )}
                  <Badge className="insideBadge"></Badge>
                </Box>
              )}

              <Box className="profileMenu-wrap" ml={1.5}>
                <Button
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="profileMenu-btn"
                >
                  <ArrowDropDownRounded />
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  // TransitionComponent={Fade}
                  className="dropdown-menu investor-only"
                >
                  {/* <MenuItem onClick={() => {
                                        handleClose();
                                        navigate("/user/projects");
                                    }}>
                                        <ListItemIcon>
                                            <AccountCircle fontSize="small" />
                                        </ListItemIcon>
                                        Profile
                                    </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/user/portfolio");
                    }}
                  >
                    <ListItemIcon>
                      <AccountCircle fontSize="small" />
                    </ListItemIcon>
                    My account
                  </MenuItem>
                  {/* <MenuItem onClick={
                                        () => {
                                            handleClose();
                                            navigate("/user/settings");
                                        }
                                    }>
                                        <ListItemIcon>
                                            <Settings fontSize="small" />
                                        </ListItemIcon>
                                        Settings
                                    </MenuItem> */}
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Box>

          </Box>
        </Box>
      </Box>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => 100 }}
          open={loading}
        ></Backdrop>
      )}
      <ConnectWalletInvestor
        open={walletDialogOpen}
        handleCloseDialog={handleCloseDialog}
        handleConnectedSuccess={walletLogin}
      // askConfirmation={!localData?.wallet_address ? true : localData?.wallet_address?.toLocaleLowerCase() != account?.address?.toLocaleLowerCase() ? true : false}
      />
    </>
  );
};

export default Header;
