import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Button, Chip, Grid, Typography } from "@mui/material";
import { BorderAll, Category, Edit, FastForward, FiberNew, Handyman, KeyboardReturn, LocalFireDepartment, MonetizationOn, PhotoSizeSelectLarge, Star } from "@mui/icons-material";
import Hero from "../Components/Hero/Hero";
import { RealEstate, mobileImage, landingPageProposerImage } from "../../Components/Images/Images";
import CategoryCard from "../Components/CategoryCard/CategoryCard";
import "./ServiceMarketplace.css";
import ServicesSearch from "../Components/ServicesSearch/ServicesSearch";
import { GetApi } from "../../Api/Api";

const ServiceMarketplace = () => {
  // Function to navigate to a specific route
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState(null);

  // Effect hook to fetch category data from an API when the component mounts
  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/services/spCategory");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    getCategoryData();
  }, []);

  // Categories data for rendering different sections
  const categories = [
    { Icon: BorderAll, Heading: 'Technology Developers & Software Services', SubHeading: '200 skills', Url: 'technology-software' },
    { Icon: BorderAll, Heading: 'Accounting & Auditing', SubHeading: '200 skills', Url: 'accounting-auditing' },
    { Icon: BorderAll, Heading: 'Legal & Tax', SubHeading: '200 skills', Url: 'research' },
    { Icon: KeyboardReturn, Heading: 'Insurance', SubHeading: '200 skills', Url: 'insurance' },
    { Icon: PhotoSizeSelectLarge, Heading: 'Escrow & Custodian', SubHeading: '200 skills', Url: 'escrow-custodian' },
    { Icon: PhotoSizeSelectLarge, Heading: 'Research', SubHeading: '200 skills', Url: 'research' },
    { Icon: PhotoSizeSelectLarge, Heading: 'Copywriting', SubHeading: '200 skills', Url: 'copywriting' },
    { Icon: MonetizationOn, Heading: 'Marketing & Digital Services', SubHeading: '200 skills', Url: 'marketing-digital' },
  ];

  // Sample service items for rendering a list
  const serviceItems = [
    {
      icon: <Edit />,
      title: 'No cost to join',
      description: 'Register and browse professional services, browse service providers, or post a request for a service',
    },
    {
      icon: <Star />,
      title: 'Work with quality vendors',
      description: 'Our rigorous screening process ensures that only experienced and qualified experts become part of our platform',
    },
    {
      icon: <FastForward />,
      title: 'Convenient and efficient',
      description: 'Finding the right professionals for your projects has never been easier. Browse and connect with experts seamlessly on our platform',
    },
    {
      icon: <Handyman />,
      title: 'Diverse Expertise',
      description: "No matter your asset type or project scale, you'll discover a wealth of talent and expertise to match your unique requirements.",
    },
  ];

  // Component for rendering individual service items
  const ServiceItem = ({ icon, title, description }) => (
    <Box className='expert-services-list'>
      <Box className="icon">{icon}</Box>
      <Box>
        <Typography className="font-22">{title}</Typography>
        <Typography className="font-12">{description}</Typography>
      </Box>
    </Box>
  );

  // Function to navigate to the service catalog route
  const handleViewSource = () => {
    navigate('/service-proposer/service-catalog')
  }

  // Effect hook to scroll to the top of the window after a delay
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 200)
  })

  // Function to navigate to a dummy route
  const handleOpenRequest = () => {
    navigate('/dummy')
  }


  return (
    <Box>
      <Box className="home-exploreBanner-section ">
        <Box className="container">
          <Box mb={3} px={'10px'}>
            <ServicesSearch categoryData={categoryData} />
          </Box>
        </Box>
        {/* Hero of home */}
        <Box className="container">
          <Box px={'10px'}>
            <Hero
              title={" Your Premier"}
              highlightText={"Service Marketplace"}
              subTitle={
                "The ultimate destination for all your investment project needs. We connect you with top-tier professionals and experts who offer specialized services to support your investment ventures."
              }
              image={RealEstate}
              btnText={'Get Started'}
            />
          </Box>
        </Box>

        {/* Browse Services by Category */}
        <Box className='categoryCard' py={8} px={'10px'}>
          <Box className="container">
            <Box mb={3}>
              <Typography className="font-45 text-white">Browse Services by Category</Typography>
              <Typography className="font-18 text-white">
                Looking for work? Browse{' '}
                <Box
                  component="span"
                  onClick={handleOpenRequest}
                  className="text-golden current-text"
                  style={{
                    position: 'relative',
                    display: 'inline-block',
                  }}
                >
                  current open requests
                  <span
                    className="underline text-golden"
                  ></span>
                </Box>
                .
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {categoryData?.map((category, index) => (
                <Grid item key={index} md={3} sm={6} xs={12}>
                  <CategoryCard
                    key={index} // It's good practice to provide a unique key for each element in the array
                    Icon={category.categoryIcon.path}
                    Heading={category.title}
                    SubHeading={`${category.totalSkill === 1 ? `${category.totalSkill} Skill` : `${category.totalSkill} Skills`} `}
                    url={category.id}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        {/* Expert Services */}
        <Box className='service-marketplace' px={'10px'}>
          <Box className="container" my={8} >
            <Box className='expert-services-bg'>
              <Grid container spacing={3}>
                <Grid item md={5} sm={12} xs={12}>
                  <Box className="left-img" justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end', lg: 'flex-end' }}>
                    <Avatar variant="square" alt="Image" src={landingPageProposerImage} sx={{ width: 'auto', maxWidth: { md: '412px', lg: '412px', xs: '280px' }, height: 'auto' }}></Avatar>
                  </Box>
                </Grid>
                <Grid item md={7} sm={12} xs={12}>
                  <Box className='expert-services'>
                    <Typography className="font-18">For Clients</Typography>
                    <Typography className="font-45" paddingRight={'20%'}>Accelerate your projects with expert services</Typography>

                    {serviceItems.map((item, index) => (
                      <ServiceItem key={index} {...item} />
                    ))}

                    {/* <Box className="btn-wrap multi-btns" >
                      <Button
                        className="btn-rounded sp btn-large btn-golden"
                        style={{ color: '#000' }}
                        startIcon={<Category />}
                      >
                        Sign up for free
                      </Button>
                    </Box> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        {/* Find great clients */}
        <Box className="staticServiceProviders-section" py={{ xs: 5, md: 8 }} mx={-4} mb={-4}>
          <Box className="container">
            <Box className="staticServiceProviders-wrap" pt={{ sm: 0, md: 20, lg: 28 }} px={'32px'}>
              <Box className="section-heading" mb={{ xs: 3, md: 6.7 }}>
                <Typography className='subtitle2 font-18' mt={0} mb={3}> For Service Providers</Typography>
                <Typography component={'h3'} className='title'>Find great clients, your way</Typography>
                <Typography className='subtitle font-18' mt={3} maxWidth={'584px'}>RACE allows anyone from anywhere to invest in projects that have not been available before. Be a part of the democratization of investing and gain access to investors like never before.</Typography>
              </Box>
              <Box className="contant-box-wrap">
                <Grid container spacing={1.5}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="contant-box" alignItems={'center'}>
                      <Typography className="font-28">Post your services and advertise</Typography>
                      <Typography className="font-18" style={{ cursor: 'pointer' }}>Post Your Services</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="contant-box" alignItems={'center'}>
                      <Typography className="font-28">Browse service requests and get hired</Typography>
                      <Typography className="font-18" style={{ cursor: 'pointer' }} onClick={handleViewSource}>View Service Request Catalog</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box >
  );
};
export default ServiceMarketplace;
