import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import "./OnboardingProfile.css"
import OnboardingServiceLeft from './OnboardingProfileComponent/OnboardingServiceLeft'
import OnboardingProfileRight from './OnboardingProfileComponent/OnboardingProfileRight'


const OnboardingServiceInformation = ({onNext,sharedData, handleDataService,onBack , previousData ,stepThreePreviousData,profileImage ,sharedDataStepTwo}) => {

   // Function to handle data update from the left component
   const handleDataUpdate = (newData) => {
    handleDataService(newData)
  };

  return (
    <Box className="onboarding-profile-main">
      <Box className="onboarding-profile-container">
        <Grid container spacing={2}>
          <Grid item xs={8} md={8} sm={12}>
            <OnboardingServiceLeft  onBack={onBack}  handleDataUpdate={handleDataUpdate}  stepThreePreviousData={stepThreePreviousData}   sharedDataStepTwo={sharedDataStepTwo} onNext={onNext} />
          </Grid>
          <Grid item xs={4} md={4} sm={12}>
            <Box pl={3}>
              <OnboardingProfileRight personalInfoData={sharedData} profileImage={profileImage} previousData={previousData} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default OnboardingServiceInformation