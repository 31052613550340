import { Box, Typography } from '@mui/material'
import React from 'react'
import './ServiceCard.css'

const ServiceCard = (props) => {
    return (
        <Box className="sp-service-card">
            <Box className="sp-img-wrap" >
                <Box className="sp-img" sx={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}${props.image})` }}></Box>
            </Box>
            <Box className="sp-title" px={1.8} pt={{ xs: 0.5, sm: 1 }}>
                <Typography className="hc-title" sx={{ color: props?.color }} >{props.title}</Typography>
            </Box>
        </Box>
    )
}

export default ServiceCard