// acceptRejectOffer.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, OFFER_STATUS } from "../../constants";

/**
 * Call acceptRejectOffer method for proposer to accept or reject offers
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function acceptRejectOffer(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {

        let acceptRejectPayload = {}
        if (assetData?.val?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            acceptRejectPayload = {
                address: CONTRACTS.WATCH_DEBT_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.WATCH_DEBT_OFFER_ADDRESS],
                account: address,
                chainId: 6805,
                functionName: "acceptRejectOffer",
                args: [
                    assetData?.val?.listing_id, // asset listing id 
                    assetData?.val?.blockchainOfferId,  // blockchain offer id 
                    assetData?.tokenImageData ? `https://gateway.pinata.cloud/ipfs/${assetData?.tokenImageData}` : '',
                    assetData?.offer_status === OFFER_STATUS.ACCEPTED ? true : false // offer status
                ]
            }
        } else if (assetData?.val?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            acceptRejectPayload = {
                address: CONTRACTS.ART_SALE_MARKETPLACE,
                abi: CONTRACT_ABI[CONTRACTS.ART_SALE_MARKETPLACE],
                account: address,
                chainId: 6805,
                functionName: "acceptRejectOffer",
                args: [
                    assetData?.val?.listing_id, // asset listing id 
                    assetData?.val?.blockchainOfferId,  // blockchain offer id 
                    assetData?.offer_status === OFFER_STATUS.ACCEPTED ? true : false // offer status
                ]
            }
        } else if (assetData?.val?.asset_data?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.val?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT || assetData?.val?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT)) {
            acceptRejectPayload = {
                address: CONTRACTS.SPV_DEBT_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.SPV_DEBT_OFFER_ADDRESS],
                account: address,
                chainId: 6805,
                functionName: "acceptRejectOffer",
                args: [
                    assetData?.val?.listing_id, // asset listing id 
                    assetData?.val?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1, // debtTypeId
                    assetData?.val?.blockchainOfferId,  // blockchain offer id 
                    assetData?.offer_status === OFFER_STATUS.ACCEPTED ? true : false // offer status
                ]
            }
        } else if (assetData?.val?.asset_data?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.val?.capital_type_id === CAPITAL_TYPE_ID.EQUITY)) {
            acceptRejectPayload = {
                address: CONTRACTS.SPV_EQUITY_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.SPV_EQUITY_OFFER_ADDRESS],
                account: address,
                chainId: 6805,
                functionName: "acceptRejectOffer",
                args: [
                    assetData?.val?.listing_id, // asset listing id 
                    assetData?.val?.blockchainOfferId,  // blockchain offer id 
                    assetData?.offer_status === OFFER_STATUS.ACCEPTED ? true : false // offer status
                ]
            }
        } else if (assetData?.val?.asset_data?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            acceptRejectPayload = {
                address: CONTRACTS.FUNDS_EQUITY_OFFER,
                abi: CONTRACT_ABI[CONTRACTS.FUNDS_EQUITY_OFFER],
                account: address,
                chainId: 6805,
                functionName: "acceptRejectInvestment",
                args: [
                    assetData?.val?.listing_id, // asset listing id 
                    assetData?.val?.blockchainOfferId,  // blockchain offer id 
                    assetData?.offer_status === OFFER_STATUS.ACCEPTED ? true : false // offer status
                ]
            }
        }

        const { request: acceptRejectOffer } = await prepareWriteContract(acceptRejectPayload)

        const { hash } = await writeContract(acceptRejectOffer);
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })

            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(2);
                setTimeout(() => {
                    handleSuccess(assetData);
                    handleModalClose();
                    handleActiveStep(-1);
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1);
                showSnackbar("Transaction Failed", 'error');
                return
            }
        }
    } catch (error) {
        console.error('Error in calling acceptRejectOffer', error);
        throw error; // propagate the error
    }
}

export { acceptRejectOffer };