import React, { useState, useEffect } from "react";
import { NewLogo } from "../Components/Images/Images";
import { Box, Modal, Avatar, Typography, Button, Stack } from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import { CAPITAL_TYPE_DISPLAY, CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE } from '../constants.js'

const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    // width: "1043px",
    width: "800px",
};
// model title styles
const textStyle = {
    color: "#B37A56",
    fontWeight: '500'
}
// table row text styles
const rowTextStyles = {
    color: "burlywood",
    fontWeight: '500',
    fontSize: '16px'
}

export default function OfferDetailsModel({ open, offerDetails, onClose, handleOfferStatusChange }) {


    const [viewTable, setViewTable] = useState([])

    useEffect(() => {
        let investType = offerDetails?.asset_data?.investment_type?.investment_name;
        if (investType === INVESTMENT_TYPE.LOAN) {
            setViewTable([
                {
                    key: 'Loan Amount',
                    value: `$${offerDetails?.offer_amount}`
                },
                {
                    key: 'Loan Duration in Months',
                    value: offerDetails?.loan_duration > 1 ? `${offerDetails?.loan_duration} Months` : `${offerDetails?.loan_duration} Month`
                },
                {
                    key: 'Interest Rate',
                    value: `${offerDetails?.loan_roi}%`
                }
            ]);
        } else if (investType === INVESTMENT_TYPE.SALE) {
            setViewTable([
                {
                    key: 'Offer Amount',
                    value: `$${offerDetails?.offer_amount}`
                }
            ]);
        } else if (investType === INVESTMENT_TYPE.FRACTION) {
            if (offerDetails.capital_type_id === CAPITAL_TYPE_ID.EQUITY) {
                setViewTable([
                    {
                        key: 'Offer Amount',
                        value: `$${offerDetails?.offer_amount}`
                    },
                    {
                        key: 'Equity',
                        value: `${offerDetails?.equity_percent}%`
                    }
                ]);
            } else if (offerDetails.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT) {
                setViewTable([
                    {
                        key: 'Offer Amount',
                        value: `$${offerDetails?.offer_amount}`
                    },
                    {
                        key: 'Interest Rate',
                        value: `${offerDetails?.loan_roi}%`
                    }
                ]);

            } else if (offerDetails.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT) {
                setViewTable([
                    {
                        key: 'Offer Amount',
                        value: `$${offerDetails?.offer_amount}`
                    },
                    {
                        key: 'Interest Rate',
                        value: `${offerDetails?.loan_roi}%`
                    }
                ]);

            } else if (offerDetails.capital_type_id == null) {
                setViewTable([
                    {
                        key: 'Investment Amount',
                        value: `$${offerDetails?.offer_amount}`
                    },
                    {
                        key: 'Class',
                        value: `${offerDetails?.class == 1 ? "A" : "B"}`
                    }
                ]);
            }

        }
    }, [offerDetails]);


    return (
        <Modal
            open={open}
            onClose={onClose}
            className="kyc-modal"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Box className="modal-body" sx={style}>
                <Box className="headContent">
                    <Box className="logo">
                        <Avatar
                            alt="Logo"
                            src={NewLogo}
                            variant="square"
                            sx={{ width: 39, height: 90, objectFit: "contain" }}
                        />
                    </Box>
                    <Close className="close-btn" onClick={onClose} />
                </Box>
                <Box className="modalContentDone " >
                    <Box className="main-content loan " >
                        <Box className="votingContent">
                            <Typography component="h1" mt={2} px={4} className="headText" textAlign={"center"}>{offerDetails?.investor_details?.member_id} has {offerDetails?.asset_data?.category_id === CATEGORY_TYPE_ID.FUNDS ? "committed" : "offered"} for <span  >{offerDetails?.asset_data?.asset_title}</span>.</Typography >
                            <Typography px={4} fontSize="16px" fontWeight={"500"} textAlign={"center"}>{offerDetails?.asset_data?.category_id === CATEGORY_TYPE_ID.FUNDS ? "Commitment" : "Offer"} Details
                                {
                                    offerDetails?.capital_type_id && ` (${CAPITAL_TYPE_DISPLAY[offerDetails?.capital_type_id]})`
                                }
                            </Typography >
                            {
                                viewTable.map((item, index) => {
                                    return (
                                        <Stack key={index} direction={'row'} justifyContent={'space-between'} px={15}>
                                            <Typography style={rowTextStyles}>{item.key}</Typography>
                                            <Typography style={rowTextStyles}>{item.value}</Typography>
                                        </Stack>
                                    )
                                })
                            }

                            <Box className='vote-btn-box' mt={2}>
                                <Button className="wallet-button appr-btn" onClick={() => handleOfferStatusChange(2, offerDetails)}> <Box className="btn-icon" ><Done />Accept {offerDetails?.asset_data?.category_id === CATEGORY_TYPE_ID.FUNDS ? "Commitment" : "Offer"}</Box></Button>
                                <Button className="wallet-button rjct-btn" onClick={() => handleOfferStatusChange(3, offerDetails)}><Box className="btn-icon" ><Close />Reject {offerDetails?.asset_data?.category_id === CATEGORY_TYPE_ID.FUNDS ? "Commitment" : "Offer"}</Box></Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal >
    );
}
