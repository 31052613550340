import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, Typography } from '@mui/material'
import './PortfolioCard.css'
import { PortfolioPic1 } from '../../../Components/Images/Images'


function PortfolioCard({image , title}) {

    let avatarSrc = PortfolioPic1; // Default avatar image

    // Check if props.persona exists and is a file object
    if (image instanceof File) {
      // Create a URL for the file object
      avatarSrc = URL.createObjectURL(image);
    } else if (image) {
      // Use the URL provided in props.persona
      avatarSrc = `${process.env.REACT_APP_IMAGE_URL}${image}`;
    }



    return (
        <Box className="pf-card-wrap">
            <Box className="pf-image-wrap" >
                <Avatar className="pf-image" src={avatarSrc} alt={title} />
            </Box>
            <Box className="pf-title-wrap">
                <Typography variant="subtitle1" className="pf-title font-18" mb={1.5}>{title ? title : 'Virtual Assistant To Help Pull Contact Information' }</Typography>
            </Box>
        </Box>
    )
}

export default PortfolioCard


//prop types
PortfolioCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
}

//default props
// PortfolioCard.defaultProps = {
//     image: PortfolioPic1,
//     title: 'Virtual Assistant To Help Pull Contact Information',
//     description: 'Portfolio Description',
//     link: 'https://via.placeholder.com/150'
// }

