import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import purify from "dompurify";
import { Box, Grid, Stack, Typography, Chip, IconButton, Tabs, Tab, Tooltip } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ParcelCardGrid from './ParcelCardGrid';
import { postApi, GetApiParam } from '../../Api/Api';
import SnackbarAlert from '../../Components/Common/SnackbarAlert';
import { Bookmark } from '@mui/icons-material';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from 'react-share';
import Popover from '@mui/material/Popover'
export default function Description({ description, title, subtitle,address, investmentType, assetType, assetSubtype, parcelCardsData, userId, assetId }) {
    // code for popover
    const [anchorEl, setAnchorEl] = useState(null);
    // Function to handle share button click
    const handleShareClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // Function to handle popover close
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'share-popover' : undefined;
    // Getting project url
    const projectUrl = window.location.href

    const data = JSON.parse(description);
    const [value, setValue] = useState('content-0');
    const [scrollArr, setScrollArr] = useState([]);
    const [bookMark, setBookMark] = useState(0)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    var localData = localStorage.getItem("Diclogin") ? JSON.parse(localStorage.getItem("Diclogin")) : ""; // Getting login status from local storage
    var userData = localStorage.getItem("user_data_dic") ? JSON.parse(localStorage.getItem("user_data_dic")) : ""; //Getting  user data from local storage

    const handleTabChange = (event, newValue) => {
        let id = newValue.charAt(8);
        document.getElementById('scroller-wrap').scrollTo({
            top: scrollArr[id],
            behavior: 'smooth'
        });
    }


    useEffect(() => {
        const scrollerArea = document.getElementById('scroller-wrap')
        const handleScroll = () => {
            let scrollAreaTop = scrollerArea.getBoundingClientRect().top;
            const contentSections = document.querySelectorAll('.content-section');
            contentSections.forEach((contentSection) => {
                let contentSectionTop = contentSection.getBoundingClientRect().top - 14;  //minus 14 to adjust the initial padding
                let contentSectionBottom = contentSectionTop + contentSection.offsetHeight;
                if (scrollAreaTop >= contentSectionTop && scrollAreaTop < contentSectionBottom) {
                    let contentSectionId = contentSection.getAttribute('id');
                    setValue(contentSectionId);
                    return;
                }
            });
        };

        scrollerArea.addEventListener('scroll', handleScroll);
        // Cleanup the event listener when the component unmounts
        return () => {
            scrollerArea.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let arr = [];
        const contentSections = document.querySelectorAll('.content-section');
        let elementTop = document.getElementById('scroller-wrap').getBoundingClientRect().top;
        contentSections.forEach((contentSection) => {
            let contentTop = contentSection.getBoundingClientRect().top;
            arr.push(contentTop - elementTop)
        });
        setScrollArr(arr)
    }, [])


    //  Getting initial Bookmark status from Api
    useEffect(() => {
        if (localData) {
            async function getBookmarkData() {
                try {
                    const payloadDataBookmark = {
                        "asset_id": assetId ? assetId : "",
                        "user_id": userData.id ? userData.id : "",
                    }

                    const res = await GetApiParam("/proposer/getBookmark", payloadDataBookmark);
                    if (res?.data?.code === 200) {
                        setBookMark(res?.data?.data[0]?.status)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            getBookmarkData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookMark])


    // Handle cloase function for the Snackbar alert
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    // Handle change function for the BookMark click 
    const handleBookMark = async () => {
        try {
            const bookmarkData =
            {
                "asset_id": (assetId ? assetId : ""),
                "user_id": userData.id ? userData.id : "",
                "status": !bookMark
            }
            const res = await postApi("/proposer/bookmarkAdd", bookmarkData)
            if (res?.data?.code === 200) {
                setBookMark(res?.data?.status)
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Function for setting Message in Snackbar
    const handleBookMarkNotification = async () => {
        setOpenSnackbar(true)
        setSnackbarMessage("Please login to add bookmark.")
    }



    return (
        <>
            <Box className="ProjectDetail-titleBar-wrap" px={1} mt={2.8} mb={3}>
                <Grid container>
                    <Grid item sm={5}>
                        <Box className="pd-titleBar">
                            <Typography component={'h4'} className="title">{title ? title : "No Title"}</Typography>
                            <Typography className="subtitle">{subtitle ? subtitle : address ? address : ""}</Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={7}>
                        <Box className='pd-titleBar-action'>
                            <Stack direction="row" spacing={3.8} alignItems={'center'} justifyContent={'flex-end'}>
                                <Box className="tage-wrap">
                                    <Stack direction="row" spacing={2} alignItems={'center'}  flexWrap={'wrap'}>
                                        <Box>
                                            {investmentType && <Chip className="square-chip" label={investmentType} sx={{ my: 0.2 }} />}
                                        </Box>
                                        <Box>
                                            {assetType && <Chip className="square-chip" label={assetType=== "Asset" ? "Luxury Cars":assetType} sx={{ my: 0.2 }} />}
                                        </Box>
                                        <Box>
                                            {assetSubtype && <Chip className="square-chip" label={assetSubtype} sx={{ my: 0.2 }} />}
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box className="btn-wrap">
                                    <Stack direction="row" spacing={1.5} alignItems={'center'}>
                                        <IconButton
                                            aria-label="Share"
                                            size="large"
                                            className='square-icon-btn share-popover-button'
                                            onClick={handleShareClick}
                                        >
                                            <ShareIcon />
                                        </IconButton>
                                        <div>
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handlePopoverClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                style={{ marginTop: '7px' }}
                                            >
                                                <div className="share-popover">
                                                    <FacebookShareButton style={{ padding: '4px 2px 0px 2px' }} url={projectUrl} quote={title}>
                                                        <FacebookIcon size={32} round style={{ color: '#1877F2', width: '32px', height: '32px' }} />
                                                    </FacebookShareButton>
                                                    <TwitterShareButton style={{ padding: '4px 2px 0px 2px' }} url={projectUrl} title={title}>
                                                        <TwitterIcon size={32} round style={{ color: '#1DA1F2', width: '32px', height: '32px' }} />
                                                    </TwitterShareButton>
                                                    <LinkedinShareButton style={{ padding: '4px 2px 0px 2px' }} url={projectUrl} title={title}>
                                                        <LinkedInIcon size={32} round style={{ color: '#0077B5 ', width: '32px', height: '32px' }} />
                                                    </LinkedinShareButton>
                                                    <EmailShareButton style={{ padding: '4px 2px 0px 2px' }} url={projectUrl} subject={title} >
                                                        <EmailIcon size={32} round style={{ color: "#2ECC71", width: '32px', height: '32px' }} />
                                                    </EmailShareButton>
                                                    {/* Add more share options as needed */}
                                                </div>
                                            </Popover>
                                        </div>
                                        {localData ?
                                            <IconButton aria-label="View" size="large" className={"square-icon-btn"} onClick={() => handleBookMark()}>
                                                {bookMark === 1 ?
                                                    <Bookmark /> :
                                                    <BookmarkBorderIcon />}
                                            </IconButton>
                                            :
                                            <IconButton aria-label="View" size="large" className={"square-icon-btn"} onClick={() => handleBookMarkNotification()}>
                                                <BookmarkBorderIcon />
                                            </IconButton>
                                        }

                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="verticalTab-wrap" mb={1} sx={{ flexGrow: 1, display: 'flex' , flexDirection: { xs: 'column', md: 'row' } }}>
                <Tabs orientation='vertical' value={value} onChange={handleTabChange} >
                    {
                        data?.map((val, ind) => {
                            return (
                                <Tab key={ind} value={`content-${ind}`} label={<Tooltip title={val.title} placement="right"><span className='des-tab-title'>{val.title}</span></Tooltip>}
                                    {...a11yProps(ind)} className='desc-tab-max-width' />
                            )
                        })
                    }
                    {

                        parcelCardsData && parcelCardsData.length > 0 &&
                        <Tab key={data?.length} value={`content-${data?.length}`} label={'Parcel Details'}
                            {...a11yProps(data?.length)} />
                    }

                </Tabs>
                <TabPanel className="verticalTab-content" >
                    <Box id='scroller-wrap'>
                        {
                            data?.map((val, ind) => {
                                return (
                                    <Box className="content-section  Parcels-scrollbar" id={`content-${ind}`} key={ind} pb={1}>
                                        <Typography variant={'h5'} className="title" my={2}>{val.title}</Typography>
                                        <Box dangerouslySetInnerHTML={{ __html: purify.sanitize(val.description) }} />
                                    </Box>
                                )
                            })
                        }
                        {
                            parcelCardsData && parcelCardsData.length > 0 &&
                            <Box className="content-section  Parcels-scrollbar" id={`content-${data?.length}`} key={data?.length} >
                                <Typography variant={'h5'} className="title" my={2}>Parcels</Typography>
                                <ParcelCardGrid cardsData={parcelCardsData} />
                            </Box>
                        }
                        {/* empty box to make sure content is scrolled till last */}
                        <Box id={`empty-content`} />
                    </Box>
                </TabPanel>
            </Box>
            <SnackbarAlert open={openSnackbar} message={snackbarMessage} severity='error' onClose={handleClose} />
        </>
    )
}

Description.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    investmentType: PropTypes.string,
    assetType: PropTypes.string,
    assetSubtype: PropTypes.string,
}

function a11yProps(index) {
    return {
        icon: <KeyboardArrowRightIcon />,
        iconPosition: "end",
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {children}

        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
