import { Box, Typography, Chip, Stack, useMediaQuery, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CAPITAL_TYPE_ID, CAPITAL_TYPE_STATUS, CATEGORY_TYPE_ID } from "../../../constants";
import { GetApiParam } from "../../../Api/Api";
import { formatNumber } from "../../../Components/Common/USFormat";

const CapitalInfoInvestor = ({ assetId, assetData, capitalInfo, selectedCapitalId }) => {
  const isXs = useMediaQuery('(max-width:600px)');

  const [fundedAmount, setFundedAmount] = useState(0);
  const [totalFundedAmount, setTotalFundedAmount] = useState(0);
  useEffect(() => {

    const getFundedAmount = async () => {
      const resp = await GetApiParam('/invest/getAssetFundedAmount', { "asset_id": assetId })
      let fundedData = {}
      if (resp?.data?.code === 200) {
        fundedData = resp?.data?.data;
      }
      if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
        let amount = fundedData[`capital_${selectedCapitalId}_totalAmount`] ? fundedData[`capital_${selectedCapitalId}_totalAmount`] : 0;
        setFundedAmount(parseFloat(amount))
      } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
        // if (Object.keys(fundedData).length > 1) {
        //   let amount1 = fundedData[`capital_${1}_totalAmount`] ? fundedData[`capital_${1}_totalAmount`] : 0;
        //   let amount2 = fundedData[`capital_${2}_totalAmount`] ? fundedData[`capital_${2}_totalAmount`] : 0;
        //   setTotalFundedAmount(parseFloat(amount1) + parseFloat(amount2))
        // } else {
        //   let amount = fundedData[`capital_${selectedCapitalId}_totalAmount`] ? fundedData[`capital_${selectedCapitalId}_totalAmount`] : 0;
        //   setTotalFundedAmount(parseFloat(amount))
        // }
        setTotalFundedAmount(parseFloat(resp?.data?.totalAmount ? resp?.data?.totalAmount : 0))
        let amount = fundedData[`capital_${selectedCapitalId}_totalAmount`] ? fundedData[`capital_${selectedCapitalId}_totalAmount`] : 0;
        setFundedAmount(parseFloat(amount))
      }
    }
    if (assetId) {
      getFundedAmount()
    }
  }, [selectedCapitalId])

  return (
    <Box className="pd-sidebar-priceOverview" >
      <Typography className='rc-detail-subtitle' variant='subtitle2' gutterBottom >Capital Info</Typography>
      {capitalInfo?.map((val, ind) => {
        // only show the capital info of the selected capital in fraction page
        if (val?.class) {
          return val.class == parseInt(selectedCapitalId) && (
            <>
              <Box
                mt={2.5}
                justifyContent={"space-between"}
                display={"flex"}
                alignItems={"center"}
              >
                <Box className='font-18'>{`Class ${val?.class == 1 ? 'A' : 'B'}`}</Box>
                {
                  val?.status === CAPITAL_TYPE_STATUS.FUNDED && (
                    <Box>
                      <Chip className="pc-chip-capitalinfo" label={"Funded"} />
                    </Box>
                  )
                }
              </Box>
              <>
                <Stack direction={"row"} mb={2} mt={1} spacing={2} justifyContent={"space-between"}>
                  <Stack spacing={0} >
                    <Typography className={isXs ? "price font-24" : "price font-24"}>
                      ${formatNumber(assetData?.fractionalize_total_price ? assetData?.fractionalize_total_price : 0)}
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Total Raise
                    </Typography>
                  </Stack>
                  <Stack spacing={0} >
                    <Typography className={isXs ? "price font-24" : "price font-24"}>
                      ${formatNumber(fundedAmount ? fundedAmount : 0)}
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Total Funded Amount
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"row"} spacing={2} justifyContent={"space-between"} mb={2}>
                  <Stack spacing={0}>
                    <Typography className="price font-24">
                      ${formatNumber((assetData?.fractionalize_total_price - totalFundedAmount) ? (assetData?.fractionalize_total_price - totalFundedAmount) : 0)}
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Remaining Amount
                    </Typography>
                  </Stack>
                  <Stack spacing={0}>
                    <Typography className="price font-24">
                      ${formatNumber(val?.minimum_investment ? val?.minimum_investment : 0)}
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Min. Investment
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                  mb={2}
                >
                  <Stack spacing={0}>
                    <Typography className="price font-24">
                      {val?.rate_of_return}%
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Hurdle Rate
                    </Typography>
                  </Stack>
                </Stack>
              </>
            </>
          );
        } else {
          return val.capital_type?.id === parseInt(selectedCapitalId) && (
            <>
              <Box
                mt={2.5}
                justifyContent={"space-between"}
                display={"flex"}
                alignItems={"center"}
              >
                <Box className='font-18'>{val.capital_type.capital_name}</Box>
                {
                  val?.status === CAPITAL_TYPE_STATUS.FUNDED && (
                    <Box>
                      <Chip className="pc-chip-capitalinfo" label={"Funded"} />
                    </Box>
                  )
                }
              </Box>
              <>
                <Stack direction={"row"} mb={2} mt={1} spacing={2} justifyContent={"space-between"}>
                  <Stack spacing={0} >
                    <Typography className={isXs ? "price font-24" : "price font-24"}>
                      ${formatNumber(val?.total_raise ? val?.total_raise : 0)}
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Total Raise
                    </Typography>
                  </Stack>
                  <Stack spacing={0} >
                    <Typography className={isXs ? "price font-24" : "price font-24"}>
                      ${formatNumber(fundedAmount ? fundedAmount : 0)}
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Total Funded Amount
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"row"} spacing={2} justifyContent={"space-between"} mb={2}>
                  <Stack spacing={0}>
                    <Typography className="price font-24">
                      ${formatNumber((val?.total_raise - fundedAmount) ? (val?.total_raise - fundedAmount) : 0)}
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Remaining Amount
                    </Typography>
                  </Stack>
                  <Stack spacing={0}>
                    <Typography className="price font-24">
                      ${formatNumber(val?.minimum_investment ? val?.minimum_investment : 0)}
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Min. Investment
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                  mb={2}
                >
                  <Stack spacing={0}>
                    <Typography className="price font-24">
                      {val?.rate_of_return}%
                    </Typography>
                    <Typography
                      className="title font-12 text-blueGray-400 text-capitalize"
                      mt={0.3}
                    >
                      Interest Rate
                    </Typography>
                  </Stack>
                  {val?.capital_type.id === CAPITAL_TYPE_ID.EQUITY && (
                    <Stack spacing={0}>
                      <Typography className="price font-24">
                        {val?.equity}%
                      </Typography>
                      <Typography
                        className="title font-12 text-blueGray-400 text-capitalize"
                        mt={0.3}
                      >
                        Equity
                      </Typography>
                    </Stack>
                  )}
                  {val?.capital_type.id !== CAPITAL_TYPE_ID.EQUITY && (
                    <Stack spacing={0}>
                      <Typography className="price font-24">
                        {parseInt(val?.capital_type.id === CAPITAL_TYPE_ID.SENIOR_DEBT ? val?.senior_duration : val?.junior_duration)} Years
                      </Typography>
                      <Typography
                        className="title font-12 text-blueGray-400 text-capitalize"
                        mt={0.3}
                      >
                        Duration of Debt
                      </Typography>
                    </Stack>
                  )}
                  {val?.capital_type.id !== CAPITAL_TYPE_ID.EQUITY && (
                    <Stack spacing={0}>
                      <Typography className="price font-24">
                        {parseInt(val?.capital_type.id === CAPITAL_TYPE_ID.SENIOR_DEBT ? val?.iop_senior : val?.iop_junior)} Months
                      </Typography>
                      <Typography
                        className="title font-12 text-blueGray-400 text-capitalize"
                        mt={0.3}
                      >
                        Interest-only Period
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </>
            </>
          );
        }
      })}
    </Box>
  );
};

export default CapitalInfoInvestor;
