import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useMediaQuery } from "@mui/material";
import { GetApi } from "../../Api/Api";
import ChatContainer from "./ChatContainer";
import Contacts from "./Contacts";
import { decreaseTotalUnreadCount } from "../../features/auth/chatSlice";
import { useSocket } from "../../Contexts/SocketContext";
import "./Chats.css";

const ChatsPage = () => {
  const isXs = useMediaQuery('(max-width:600px)');
  const sidebarOpen = useOutletContext();
  const socket = useSocket();
  const dispatch = useDispatch();
  const onlineUsers = useSelector((state) => state.chat.onlineUsersIds);

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user_data"));
    const userData = JSON.parse(localStorage.getItem("user_data_dic"));
    /**
     * Checking whether the user or userData is Available or not
     */
    if (!user && !userData) {
      navigate("/login");
    } else {
      socket.emit("refresh-unread", user?.id);
      setCurrentUser(user);
    }
  }, []);

  useEffect(() => {
    const getContacts = async () => {
      let res = await GetApi(`chat/getChat/${currentUser ? currentUser.id : state.dicId}`);
      setContacts(res?.data?.data);
      /**
       * Checking Condition for the case when user comes from Project Detail page
       * will open the chat of the selected asset and receiver
       */
      if (state?.assetId) {
        let dataForChat = res?.data?.data?.filter((elem) => {
          return (elem.asset_id === state.assetId)
        })
        if (dataForChat?.length) {
          setCurrentChat(dataForChat[0]);
        }
      }
    };
    if (currentUser || state) {
      getContacts();
    }
  }, [currentUser, state]);

  const handleChatChange = (chat) => {
    // reset unread count to 0 for the selected chat contact
    let countTobeRead = 0;
    contacts.forEach((element, index) => {
      if (element.id === chat.id) {
        countTobeRead = element.unread;
        contacts[index].unread = 0;
      }
    })
    dispatch(decreaseTotalUnreadCount(countTobeRead));
    setContacts(contacts);
    setCurrentChat(chat);
    // emit seen message event
    socket.emit("seen-msg", { chatId: chat.id, userId: currentUser.id });
  };
  const handleBackToChat = () => {
    setCurrentChat(null);
  }
  return (
    <Grid container className="ctPage" spacing={0} marginBottom={'-64px'} marginRight={'-20px'} >
      {isXs ?
        <>
          {currentChat == null ?
            <Grid item xs={12} md={sidebarOpen ? 3.5 : 3} sm={4}>
              <Contacts contacts={contacts} changeChat={handleChatChange} onlineUsers={onlineUsers} currentChat={currentChat} />
            </Grid> : null
          }
          {currentChat ?
            <Grid item xs={12} md={sidebarOpen ? 8.5 : 9} sm={8} >
              <ChatContainer currentChat={currentChat} socket={socket} handleBackToChat={handleBackToChat} />
            </Grid>
            : null
          }
        </> :
        <>
          <Grid item xs={12} md={sidebarOpen ? 3.5 : 3} sm={4}>
            <Contacts contacts={contacts} changeChat={handleChatChange} onlineUsers={onlineUsers} currentChat={currentChat} />
          </Grid>
          <Grid item xs={12} md={sidebarOpen ? 8.5 : 9} sm={8} >
            <ChatContainer currentChat={currentChat} socket={socket} noBtn={true} />
          </Grid>
        </>
      }

    </Grid>
  );
};

export default ChatsPage;
