import { Box, Button, IconButton, InputAdornment, Paper, Stack, TextField, Tooltip, Typography, styled, useMediaQuery } from '@mui/material'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useNavigate } from 'react-router-dom';
import "../Investor.css"
import { forwardRef } from 'react';
import { Info } from '@mui/icons-material';
import NumericFormatCustom from '../../../Components/Common/NumericFormatCustom';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiFilledInput-root': {
        backgroundColor: '#06487E',
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: '#06487E',
        },
        '&.Mui-focused': {
            backgroundColor: '#06487E',
        }
    },
    '& .MuiInputBase-input.MuiFilledInput-input': {
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '16px',
    },
    '& .MuiFilledInput-underline:before': {
        display: 'none',
    },
    '& .MuiFilledInput-underline:after': {
        display: 'none',
    },
    '& .MuiFilledInput-underline:hover:before': {
        display: 'none',
    },
    '& .MuiFilledInput-underline:hover:after': {
        display: 'none',
    },
}));

const Sale = forwardRef(({ assetTitle, salePrice, depositAmount, changeAmount, changeOfferState, state }, ref) => {
    const [isDeposit, setIsDeposit] = useState(false);
    const navigate = useNavigate();
    const [amount, setAmount] = useState(salePrice)
    const [saleAmountError, setSaleAmountError] = useState({ error: false, message: '' });
    const [depositAmountError, setDepositAmountError] = useState({ error: false, message: '' });
    const [tooltipOpen, setTooltipOpen] = useState({ 1: false });
    const isMobileOrTab = useMediaQuery('(max-width: 1024px)');

    const handleBackBtn = () => {
        // navigate(-1)
        navigate(`/project-details/${state?.assetId}`, { replace: true })
    }

    useEffect(() => {
        changeAmount(amount)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount])

    // Expose the child function through the ref
    useImperativeHandle(ref, () => ({
        /**
         * runs validations and returns data according to validations
         * @returns {object | false}
         */
        getData() {
            if (validationCheckSale(amount) === false) {
                return false
            }
            return { offer_amount: amount, isOffer: isDeposit, depositAmount }
        }
    }));

    /**
     * checks validation 
     * @param {string | number} amount 
     * @returns {boolean} true if no error
     */
    const validationCheckSale = (amount) => {
        let value = parseFloat(amount)
        if (isNaN(value)) {
            setSaleAmountError({ error: true, message: 'Amount is required' })
            return false
        } else if (value === 0) {
            setSaleAmountError({ error: true, message: 'Amount Should be greater than zero' })
            return false
        } else if (value > salePrice) {
            setSaleAmountError({ error: true, message: `Amount should be less than $${salePrice}` })
            return false
        } else if (isDeposit && value <= depositAmount) {
            setSaleAmountError({ error: true, message: `Amount should be greater than $${depositAmount}` })
            return false
        }
        else { //no error
            setSaleAmountError({ error: false, message: '' })
            return true
        }
    }

    /**
     * 
     * @param {boolean} isDeposit  true if deposit is selected 
     */
    const handleChangeDeposit = (isDeposit) => {
        setIsDeposit(isDeposit)
        if (isDeposit) {
            changeOfferState(true);
            setAmount(salePrice)
        } else {
            changeOfferState(false);
            setAmount(salePrice)
        }
        setSaleAmountError({ error: false, message: '' })
    }

    /**
     * Tooltip Close function
     * @param {*} tooltipId 
     */
    const handleTooltipClose = (tooltipId) => {
        if (isMobileOrTab) {
            setTooltipOpen(prevState => ({
                ...prevState,
                [tooltipId]: false
            }));
        }
    };

    /**
     * Tooltip Open Function
     * @param {*} tooltipId 
     */
    const handleTooltipOpen = (tooltipId) => {
        if (isMobileOrTab) {
            setTooltipOpen(prevState => ({
                ...prevState,
                [tooltipId]: true
            }));
        }
    };





    return (
        <Paper elevation={3} sx={{ p: 2 }} className='rc-basic-container'>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                <IconButton onClick={handleBackBtn} sx={{ color: '#ffffff' }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant='h6' sx={{ flexGrow: 1 }}>Invest in {assetTitle}</Typography>
            </Stack>
            <Box component={'hr'} className='rc-hr' />
            <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom>Investment Amount</Typography>
            <Box className="payment-enter-investor">
                <CustomTextField onChange={(event) => { setAmount(event.target.value); validationCheckSale(event.target.value) }}
                    value={amount}
                    hiddenLabel
                    placeholder="Amount"
                    variant='filled'
                    error={saleAmountError.error}
                    helperText={saleAmountError.message || `Full amount $${salePrice}`}
                    fullWidth
                    InputProps={{
                        inputComponent: NumericFormatCustom,
                        startAdornment: <InputAdornment position="start"><AttachMoneyIcon sx={{ color: '#C5E4FF' }} /></InputAdornment>,
                        endAdornment: <InputAdornment position="end"><Button onClick={() => { setAmount(salePrice); validationCheckSale(salePrice) }} className="rc-tertiary-btn btn-golden" variant="filled" sx={{ height: '27px', marginRight: '11px' }}> Max</Button>
                            <Tooltip classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow', }} PopperProps={{ style: { maxWidth: '200px', maxHeight: '130px' } }} title={<span className='font-14'>You can submit an offer between the minimum investment to the max loan amount requested</span>} placement='bottom' arrow
                                open={isMobileOrTab ? tooltipOpen[1] : undefined}
                                onClose={() => handleTooltipClose(1)}>
                                <Info style={{ color: 'white' }} onClick={() => handleTooltipOpen(1)}
                                    onMouseOver={isMobileOrTab ? undefined : () => handleTooltipOpen(1)}
                                    onMouseOut={isMobileOrTab ? undefined : () => handleTooltipClose(1)} />
                            </Tooltip>

                        </InputAdornment>,
                        readOnly: !isDeposit // if deposit is selected then amount is editable
                    }}
                    sx={{ input: { color: 'white !important' } }}
                    FormHelperTextProps={{ sx: { color: 'white' } }}
                // onInput={(event) => {
                //     event.target.value = event.target.value
                //         .replace(/[^\d.]/g, "")
                // }}
                />
            </Box>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent='space-around' alignItems='center' sx={{ mt: 2 }}>
                <Button variant={!isDeposit ? 'outlined' : "contained"} className={!isDeposit ? 'rc-primary-btn-outline-rounded' : 'rc-primary-btn-rounded'} sx={{ width: '100%' }} onClick={() => { handleChangeDeposit(false) }} >Make Full Payment</Button>
                <Button variant={isDeposit ? 'outlined' : "contained"} className={isDeposit ? 'rc-primary-btn-outline-rounded' : 'rc-primary-btn-rounded'} sx={{ width: '100%' }} onClick={() => { handleChangeDeposit(true) }}  >Pay Deposit</Button>
            </Stack>
            {
                isDeposit &&
                <>
                    <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom>Deposit Amount</Typography>
                    <Box className="payment-enter-investor-ro">
                        <CustomTextField hiddenLabel variant='filled' FormHelperTextProps={{ color: 'primary' }} helperText="This deposit amount will be charged immediately to your payment method of choice"
                            fullWidth InputProps={{
                                startAdornment: <InputAdornment position="start"><AttachMoneyIcon sx={{ color: '#6A91A9' }} /></InputAdornment>,
                                readOnly: true,
                            }}
                            defaultValue={depositAmount}
                            sx={{ input: { color: 'white !important' } }} />
                    </Box>
                </>
            }

        </Paper >
    )

});

export default Sale


