import React, { useState } from 'react'
import './ProfileDescription.css'
import { Avatar, Box, Button, Icon, IconButton, Paper, Stack, Typography } from '@mui/material'
import { CompanyBannerImg, MessageButtonGradient, PersonaPicBg, ShareLinkGradient, EditButtonGradient } from '../../../Components/Images/Images'
import PropTypes from 'prop-types'
import LinkIcon from '@mui/icons-material/Link';
import { ArrowDropDown } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import { IndividualProfilePersona, EditIconGradient } from "../../../Components/Images/Images"
import { useNavigate } from 'react-router-dom'

function ProfileDescription(props) {

    console.log("props...", props)
    const [readMoreStatus, setReadMoreStatus] = useState(false)
    const navigate = useNavigate()
    let avatarSrc = IndividualProfilePersona; // Default avatar image

    /**
     * HandleCLick Function for the Read More Button
     */
    const handleClickReadMore = () => {
        setReadMoreStatus(true)
    }

    /**
     * Onclick function for the Read Less Function
     */
    const handleClickReadLess = () => {
        setReadMoreStatus(false)
    }

    // Check if props.persona exists and is a file object
    if (props.persona instanceof File) {
        // Create a URL for the file object
        avatarSrc = URL.createObjectURL(props.persona);
    } else if (props?.persona) {
        // Use the URL provided in props.persona
        avatarSrc = `${process.env.REACT_APP_IMAGE_URL}${props.persona}`;
    }


    const handleChat = () => {
        navigate("/service-proposer-dashboard/chats", {
            state: {
                user_id: props?.user_id,
            },
        });
    };




    return (
        <Box className="profile-description-wrap">
            <Box className="profile-banner-img-wrap" >
                <Avatar className='profile-banner-img' src={props.banner} />
            </Box>
            <Box className="profile-persona-img-wrap" >
                <Avatar className='profile-persona-img user-img-divs' src={avatarSrc} />
            </Box>
            <Box>
                <Stack direction="row" justifyContent={'space-between'} mt={-5} ml={17} >
                    <Box>
                        <Typography variant="h5" className="profile-name">{props.name}</Typography>
                        <Typography variant="body1" className="profile-description text-blueGray-400">{props.address}</Typography>
                    </Box>

                    {(props.from !== "serviceProviderProfile") ?
                        <Button startIcon={<AddIcon />} variant="contained" className="btn-rounded" style={{ background: "var(--golden-gradient)" }}>Hire</Button>
                        : ""
                    }
                </Stack>
            </Box>
            <Stack mt={2} direction={'row'} spacing={6} justifyContent={'space-between'} >
                <Box pt={props.description === "No discription found." ? 4 : 0}>
                    <Typography variant="h6" sx={{ fontSize: '16px' }} className="profile-description">{props.title}</Typography>
                    {
                        readMoreStatus ?
                            <Typography variant="body1" className="profile-description text-blueGray-400">{props.description}</Typography>
                            :
                            <Typography sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                            }} variant="body1" className="profile-description text-blueGray-400">{props.description}</Typography>
                    }
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Typography variant="h6" sx={{ fontSize: '14px' }} className="profile-description text-white">{props.hourlyrate ? `$${props.hourlyrate}/hr` : '$0/hr'}</Typography>
                    <img src={props.from === "serviceProviderProfile" ? EditIconGradient : ShareLinkGradient} alt={""} style={{ margin: '0px 22px 4px 12px' }} />
                </Box>
            </Stack>
            <Box mt={3}>
                {readMoreStatus ?
                    <Button variant="contained" className="btn-rounded read-more-btn" endIcon={<KeyboardArrowUpIcon />} onClick={() => handleClickReadLess()}><Typography>Read Less</Typography></Button>
                    :
                    <Button variant="contained" className="btn-rounded read-more-btn" endIcon={<KeyboardArrowDownIcon />} onClick={() => handleClickReadMore()}><Typography>Read More</Typography></Button>
                }
            </Box>
            <Box mt={3}>
                <img onClick={() => handleChat()} src={props.from === "serviceProviderProfile" ? EditButtonGradient : MessageButtonGradient} alt={''} />
            </Box>
        </Box >
    )
}

export default ProfileDescription

//Prop Types

ProfileDescription.propTypes = {
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    persona: PropTypes.string,
    banner: PropTypes.string,
    from: PropTypes.string
}

//Default Props

ProfileDescription.defaultProps = {
    name: "Name is not available",
    address: 'No address found.',
    title: 'No title found.',
    description: "No discription found.",
    persona: PersonaPicBg,
    banner: CompanyBannerImg,
    from: "any page"
}
