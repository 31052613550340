import { Box } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import RaceProjectCard from "../RaceProjectCard/RaceProjectCard";
const SlickSlider = () => {
  const settings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
  };
  return (
    <Box>
      <div className="slider-container">
        <Slider {...settings}>
          <div style={{ marginRight: "10px" }}>
            <RaceProjectCard />
          </div>
          <div style={{ marginRight: "10px" }}>
            <RaceProjectCard />
          </div>
          <div style={{ marginRight: "10px" }}>
            <RaceProjectCard />
          </div>
          <div style={{ marginRight: "10px" }}>
            <RaceProjectCard />
          </div>
          <div style={{ marginRight: "10px" }}>
            <RaceProjectCard />
          </div>
          <div style={{ marginRight: "10px" }}>
            <RaceProjectCard />
          </div>
        </Slider>
      </div>
    </Box>
  );
};

export default SlickSlider;
