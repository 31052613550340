import React, { useState ,useEffect } from "react";
import "./SavedServices.css";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import { Watches, ChipIcon } from "../../../Components/Images/Images";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { GetApi } from '../../../Api/Api'
import MainCard from "../../Components/MainCard/MainCard";

const SavedServices = () => {

    
  return (
    <Box className="saved-service-container">
      <Typography className="font-28">Your Saved Services</Typography>

      <Box className="saved-service-card-wrapper">
          <ServicesSection />
      </Box>
    </Box>
  );
};

export default SavedServices;
function ServicesSection() {
    const [serviceData, setServicesData] = useState([]);

    useEffect(() => {
        // Api to get categoryData
        async function getServicesData() {
            try {
                const res = await GetApi("/services/getServices");
                setServicesData(res?.data?.data?.length ? res.data.data : []);
            } catch (error) {
                console.log(error);
            }
        }
        getServicesData();
    }, [])

    return (
        <Box margin={'10px 0px 0px 16px'}>
            <Grid container spacing={2}>
                {
                    <Grid container spacing={1}>
                        {serviceData?.slice(0, 3).map((card, index) => (
                            <Grid key={index} item md={4} sm={6} xs={12}>
                                <MainCard {...card} bgColor='var(--blue-800)' />
                            </Grid>
                        ))}
                    </Grid>
                }
            </Grid>
        </Box>
    )
}