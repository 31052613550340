import React, { useEffect, useState } from "react";
import Grid from "@mui/system/Unstable_Grid";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GetApi, GetApiParam, postApi } from "../Api/Api";
import "react-image-gallery/styles/css/image-gallery.css";
import ProjectDetailFactory from "./ProjectDetailFactory";
import { SUGGEST_REVIEW } from "../constants";
import ModalContent from "../DIC/Modals/ModalContent";
import TransactionProgressModal from "../Components/SmartContract/TransactionProgressModal";
import "./ProjectDetails.css";

const ProjectDetailsPage = () => {
  const { assetId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;

  const [projectDetailsData, setProjectDetailsData] = useState({});
  const [getReviewData, setReviewData] = useState([]);
  const [isTermsAvailable, setIsTermsAvailable] = useState(false);
  const [forSuggestProjectDetails, setForSuggestProjectDetails] =
    useState(false); // state for handling button trigger
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openVotingModal, setOpenVotingModal] = useState(false);
  const [openReviewSuggestionModal, setReviewSuggestionModal] = useState(false);
  const [forReviewProjectDetails, setForReviewProjectDetails] = useState(false);
  const [isStateFromChanged, setIsStateFromChanged] = useState(false)
  const [updateReviewData, setUpdatedReviewData] = useState([])
  const [loading, setLoading] = useState(true)
  const [assetData, setAssetData] = useState({})
  const [dataFromModals, setDataFromModals] = useState({})
  // state to handle add review progress modal open close
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [proposerDecision, setProposerDecision] = useState(false);
  // fetching data from local storage
  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : "";

  //  Navigeting on myproject page on click of arrow.
  const backPage = () => {
    navigate(-1, {
      state: {
        from: "projectDetails",
      },
      replace: true
    });
  };

  useEffect(() => {
    if (isTermsAvailable && (stateData?.proState === "notification" || stateData?.proState == "inReview")) {
      setIsStateFromChanged(!isStateFromChanged)
      setForReviewProjectDetails(true);
    }
  }, [isTermsAvailable, stateData]);

  useEffect(() => {
    async function getOfferStatus() {
      if (localData?.id) {
      try {
        let res = await GetApi(
          `/invest/checkDoOfferValidity?asset_id=${assetId}`
        );
        if (res?.data) {

          try {
            const overviewPayload = {
              asset_id: assetId,
            };
            const response = await postApi(
              "/proposer/assetsDetails",
              overviewPayload
            );
            if (response?.data?.data) {
              setAssetData(response.data.data[0])
              response.data.data[0].isOfferAccepted =
                res?.data?.isOfferValidity === true ? false : true;
              setLoading(false)
              setProjectDetailsData(
                ProjectDetailFactory(
                  response?.data?.data[0]?.category_id,
                  response?.data?.data[0]
                )
              );
            }
          } catch (error) {
            console.log(error);
          }
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        // try {
        //   const overviewPayload = {
        //     asset_id: assetId,
        //   };

        //   const response = await postApi(
        //     "/proposer/assetsDetails",
        //     overviewPayload
        //   );
        //   if (response?.data?.data) {
        //     setAssetData(response.data.data[0])
        //     response.data.data[0].isOfferAccepted = false;

        //     setProjectDetailsData(
        //       ProjectDetailFactory(
        //         response?.data?.data[0]?.category_id,
        //         response?.data?.data[0]
        //       )
        //     );
        //   }
        // } catch (error) {
        //   console.log(error);
        // }
        console.log(error);
      }
    }else{
      navigate("/")
    }
    }

    /**
     * Api for getting data to know project review status
     */
    async function getReviewData() {
      if (localData?.id) {
        try {
          const overviewPayload = {
            asset_id: assetId,
            proposer_id: localData?.id,
          };
          const res = await GetApiParam(
            "/committee/getSuggestReview",
            overviewPayload
          );

          if (res?.data?.data) {
            if (
              res?.data?.data?.length !== 0 &&
              res?.data?.data[0]?.status === SUGGEST_REVIEW.PENDING
            ) {
              setIsTermsAvailable(true);
            }
            setReviewData(res.data.data[0]);
            setUpdatedReviewData(res.data.data[0])
          }
        } catch (error) {
          console.log(error);
        }
      }else{
        navigate("/")
      }
    }

    //Timeout to send token when refreshing the page 
    setTimeout(() => {
        getOfferStatus();
        getReviewData();
    }, 100);

    // Scroll to the top on page load
    window.scrollTo(0, 0);
  }, [assetId]);

  // Generate payload using checkedCheckboxes state
  const generatePayload = () => { };

  // send approve reject to blockchain
  const handleProposerDecision = async () => {
    setOpenVotingModal(false);
    setOpenReviewModal(false);
    setReviewSuggestionModal(false);
    navigate("/user/projects");
  }

  // Callback function to receive data from the child component
  const receiveDataFromModalContent = async (dataFromModal) => {
    if (dataFromModal?.data === "accept") {
      setProposerDecision(true);
    } else if (dataFromModal?.data === "reject") {
      setProposerDecision(false);
    }
    setDataFromModals(dataFromModal)
    setOpenTransactionModal(true);
  };

  const handleReviewSuggestionModal = (vals) => {
    setReviewSuggestionModal(vals)
  }

  /**
  * Function to handle contract progress modal status
  */
  const handleModalClose = () => {
    setOpenTransactionModal(false)
  }


  return (
    <>
      <div className="main-layout">
        <Grid>
          <Box className="arrowHeader rightArrowSign">
            <Box className="container">
              <Box
                sx={{
                  cursor: "pointer",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                <ArrowBackIcon onClick={() => backPage()} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Box className="pdPage-main projectDetails-page" pb={6.2} mb={0}>
          <Box className="container">
            <Box className="projectDetails-wrap">
              <Grid container spacing={2}>
                {/* this template id for left panel of project details page */}

                <Grid sm={12} md={8} xs={12}>
                  {projectDetailsData.leftPanel?.length ?
                    <Box className="projectDetails-left">
                      <Box className="projectDetails-left-inner">
                        {
                          // left panel is loaded here
                          projectDetailsData.leftPanel &&
                          projectDetailsData.leftPanel.map((component, index) => {
                            return <Box key={index}>{component}</Box>;
                          })
                        }
                      </Box>
                    </Box>
                    : null
                  }
                </Grid>

                {/* this template id for right panel of project details page */}
                <Grid sm={12} md={4} xs={12}>
                  {projectDetailsData.rightPanel?.length ?
                    <Box className="projectDetails-right">
                      <Box className="pdPage-rs">
                        {
                          // Right Panel is loaded here
                          (projectDetailsData.rightPanel || []).map((component) => {
                            return component;
                          })
                        }
                      </Box>
                    </Box>
                    : null}
                </Grid>
              </Grid>
              {/* Start help button */}
              {/* <Button
                className="squareHelp-btn btn-rounded btn-yellow-100 btn-large"
                onClick={handleClick}
              >
                <Avatar
                  alt="Icon"
                  src={squareQuestionsMark}
                  sx={{ width: "36px", height: "36px" }}
                ></Avatar>
              </Button> */}
            </Box>
          </Box>
        </Box>
        <ModalContent
          // assetData={assetData}
          // data={data}
          assetId={assetId}
          openVotingModal={openVotingModal}
          setOpenVotingModal={setOpenVotingModal}
          openReviewModal={openReviewModal}
          setOpenReviewModal={setOpenReviewModal}
          openReviewSuggestionModal={openReviewSuggestionModal}
          setReviewSuggestionModal={handleReviewSuggestionModal}
          checkboxPayload={generatePayload()}
          sendDataToCheckReview={receiveDataFromModalContent}
          forSuggestProjectDetails={forSuggestProjectDetails}
          setForSuggestProjectDetails={setForSuggestProjectDetails}
          forReviewProjectDetails={forReviewProjectDetails}
          setForReviewProjectDetails={setForReviewProjectDetails}
          updateReviewData={updateReviewData}
          isStateFromChanged={isStateFromChanged}
        />
      </div>

      {/* stake race token progress modal approve by proposer*/}
      {openTransactionModal ? <TransactionProgressModal identity={'approve-suggestion'} confirmStake={handleProposerDecision} propData={assetData} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} proposerDecision={proposerDecision} /> : null}

      {loading && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      }
    </>
  );
};

export default ProjectDetailsPage;
