import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-multi-carousel/lib/styles.css";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { GetApiParam } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { DicCard } from "../DICCard/DicCard";
import "../../Pages/Home/Home.css"

const OverviewProjectList = () => {
    const location = useLocation();
    const state = location.state;
    const { showSnackbar } = useSnackbar();

    const [assetData, setAssetData] = useState();

    // State variable for the dynamic inputes
    const [serverTime, setServerTime] = useState();
    const [serverDate, setServerDate] = useState();
    const [serverClockTime, setServerClock] = useState();
    //   const [investVal, setInvestVal] = useState([]);
    //   const [defaults, setDefaults] = useState({});
    const [bookmark, setBookmark] = useState() // for Bookmark

    // fetching data from local
    const localData = localStorage.getItem("user_data_dic") ? JSON.parse(localStorage.getItem("user_data_dic")) : ""

    useEffect(() => {
        scrollToTop();

    }, []);

    // for Bookmark functionality
    useEffect(() => {
        if (localData?.id) {
            // Api to get InvestmentTypeData
            async function getBookmarkData() {
                try {
                    const payloadData = {
                        "user_id": localData.id
                    }
                    const res = await GetApiParam("/proposer/getBookmark/", payloadData);
                    setBookmark(res?.data?.data ? res?.data?.data : [])
                } catch (error) {
                    console.log(error);
                }
            }
            const timer = setTimeout(() => {
                getBookmarkData()
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        async function getProjectData() {
            try {
                if (localData.id) {
                    const payloadData = {
                        // "committee_member_id": localData.id
                        "id": localData.id
                    }

                    const res = await GetApiParam("/committee/getProject", payloadData);
                    if (res) {
                        setAssetData(res.data?.data)
                        setServerTime(res.data?.server_time)
                        setServerDate(res.data?.server_date)
                        setTimer(res)
                    }
                } else {
                    showSnackbar("Member id is missing.", "error");
                }

            } catch (error) {
                console.log(error)
            }
        }
        const timer = setTimeout(() => {
            getProjectData()
        }, 50);
        return () => clearTimeout(timer);
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    function setTimer(res) {
        // initialize server clock
        const serverClockTime = new Date(res.data.server_date);
        var timestamp = res.data.server_time.split(':');
        serverClockTime.setHours(timestamp[0])
        serverClockTime.setMinutes(timestamp[1])
        serverClockTime.setSeconds(timestamp[2])
        setServerClock(serverClockTime);
    }

    // start clock interval
    setInterval(() => {
        if (serverClockTime) {
            serverClockTime.setSeconds(serverClockTime.getSeconds() + 1);
            setServerClock(serverClockTime);
            const event = new CustomEvent('clocktime', { detail: serverClockTime, bubbles: true });
            document.dispatchEvent(event);
        }
    }, 1000);


    // Product slider
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
        },
        desktopXXl: {
            breakpoint: { max: 3000, min: 2000 },
            items: 5,
        },
        desktopXl: {
            breakpoint: { max: 2000, min: 1400 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1023, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    /**
     * set the data from the filter component
     */
    //   const FilteredData = (data) => {
    //     setAssetData(data);
    //   };

    //   const handleSortChange = (sortBy) => {

    //     if (assetData || assetData.length !== 0) {
    //       if (sortBy === 'Name') {
    //         assetData?.sort((a, b) => a?.asset_title?.localeCompare(b?.asset_title));
    //         setAssetData([...assetData]);
    //       }
    //       else if (sortBy === 'Amount') {
    //       } else if (sortBy === 'Asset Type') {
    //         assetData?.sort((a, b) => a?.assets_category?.title?.localeCompare(b?.assets_category?.title));
    //         setAssetData([...assetData]);
    //       }
    //       else if (sortBy === 'Default') {
    //       }
    //     }

    //   };


    // functionality for Bookmark
    const bookmarkVal = (asset_id) => {
        return bookmark?.some((item) => item.asset_id === asset_id && item.status === 1);
    }

    return (
        <Box>
            {/*All projects Explore start */}
            <Box className="HomeExplore-section" sx={{ mb: 6, mt: 3 }}>
                <Box className="container">
                    <Box className="filter-panel">
                        <Box className="fp-left">
                            <Box className="heading-left">
                                <Typography component={"h2"} >All Overview Projects</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="HomeExplore-wrap">
                        <Grid spacing={{ xs: 1, sm: 1, md: 2 }} container>
                            {(() => {
                                const projects = [];
                                for (let i = 0; i < assetData?.length; i++) {
                                    const galleryimg = [];

                                    // Inserting gellery Images Into Slider
                                    assetData[i]?.asset_gallery.map((val, ind) => {
                                        if (val.title === 'gellary') {
                                            galleryimg.push(`${process.env.REACT_APP_IMAGE_URL}${val.images.path}`)
                                        }
                                    })
                                    projects.push(
                                        <Grid item xs={12} sm={6} md={4} lg={4}>
                                            <DicCard
                                                id={assetData[i]?.id}
                                                category_id={assetData[i]?.category_id}
                                                title={assetData[i]?.asset_title}
                                                address={assetData[i]?.owner_information?.owner_address}
                                                subTitle={assetData[i]?.asset_address}
                                                investType={assetData[i]?.investment_type?.investment_name}
                                                isLoan={assetData[i]?.assets_category?.title}
                                                isDraft={false} isClock={false}
                                                isPending={assetData[i]?.status === "Pending" || assetData[i]?.status ===
                                                    "inreview" ? true : false}
                                                isDelete={assetData[i]?.status === "Rejected" ? true : false}
                                                isSaved={false}
                                                imgSrc={galleryimg}
                                                isSaveDeal={false}
                                                link={`/dic/project-details-view/${assetData[i]?.id}`}
                                                investment_expiry_time={assetData[i]?.investment_expiry_time}
                                                server_date={serverDate}
                                                server_time={serverTime}
                                                projectProgress={assetData[i]?.project_process}
                                                memberDetails={assetData[i]?.dicMemberData}
                                                status={assetData[i].status}
                                                bookMarkStatus={bookmarkVal(assetData[i]?.id)}
                                                from={"overview"}
                                            />
                                        </Grid>
                                    )
                                }
                                return projects
                            }
                            )()}

                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default OverviewProjectList;
