import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import "./PostProject.css";
import { NavLink } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { postApi, GetApi ,updateApi } from '../../../Api/Api';

const PostProject = (data) => {

    const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""
    const navigate = useNavigate();

    const handleBack = () => {
        const previewData = data
        navigate("/service-proposer-dashboard/postProject", { state: { previewData, form: "PostProject" } })
    }

    const propData = data?.data?.data

    /**
     * Function to handle Save Draft
     */
    const handleSaveDraft = async () => {
        let formData = new FormData();
        formData.append("user_id", localData.id);
        formData.append("sp_category_id", propData?.CategoryId);
        formData.append("project_title", propData?.Title);
        formData.append("project_size", propData?.ProjectSize);
        formData.append("work_time", propData?.ProjectTime);
        formData.append("level_of_experience", propData?.ExperienceLevel);
        formData.append("budget", propData.projectBudget === "hourlyRate" ? "Hourly Rate" : "Project Budget");
        formData.append("budget_from", propData?.HourlyRateFrom ? propData?.HourlyRateFrom : propData?.ProjectBudgetFrom);
        formData.append("budget_to", propData?.HourlyRateTo ? propData?.HourlyRateTo : propData?.ProjectBudgetTo);
        formData.append("describe_services", propData?.Description);
        formData.append("status", 0);

        // Append Skill Ids as an array
        const skillIds = propData.Skills.map(skill => skill.id);
        formData.append("skill", JSON.stringify(skillIds));


        // Append documents here
        propData?.Document?.forEach((val) => {
            formData.append("document_file", val);
        });

        if (propData?.from === "MyPorjectPosting" && propData?.projectId) {
            try {
                const res = await updateApi(`/services/sp_projectRequirement/${propData?.projectId}`, formData);
                if (res.data.code === 200) {
                    navigate("/service-proposer-dashboard/projectposting")
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const res = await postApi("/services/sp_projectRequirement", formData);
                if (res.data.code === 200) {
                    navigate("/service-proposer-dashboard/projectposting")
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    /**
    * Function to handle post project
     */
    const handlePostProject = async () => {
        let formData = new FormData();
        formData.append("user_id", localData.id);
        formData.append("sp_category_id", propData?.CategoryId);
        formData.append("project_title", propData?.Title);
        formData.append("project_size", propData?.ProjectSize);
        formData.append("work_time", propData?.ProjectTime);
        formData.append("level_of_experience", propData?.ExperienceLevel);
        formData.append("budget", propData.projectBudget === "hourlyRate" ? "Hourly Rate" : "Project Budget");
        formData.append("budget_from", propData?.HourlyRateFrom ? propData?.HourlyRateFrom : propData?.ProjectBudgetFrom);
        formData.append("budget_to", propData?.HourlyRateTo ? propData?.HourlyRateTo : propData?.ProjectBudgetTo);
        formData.append("describe_services", propData?.Description);
        formData.append("status", 1);

        // Append Skill Ids as an array
        const skillIds = propData.Skills.map(skill => skill.id);
        formData.append("skill", JSON.stringify(skillIds));


        // Append documents here
        propData?.Document?.forEach((val) => {
            formData.append("document_file", val);
        });

        if (propData?.from === "MyPorjectPosting" && propData?.projectId) {
            try {
                const res = await updateApi(`/services/sp_projectRequirement/${propData?.projectId}`, formData);
                if (res.data.code === 200) {
                    navigate("/service-proposer-dashboard/projectposting")
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const res = await postApi("/services/sp_projectRequirement", formData);
                if (res.data.code === 200) {
                    navigate("/service-proposer-dashboard/projectposting")
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <>
            <Box className="post-projct-main">
                <Box>
                    <Typography component="h4" className='title font-22'>Project Details</Typography>
                </Box>
                <Box className="project-title">
                    <Typography component="h4" className='title font-16'>{propData?.Title}</Typography>
                    <Typography component="h5" className='sub-title font-12'>{propData?.Description}</Typography>

                </Box>
                <Box className="post-project-category">
                    <Typography component="h4" className='title font-16'>Category</Typography>
                    <Typography component="h5" className='sub-title font-12'>{propData?.Category}</Typography>
                </Box>
                <Box className="post-project-skill">
                    <Typography component="h4" className='title font-16'>Skills</Typography>
                    <Box className="button-div">
                        {propData?.Skills?.map((skill) => {
                            return <Button variant="outlined" className='btn btn-rounded' startIcon={<AddIcon />}>
                                {skill?.skill_name}
                            </Button>
                        })}
                    </Box>
                </Box>
                <Box className="post-project-scope">
                    <Typography component="h4" className='title font-16'>Scope</Typography>
                    <Typography component="h5" className='sub-title font-12'>{propData?.ProjectSize}, {propData?.ProjectTime} months, {propData?.ExperienceLevel}</Typography>
                </Box>
                <Box className="post-project-budget">
                    <Typography component="h4" className='title font-16'>Budget</Typography>
                    {propData?.HourlyRateFrom ?
                        <Typography component="h5" className='sub-title font-12'>${propData?.HourlyRateFrom} - ${propData?.HourlyRateTo} /hr</Typography>
                        :
                        <Typography component="h5" className='sub-title font-12'>${propData?.ProjectBudgetFrom} - ${propData?.ProjectBudgetTo}</Typography>
                    }
                </Box>

            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }} mt={2} mx={3}>
                <Box>
                    <Button className='btn-rounded-black btn-outline-sp' onClick={() => handleBack()}>
                        Back
                    </Button>
                </Box>
                <Box >
                    <Button className='btn-rounded-black' onClick={() => handleSaveDraft()}>
                        Save as draft
                    </Button>
                    <Button
                        className="btn-rounded"
                        style={{ background: "var(--golden-gradient)" }}
                        startIcon={<ArrowForward />}
                        onClick={() => handlePostProject()}
                    >
                        Post Project
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default PostProject