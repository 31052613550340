import React from "react";
import { Autocomplete, Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./ListService.css";
const ServiceOverview = () => {
  const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
    {
      label: "The Lord of the Rings: The Return of the King",
      year: 2003,
    },
    { label: "The Good, the Bad and the Ugly", year: 1966 },
    { label: "Fight Club", year: 1999 },
    {
      label: "The Lord of the Rings: The Fellowship of the Ring",
      year: 2001,
    },
    {
      label: "Star Wars: Episode V - The Empire Strikes Back",
      year: 1980,
    },
    { label: "Forrest Gump", year: 1994 },
    { label: "Inception", year: 2010 },
    {
      label: "The Lord of the Rings: The Two Towers",
      year: 2002,
    },
    { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { label: "Goodfellas", year: 1990 },
    { label: "The Matrix", year: 1999 },
    { label: "Seven Samurai", year: 1954 },
    {
      label: "Star Wars: Episode IV - A New Hope",
      year: 1977,
    },
    { label: "City of God", year: 2002 },
    { label: "Se7en", year: 1995 },
    { label: "The Silence of the Lambs", year: 1991 },
    { label: "It's a Wonderful Life", year: 1946 },
    { label: "Life Is Beautiful", year: 1997 },
    { label: "The Usual Suspects", year: 1995 },
    { label: "Léon: The Professional", year: 1994 },
    { label: "Spirited Away", year: 2001 },
    { label: "Saving Private Ryan", year: 1998 },
    { label: "Once Upon a Time in the West", year: 1968 },
    { label: "American History X", year: 1998 },
    { label: "Interstellar", year: 2014 },
    { label: "Casablanca", year: 1942 },
    { label: "City Lights", year: 1931 },
    { label: "Psycho", year: 1960 },
    { label: "The Green Mile", year: 1999 },
    { label: "The Intouchables", year: 2011 },
    { label: "Modern Times", year: 1936 },
    { label: "Raiders of the Lost Ark", year: 1981 },
    { label: "Rear Window", year: 1954 },
    { label: "The Pianist", year: 2002 },
    { label: "The Departed", year: 2006 },
    { label: "Terminator 2: Judgment Day", year: 1991 },
    { label: "Back to the Future", year: 1985 },
    { label: "Whiplash", year: 2014 },
    { label: "Gladiator", year: 2000 },
    { label: "Memento", year: 2000 },
    { label: "The Prestige", year: 2006 },
    { label: "The Lion King", year: 1994 },
    { label: "Apocalypse Now", year: 1979 },
    { label: "Alien", year: 1979 },
    { label: "Sunset Boulevard", year: 1950 },
    {
      label:
        "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
      year: 1964,
    },
    { label: "The Great Dictator", year: 1940 },
    { label: "Cinema Paradiso", year: 1988 },
    { label: "The Lives of Others", year: 2006 },
    { label: "Grave of the Fireflies", year: 1988 },
    { label: "Paths of Glory", year: 1957 },
    { label: "Django Unchained", year: 2012 },
    { label: "The Shining", year: 1980 },
    { label: "WALL·E", year: 2008 },
    { label: "American Beauty", year: 1999 },
    { label: "The Dark Knight Rises", year: 2012 },
    { label: "Princess Mononoke", year: 1997 },
    { label: "Aliens", year: 1986 },
    { label: "Oldboy", year: 2003 },
    { label: "Once Upon a Time in America", year: 1984 },
    { label: "Witness for the Prosecution", year: 1957 },
    { label: "Das Boot", year: 1981 },
    { label: "Citizen Kane", year: 1941 },
    { label: "North by Northwest", year: 1959 },
    { label: "Vertigo", year: 1958 },
    {
      label: "Star Wars: Episode VI - Return of the Jedi",
      year: 1983,
    },
    { label: "Reservoir Dogs", year: 1992 },
    { label: "Braveheart", year: 1995 },
    { label: "M", year: 1931 },
    { label: "Requiem for a Dream", year: 2000 },
    { label: "Amélie", year: 2001 },
    { label: "A Clockwork Orange", year: 1971 },
    { label: "Like Stars on Earth", year: 2007 },
    { label: "Taxi Driver", year: 1976 },
    { label: "Lawrence of Arabia", year: 1962 },
    { label: "Double Indemnity", year: 1944 },
    {
      label: "Eternal Sunshine of the Spotless Mind",
      year: 2004,
    },
    { label: "Amadeus", year: 1984 },
    { label: "To Kill a Mockingbird", year: 1962 },
    { label: "Toy Story 3", year: 2010 },
    { label: "Logan", year: 2017 },
    { label: "Full Metal Jacket", year: 1987 },
    { label: "Dangal", year: 2016 },
    { label: "The Sting", year: 1973 },
    { label: "2001: A Space Odyssey", year: 1968 },
    { label: "Singin' in the Rain", year: 1952 },
    { label: "Toy Story", year: 1995 },
    { label: "Bicycle Thieves", year: 1948 },
    { label: "The Kid", year: 1921 },
    { label: "Inglourious Basterds", year: 2009 },
    { label: "Snatch", year: 2000 },
    { label: "3 Idiots", year: 2009 },
    { label: "Monty Python and the Holy Grail", year: 1975 },
  ];
  return (
    <Box className="list-service-overview-container">
      <Typography className="font-36">Service Overview</Typography>
      <Box className="service-title-container">
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4}>
            <Box className="service-title-left">
              <Typography className="font-22">Service Title</Typography>
              <ul>
                <li className="text-blueGray-300 font-12">
                  Make sure that your title is short, clear, and to the point
                </li>
                <li className="text-blueGray-300 font-12">
                  Clients should understand the type of service that you offer
                  immediately
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={8}>
            <Box className="service-title-right">
              <TextField
                id="outlined-basic"
                label="Service Title"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                placeholder="I will..."
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="service-category-container">
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4}>
            <Box className="service-category-left">
              <Typography className="font-22">Service Category</Typography>
              <Typography className="font-12 text-blueGray-300">
                Select the appropriate Category and Sub-category for your
                service in the dropdown menu. You won’t be able to change this
                once the service is published.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={8}>
            <Box className="service-category-right">
              <Grid container spacing={2}>
                <Grid xs={12} sm={6} md={6}>
                  <Box className="service-cat-sub-left">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={top100Films}
                      defaultValue={{ shrink: true }}
                      InputLabelProps={{ shrink: true }}
                      renderInput={(params) => (
                        <TextField {...params} label="Category" />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} sm={6} md={6}>
                  <Box className="service-cat-sub-left">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={top100Films}
                      defaultValue={{ shrink: true }}
                      InputLabelProps={{ shrink: true }}
                      renderInput={(params) => (
                        <TextField {...params} label="Sub-category" />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="service-search-container">
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4}>
            <Box className="service-search-left">
              <Typography className="font-22">Search Tags</Typography>
              <Typography className="font-12 text-blueGray-300">
                Add up to 5 words or phrases that best describes your service.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={8}>
            <Box className="service-search-right">
              <TextField
                id="outlined-basic"
                label="Search skills or add your own"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                placeholder="E.g. Web Development"
              />
            </Box>
            <Box className="most-popular-services">
              <Typography className="font-16">Most popular serices</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="discribe-service-container">
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4}>
            <Box className="discribe-service-left">
              <Typography className="font-22">Describe the Service</Typography>
              <Typography className="font-12 text-blueGray-300">
                Briefly describe your Gig (up to 1,200 characters). Make sure
                you:
              </Typography>
              <ul>
                <li className="font-12 text-blueGray-300">
                  Include clear expectations about your tasks or deliverables
                </li>
                <li className="font-12 text-blueGray-300">
                  Are as detailed as possible so clients are able to determine
                  if your service meets their needs.
                </li>
                <li className="font-12 text-blueGray-300">
                  Never add contact information
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={8}>
            <Box className="discribe-service-right">
              <TextField
                id="description"
                name="description"
                variant="outlined"
                placeholder="Already have a description? Paste it here!"
                // value={formData.description}
                // onChange={handleInputChange}
                className="onboarding-input-text"
                multiline // Enable multiline
                rows={4} // Set the number of rows to display
                // error={Boolean(errors.description)}
                // helperText={errors.description}
                style={{ minHeight: "80px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="btn-content-div">
      <Stack direction="row" spacing={2} alignItems="center">
                    <Box className='go-back-btn'>
                        <Button startIcon={<ArrowBackIcon />} >
                            Go Back
                        </Button>
                    </Box>
                    <Box className="continue-btn">
                        <Button
                            variant="contained"
                            startIcon={<ArrowForwardIcon />}
                            className='btn-rounded btn-large'
                            style={{ background: "var(--golden-gradient)" }}
                           
                        >
                            Continue
                        </Button>
                    </Box>
                </Stack>
      </Box>
    </Box>
  );
};

export default ServiceOverview;
