import React from "react";
import { LocationOn } from "@mui/icons-material";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import "./ListCard.css";

const ListCard = ({
  title,
  titleColor,
  subtitle1,
  subtitle1Color,
  subtitle2,
  listIcon,
  iconColor,
  type,
  bgColor,
  from,
}) => {
  if (from === "RightProjectProfileSection") {
    return (
      <Stack direction="row" alignItems={"center"} className="listCard">
        {listIcon && type === "icon" ? (
          // for mui icons
          <Box
            className="listCard-icon"
            style={{ background: bgColor ? bgColor : "" }}
            color={iconColor ? iconColor : ""}
          >
            {listIcon}
          </Box>
        ) : (
          // for image types
          <Avatar src={listIcon} />
        )}
        <Stack alignItems={"flex-start"} gap={"2px"}>
          <Typography
            variant="subtitle2"
            color={titleColor ? titleColor : ""}
            sx={{ wordBreak: "break-word" }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            color={subtitle1Color ? subtitle1Color : ""}
            className="font-12 text-blueGray-500"
            sx={{ wordBreak: "break-word" }}
          >
            {subtitle1}
          </Typography>
          <Typography
            variant="subtitle1"
            className="font-12 text-blueGray-500"
            sx={{ wordBreak: "break-word" }}
          >
            {subtitle2}
          </Typography>
        </Stack>
      </Stack>
    );
  } else {
    return (
      <Stack
        direction="row"
        spacing={2}
        ml={1}
        mb={"4px"}
        alignItems={"center"}
        className="listCard"
      >
        {listIcon && type === "icon" ? (
          // for mui icons
          <Box
            className="listCard-icon"
            style={{ background: bgColor ? bgColor : "" }}
            color={iconColor ? iconColor : ""}
          >
            {listIcon}
          </Box>
        ) : (
          // for image types
          <Avatar src={listIcon} />
        )}
        <Stack alignItems={"flex-start"} gap={"2px"}>
          <Typography
            variant="subtitle2"
            color={titleColor ? titleColor : ""}
            sx={{ wordBreak: "break-word" }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            color={subtitle1Color ? subtitle1Color : ""}
            className="font-12 text-blueGray-500"
            sx={{ wordBreak: "break-word" }}
          >
            {subtitle1}
          </Typography>
          <Typography
            variant="subtitle1"
            className="font-12 text-blueGray-500"
            sx={{ wordBreak: "break-word" }}
          >
            {subtitle2}
          </Typography>
        </Stack>
      </Stack>
    );
  }
};

export default ListCard;
