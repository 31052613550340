import React, { useEffect, useState, useMemo } from "react";
import ServicesSearch from "../Components/ServicesSearch/ServicesSearch";
import { Grid, Typography, Box, Button, Chip } from "@mui/material";
import SearchCompo from "../Components/SearchCompo/SearchCompo";
import ProfileCard from "../Components/ProfileCard/ProfileCard";
import MainCard from "../Components/MainCard/MainCard";
import { IconButton, TextField, styled } from "@mui/material";
import { HomeImage, UserBorder } from "../../Components/Images/Images";
import {
  KeyboardArrowDown,
  Search,
  ArrowForward,
  Dock,
  Payment,
  LocalFireDepartment,
  Payments,
} from "@mui/icons-material";
import { GetApi } from "../../Api/Api";
import { NavLink, useNavigate } from "react-router-dom";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import DescriptionIcon from "@mui/icons-material/Description";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import "./FindServiceProvider.css";
import ServiceProviderSearch from "../Components/ServiceProviderSearch/ServiceProviderSearch";

const FindServiceProvider = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [serviceData, setServicesData] = useState([]);
  const [serviceProvider, setServiceProvider] = useState([]);
  const navigate = useNavigate();

  const SearchTextField = styled(TextField)({
    maxWidth: "662px",
    height: "48px",
    "& .MuiOutlinedInput-root": {
      "& input": {
        padding: "12px 24px",
      },
      "& fieldset": {
        borderRadius: "48px",
      },
      backgroundColor: "#ffffff",
      color: "#ffffff",
      borderRadius: "48px",
      "MuiInputBase-input": {
        color: "#fff",
      },
    },
  });

  const SearchButton = styled(IconButton)({
    "& .MuiIconButton-root": {
      padding: "10px",
      backgroundColor: "#398CD1",
      borderRadius: "48px",
      "& .MuiSvgIcon-root": {
        color: "#ffffff",
        height: "12px",
        width: "12px",
      },
    },

    // disable hover
    "&:hover": {
      backgroundColor: "#398CD1",
      "& .MuiSvgIcon-root": {
        color: "#ffffff",
      },
    },
  });

  /**
   * All Get API calling for service provider
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryRes, servicesRes, serviceProviderRes] =
          await Promise.all([
            GetApi("/services/spCategory"),
            GetApi("/services/getServices"),
            GetApi("/services/getProfiles"),
          ]);
        setCategoryData(categoryRes?.data?.data || []);
        setServicesData(
          servicesRes?.data?.data?.length ? servicesRes.data.data : []
        );
        setServiceProvider(
          serviceProviderRes?.data?.data?.length
            ? serviceProviderRes.data.data
            : []
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // Filter service providers for each category
  const filteredServiceProvidersByCategory = categoryData.map((category) => {
    const filteredProviders =
      serviceProvider &&
      serviceProvider.filter(
        (provider) => provider?.serviceCategory?.title === category.title
      );
    return { category, filteredProviders };
  });

  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, 200);

  const handleSearchResults = () => {
    navigate("/service-proposer/service-provider-results");
  };

  return (
    <Box className="">
      <Box className="find-sp-profile-page">
        {" "}
        {/* backgroundColor: '#F1F9FF', */}
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={4}
          maxWidth={"660px"}
        >
          <Typography className="font-64">
            Find the professional you need
          </Typography>
          <Typography className="font-18">
            We have vetted service providers in over 300 services. Sign up to
            find the perfect service provider for all your investment needs.
          </Typography>
        </Box>
        <Box className="">
          <Box mt={3}>
            <ServiceProviderSearch categoryData={categoryData} pageTitle={""} />
          </Box>
        </Box>
      </Box>

      <Grid container spacing={2} mt={6}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12} sm={6} md={2.5}>
              <Box className="dashboard-workspace">
                <NavLink className="dashboard-navlink">
                  <Dock className="icon" />
                  <span>Discover</span>
                </NavLink>
              </Box>
              <Box className="dashboard-workspace">
                <NavLink className="dashboard-navlink">
                  <Payment className="icon" />
                  <span>Your hires</span>
                </NavLink>
              </Box>

              <Box className="dashboard-workspace">
                <NavLink className="dashboard-navlink">
                  <LocalFireDepartment className="icon" />
                  <span>Recently viewed</span>
                </NavLink>
              </Box>
              <Box className="dashboard-workspace">
                <NavLink className="dashboard-navlink">
                  <Payments className="icon" />
                  <span>Saved Service Providers</span>
                </NavLink>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={9.5} p={2}>
              <Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Typography style={{ fontSize: "45px", minWidth: "465px" }}>
                      Explore Providers
                    </Typography>
                  </Box>
                  {/* <Box style={{ minWidth: '400px' }}>
                                        <SearchCompo url={'/service-provider-results'} pageTitle={'find-service-provider'}/>
                                    </Box> */}
                  <Box className={"service-search-item "}>
                    <SearchTextField
                      className="service-search-items-black "
                      variant="outlined"
                      placeholder="Search service provider"
                      InputProps={{
                        endAdornment: (
                          <SearchButton
                            className="search-button bg-golden"
                            aria-label="search"
                            onClick={handleSearchResults}
                          >
                            <Search
                              style={{ color: "black" }}
                              sx={{
                                color: "black",
                                height: "12px",
                                width: "12px",
                              }}
                            />
                          </SearchButton>
                        ),
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography style={{ fontSize: "18px", minWidth: "465px" }}>
                    {serviceProvider && serviceProvider?.length} Service
                    Providers
                  </Typography>
                </Box>
              </Box>

              {/* Profile Cards for Categories */}
              {filteredServiceProvidersByCategory &&
                filteredServiceProvidersByCategory
                  .filter(
                    ({ filteredProviders }) => filteredProviders.length > 0
                  )
                  .map(({ category, filteredProviders }, index) => (
                    <Box key={index}>
                      <Box
                        mt={5}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="font-24" color={"#ffffff"}>
                          {category?.title &&
                          category?.title ===
                            "Technology Developers & Software Services"
                            ? "Development & IT"
                            : category?.title}
                        </Typography>
                        <Box className="btn-wrap sp multi-btns">
                          <NavLink to="/service-proposer/service-provider-results">
                            <Button
                              className="btn-rounded btn-large"
                              style={{ background: "var(--golden-gradient)" }}
                              startIcon={<ArrowForward />}
                            >
                              See more profiles
                            </Button>
                          </NavLink>
                        </Box>
                      </Box>
                      <Box my={2} className="profile-card">
                        <Grid container spacing={3}>
                          {filteredProviders.length ? (
                            filteredProviders.map((card, index) => (
                              <Grid key={index} item md={4} sm={6} xs={12}>
                                <NavLink
                                  to={`/service-proposer/profile/individual/${card?.id}`}
                                  state={{ user_id: card?.user_id }}
                                >
                                  <ProfileCard {...card} />
                                </NavLink>
                              </Grid>
                            ))
                          ) : (
                            <Typography ml={4} className="font-16">
                              No service Provider Found
                            </Typography>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  ))}

              {/* Services you may like */}
              <Box
                mt={5}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                
                }}
              >
                <Box>
                  <Typography className="font-45" color={"#fffff"}>
                    Services you may like
                  </Typography>
                  <Typography className="font-18" color={"#fffff"}>
                    Pressed for time? Browse and buy prescoped services in just
                    a few clicks
                  </Typography>
                </Box>
                <Box className="btn-wrap  multi-btns">
                  <Button
                    className="btn-rounded sp btn-large"
                    style={{ background: "var(--golden-gradient)" }}
                    startIcon={<ArrowForward />}
                  >
                    Browse by services
                  </Button>
                </Box>
              </Box>

              {/* Main-card */}
              <Box className="main-card" padding={"32px 0px"}>
                <Grid container spacing={3}>
                  {serviceData?.map((card, index) => (
                    <Grid key={index} item md={4} sm={6} xs={12}>
                      <MainCard {...card} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FindServiceProvider;
