import React from 'react';
import { Box, Typography } from '@mui/material';
import Slider from 'react-slick';
import { ecosystem_1, ecosystem_2, ecosystem_3, ecosystem_4, ecosystem_5, ecosystem_6, ecosystem_7, ecosystem_8, Metamask, theGraph, treasure, chainLink, ethereum } from '../../../Components/Images/Images';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./EcosystemSlider.css"

const galleryImages = [
  ecosystem_1,
  ecosystem_4,
  ecosystem_3,
  ecosystem_5,
  ecosystem_6,
  ecosystem_2,
  ecosystem_7,
  ecosystem_8,
  Metamask
];


const EcosystemSlider = () => {

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0, // Set this to a positive value like 2000 for a delay between slides
    speed: 5000, // Adjust the speed to make the transition slower
    cssEase: 'linear',
    infinite: true, // Ensure infinite is set to true
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <Box className="Ecosystem-wrapper">
      <Box className="container-fluid">
        <Typography variant='p' component={'p'} textAlign={'center'} mb={3} className='font-18'
          color="darkgray">Ecosystem</Typography>
        <Box id="gallery">
          <Slider {...settings}>
            {galleryImages.map((src, index) => (
              <Box key={index}>
                <img src={src} alt={`Gallery Image ${index + 1}`} style={{ maxHeight: '35px', height: '100%' }} />
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
  )
}

export default EcosystemSlider