import React, { useEffect, useId, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import LeftPersonProfileSection from './LeftPersonProfileSection'
import RightPersonProfileSection from './RightPersonProfileSection'
import ServicesSearch from '../Components/ServicesSearch/ServicesSearch'
import { Box, Grid } from '@mui/material'
import './IndividualProfilePage.css'
import { GetApi, GetApiParam } from '../../Api/Api'

function IndividualProfilePage() {
  const [categoryData, setCategoryData] = useState(null);
  const [profileData, setProfileData] = useState([])
  const [userId, setUserId] = useState("")
  const location = useLocation();

  const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""

  useEffect(() => {
    async function getCategoryData() {
      try {
        const res = await GetApi("/services/spCategory");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }

    
    async function getProfileData() {
      try {
        const res = await GetApiParam("/services/ProfileDetails/", { user_id: userId ? userId : ""});
        setProfileData(res?.data?.data ? res?.data?.data[0] : []);
      } catch (error) {
        console.log(error);
      }
    }

    getCategoryData()
    if(userId){
    getProfileData()
    }
  }, [userId])

  useEffect(() => {
    if (location.state) {
      setUserId(location.state.user_id)
    }
  }, [location])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])


  return (
    <>
      <Box className="container">
        <ServicesSearch showSearchBar={true} pageTitle={'Service Catalog'} categoryData={categoryData} />
        <Grid container spacing={'20px'} mt={2} >
          <Grid item xs={12} md={8.5} >
            <LeftPersonProfileSection profileData={profileData ? profileData : null} />
          </Grid>
          <Grid item xs={12} md={3.5} >
            <RightPersonProfileSection profileData={profileData ? profileData : null} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default IndividualProfilePage