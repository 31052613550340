import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Button, Box, Typography, Badge, Avatar } from '@mui/material';
import { Upgrade, Done, KeyboardArrowRight, PieChart, CheckCircleOutline, MonetizationOn, CalendarToday, BookmarkBorder, Palette, House, Watch, ViewWeek, Paid, DirectionsCar, CurrencyBitcoin } from '@mui/icons-material';

// Import custom components for this page
import { NewLogo, SaveDeal } from "../../../Components/Images/Images"
import LinearProgressCompo from "./Components/LinearProgressCompo";
import KYCShufti from "../../KYC/KYCShufti";
import { postApi } from "../../../Api/Api";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import { INVESTMENT_TYPE_ID } from "../../../constants";
import "./Components/ProposerStakeRaceTokens.css"
import { formatNumber } from "../../../Components/Common/USFormat";

export default function PreviewListing(props) {
  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, saveProposal, getProgress } = props;

  // Getting current proposal and progress data from parent
  var propData = getProposalData();
  let currentProgress = getProgress();

  const { showSnackbar } = useSnackbar();

  // State for modal visibility
  const [openKYCForm, setOpenKYCForm] = useState(false);

  const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""

  /**
   * Capitalizes the first letter of a string.
   * @param {string} str - The input string.
   * @returns {string} - The string with the first letter capitalized.
   */
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Handles the Save and Exit action.
   */
  const SaveAndExit = () => {
    saveProposal();
  }

  /**
   * Handles the Next button click, triggering the modal opening.
   */
  const handleNextClick = async () => {
    try {
      async function checkWallet() {
        const checkRes = await postApi(`/user/checkWallet`, { user_id: localData?.id });
        let walletData = checkRes?.data?.data;
        if (!walletData?.kyc_status) {
          setOpenKYCForm(true);
        } else {
          handleNext();
        }
      }
      const loggedIn = localStorage.getItem("login");
      if (loggedIn && localData) {
        checkWallet();
      }
    } catch (error) {
      showSnackbar('There was some error', 'error')
    }
  }

  /**
   * Function to handle kyc modal close
   */
  const handleKYCClose = () => {
    setOpenKYCForm(false)
  }

  /**
   * Function call when kyc completed
   * @param {Object} data 
   */
  const handleKycComplete = (data) => {
    handleNext();
  }

  return (
    <Box className="projectProposer questionnaire-page">
      <KYCShufti kycOpen={openKYCForm} onClose={handleKYCClose} handleKycComplete={handleKycComplete} />

      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Check out your listing
                </Typography>
                <Typography component="p" className="sub-title">
                  we’ll ask you to confirm a few details before you can publish.
                </Typography>
              </Box>
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit} className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className='viewListingMCard qsn-middle-content' sx={{ pt: 4, mb: 5 }}>
                <Box className='vLMCard-Mobile' sx={{ border: '14px solid lightgray' }}>
                  <Box className='vLMCard-Card' m={2}>
                    <Box sx={{ position: 'relative', borderRadius: "12px" }}>
                      <Box className="vLMCard-bookmark">
                        <Box className='vLMCard-bookmark-icon'><BookmarkBorder /></Box>
                      </Box>
                      <img src={(propData?.asset_coverphoto_view?.startsWith("proposer/uploads/")) ? `${process.env.REACT_APP_IMAGE_URL}${propData?.asset_coverphoto_view}` : propData?.asset_coverphoto_view} alt="" className='vLMCard-image' />
                    </Box>
                    <Box className=''>
                      <Box className='vLMCard-title' my={1}>{propData?.asset_title}</Box>
                      <Box className='vLMCard-subtitle' my={1}>{propData?.asset_address}</Box>
                      {
                        (propData?.selectedCategory === 'art') &&
                        <Box>
                          <Box my={1}>
                            <Button className='vLMCard-type-icon btn-rounded btn-text-white' sx={{ cursor: "not-allowed", height: '32px !important' }}>
                              {capitalizeFirstLetter(propData?.selectedListingType)}
                            </Button>
                            <Button className='vLMCard-type-icon btn-rounded btn-text-white' sx={{ cursor: "not-allowed", height: '32px !important' }}
                              startIcon={<Palette />}
                            >{capitalizeFirstLetter(propData?.selectedCategory)}</Button>
                          </Box>
                          <Box className='vLMCard-upgrade' my={1}>
                            <PieChart className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle" >
                                Sale Price
                              </Box>
                              <Box className='vLMCard-subtitle1'> {"$"}{formatNumber(propData?.selectedListingType === 'parcel' ? propData?.parcel_total_price : propData?.sale_price)}</Box>
                            </Box>
                          </Box>
                          <Box className='vLMCard-upgrade' my={1}>
                            <MonetizationOn className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle">{propData?.selectedListingType === 'parcel' ? `Number of Parcels` : `Deposit Amount`}</Box>
                              <Box className='vLMCard-subtitle1'> {propData?.selectedListingType === 'parcel' ? propData?.number_of_parcel : `$${formatNumber(propData?.sale_deposit_amount)}`}</Box>
                            </Box>
                          </Box>
                          {propData?.selectedListingType === 'parcel' &&
                            <Box className='vLMCard-upgrade' my={1}>
                              <CalendarToday className="icons-color" />
                              <Box ml={2}>
                                <Box className="vLMCard-subtitle">{propData?.selectedListingType === 'parcel' ? `Minimum Investment per Parcel` : ""}</Box>
                                <Box className='vLMCard-subtitle1'> {propData?.selectedListingType === 'parcel' ? `$${formatNumber(propData?.minimun_investment_per_parcel)}` : ""}</Box>
                              </Box>
                            </Box>
                          }
                        </Box>
                      }
                      {
                        (propData?.selectedCategory === 'watch' || propData?.selectedCategory === 'car') &&
                        <Box>
                          <Box my={1}>
                            <Button className='vLMCard-type-icon btn-rounded btn-text-white' sx={{ cursor: "not-allowed", height: '32px !important' }}>
                              {capitalizeFirstLetter(propData?.selectedListingType)}
                            </Button>
                            <Button className='vLMCard-type-icon btn-rounded btn-text-white' sx={{ cursor: "not-allowed", height: '32px !important' }}
                              startIcon={propData?.selectedCategory === 'watch' ? <Watch /> : <DirectionsCar />}
                            >{capitalizeFirstLetter(propData?.selectedCategory === "car" ? "Luxury Cars" : propData?.selectedCategory)}</Button>
                          </Box>
                          <Box>
                            <Grid container>
                              {
                                propData?.capital_type?.map((item, index) => <React.Fragment key={index} >
                                  <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', color: '#FFFFFF' }} item md={6}>
                                    <CheckCircleOutline style={{ marginRight: '8px', color: "#94A3B8", fontSize: '24px' }} />
                                    {propData?.capital_type[index]}
                                  </Grid>
                                </React.Fragment>)
                              }
                            </Grid>
                          </Box>
                          {propData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN ?
                            <>
                              <Box className='vLMCard-upgrade' my={1}>
                                <MonetizationOn className="icons-color" />
                                <Box ml={2}>
                                  <Box className="vLMCard-subtitle" >Loan Amount</Box>
                                  <Box className='vLMCard-subtitle1'>  <Box className='vLMCard-subtitle1'>{"$"}{formatNumber(propData?.loan_amount)}
                                  </Box></Box>
                                </Box>
                              </Box>
                              <Box className='vLMCard-upgrade' my={1}>
                                <Upgrade className="icons-color" />
                                <Box ml={2}>
                                  <Box className="vLMCard-subtitle">Interest Rate</Box>
                                  <Box className='vLMCard-subtitle1'> {`${propData?.loan_roi}%`}</Box>
                                </Box>
                              </Box>
                              <Box className='vLMCard-upgrade' my={1}>
                                <CalendarToday className="icons-color" />
                                <Box ml={2}>
                                  <Box className="vLMCard-subtitle">Loan Duration</Box>
                                  <Box className='vLMCard-subtitle1'> {propData?.loan_duration_month > 0 ? propData?.loan_duration_month > 1 ? `${propData?.loan_duration_month} Months` : `${propData?.loan_duration_month} Month` : ''}</Box>
                                </Box>
                              </Box>
                              <Box className='vLMCard-upgrade' my={1}>
                                <MonetizationOn className="icons-color" />
                                <Box ml={2}>
                                  <Box className="vLMCard-subtitle">Estimated value at purchase</Box>
                                  <Box className='vLMCard-subtitle1' > {`$${formatNumber(propData?.watchInformation?.watch_estimated ? propData?.watchInformation?.watch_estimated : propData?.carInformation?.car_estimated ? propData?.carInformation?.car_estimated : 0)}`}</Box>
                                </Box>
                              </Box>
                            </>
                            :
                            <>
                              <Box className='vLMCard-upgrade' my={1}>
                                <PieChart className="icons-color" />
                                <Box ml={2}>
                                  <Box className="vLMCard-subtitle" >
                                    Sale Price
                                  </Box>
                                  <Box className='vLMCard-subtitle1'> {"$"}{propData?.selectedListingType === 'parcel' ? propData?.parcel_total_price : propData?.sale_price}</Box>
                                </Box>
                              </Box>
                              <Box className='vLMCard-upgrade' my={1}>
                                <MonetizationOn className="icons-color" />
                                <Box ml={2}>
                                  <Box className="vLMCard-subtitle">{propData?.selectedListingType === 'parcel' ? `Number of Parcels` : `Deposit Amount`}</Box>
                                  <Box className='vLMCard-subtitle1'> {propData?.selectedListingType === 'parcel' ? propData?.number_of_parcel : `$${propData?.sale_deposit_amount}`}</Box>
                                </Box>
                              </Box>
                            </>
                          }
                        </Box>
                      }
                      {
                        (propData?.selectedCategory === 'gold') &&
                        <Box>
                          <Box my={1}>
                            <Button className='vLMCard-type-icon btn-rounded btn-text-white' sx={{ cursor: "not-allowed", height: '32px !important' }}>
                              {capitalizeFirstLetter(propData?.selectedListingType)}
                            </Button>
                            <Button className='vLMCard-type-icon btn-rounded btn-text-white' sx={{ cursor: "not-allowed", height: '32px !important' }}
                              startIcon={<ViewWeek />}
                            >{capitalizeFirstLetter(propData?.selectedCategory)}</Button>
                          </Box>
                          <Box>
                            <Grid container>
                              {
                                propData?.capital_type?.map((item, index) => <React.Fragment key={index} >
                                  <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', color: '#FFFFFF' }} item md={6}>
                                    <CheckCircleOutline style={{ marginRight: '8px', color: "#94A3B8", fontSize: '24px' }} />
                                    {propData?.capital_type[index]}
                                  </Grid>
                                </React.Fragment>)
                              }
                            </Grid>
                          </Box>
                          <Box className='vLMCard-upgrade' my={1}>
                            <MonetizationOn className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle" >Loan Amount</Box>
                              <Box className='vLMCard-subtitle1'>  <Box className='vLMCard-subtitle1'>{"$"}{formatNumber(propData?.loan_amount)}
                              </Box></Box>
                            </Box>
                          </Box>
                          <Box className='vLMCard-upgrade' my={1}>
                            <Upgrade className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle">Interest Rate</Box>
                              <Box className='vLMCard-subtitle1' > {`${propData?.loan_roi}%`}</Box>
                            </Box>
                          </Box>
                          <Box className='vLMCard-upgrade' my={1}>
                            <CalendarToday className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle">Loan Duration</Box>
                              <Box className='vLMCard-subtitle1' > {propData?.loan_duration_month > 0 ? propData?.loan_duration_month > 1 ? `${propData?.loan_duration_month} Months` : `${propData?.loan_duration_month} Month` : ''}</Box>
                              {/* <Box className='vLMCard-subtitle1' ml={1}> {propData?.loan_duration_month > 0 ? propData?.loan_duration_month > 1 ? `${propData?.loan_duration_month} Months` : `${propData?.loan_duration_month} Month` : ''} {propData?.loan_duration} {propData?.loan_duration > 1 ? "Days" : 'Day'}</Box> */}
                            </Box>
                          </Box>
                          <Box className='vLMCard-upgrade' my={1}>
                            <MonetizationOn className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle">Estimated value at purchase</Box>
                              <Box className='vLMCard-subtitle1' > {`$${formatNumber(propData?.goldInformation.gold_estimated)}`}</Box>
                            </Box>
                          </Box>
                        </Box>
                      }
                      {
                        (propData?.selectedCategory === 'funds') &&
                        <Box>
                          <Box my={1}>
                            <Button className='vLMCard-type-icon btn-rounded btn-text-white' sx={{ cursor: "not-allowed", height: '32px !important' }}>
                              {capitalizeFirstLetter(propData?.selectedListingType)}
                            </Button>
                            <Button className='vLMCard-type-icon btn-rounded btn-text-white' sx={{ cursor: "not-allowed", height: '32px !important' }}
                              startIcon={<Paid />}
                            >{capitalizeFirstLetter(propData?.selectedCategory)}</Button>
                          </Box>
                          <Box>
                            <Grid container>
                              {propData?.capital_info?.map((item, index) => <React.Fragment key={index} >
                                <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', color: '#FFFFFF' }} item md={6}>
                                  <CheckCircleOutline style={{ marginRight: '8px', color: "#94A3B8", fontSize: '24px' }} />
                                  {item.class == 1 ? 'Class A' : 'Class B'}
                                </Grid>
                              </React.Fragment>)
                              }
                            </Grid>
                          </Box>
                          <Box className='vLMCard-upgrade' my={1}>
                            <MonetizationOn className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle">Total Raise</Box>
                              <Box className='vLMCard-subtitle1'> {"$"}{propData?.fractionalize_total_price}</Box>
                            </Box>
                          </Box>
                          {/* <Box className='vLMCard-upgrade' my={1}>
                            <PieChart className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle" >Minimum Investment</Box>
                              <Box className='vLMCard-subtitle1'> {"$"}{propData?.fractionalize_minimum_investment}</Box>
                            </Box>
                          </Box> */}
                          <Box className='vLMCard-upgrade' my={1}>
                            <CalendarToday className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle">Duration of Project</Box>
                              <Box className='vLMCard-subtitle1' > {propData?.fractionalize_duration_of_project} {propData?.fractionalize_duration_of_project > 1 ? 'Years' : 'Year'}</Box>
                            </Box>
                          </Box>
                        </Box>
                      }
                      {
                        (propData?.selectedCategory === 'real-estate') &&
                        <Box>
                          <Box my={1}>
                            <Button className='vLMCard-type-icon btn-rounded btn-text-white' sx={{ cursor: "not-allowed", height: '32px !important' }}>
                              {capitalizeFirstLetter(propData?.selectedListingType)}
                            </Button>
                            <Button className='vLMCard-type-icon btn-rounded btn-text-white' sx={{ cursor: "not-allowed", height: '32px !important' }}
                              startIcon={<House />}
                            >{capitalizeFirstLetter(propData?.selectedCategory)}</Button>
                          </Box>
                          <Box>
                            <Grid container>
                              {
                                propData?.capital_type?.map((item, index) => <React.Fragment key={index} >
                                  <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', color: '#FFFFFF' }} item md={6}>
                                    <CheckCircleOutline style={{ marginRight: '8px', color: "#94A3B8", fontSize: '24px' }} />
                                    {propData?.capital_type[index]}
                                  </Grid>
                                </React.Fragment>)
                              }
                            </Grid>
                          </Box>
                          <Box className='vLMCard-upgrade' my={1}>
                            <PieChart className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle" >Total Project Value</Box>
                              <Box className='vLMCard-subtitle1' > {"$"}{propData?.fractionalize_project_size}</Box>
                            </Box>
                          </Box>
                          <Box className='vLMCard-upgrade' my={1}>
                            <MonetizationOn className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle">Total Raise</Box>
                              <Box className='vLMCard-subtitle1' > {"$"}{propData?.fractionalize_total_price}</Box>
                            </Box>
                          </Box>
                          <Box className='vLMCard-upgrade' my={1}>
                            <CalendarToday className="icons-color" />
                            <Box ml={2}>
                              <Box className="vLMCard-subtitle">Duration of Project</Box>
                              <Box className='vLMCard-subtitle1'> {propData?.fractionalize_duration_of_project} {propData?.fractionalize_duration_of_project > 1 ? 'Years' : 'Year'}</Box>
                            </Box>
                          </Box>
                        </Box>
                      }
                      {
                        (propData?.selectedCategory === 'tokenize') &&
                        <Box>
                          <Box my={1}>
                            <Button className='vLMCard-type-icon btn-rounded btn-text-white' sx={{ cursor: "not-allowed", height: '32px !important' }}
                              startIcon={<CurrencyBitcoin />}
                            >{capitalizeFirstLetter(propData?.selectedCategory === "car" ? "Luxury Cars" : propData?.selectedCategory)}</Button>
                          </Box>
                          <>
                            <Box className='vLMCard-upgrade' my={1}>
                              <MonetizationOn className="icons-color" />
                              <Box ml={2}>
                                <Box className="vLMCard-subtitle">Estimated value at purchase</Box>
                                <Box className='vLMCard-subtitle1' > {`$${formatNumber(propData?.watchInformation?.watch_estimated ? propData?.watchInformation?.watch_estimated : propData?.carInformation?.car_estimated ? propData?.carInformation?.car_estimated : 0)}`}</Box>
                              </Box>
                            </Box>
                          </>
                        </Box>
                      }
                      <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                        <Box className='d-flex' my={1}>
                          <Box mr={2}>
                            <Badge className="text-black" badgeContent={<Done className="vLMCard-Card-done" sx={{ width: '18px', height: '18px' }} />} anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }} >
                              {/* <img src={propData?.persona_picture ?? SaveDeal} alt='' /> */}
                              <Avatar sx={{ width: '40px', height: '40px' }} alt="User image" src={propData?.persona_picture ?? SaveDeal} />
                            </Badge>
                          </Box>
                          <Box className='vLMCard-Card-ts-box'>
                            <span className="ownerName">{propData?.project_proposer}</span>
                            <span className="owner" >
                              Project Proposer
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box mt={2}>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleback}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"}
                    className='quests-btn-box'

                  >
                    <Button onClick={handleNextClick} endIcon={<KeyboardArrowRight />} className="btn-rounded btn-text-white btn-blue-600">
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}




