// payPrepayment.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";
import convertToDecimal from "../Common/convertToDecimal";
import convertToSixDecimal from "../Common/convertToSixDecimal";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Call repayment method to repay the emi
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function payPrepayment(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // set active step to 2
        handleActiveStep(2)

        let contractAddress = CONTRACTS.WATCH_REPAYMENT_ADDRESS;
        if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            contractAddress = CONTRACTS.SPV_DEBT_REPAYMENT_ADDRESS
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            contractAddress = CONTRACTS.WATCH_REPAYMENT_ADDRESS
        }

        // make offer by investor
        const { request: prepayment } = await prepareWriteContract({
            address: contractAddress,
            abi: CONTRACT_ABI[contractAddress],
            account: address,
            chainId: 6805,
            functionName: "payPrepaymentAmount",
            args: [
                assetData?.listing_id, // listing id / ipfs code id Integer
                // assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN ? getCurrencyTypeAddress(assetData?.feeTokenType) : CONTRACTS.TOKEN,// _feeToken
                getCurrencyTypeAddress(assetData?.feeTokenType),// _feeToken
                // (assetData?.feeTokenType === 'USDT' || assetData?.feeTokenType === 'USDC') ? convertToSixDecimal(Math.ceil(assetData?.prepayment_amount)) : convertToDecimal(assetData?.prepayment_amount), //  // offer amount / offeredAmount
                getCurrencyTypeDecimal(assetData?.feeTokenType, Math.ceil(assetData?.prepayment_amount)),  // offer amount / offeredAmount
            ]
        })

        const { hash } = await writeContract(prepayment)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess()
                    handleModalClose();
                    handleActiveStep(-1)

                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error calling prepayment method:', error);
        throw error; // propagate the error
    }
}

export { payPrepayment };