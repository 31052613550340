import React, { useState, useEffect } from 'react';
import OnboardingPersonalInforamtion from "./OnboardingPersonalInfo";
import OnboardingProfessionalInfo from "./OnboardingProfessional";
import OnboardingServiceInformation from "./OnboardingServiceInformation";
import OnboardingReviewPage from '../OnboardingReviewPage/OnboardingReviewPage';
import { Button, Chip, Step, StepContent, StepLabel, Stepper, Typography, Box, Grid } from '@mui/material';
import { Done } from '@mui/icons-material';
import './OnboardingProfile.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { ServiceProviderLogo } from '../../Components/Images/Images';

const OnboardingSteps = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [allStepsCompleted, setAllStepsCompleted] = useState(false);
    const [sharedData, setSharedData] = useState([]); // State to hold shared data
    const [sharedDataStepTwo, setSharedDataStepTwo] = useState([]); // State to hold shared data
    const [sharedDataStepThree, setSharedDataStepThree] = useState([]); // State to hold shared data
    const [previousData, setPreviousData] = useState([])
    const [stepTwoPreviousData, setStepTwoPreviousData] = useState([])
    const [stepThreePreviousData, setStepThreePreviousData] = useState([])
    const [ProfileImage, setProfileImage] = useState("")
    const navigate = useNavigate();
    const location = useLocation()
    // Default steps for a process
    const steps = [
        { label: 'PERSONAL INFORMATION' }, // Step indicating Personal Information
        { label: 'PROFESSIONAL INFORMATION' }, // Step indicating Professional Information
        { label: 'SERVICE INFORMATION ' }, // Step indicating Service Information
        // { label: 'REVIEW PROFILE' }, //Step indiacating review profile
    ];


    const handleNext = () => {
        console.log("onnext calle")
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setPreviousData({
            "description": sharedData.description,
            "location": sharedData.location,
            "firstName": sharedData.firstName,
            "lastName": sharedData.lastName,
            "profileImage": ProfileImage,
            "hourlyRate": sharedDataStepThree?.hourlyRate ? sharedDataStepThree?.hourlyRate : 0,
            "title": sharedDataStepTwo?.title ? sharedDataStepTwo?.title : ""
        })
    };

    /**
     * Function to hadle back button .
     */
    const handleBackFirst = () => {
        navigate("/service-provider/onbording2");
    };

    /**
     * Function handling Back button on step Two.
     * Setting Data into state var when user comes back , setting previous step data for step two.
     */
    const handleBackStepTwo = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setStepTwoPreviousData({
            "title": sharedDataStepTwo.title,
            "details": sharedDataStepTwo.details,
            "educationDetails": sharedDataStepTwo.educationDetails,
            "selectedLanguages": sharedDataStepTwo.selectedLanguages,
            "category": sharedDataStepTwo.categories,
            "selectedCategory": sharedDataStepTwo.selectedCategory,
            "selectedSkills": sharedDataStepTwo.selectedSkills,
            "stepSkill": sharedDataStepTwo.stepSkill,
            "serviceData": sharedDataStepTwo.serviceData,
            "documents": sharedDataStepTwo.Documents,
            "organizationValue": sharedDataStepTwo.associated_organization,
        })
    }

    /**
     * Function handling Back button on step Three.
     * Setting Data into state var when user comes back , setting previous step data for step three
     */
    const handleBackStepThree = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setStepThreePreviousData({
            "mainServices": sharedDataStepThree.mainServices,
            "hourlyRate": sharedDataStepThree.hourlyRate,
            "serviceFee": sharedDataStepThree.serviceFee,
            "youWillGet": sharedDataStepThree.youWillGet,
            "availability": sharedDataStepThree.availability
        })
    }

    /**
     * Function  callesd when all step completed.
     */
    const handleFinish = () => {
        // Mark all steps as completed
        setAllStepsCompleted(true);
    };

    /**
     * Function handling data of step first 
     * Function to handle data update from the left component
     * @param {*} newData 
     */
    const handleDataProfile = (newData) => {
        setSharedData(newData);
    };


    /**
     * Function handling the Data filled at Step Two.
     * Collecting All Data from step two Callback and setting it into state variable.
     * Collecting Profession data and storing it into sharedData var to show on preview page.
     * @param {*} newData 
     */
    const handleDataProfessional = (newData) => {
        setSharedDataStepTwo(newData)
        const profession = newData.title;

        setSharedData((prevData) => ({
            ...prevData,
            title: profession !== '' ? profession : "",
        }));
    }

    /**
     * Function handling the Data filled at Step Three.
     * Collecting All Data from step three Callback and setting it into state variable.
     * Collecting hourly rate data and storing it into sharedData var to show on preview page.
     * @param {*} newData 
     */
    const handleDataService = (newData) => {
        setSharedDataStepThree(newData);
        const hourlyRateData = newData.hourlyRate;

        setSharedData((prevData) => ({
            ...prevData,
            hourlyRate: hourlyRateData !== '' ? hourlyRateData : 0,
        }));
    };

    /**
     * Setting User Profile image into Variable
     * @param {*} image 
     */
    const userProfileImage = (image) => {
        setProfileImage(image)
    }

    /**
     * Function to handle the step colors 
     * @param {*} param0 
     * @returns 
     */
    const CustomStepIcon = ({ active, completed, index }) => {
        // const allStepsCompleted = activeStep === steps.length;
        const getColor = (stepIndex) => {
            if (allStepsCompleted) return "green"; // All steps completed color
            if (active && !completed && stepIndex === activeStep) return "active-step"; // Active step color
            if (completed) return "green"; // Completed step color
            if (!active && index < activeStep) return "green"; // Previous steps completed color
            return "rednew"; // Inactive step color
        };

        const color = getColor(index);

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Chip
                    label={index + 1}
                    style={{
                        // backgroundColor: color,
                        color: color === "active-step" ? "white" : "white", // Adjust text color based on background color
                    }}
                    className={allStepsCompleted ? "sp-stepper-onboarding-cm" : active || completed ? "sp-stepper-onboarding" : 'sp-stepper-onboarding-nc'}
                    size="small"
                />
                <Typography variant="body1" style={{ marginLeft: 8, color: color === "active-step" ? "#b37a56" : "#94A3B8" }}>
                    {steps[index].label}
                </Typography>
            </div>
        );
    };
    return (
        <>
            <Box className="logo-onboarding-one">
                <Link to="/">
                    <img src={ServiceProviderLogo} alt="Logo" />
                </Link>
            </Box>
            <Box>
                {activeStep < 3 ? <Grid container spacing={2}>
                    <Grid item xs={12} md={8} sm={12} >
                        <Box className="sp-box-main">
                            <Stepper activeStep={activeStep} className='sp-stepper-main'>
                                {steps.map((step, index) => (
                                    <Step key={step.label} >
                                        <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} index={index} />} />
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </Grid>
                </Grid>
                    : null}
                <Box className="sp-steps">
                    {activeStep === 0 ? (
                        <OnboardingPersonalInforamtion
                            onNext={handleNext}
                            onBack={handleBackFirst}
                            handleDataProfile={handleDataProfile}
                            previousData={previousData}
                            userProfileImage={userProfileImage}
                        />
                    ) : activeStep === 1 ? (
                        <OnboardingProfessionalInfo
                            sharedData={sharedData}
                            onNext={handleNext}
                            onBack={handleBack}
                            handleDataProfessional={handleDataProfessional}
                            previousData={previousData}
                            stepTwoPreviousData={stepTwoPreviousData}
                            ProfileImage={ProfileImage}
                        />
                    ) : activeStep === 2 ? (
                        <OnboardingServiceInformation
                            onNext={handleNext}
                            onBack={handleBackStepTwo}
                            handleDataService={handleDataService}
                            stepThreePreviousData={stepThreePreviousData}
                            sharedData={sharedData}
                            sharedDataStepTwo={sharedDataStepTwo}
                            previousData={previousData}
                            ProfileImage={ProfileImage}
                        />
                    ) : activeStep === 3 ? (
                        <OnboardingReviewPage
                            onFinish={handleFinish}
                            onBack={handleBackStepThree}
                            sharedDataStepTwo={sharedDataStepTwo}
                            sharedData={sharedData}
                            sharedDataStepThree={sharedDataStepThree}
                            previousData={previousData}
                            ProfileImage={ProfileImage}
                        />
                    ) : null}
                </Box>


            </Box>
        </>
    );
};

export default OnboardingSteps;
