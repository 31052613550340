import React from 'react'
import { Box, FormControl, InputLabel, ListItemText, Select, Typography } from "@mui/material";
import { MenuItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import './Filter.css'
import { Assessment, Paid, SwapVert, ViewComfy  } from '@mui/icons-material';
const Filter = ({ title , totalServices}) => {
    return (
        <>
            <Box className='container'>
                <Stack direction="row" justifyContent={"space-between"} spacing={2}>
                    <Box>
                    <Typography variant='h4' className='text-white'>{title}</Typography>
                    <Typography  className='text-white font-14'>{totalServices ? `${totalServices} Services ` :""} </Typography>
                    </Box>
                    <Stack direction="row">
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="filter-select-rounded" sx={{ minWidth: '130px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                    Price Range
                                    <ViewComfy className='text-white' />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    label="Price Range"
                                    variant='outlined'
                                >
                                    {/* <MenuItem value={0}>All</MenuItem> */}
                                    {[{ id: 1, title: '0-500' }, { id: 2, title: '500-1000' }]?.map((value, ind) => {
                                        return (
                                            <MenuItem key={ind} value={value.id}>
                                                <ListItemText>{value.title}</ListItemText>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="filter-select-rounded" sx={{ minWidth: '120px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                    Locations
                                    <Assessment className='text-white' />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    label="Locations"
                                    variant='outlined'
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    {[]?.map((value, ind) => {
                                        return (
                                            <MenuItem key={ind} value={value.id}>
                                                <ListItemText>{value.title}</ListItemText>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="filter-select-rounded" sx={{ minWidth: '100px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                    Ratings
                                    <Paid className='text-white' />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    label="Ratings"
                                    variant='outlined'
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    {[]?.map((value, ind) => {
                                        return (
                                            <MenuItem key={ind} value={value.id}>
                                                <ListItemText>{value.title}</ListItemText>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="filter-select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                Sort By: Name
                                    <SwapVert className='text-white' />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    label="Sort By: Name"
                                    variant='outlined'
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    {[]?.map((value, ind) => {
                                        return (
                                            <MenuItem key={ind} value={value.id}>
                                                <ListItemText>{value.title}</ListItemText>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className="select-group " ml={1.5}>
                            <FormControl className="filter-select-rounded " sx={{ minWidth: '165px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                Saved Services
                                    <Assessment className='text-white' />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    label="Saved Services"
                                    variant='outlined'
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    {[]?.map((value, ind) => {
                                        return (
                                            <MenuItem key={ind} value={value.id}>
                                                <ListItemText>{value.title}</ListItemText>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Stack>
                </Stack>
            </Box>
        </>
    )
}


export default Filter;
