import React, { useEffect, useState } from "react";
import { useAccount, useDisconnect, useNetwork } from "wagmi";
import { useDispatch } from "react-redux";
import { Close, Done } from "@mui/icons-material";
import { Box, Button, Typography, IconButton, Modal, Backdrop, CircularProgress, Chip, Stepper, Step, StepLabel, StepContent } from "@mui/material";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import { CONTRACTS } from "../../Web3/Contracts";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { GetApi, postApi, updateApi } from "../../Api/Api";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";
import { update } from "../../features/auth/authSlice";
import { checkBalance } from "./checkBalance";
import { checkAllowance } from "./checkAllowance";
import { approveToken } from "./approveToken";
import { proposerStake } from "./proposerStake";
import { dicStake } from "./dicStake";
import { completeReview } from "./completeReview";
import { proposerApproveSuggestion } from "./proposerApproveSuggestion";
import { completeVote } from "./completeVote";
import { assetListing } from "./assetListing";
import { makeOffer } from "./makeOffer";
import { cancelOffer } from "./cancelOffer";
import { changingOffer } from "./changingOffer";
import { acceptRejectOffer } from "./acceptRejectOffer";
import { directBuy } from "./directBuy";
import { makeRemainingPayment } from "./makeRemainingPayment";
import { createEMI } from "./createEMI";
import { dicSuggestions } from "./dicSuggestions";
import { payPrepayment } from "./payPrepayment";
import { buyParcel } from "./buyParcel";
import { safeMint } from "./safeMint";
import { approveMintAsset } from "./approveMintAsset";
import { getMintAssetApproved } from "./getMintAssetApproved";
import { withdrawAmountSale } from "./withdrawAmountSale";
import { withdrawAmountParcel } from "./withdrawAmountParcel";
import { withdrawAmountSPVEquity } from "./withdrawAmountSPVEquity";
import { withdrawAmountSPVDebt } from "./withdrawAmountSPVDebt";
import { withdrawalAmountFunds } from "./withdrawAmountFunds";
import { payEMI } from "./payEMI";
import { payMinimumPrepayment } from "./payMinimumPrepayment";
import { payDividendAmount } from "./payDividendAmount";
import { storeCallData } from "./storeCallData";
import { payCommitmentPayment } from "./makeCommitmentPayment";
import SnackbarAlert from "../Common/SnackbarAlert";
import { withdrawDICStake } from "./withdrawDICStake";
import { race } from "../../Web3/race-chain";
import { releasedAsset } from "./releasedAsset";
import '../../Pages/ProjectProposer/Questionnaire/Components/ProposerStakeRaceTokens.css'
import { createTokenizeAsset } from "./createTokenizeAsset";

export default function TransactionProgressModal(props) {
    const dispatch = useDispatch();
    const { chain } = useNetwork();
    console.log('transaction progress props...', props)

    const identity = props?.identity; // variable to identify the method type

    // Getting user wallet address
    const { address } = useAccount(); // wagmi (Hook) for accessing user account information
    // Wallet disconnect function
    const { disconnect } = useDisconnect(); // wagmi Hook for handling wallet disconnection
    const { showSnackbar } = useSnackbar(); // Hook for displaying snackbar or notifications

    // State variables initialization
    const [activeStep, setActiveStep] = useState(-1); // State variable for tracking active step in a progress modal
    const [associatedWallet, setAssociatedWallet] = useState(null); // State variable to hold associate wallet address in a progress modal

    // State to check the token status
    const [TokenStatus, SetTokenStatus] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false)



    // Default steps for a process
    const [steps, setSteps] = useState([
        { label: 'Wallet Connected', description: `You need to connect your wallet account.` }, // Step indicating that the wallet is connected
        { label: 'Confirm Transaction', description: `You need to pay a transaction fee.` } // Step waiting for transaction confirmation
    ]);

    // Retrieving data from local storage based on certain conditions(please add the identity here for proposer side integration)
    const localData = (identity === 'proposer-stake' || identity === 'approve-suggestion' || identity === 'listing' || identity === 'make-offer' || identity === 'cancel-offer' || identity === 'changing-offer' || identity === 'accept-reject-offer' || identity === "direct-buy" || identity === 'withdrawal-asset-sale' || identity === 'remaining-payment' || identity === 'create-emi' || identity === 'claim-token' || identity === 'pay-for-closure' || identity === 'buy-parcel' || identity === 'withdrawal-parcel' || identity === 'withdraw-spv' || identity === 'withdraw-spv-debt' || identity === "pay-emi" || identity === 'prepayment' || identity === 'minimum-prepayment' || identity === 'pay-dividend' || identity === 'store-call-data' || identity === 'withdraw-funds' || identity === 'released-asset' || identity === 'tokenize-mint') ?
        JSON.parse(localStorage.getItem("user_data")) :
        JSON.parse(localStorage.getItem("user_data_dic"));

    // monitor changes in wallet address
    useEffect(() => {
        console.log("wallet address", address); // Logging wallet address when it changes
    }, [address]); // Dependency array to trigger effect on changes to 'address'

    // Extracting necessary data from props
    let stakeAmt = props?.stakeAmt; // Amount to stake
    let offerAmount = identity === 'changing-offer' ? props?.propData?.editedData[0].value : props?.propData?.offer_amount;
    let changingAmt = identity === 'changing-offer' ? (parseFloat(props?.propData?.editedData[0].value) - parseFloat(props?.propData?.offerDetails?.offer_amount) > 0 ? parseFloat(props?.propData?.editedData[0].value) - parseFloat(props?.propData?.offerDetails?.offer_amount) : 0) : props?.propData?.offer_amount;
    let projectAmt = props?.projectAmt; // Amount of the project
    let proposalId = props?.propData?.blockchain_id; // Blockchain ID of the project
    let dicVoteType = props?.dicVoteType; // Type of vote from DIC
    let proposerDecision = props?.proposerDecision; // Decision made by the proposer
    let assetData = props?.propData; // Asset Data
    let depositAmount = props?.propData?.depositAmount ? props?.propData?.depositAmount : 0; // sale deposit amount
    let remainingAmount = props?.propData?.remaining_amount ? props?.propData?.remaining_amount : 0; // sale remaining payment

    // get decimals based on selected fees token type
    let decimals = (assetData?.feeTokenType == 'USDT' || assetData?.feeTokenType == 'USDC') ? 1e6 : 1e18;
    /**
     * Function to set the disclaimer message for each step
     * @param {string} val The identity string
     * @returns {string[]} Array of disclaimer messages
     */
    const disclaimer = (val) => {
        switch (val) {
            case "proposer-stake":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "dic-stake":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "make-offer":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];

            case "changing-offer":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "remaining-payment":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "direct-buy":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "prepayment":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "minimum-prepayment":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "pay-emi":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "pay-dividend":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "pay-for-closure":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "buy-parcel":
                return [
                    "You need to connect your wallet account.",
                    "Confirmation to approve tokens.",
                    "You need to pay the stake amount."
                ];
            case "listing":
                return [
                    "You need to connect your wallet account.",
                    "Minting asset.",
                    "Approve minted asset.",
                    "You need to pay a transaction fee."
                ];
            default:
                return ["", "", ""];
        }
    };

    /**
     * Function to set the description text
     */
    useEffect(() => {
        if (identity === "proposer-stake" || identity === "dic-stake" || identity === "make-offer" || identity === "changing-offer" || identity === "remaining-payment" || identity === "direct-buy" || identity === "prepayment" || identity === "pay-for-closure" || identity === 'buy-parcel' || identity === 'pay-emi' || identity === 'minimum-prepayment' || identity === 'pay-dividend') {
            // Setting new steps for the process
            const newSteps = [
                { label: "Wallet Connected", description: disclaimer(identity)[0] },
                { label: "Approve Tokens", description: disclaimer(identity)[1] },
                { label: "Confirm Payment", description: disclaimer(identity)[2] }
            ];
            setSteps(newSteps);
        } else if (identity === 'listing') {
            // Setting new steps for the process
            const newSteps = [
                { label: "Wallet Connected", description: disclaimer(identity)[0] },
                { label: "Mint Asset", description: disclaimer(identity)[1] },
                { label: "Approve Minted Asset", description: disclaimer(identity)[2] },
                { label: "Confirm Transaction", description: disclaimer(identity)[3] }
            ];
            setSteps(newSteps);
        }
    }, [identity]);

    /**
     * Calling Api to check the Token Current Status
     */
    useEffect(() => {
        async function CheckToken() {
            try {
                const checkTokenRes = await GetApi("/user/checkToken")
                if (checkTokenRes?.data?.code === 200 && checkTokenRes?.data?.status) {
                    SetTokenStatus(checkTokenRes?.data)
                }
            } catch (error) {
                console.log(error);
            }
        }
        CheckToken();
    }, []);

    // smart contract -- start
    useEffect(() => {
        if (TokenStatus && chain?.id == race?.id) {
            if (TokenStatus?.status) {
                if (address) {
                    // Function to make transaction to blockchain
                    async function makeTransaction() {
                        try {
                            console.log('investment_type_id', assetData?.investment_type_id)

                            // Set active step to 1
                            setActiveStep(1);

                            let balanceOf = 0;
                            if (assetData.selectedCategory != 'tokenize') {
                                // Fetching balance of connected wallet address
                                balanceOf = await checkBalance(address, assetData?.feeTokenType)
                                console.log('balanceOf', Number(balanceOf) / decimals);
                            }

                            // Set the allowance amount and spender contract address
                            let allowanceAmt = null;
                            let spenderAddress = null;
                            if ((identity === 'proposer-stake' || identity === 'dic-stake')) {
                                if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
                                    spenderAddress = CONTRACTS.FUND_STAKE_CONTRACT
                                } else {
                                    spenderAddress = CONTRACTS.STAKE_CONTRACT
                                }
                                allowanceAmt = stakeAmt
                            } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                                spenderAddress = CONTRACTS.ART_SALE_MARKETPLACE
                                if (identity === 'make-offer') {
                                    allowanceAmt = depositAmount
                                } else if (identity === 'direct-buy') {
                                    allowanceAmt = (assetData?.amount)
                                } else if (identity === 'remaining-payment') {
                                    allowanceAmt = remainingAmount;
                                }
                            } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
                                spenderAddress = CONTRACTS.ART_PARCEL
                                if (identity === 'buy-parcel') {
                                    allowanceAmt = Math.ceil(assetData?.amount * 10) / 10
                                }
                            } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                                spenderAddress = CONTRACTS.WATCH_DEBT_OFFER_ADDRESS
                                if (identity === 'changing-offer') {
                                    allowanceAmt = changingAmt
                                } else if ((identity === 'make-offer')) {
                                    allowanceAmt = offerAmount;
                                } else if (identity === 'prepayment') {
                                    spenderAddress = CONTRACTS.WATCH_REPAYMENT_ADDRESS
                                    allowanceAmt = Math.ceil(assetData?.prepayment_amount)
                                } else if (identity === 'pay-emi') {
                                    spenderAddress = CONTRACTS.WATCH_REPAYMENT_ADDRESS
                                    allowanceAmt = (assetData?.emi_amount)
                                } else if (identity === 'minimum-prepayment') {
                                    spenderAddress = CONTRACTS.WATCH_REPAYMENT_ADDRESS
                                    allowanceAmt = (assetData?.min_prepayment_amount)
                                }
                            } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT || assetData?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT)) {
                                spenderAddress = CONTRACTS.SPV_DEBT_OFFER_ADDRESS
                                if (identity === 'make-offer') {
                                    allowanceAmt = offerAmount
                                } else if (identity === 'changing-offer') {
                                    allowanceAmt = changingAmt
                                } else if (identity === 'prepayment') {
                                    spenderAddress = CONTRACTS.SPV_DEBT_REPAYMENT_ADDRESS
                                    allowanceAmt = (assetData?.prepayment_amount)
                                } else if (identity === 'pay-emi') {
                                    spenderAddress = CONTRACTS.SPV_DEBT_REPAYMENT_ADDRESS
                                    allowanceAmt = (assetData?.emi_amount)
                                } else if (identity === 'minimum-prepayment') {
                                    spenderAddress = CONTRACTS.SPV_DEBT_REPAYMENT_ADDRESS
                                    allowanceAmt = (assetData?.min_prepayment_amount)
                                }
                            } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.capital_type_id === CAPITAL_TYPE_ID.EQUITY)) {
                                spenderAddress = CONTRACTS.SPV_EQUITY_OFFER_ADDRESS
                                if (identity === 'make-offer') {
                                    allowanceAmt = offerAmount
                                } else if (identity === 'changing-offer') {
                                    allowanceAmt = changingAmt
                                } else if (identity === 'pay-dividend') {
                                    spenderAddress = CONTRACTS.SPV_EQUITY_REPAYMENT_ADDRESS
                                    allowanceAmt = assetData?.amount
                                }
                            } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
                                spenderAddress = CONTRACTS.FUNDS_EQUITY_OFFER
                                if (identity === 'make-offer') {
                                    allowanceAmt = offerAmount
                                } else if (identity === 'remaining-payment') {
                                    allowanceAmt = remainingAmount;
                                }
                            }
                            console.log('allowanceAmt', allowanceAmt);
                            console.log('spenderAddress', spenderAddress);

                            // check if allowance amount and spender address exist
                            if (allowanceAmt && spenderAddress) {

                                // Return and Exit if insufficient RACE/USDT Tokens balance in the wallet
                                if (Number(balanceOf) / decimals < Math.ceil(allowanceAmt * 10) / 10) {
                                    showSnackbar(`Insufficient ${assetData?.feeTokenType ? assetData?.feeTokenType : 'USDT'} Tokens`, 'error');
                                    props?.handleModalClose();
                                    return
                                }

                                // Now check for allowance RACE/USDT Tokens
                                const allowance = await checkAllowance(address, spenderAddress, assetData?.feeTokenType);
                                console.log('allowance', parseFloat((allowance) / decimals).toFixed(2));

                                // If not enough allowance available then, call approve method
                                if (parseFloat((allowance) / decimals).toFixed(2) < Math.ceil(allowanceAmt * 10) / 10) {
                                    const data = await approveToken(
                                        address, // user wallet address
                                        spenderAddress, // spender address
                                        Number(parseFloat(allowanceAmt)), // allowance amount
                                        assetData?.feeTokenType, // fee token type
                                    )
                                    if (data.status === "success") {
                                        showSnackbar("Transaction Successful", 'success')
                                    } else {
                                        props?.handleModalClose();
                                        setActiveStep(-1)
                                        showSnackbar("Transaction Failed", 'error')
                                        return
                                    }
                                }

                                // Now check for allowance RACE/USDT Tokens
                                const newAllowance = await checkAllowance(address, spenderAddress, assetData?.feeTokenType);
                                console.log('newAllowance', parseFloat(newAllowance / decimals).toFixed(2));

                                // Return and Exit if insufficient RACE/USDT Tokens allowance to spender contract
                                if (parseFloat(newAllowance / decimals).toFixed(2) < Math.ceil(allowanceAmt * 10) / 10) {
                                    showSnackbar('Insufficient allowance', 'error');
                                    props?.handleModalClose();
                                    return
                                }

                                // Return and Exit if project is not live
                                if ((identity === 'make-offer' || identity === "direct-buy" || identity === "buy-parcel") && !assetData?.listing_id) {
                                    showSnackbar("This project is currently not live.", 'error')
                                    props?.handleModalClose();
                                    return
                                }
                            }

                            // mint asset and approve minted asset
                            let mintedAssetId = '';
                            if (identity === 'listing') {
                                // listing mint asset 
                                if (assetData?.assetDetails?.mint_token_id) {
                                    mintedAssetId = assetData?.assetDetails?.mint_token_id;
                                    setActiveStep(2);
                                } else {
                                    const newAssetData = await postApi("/proposer/assetsDetails", { "asset_id": assetData?.assetDetails?.id });
                                    if (newAssetData?.data?.data && newAssetData?.data?.data[0] && newAssetData?.data?.data[0].mint_token_id) {
                                        mintedAssetId = newAssetData?.data?.data[0]?.mint_token_id;
                                    } else {
                                        mintedAssetId = await safeMint(address, assetData);
                                        const res = await updateApi('proposer/updateMintTokenId/' + assetData?.assetDetails?.id, { mint_token_id: mintedAssetId });
                                        if (res?.data?.code === 200) {
                                            console.log('Updated Token ID')
                                        }
                                        setActiveStep(2);
                                    }
                                }

                                // listing approve minted asset (sale)
                                if (mintedAssetId) {
                                    const newAssetData = await postApi("/proposer/assetsDetails", { "asset_id": assetData?.assetDetails?.id });
                                    if (newAssetData?.data?.data && newAssetData?.data?.data[0] && newAssetData?.data?.data[0].approved_token_id) {
                                        let escrowAddress = await getMintAssetApproved(address, mintedAssetId);
                                        setActiveStep(3);
                                    } else {
                                        let escrowAddress = CONTRACTS.ART_SALE_MARKETPLACE;
                                        if (assetData?.assetDetails?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
                                            escrowAddress = CONTRACTS.ART_PARCEL
                                        } else if (assetData?.assetDetails?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                                            escrowAddress = CONTRACTS.WATCH_MARKETPLACE
                                        } else if (assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                                            escrowAddress = CONTRACTS.SPV_MARKETPLACE
                                        } else if (assetData?.assetDetails?.category_id === CATEGORY_TYPE_ID.FUNDS) {
                                            escrowAddress = CONTRACTS.FUNDS_MARKETPLACE
                                        }
                                        await approveMintAsset(address, mintedAssetId, escrowAddress);
                                        const res = await updateApi('proposer/updateMintTokenId/' + assetData?.assetDetails?.id, { approved_token_id: true });
                                        if (res?.data?.code === 200) {
                                            console.log('Updated minted asset status')
                                        }
                                        setActiveStep(3);
                                    }
                                }
                            }

                            // Now call the method as required
                            switch (identity) {
                                case 'dic-stake':
                                    await handleDicStake();
                                    break;
                                case 'dic-suggestions':
                                    await handleDicSuggestions();
                                    break;
                                case 'tokenize-mint':
                                    await handleTokenizeMint();
                                    break;
                                case 'add-review':
                                    await handleAddReview();
                                    break;
                                case 'released-asset':
                                    await handleReleasedAsset();
                                    break;
                                case 'vote-on-proposal':
                                    await handleVoteOnProposal();
                                    break;
                                case 'proposer-stake':
                                    await handleProposerStake();
                                    break;
                                case 'approve-suggestion':
                                    await handleApproveSuggestion();
                                    break;
                                case 'listing':
                                    await handleListing(mintedAssetId);
                                    break;
                                case 'make-offer':
                                    await handleMakeOffer();
                                    break;
                                case 'cancel-offer':
                                    await handleCancelOffer();
                                    break;
                                case 'changing-offer':
                                    await handleChangingOffer();
                                    break;
                                case 'accept-reject-offer':
                                    await handleAcceptRejectOffer();
                                    break;
                                case 'direct-buy':
                                    await handleDirectBuy();
                                    break;
                                case 'remaining-payment':
                                    await handleRemainingPayment();
                                    break;
                                case 'withdrawal-asset-sale':
                                    await handleWithdrawSaleAsset();
                                    break;
                                case 'withdrawal-parcel':
                                    await handleWithdrawParcelAsset();
                                    break;
                                case 'withdraw-spv':
                                    await handleWithdrawAmountSPV();
                                    break;
                                case 'withdraw-spv-debt':
                                    await handleWithdrawAmountSPVDebt();
                                    break;
                                case 'withdraw-funds':
                                    await handleWithdrawAmountFunds();
                                    break;
                                case 'create-emi':
                                    await handleCreateEMI();
                                    break;
                                case 'prepayment':
                                    await handlePrepayment();
                                    break;
                                case 'minimum-prepayment':
                                    await handleMinimumPrepayment();
                                    break;
                                case 'pay-emi':
                                    await handlePayEMI();
                                    break;
                                case 'pay-dividend':
                                    await handlePayDividend();
                                    break;
                                case 'buy-parcel':
                                    await handleBuyParcel();
                                    break;
                                case 'store-call-data':
                                    await handleStoreCallData();
                                    break;
                                case 'withdraw-stake-dic':
                                    await handleWithdrawStakeDic();
                                    break;
                                default:
                                    // handle default case if needed
                                    break;
                            }

                            /**
                            * handle proposer stack amount for project propose
                            */
                            async function handleProposerStake() {
                                await proposerStake(address, props?.propData?.user_id, props?.propData?.asset_id, props?.propData?.blockchain_category_id, props?.propData?.blockchain_investment_type_id, Number(projectAmt), Number(stakeAmt), showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                            * handle pay dic stake amount 
                            */
                            async function handleDicStake() {
                                await dicStake(address, props?.propData?.user_id, props?.propData?.asset_id, proposalId, stakeAmt, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                          * handle pay dic stake amount 
                          */
                            async function handleTokenizeMint() {
                                await createTokenizeAsset(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }
                            /**
                            * handle pay dic stake amount 
                            */
                            async function handleStoreCallData() {
                                await storeCallData(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay dic stake amount 
                            */
                            async function handleWithdrawStakeDic() {
                                await withdrawDICStake(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle dic suggestion terms for all assets 
                            */
                            async function handleDicSuggestions() {
                                await dicSuggestions(address, props?.suggestedDataPayload, assetData, proposalId, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle complete review by dic  
                            */
                            async function handleAddReview() {
                                await completeReview(address, proposalId, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                            * handle complete review by dic  
                            */
                            async function handleReleasedAsset() {
                                await releasedAsset(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle complete vote by dic  
                            */
                            async function handleVoteOnProposal() {
                                await completeVote(address, proposalId, dicVoteType, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                            * handle update deal terms by dic 
                            */
                            async function handleApproveSuggestion() {
                                await proposerApproveSuggestion(address, proposalId, proposerDecision, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, props?.propData);
                            }

                            /**
                            * handle listing of project or go publish 
                            */
                            async function handleListing(tokenId) {
                                await assetListing(address, assetData, props?.propData?.assetDetails, props?.propData?.parcelNFTs, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, tokenId);
                            }

                            /**
                            * handle create offer by investor
                            */
                            async function handleMakeOffer() {
                                await makeOffer(address, assetData, offerAmount, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle cancel the offer by investor  
                            */
                            async function handleCancelOffer() {
                                await cancelOffer(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle edit the offer by investor  
                            */
                            async function handleChangingOffer() {
                                await changingOffer(address, assetData, offerAmount, Number(props?.propData?.editedData[2]?.value), Number(props?.propData?.editedData[1]?.value), showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle offer accept/reject by proposer
                            */
                            async function handleAcceptRejectOffer() {
                                await acceptRejectOffer(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle make full payment on direct buy sale asset 
                            */
                            async function handleDirectBuy() {
                                await directBuy(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle make full payment on direct buy sale asset 
                            */
                            async function handleBuyParcel() {
                                await buyParcel(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay remaining payment of the offer on sale asset 
                            */
                            async function handleRemainingPayment() {
                                if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.FRACTION) {
                                    await payCommitmentPayment(address, assetData, remainingAmount, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                                } else {
                                    await makeRemainingPayment(address, assetData, remainingAmount, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                                }
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawSaleAsset() {
                                await withdrawAmountSale(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawParcelAsset() {
                                await withdrawAmountParcel(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawAmountSPV() {
                                await withdrawAmountSPVEquity(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawAmountSPVDebt() {
                                await withdrawAmountSPVDebt(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle withdrawal of asset  
                            */
                            async function handleWithdrawAmountFunds() {
                                await withdrawalAmountFunds(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle create EMI for proposer by investor   
                            */
                            async function handleCreateEMI() {
                                await createEMI(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay prepayment amount
                            */
                            async function handlePrepayment() {
                                await payPrepayment(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay prepayment amount
                            */
                            async function handlePayDividend() {
                                await payDividendAmount(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay minimum prepayment amount   
                            */
                            async function handleMinimumPrepayment() {
                                await payMinimumPrepayment(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }

                            /**
                            * handle pay emi  
                            */
                            async function handlePayEMI() {
                                await payEMI(address, assetData, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake);
                            }
                        } catch (error) {
                            console.log('error', { error })
                            if (error?.cause?.reason && error?.cause?.reason?.split(":")?.length) {
                                let reason = error.cause.reason;
                                let message = reason?.split(":")
                                showSnackbar(message[1] ? message[1] == " Minimum Pre-Payment under Min-Max % of Fullfilled Amount!" ? "You've either exceeded or fallen short of the minimum prepayment percentage as set by admin!" : message[1] : message[0], 'error')
                            } else {
                                showSnackbar("Transaction Failed", 'error')
                            }
                            props?.handleModalClose({ warning: identity === 'remaining-payment' ? true : false });
                        }
                    }
                    // Function to check associate wallet with the account
                    async function checkAssociatedWallet() {
                        try {
                            const checkRes = await postApi(`/user/checkWallet`, { wallet_address: address?.toLowerCase(), user_id: localData?.id });

                            if (checkRes?.data?.status) {
                                setActiveStep(0);
                                setAssociatedWallet(address);
                                makeTransaction();
                                handleCloseDialog();
                            } else {
                                handleCloseDialog();
                                setActiveStep(0);
                                showSnackbar('This wallet is not associated with the account', 'error');
                                setTimeout(() => {
                                    // disconnect();
                                    // props?.handleModalClose();
                                }, 1000)
                                return
                            }
                        } catch (error) {
                            console.log('There was an error')
                        }
                    }
                    // Call the function checkAssociatedWallet
                    checkAssociatedWallet()
                } else {
                    setActiveStep(0);
                }
            } else {
                setOpenSnackbar(true)
                setTimeout(() => {
                    window.location.href = '/';
                }, 4000);
                handleCloseDialog()
            }
        }

    }, [address, TokenStatus, chain])
    //  smart contract -- end

    /**
     * Set the active step value
     * @param {Number} value 
     */
    const handleActiveStep = (value) => {
        setActiveStep(value)
    }

    //Wallet connection code start
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);

    /**
     * Function to handle wallet modal open
     */
    const handleClickOpen = async () => {
        setWalletDialogOpen(true);
    };

    /**
     * Function to handle wallet modal close
     */
    const handleCloseDialog = () => {
        setWalletDialogOpen(false);
    };

    /**
     * gets called when a wallet is connect successfully
     * @param {*} acc
     */
    const walletLogin = async (acc) => {
        dispatch(update({ wallet_address: acc?.toLowerCase() }));
    };
    //Wallet connection code end

    /**
     * Function to  handle custom icons
     * @returns custom icons for progress steps
     */
    const CustomStepIcon = ({ active, completed, index }) => {
        if (completed) {
            // You can customize the completed state icon here
            return <Chip label={<Done />} color="primary" className="item-completed" size="small" />;
        }

        // Customize the active state icon with CircularProgress loader
        return active ? <CircularProgress color="inherit" size={20} /> :
            <Chip label={index + 1} color="primary" className="item-indexing" size="small" />; // Change the icon based on your preference
    };

    /**
     * Function to handle modal close
     * @param {Event} reason 
     * @returns 
     */
    const handleWalletTransactionModal = (reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown' || reason?.code === "Escape") {
            return; //do nothing basically do not close modal
        } else {
            props?.handleModalClose({ warning: true });
        }
    };

    /**
    * handles snackbar close
    * @param {*} event 
    * @param {*} reason 
    * @returns 
    */
    const handleClose = (event, reason) => {
        setOpenSnackbar(false);
    };

    return (
        <>
            {/* Stepper modal for transaction flow for abi*/}
            <Modal
                open={props?.openTransactionModal}
                onClose={handleWalletTransactionModal}
                className="kyc-modal wallet-transaction-flow"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                BackdropComponent={Backdrop}
                BackdropProps={{ open: false }}
                sx={{ backdropFilter: 'blur(2px)' }}
            >
                <Box className="set-stack-rts common-modal-design">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                        {/* {activeStep >= steps?.length - 1 ? */}
                        {activeStep > 0 ?
                            <></>
                            :
                            <IconButton >
                                <Close style={{ color: '#fff' }} onClick={handleWalletTransactionModal} />
                            </IconButton>
                        }
                    </Box>
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps?.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} index={index} />}>
                                        {!associatedWallet && index === 0 ? <Button className="btn-rounded" onClick={handleClickOpen}>Connect Wallet</Button> : index === 0 ? <Button className="btn-rounded btn-green-400">Wallet connected</Button> : step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography className={associatedWallet && index === 0 ? "wallet-address-text" : ''}>{associatedWallet && index === 0 ? associatedWallet : step.description}</Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Box>
            </Modal >

            <SnackbarAlert open={openSnackbar} message={"Your Token has been expire , please login again"} severity={"info"} onClose={handleClose} />

            {/* wallet connect component */}
            <ConnectWalletInvestor
                open={walletDialogOpen}
                handleCloseDialog={handleCloseDialog}
                handleConnectedSuccess={walletLogin}
                closeTransactionModal={props?.handleModalClose}
            />
        </>
    );
}
