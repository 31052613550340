import React from "react";
import { Avatar, Box, Chip, Grid, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import imgBg from "../../../Assets/Images/service-provider/img-card.png";
import downloadIcon from "../../../Assets/Images/service-provider/download.png";
import audiList from "../../../Assets/Images/audi-img.png";
import UploadIcon from '@mui/icons-material/Upload';
import ErrorIcon from "@mui/icons-material/Error";
import "./MyJobs.css";
import DetailsJob from "./DetailsJob";
import TimeTracking from "./TimeTracking";
const IndividualJobActivity = () => {
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box className="individual-job-activity-container">
      <Box>
        <Grid container spacing={2}>
          <Grid xs={12} md={8} sm={12}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Activity" {...a11yProps(0)} className={value === 0 ? "st-active" : "st-inactive"} />
                  <Tab label="Details" {...a11yProps(1)} className={value === 1 ? "st-active" : "st-inactive"} />
                  <Tab label="Requirements" {...a11yProps(2)} className={value === 2 ? "st-active" : "st-inactive"} />
                  <Tab label="Delivery" {...a11yProps(3)} className={value === 3 ? "st-active" : "st-inactive"} />
                  <Tab label="Time Tracking" {...a11yProps(4)} className={value === 4 ? "st-active" : "st-inactive"} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box className="activity-container">
                  <Box className="date-btn">
                    <Chip label="Apr 8" />
                  </Box>
                  <Box className="user-chat">
                    <Box className="chat-left">
                      <AccessTimeIcon />
                    </Box>
                    <Box className="chat-right">
                      <Typography className="font-16">
                        You sent a request to update delivery date to April 9
                      </Typography>
                      <Typography className="font-12 text-blueGray-300">
                        Apr 8, 9:45PM
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="user-chat">
                    <Box className="chat-left">
                      <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                      />
                    </Box>
                    <Box className="chat-right">
                      <Typography className="font-16">Me</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        Apr 8, 9:46PM
                      </Typography>
                      <Box className="msg-box">
                        <Typography className="font-16">Message</Typography>
                        <Typography className="font-12">
                          This helps your project post stand out to the right
                          service providers. It’s the first thing they will see,
                          so make it stick!
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="user-chat">
                    <Box className="chat-left">
                      <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                      />
                    </Box>
                    <Box className="chat-right">
                      <Typography className="font-16">Usman Abbasi</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        Apr 8, 9:46PM
                      </Typography>
                      <Box className="msg-box">
                        <Typography className="font-16">Message</Typography>
                        <Typography className="font-12">
                          This helps your project post stand out to the right
                          service providers. It’s the first thing they will see,
                          so make it stick!
                        </Typography>
                      </Box>
                      <Box className="img-box">
                        <Box className="img-box-div">
                          <img src={imgBg} alt="" />
                        </Box>
                        <Box className="img-box-bottom">
                          <Box>
                            <Typography className="font-16">Title</Typography>
                            <Typography className="font-12 text-blueGray-300">
                              100MB
                            </Typography>
                          </Box>
                          <Box>
                            <img src={downloadIcon} alt="" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
               <DetailsJob/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                Item Three
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                Item Three
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
               <TimeTracking/>
              </CustomTabPanel>
            </Box>
          </Grid>
          <Grid xs={12} md={4} sm={12}>
            <Box className="activity-right-section">
              <Box>
                <Typography className="font-16">Order Details</Typography>
              </Box>
              <Box className="order-list-box">
                <Box>
                  <img src={audiList} />
                </Box>
                <Box className="right-section">
                  <Typography className="font-16">
                    Auditing for Houston Project
                  </Typography>
                  <Typography className="font-12 text-blueGray-300">
                    Ongoing
                  </Typography>
                </Box>
              </Box>
              <Box className="service-box">
                <Box className="service-container">
                  <Typography className="font-12">Service To</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    Usman Abbasi
                  </Typography>
                </Box>
                <Box className="service-container">
                  <Typography className="font-12">Total Price</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    SGD 125.86
                  </Typography>
                </Box>
                <Box className="service-container">
                  <Typography className="font-12">Order Number</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    #ON123475967
                  </Typography>
                </Box>
              </Box>
              <Box className="action-div">
                <Typography className="font-16">Actions</Typography>
                <Box>
                  <ErrorIcon />
                </Box>
              </Box>
              <Box className="deliver-order-box">
              <Typography className="font-14">Deliver Order</Typography>
              <img src=""/>
              </Box>
              <Box className="upload-files-box">
              <Typography className="font-14">Upload files</Typography>
              <UploadIcon/>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default IndividualJobActivity;
