// Importing necessary dependencies and styles
import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  InputBase,
  Stack,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { ArrowBackIos, KeyboardArrowDown, Search } from "@mui/icons-material";
import {
  Logo,
  User,
  cusDownArrow,
  Notification,
  Timelapse,
  Message,
  customDownArrow,
} from "../../Components/Images/Images";
import "./HeaderServiceProvider.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Settings from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import logout from "../../features/auth/authSlice";
import Logout from "@mui/icons-material/Logout";
import { GetApiParam } from "../../Api/Api";

// Component for rendering the logo
const LogoIcon = ({ navigateHome }) => (
  <Box className="logo">
    <img
      src={Logo}
      alt="Logo"
      onClick={navigateHome}
      style={{ cursor: "pointer" }}
    />
  </Box>
);

// Component for rendering each header item with text and optional icon
const HeaderItem = ({ text, icon, position, link }) => (
  <Box className="header-items" flexDirection={position ? "row-reverse" : ""}>
    {icon && React.cloneElement(icon, { className: "icon" })}
    <Link to={link}>
      <Typography className="text">{text}</Typography>
    </Link>
  </Box>
);

// Component for rendering the search box
const SearchBox = () => (
  <Box className="sp-search-box">
    <Search className="icon" />
    <InputBase placeholder={"Search Services"} className="input" />
  </Box>
);

// Component for rendering the "Get Started" button
const GetStartedButton = () => (
  <Box className="getStarted">
    <Button variant="contained" className="btn-rounded btn-golden">
      Get Started
    </Button>
  </Box>
);

// Main Header component
const Header = ({ handleTimelapseClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  // State to handle user login
  const [isLogin, setIsLogin] = useState(false);
  const [profilestate, setProfileState] = useState(false);

  // Get the dispatch function from the Redux store
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const openheadermenu = Boolean(anchorEl2);

  const location = useLocation();
  var localData = localStorage.getItem("login")
    ? JSON.parse(localStorage.getItem("login"))
    : "";
  const localStateData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : "";

  useEffect(() => {
    if (localData) {
      setIsLogin(true);
    }
  }, [localData]);

  /**
   * function to handle logout functionality
   */
  // const handleLogout = () => {
  //   localStorage.clear()
  //   navigate("/");
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 500);
  // };

  /**
   * Checking for profile that user created profile or not
   */
  useEffect(() => {
    async function profileCheck() {
      try {
        const profileCheckResponce = await GetApiParam(
          "/services/checkProfile",
          { user_id: localStateData?.id ? localStateData?.id : "" }
        );
        setProfileState(
          profileCheckResponce?.data?.code === 200
            ? profileCheckResponce?.data?.profile
            : ""
        );
      } catch (error) {
        console.log(error);
      }
    }
    profileCheck();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = (id) => {
    setAnchorEl1(null);
    document.documentElement.scrollTop = 0;
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = (id) => {
    setAnchorEl2(null);
    document.documentElement.scrollTop = 0;
  };

  const navigate = useNavigate();

  const handleClickGetStart = () => {
    navigate("/login");
  };

  /**
 * function to clear Local storage
 */
  const ClearLocalStorageData = () => {
    localStorage.removeItem("chatbot-thread-id")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("rememberMeToken")
    localStorage.removeItem("Diclogin")
    localStorage.removeItem("user_data_dic")
    localStorage.removeItem("user_data")
    localStorage.removeItem("token")
    localStorage.removeItem("login")
    localStorage.removeItem("kyc_modal_closed")
  }

  return (
    <Box className="service-marketplace-home">
      <Box className="">
        <Stack
          className="main-header"
          spacing={1}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
        >
          <LogoIcon navigateHome={() => navigate("/")} />
          <HeaderItem
            text="Back to Investing Dashboard"
            icon={<ArrowBackIos />}
            link={"/"}
          />
          <SearchBox />
          {/* <HeaderItem text="Find Service Providers" position={'reverse'} icon={<KeyboardArrowDown />} /> */}
          <Box className="header-items">
            <Box className="menu-item">
              <Box
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="header-items"
              >
                <Typography className="text">
                  {"Find Service Providers"}
                </Typography>
                <KeyboardArrowDown className="icon" />
              </Box>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="dropdown-menu InvesterHeader-dropdown"
                mt={8}
              >
                <Link to="/service-proposer/find-a-service-provider">
                  <MenuItem
                    onClick={handleClose}
                    className={
                      location.pathname ===
                        "/service-proposer/find-a-service-provider"
                        ? "active"
                        : ""
                    }
                  >
                    Find Service Providers
                  </MenuItem>
                </Link>
              </Menu>
            </Box>
          </Box>
          {/* <HeaderItem text="Find Work" position={'reverse'} icon={<KeyboardArrowDown />} /> */}
          <Box className="header-items">
            <Box className="menu-item">
              <Box
                id="fade-button"
                aria-controls={open1 ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick1}
                className="header-items"
              >
                <Typography className="text">{"Find Work"}</Typography>
                <KeyboardArrowDown className="icon" />
              </Box>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                className="dropdown-menu InvesterHeader-dropdown"
                mt={8}
              >
                <Link to="/service-provider/browser-request">
                  <MenuItem
                    onClick={handleClose1}
                    className={
                      location.pathname === "/service-provider/browser-request"
                        ? "active"
                        : ""
                    }
                  >
                    Find Work
                  </MenuItem>
                </Link>
              </Menu>
            </Box>
          </Box>
          {isLogin ? (
            <>
              <img src={Notification} alt="" className="header-login" />
              <img src={Timelapse} alt="" className="header-login" />
              <img src={Message} alt="" className="header-login" />
              <Box className="profile-div-main ">
                <Avatar src={User} />
                <Box className="profileMenu-wrap" ml={1.5}>
                  <Button
                    id="fade-button"
                    aria-controls={openheadermenu ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openheadermenu ? "true" : undefined}
                    onClick={handleClick2}
                    className="profileMenu-btn"
                  >
                    <img
                      src={customDownArrow}
                      alt="Logo"
                      className="header-image-arrow"
                    />
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorEl={anchorEl2}
                    open={openheadermenu}
                    onClose={handleClose2}
                    // TransitionComponent={Fade}
                    className="dropdown-menu"
                  >
                    {profilestate ? (
                      <MenuItem
                        onClick={() => {
                          handleClose2();
                          navigate("/service-provider-dashboard/overview");
                        }}
                      >
                        <ListItemIcon>
                          <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        Profile
                      </MenuItem>
                    ) : null}
                    <MenuItem
                      onClick={() => {
                        // localStorage.removeItem("login");
                        // localStorage.clear();
                        ClearLocalStorageData()
                        navigate("/");
                        window.location.reload(true);
                      }}
                    >
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <HeaderItem text="Sign In" link={"/login"} />
              <Box onClick={() => handleClickGetStart()}>
                <GetStartedButton />
              </Box>
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default Header;
