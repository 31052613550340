// safeMint.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";

/**
 * Approve the tokens for spender contract
 * @param {String} address user wallet address
 * @param {String} spenderAddress spender contract address
 * @param {String} allowanceAmt  amount to approve
 * @returns transaction data
 */
async function tokenizeNft(address, assetData) {
    let ipfsData = assetData?.ipfsData;

    try {
        // Now check for allowance RACE Tokens
        const { request: tokenizeNft } = await prepareWriteContract({
            address: CONTRACTS.TOKENIZE_NFT_ADDRESS,
            abi: CONTRACT_ABI[CONTRACTS.TOKENIZE_NFT_ADDRESS],
            account: address,
            chainId: 6805,
            functionName: "mint",
            args: [
                address, // to (address)
                `https://gateway.pinata.cloud/ipfs/${ipfsData?.gellery[0]?.images?.IpfsHash}` // uri (string)
            ]
        })

        const { hash } = await writeContract(tokenizeNft);

        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })

            if (data?.logs?.length) {
                return Number(data.logs[0].topics[3]);
            } else {
                return null
            }
        }
    } catch (error) {
        console.error('Error minting asset:', error);
        throw error; // propagate the error
    }
}

export { tokenizeNft };