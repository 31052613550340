import React, { useEffect, useState, Fragment } from "react";
import { Box, Typography, Divider, useMediaQuery, Backdrop } from "@mui/material";
import PropTypes from "prop-types";
import DropdownBox from "../InvestorOnly/Component/DropdownBox";
import { useSelector, useDispatch } from "react-redux";
import { GetApiParam, updateApi } from "../Api/Api";
import { updateStep } from "../features/auth/tooltipSlice"
import InvestorButtons from "./InvestorButtons";
import CapitalInfo from "./CapitalInfo";

export default function PriceOverview({ prices, isLoan, isParcel, isSale, isFraction, isFundFraction, assetData, listingType, btnTitle, assetId, isOfferAccepted, isOnboard, isOwner, capitalInfo }) {

    const [tolPosition, setTolPosition] = useState(0)
    const isXs = useMediaQuery('(max-width:600px)');
    const step = useSelector((state) => {
        if (isLoan) {
            return state.tooltipSlice.loan_step_number
        } else if (isParcel) {
            return state.tooltipSlice.parcel_step_number
        } else if (isSale) {
            return state.tooltipSlice.sale_step_number
        } else if (isFraction) {
            return state.tooltipSlice.re_step_number
        } else if (isFundFraction) {
            return state.tooltipSlice.funds_step_number
        }
    }
    );
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const localData = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : "";

    useEffect(() => {
        if ((tolPosition > 11 && isLoan) || (tolPosition > 8 && isParcel) || (tolPosition > 7 && isSale) || (tolPosition > 9 && isFraction) || (tolPosition > 7 && isFundFraction)) {
            setLoading(false);
        }
    }, [tolPosition]);

    async function setStep(tolPositionData) {
        try {
            let body = { "loan_step_number": tolPositionData };
            if (isParcel) {
                body = { "parcel_step_number": tolPositionData };
            } else if (isSale) {
                body = { "sale_step_number": tolPositionData };
            } else if (isFraction) {
                body = { "re_step_number": tolPositionData };
            } else if (isFundFraction) {
                body = { "funds_step_number": tolPositionData };
            }
            const data = await updateApi("/user/updateUser/" + localData?.id, body);
            if (data?.data?.data) {
                if ((data.data?.data?.loan_step_number >= 6 && data.data?.data?.loan_step_number <= 11) && isLoan) {
                    setTolPosition(data.data?.data?.loan_step_number ? data.data.data.loan_step_number : 0)
                    setLoading(true)
                } else if ((data.data?.data?.parcel_step_number >= 6 && data.data?.data?.parcel_step_number <= 8) && isParcel) {
                    setTolPosition(data.data?.data?.parcel_step_number ? data.data.data.parcel_step_number : 0)
                    setLoading(true)
                }
                else if ((data.data?.data?.sale_step_number >= 6 && data.data?.data?.sale_step_number <= 7) && isSale) {
                    setLoading(true)
                    setTolPosition(data.data?.data?.sale_step_number ? data.data.data.sale_step_number : 0)
                }
                else if ((data.data?.data?.re_step_number >= 6 && data.data?.data?.re_step_number <= 9) && isFraction) {
                    setTolPosition(data.data?.data?.re_step_number ? data.data.data.re_step_number : 0)
                    setLoading(true)
                } else if ((data.data?.data?.funds_step_number >= 6 && data.data?.data?.funds_step_number <= 7) && isFundFraction) {
                    setTolPosition(data.data?.data?.funds_step_number ? data.data.data.funds_step_number : 0)
                    setLoading(true)
                }
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    /**
     * Callback function for button click
     * @param {*} childData 
     */
    const handleDataFromChild = async (childData) => {
        dispatch(updateStep({ key: 'active_how_it_work', value: true }));
        if (localData && localData?.id) {
            try {
                const data = await GetApiParam("/user/currentTooltipPosition", { "user_id": localData.id });
                if (data?.data?.data) {
                    if ((data.data?.data?.loan_step_number >= 6 && data.data?.data?.loan_step_number <= 11) && isLoan) {
                        setTolPosition(data.data?.data?.loan_step_number ? data.data.data.loan_step_number : 0)
                        setLoading(true)
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    } else if ((data.data?.data?.parcel_step_number >= 6 && data.data?.data?.parcel_step_number <= 8) && isParcel) {
                        setTolPosition(data.data?.data?.parcel_step_number ? data.data.data.parcel_step_number : 0)
                        setLoading(true)
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    }
                    else if ((data.data?.data?.sale_step_number >= 6 && data.data?.data?.sale_step_number <= 7) && isSale) {
                        setLoading(true)
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                        setTolPosition(data.data?.data?.sale_step_number ? data.data.data.sale_step_number : 0)
                    }
                    else if ((data.data?.data?.re_step_number >= 6 && data.data?.data?.re_step_number <= 9) && isFraction) {
                        setTolPosition(data.data?.data?.re_step_number ? data.data.data.re_step_number : 0)
                        setLoading(true)
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    }
                    else if ((data.data?.data?.funds_step_number >= 6 && data.data?.data?.funds_step_number <= 7) && isFundFraction) {
                        setTolPosition(data.data?.data?.funds_step_number ? data.data.data.funds_step_number : 0)
                        setLoading(true)
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    }
                }
            } catch (error) {
                console.log('error', error)
            }
        }
    };

    const scrollToPosition = (findId) => {
        const element = document.getElementById(`${findId}`);
        if (element) {
            const offset = 0;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }
    const handleTolPosition = () => {
        setTolPosition(pre => pre + 1)
        setStep(tolPosition + 1)
        if (isLoan)
            dispatch(updateStep({ key: 'loan_step_number', value: tolPosition + 1 }));
        if (isSale)
            dispatch(updateStep({ key: 'sale_step_number', value: tolPosition + 1 }));
        if (isParcel)
            dispatch(updateStep({ key: 'parcel_step_number', value: tolPosition + 1 }));
        if (isFraction)
            dispatch(updateStep({ key: 're_step_number', value: tolPosition + 1 }));
        if (isFundFraction)
            dispatch(updateStep({ key: 'funds_step_number', value: tolPosition + 1 }));

        if ((tolPosition === 11 && isLoan) || (tolPosition == 8 && isParcel) || (tolPosition == 7 && isSale) || (tolPosition == 9 && isFraction) || (tolPosition == 7 && isFundFraction)) {
            setTimeout(() => {
                scrollToPosition("scroll-to-assetdetails");
            }, 200);
        }
    }

    const getDescriptionByName = (name) => {
        let description = ''
        switch (name) {
            case 'Total Loan Amount':
            case 'Total Raise':
                description = "This is the total amount that the asset owner  is looking to raise for his project.";
                break;
            case 'Preferred Interest Rate Range':
                description = "The interest rate asset owner prefers to offer to investors for their investment.";
                break;
            case 'Preferred Loan Duration Range':
                description = `The period over which the loan will be repaid. In this case, the loan duration is ${assetData?.loan_duration_month ? assetData?.loan_duration_month : ""} months.`;
                break;
            case 'Interest Only period':
                description = `During this initial period, only the interest will be paid. For this project, it is the ${assetData?.loan_iop == 1 ? "1st" : assetData?.loan_iop == 2 ? '2nd' : `${assetData?.loan_iop}th`} month.`;
                break;
            case 'Minimum Investment':
                description = isParcel ? "The minimum investment denotes the lowest price of a parcel, each parcel might have different purchase price" : "The least amount of money you can invest in this project.";
                break;
            case 'Payment Date':
                description = `The Equated Monthly Installment (EMI) payments will be made on the ${assetData?.loan_repay_day == 1 ? "1st" : assetData?.loan_repay_day == 2 ? '2nd' : `${assetData?.loan_repay_day}th`} of each month.`
                break;
            case 'Sale Price':
                description = "Total Price of the Art.";
                break;
            case 'Number of Parcels':
                description = "Each parcel is a unique section of the Art work, the number of parcels denote the number of sections available for sale.";
                break;
            case 'Deposit Amount':
                description = "The least amount of money you have to deposit to make offer on the asset.";
                break;
            case 'Total Project Value':
                description = "This is the size of the project.";
                break;
            case 'Project Duration':
                description = "This is the max duration of the asset for the asset owner want to raise the money.";
                break;
        }
        return description
    }

    return (
        <>
            <Box className="pd-sidebar-priceOverview" id="pd-sidebar-priceoverview" pt={0.8} pb={1.5} display={'flex'} flexWrap={'wrap'}>
                <Divider />
                {
                    Object.keys(prices).map((key, index) => {
                        return (
                            <Fragment key={index}>
                                {((tolPosition == 6 && key == "Total Loan Amount") || (tolPosition == 7 && key == "Preferred Interest Rate Range") || (tolPosition == 8 && key == "Preferred Loan Duration Range") || (tolPosition == 9 && key == "Interest Only period") || (tolPosition == 10 && key == "Minimum Investment") || (tolPosition == 11 && key == "Payment Date")) && isLoan || ((tolPosition == 6 && key == "Sale Price") || (tolPosition == 7 && key == "Number of Parcels") || (tolPosition == 8 && key == "Minimum Investment")) && isParcel || ((tolPosition == 6 && key == "Sale Price") || (tolPosition == 7 && key == "Deposit Amount")) && isSale || ((tolPosition == 6 && key == "Total Project Value") || (tolPosition == 7 && key == "Total Raise") || (tolPosition == 8 && key == "Project Duration" || tolPosition == 9 && key == "Payment Date")) && isFraction || ((tolPosition == 6 && key == "Total Raise") || (tolPosition == 7 && key == "Project Duration")) && isFundFraction ?
                                    <Box className="priceOverview" sx={{ zIndex: 100 }} mt={1.2} pl={1} minWidth={'50%'}>
                                        <DropdownBox render={
                                            <Box style={{
                                                backgroundColor: "#06487E",
                                                color: "white", borderRadius: "8px", zIndex: "3",
                                                padding: '4px'
                                            }}>
                                                <Typography className="title font-12 text-blueGray-400 text-capitalize" mt={0.3}>{key}</Typography>
                                                <Typography className={isXs ? "price font-18" : "price font-22"}>{prices[key]}</Typography>
                                            </Box>
                                        }
                                            setState={handleTolPosition} description={getDescriptionByName(key)}
                                        />
                                    </Box>
                                    :
                                    <Box className="priceOverview" pl={1} minWidth={'50%'} mt={1.2}>
                                        <Typography className="title font-12 text-blueGray-400 text-capitalize" mt={0.3}>{key}</Typography>
                                        <Typography className={isXs ? "price font-18" : "price font-22"}>{prices[key]}</Typography>
                                    </Box>
                                }
                            </Fragment>
                        )
                    })
                }
                {loading &&
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 99 }}
                        open={loading}
                    >
                    </Backdrop>
                }
            </Box>
            {(isFraction || isFundFraction) ?
                <CapitalInfo capitalInfo={capitalInfo} />
                : null
            }
            {!isOwner ?
                <InvestorButtons listingType={listingType} btnTitle={btnTitle} assetId={assetId} isOfferAccepted={isOfferAccepted} isOnboard={isOnboard} DataFromInvestor={handleDataFromChild} />
                : null}
        </>
    )
}

PriceOverview.propTypes = {
    prices: PropTypes.shape({
        any: PropTypes.any
    }),
}
