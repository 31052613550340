import React, { useState } from "react";
import { Modal, Button, TextField, Box, Backdrop, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./EmailForm.css";

const EmailForm = ({ onSend, showModal, handleClose }) => {
  const [to, setTo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    to: "",
    firstName: "",
    lastName: "",
    subject: "",
    message: "",
  });

  /**
   * Style for the Modal
   */
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    borderRadius: "24px",
    width: "900px",
    padding: " 32px 66px 40px 66px",
    background: "var(--blueGray-900)",

    // Media query for small devices
    "@media (max-width: 600px)": {
      width: "350px", // Set a different height for small screens
    },
    // Additional media queries for other screen sizes if needed
    "@media (min-width: 601px) and (max-width: 900px)": {
      // Custom styles for devices between 601px and 900px width
      width: "580px",
      maxHeight: "460px",
      overflow: "auto",
    },

    "@media (min-width: 901px) and (max-width: 1200px)": {
      // Custom styles for devices between 901px and 1200px width
      width: "750px",
      maxHeight: "460px",
      overflow: "auto",
    },

    // Specify the maximum width for large screens
    "@media (min-width: 1201px)": {
      maxWidth: "1043px",
      maxHeight: "460px",
      overflow: "auto",
    },
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!isEmailValid(to)) {
      newErrors.to = "Invalid email address";
      valid = false;
    }

    if (!firstName.trim()) {
      newErrors.firstName = "First Name is required";
      valid = false;
    }

    if (!lastName.trim()) {
      newErrors.lastName = "Last Name is required";
      valid = false;
    }

    if (!subject.trim()) {
      newErrors.subject = "Subject is required";
      valid = false;
    }

    if (!message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };


  const handleSend = () => {
    if (validateForm()) {
      const emailData = {
        to,
        subject,
        message,
        firstName,
        lastName,
      };

      setLoading(true);

      // Simulate API call
      onSend(emailData)
        .then(() => {
          handleClose();
          setErrors({
            to: "",
            subject: "",
            message: "",
            firstName: "",
            lastName: "",
          });
        })
        .catch(() => {
          setLoading(false);
          // Handle API error response if needed
        });
    }
  };

  return (
    <Box>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="contact-popup-main"

      >
        <Box>
          <Box sx={style} id="email-scroller-wrapper" className={loading && `overflowHidden`}>
            <Box className="close-icon">
              <CloseIcon onClick={handleClose} />
            </Box>
            <Box>
              <h2 id="email-form-title">Compose Email</h2>
              <TextField
                id="to"
                label="Email"
                placeholder="Enter your Email"
                value={to}
                onChange={(e) => {
                  setTo(e.target.value);
                  setErrors({ ...errors, to: "" }); // Clear error message for Email
                }}
                error={!!errors.to}
                helperText={errors.to}
                fullWidth
                variant="outlined"
                margin="normal"
              />
              <TextField
                id="firstName"
                label="First Name"
                placeholder="Enter your First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value)
                  setErrors({ ...errors, firstName: "" }); // Clear error message for first name
                }}
                error={!!errors.firstName}
                helperText={errors.firstName}
                fullWidth
                variant="outlined"
                margin="normal"
              />
              <TextField
                id="lastName"
                label="Last Name"
                placeholder="Enter your Last Name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                  setErrors({ ...errors, lastName: "" }); // Clear error message for Last Name
                }}
                error={!!errors.lastName}
                helperText={errors.lastName}
                fullWidth
                variant="outlined"
                margin="normal"
              />
              <TextField
                id="subject"
                label="Subject"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                  setErrors({ ...errors, subject: "" }); // Clear error message for Subject
                }}
                error={!!errors.subject}
                helperText={errors.subject}
                fullWidth
                variant="outlined"
                margin="normal"
              />
              <TextField
                id="message"
                label="Message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value)
                  setErrors({ ...errors, message: "" }); // Clear error message for Message
                }}
                error={!!errors.message}
                helperText={errors.message}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px",
              }}
            >
              <Button
                variant="outline"
                className="btn-rounded btn-golden"
                onClick={handleClose}
                style={{ marginRight: "10px" }}
              >
                Close
              </Button>
              <Box className="send-btn">
                <Button
                  variant="contained"
                  onClick={handleSend}
                  className="btn-rounded"
                // disabled={loading}
                >
                  Send
                </Button>
              </Box>
            </Box>
          </Box>
          {loading &&
            <Backdrop
              sx={{ color: '#fff', zIndex: 1000, }}
              open={loading}
            >
              <CircularProgress color="primary" />
            </Backdrop>
          }
        </Box>
      </Modal>

    </Box>
  );
};

export default EmailForm;
