import React, { useEffect, useState, useMemo } from "react";
import { Box, Grid, Typography, Chip, Button } from "@mui/material";
import ServiceProviderSearch from "../Components/ServiceProviderSearch/ServiceProviderSearch";
import { GetApi, GetApiParam, postApi } from "../../Api/Api";
import Filter from "../Components/Filters/Filter";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import RadioUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { CheckedRadioButton } from "../../Components/Images/Images";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import Bookmarks from "@mui/icons-material/Bookmarks";
import "./BrowserRequest.css";
import { Link, useNavigate } from "react-router-dom";

const BrowserRequest = () => {
  const [categoryData, setCategoryData] = useState(null);
  const [projectServiceDetails, setProjectServiceDetails] = useState([]);
  const [value, setValue] = React.useState(0); // Setting default value to 0
  const [expandedStates, setExpandedStates] = useState([]);

  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : "";

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const serviceProviderData = async () => {
    try {
      const payload = {
        status: 1,
      };
      const projectRes = await GetApiParam(
        "/services/sp_projectRequirement",
        payload
      );
      setProjectServiceDetails(projectRes?.data?.data || []);

      console.log("projectRes....ori", projectRes?.data?.data);
      // Initialize expanded state for each card
      setExpandedStates(new Array(projectRes?.data?.data.length).fill(false));
    } catch (error) {
      console.log(error);
    }
  };
  const checkBookmark = async () => {
    try {
      const projectRes = await GetApi(`/services/getBookmarkProjects`);
      const localProjectRes = projectRes?.data?.data || [];

      // Extract projectDetails and setProjectServiceDetails
      localProjectRes.forEach((item) => {
        const projectDetails = item.projectDetails;
        if (projectDetails) {
          setProjectServiceDetails((prevState) => [
            ...prevState,
            projectDetails,
          ]);
        }
      });

      // Initialize expanded state for each card
      setExpandedStates(new Array(localProjectRes.length).fill(false));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const categoryRes = await GetApi("/services/spCategory");
        setCategoryData(categoryRes?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    serviceProviderData();
    checkBookmark();
    fetchData();
  }, []);

  /**
   * Filtering Data According to the Category that matches the name and value.
   */
  const filteredData = useMemo(() => {
    if (!categoryData || !projectServiceDetails.length) return [];

    const selectedCategory = categoryData[value]?.title;
    return projectServiceDetails.filter(
      (serviceVal) => serviceVal.categoryDetails.title === selectedCategory
    );
  }, [categoryData, projectServiceDetails, value]);

  const handleViewMore = (ind) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = true;
      return newStates;
    });
  };

  const handleViewLess = (ind) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = false;
      return newStates;
    });
  };

  const handleSeeMore = (details) => {
    navigate("/service-provider/view-single-request", {
      state: { prevStepDetails: details },
    });
  };

  /**
   *
   * @param {*} item
   */
  const handleClickBookmark = async (item) => {
    const payloadData = {
      project_id: item.id,
      user_id: localData.id,
      is_bookmark: item.is_bookmark ? 0 : 1,
    };

    if (localData.id) {
      const bookmarkResponce = await postApi(
        "/services/isBookmark",
        payloadData
      );
      if (bookmarkResponce?.data?.code) {
        checkBookmark();
      }
    }
  };

  return (
    // <Box className="container">
    <Box className="brower-request-container">
      <Box className="find-profile-page">
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={4}
          maxWidth={"660px"}
        >
          <Typography className="font-64">
            Find the best job for your expertise
          </Typography>
          <Typography className="font-18">
            Browse jobs posted on RACE, or jump right in and create a free
            profile to find the work that you love to do.
          </Typography>
        </Box>
        <Box>
          <Box mt={3}>
            <ServiceProviderSearch
              categoryData={categoryData}
              showSearchBar={false}
            />
          </Box>
        </Box>
      </Box>
      <Box className="brower-request-main">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3} md={3}>
              <Box className="brower-request-left">
                <Typography className="title">Types of skillsets</Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    orientation="vertical"
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Any Types"
                      {...a11yProps(0)}
                      icon={
                        value === 0 ? (
                          <img src={CheckedRadioButton} />
                        ) : (
                          <RadioUncheckedIcon />
                        )
                      }
                      iconPosition="start"
                    />
                    {categoryData &&
                      categoryData.map((categoryVal, index) => (
                        <Tab
                          key={index}
                          label={
                            categoryVal.title ===
                            "Technology Developers & Software Services"
                              ? "Development & IT"
                              : categoryVal.title
                          }
                          {...a11yProps(index + 1)}
                          icon={
                            value === index + 1 ? (
                              <img src={CheckedRadioButton} />
                            ) : (
                              <RadioUncheckedIcon />
                            )
                          }
                          iconPosition="start"
                        />
                      ))}
                  </Tabs>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={9} md={9}>
              <Box className="brower-request-right">
                <Box mb={2} className="filter-container">
                  <Filter
                    title={"Results"}
                    totalServices={
                      value === 0
                        ? projectServiceDetails && projectServiceDetails?.length
                        : filteredData?.length
                    }
                  />
                </Box>
                <CustomTabPanel value={value} index={0}>
                  {projectServiceDetails?.length ? (
                    <Grid container spacing={2}>
                      {projectServiceDetails?.map((serviceVal, ind) => (
                        <Grid item xs={6} key={ind}>
                          <Box className="card-box">
                            <Box className="top-header">
                              <Box className="top-left">
                                <Box className="pc-chip-wrep-browse">
                                  <Chip className="pc-chip" label={"New"} />
                                </Box>
                                <Box className="top-sub-left">
                                  <Typography className="font-16">
                                    {serviceVal?.project_title}
                                  </Typography>
                                  <Typography className="font-12">
                                    Fixed-price | Posted 21 hours ago
                                  </Typography>
                                </Box>
                              </Box>
                              {serviceVal && serviceVal?.is_bookmark ? (
                                <Box className="top-right">
                                  <Bookmarks
                                    className="bookmark-icon"
                                    onClick={() =>
                                      handleClickBookmark(serviceVal)
                                    }
                                  />
                                </Box>
                              ) : (
                                <Box className="top-right">
                                  <BookmarkBorderIcon
                                    className="bookmark-icon"
                                    onClick={() =>
                                      handleClickBookmark(serviceVal)
                                    }
                                  />
                                </Box>
                              )}
                            </Box>
                            <Box className="card-body">
                              <Box className="card-body-top">
                                <Box className="left-section">
                                  <Typography className="title font-12">
                                    {serviceVal?.budget === "Project Budget"
                                      ? "Fixed-price"
                                      : serviceVal?.budget}
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="sub-title font-16"
                                  >
                                    ${serviceVal?.budget_from}
                                  </Typography>
                                </Box>
                                <Box className="right-section">
                                  <Typography
                                    component="h4"
                                    className="title font-12"
                                  >
                                    Experience Level
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="sub-title font-16"
                                  >
                                    {serviceVal?.level_of_experience}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box className="card-body-discription">
                                {expandedStates[ind] ? (
                                  <>
                                    <Typography
                                      component="p"
                                      className="title font-14"
                                    >
                                      {serviceVal?.describe_services}
                                    </Typography>
                                    <Link onClick={() => handleViewLess(ind)}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color: "#398CD1",
                                          fontSize: "14px",
                                          fontWeight: 700,
                                          textDecoration: "underline",
                                          textDecorationColor: "#398CD1",
                                        }}
                                        mt={1}
                                      >
                                        View Less
                                      </Typography>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      component="p"
                                      className="title font-14"
                                      sx={{
                                        mt: "12px",
                                        color: "#FFFFFF",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "2",
                                        WebkitBoxOrient: "vertical",
                                      }}
                                    >
                                      {serviceVal?.describe_services}
                                    </Typography>
                                    <Link onClick={() => handleViewMore(ind)}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color: "#398CD1",
                                          fontSize: "14px",
                                          fontWeight: 700,
                                          textDecoration: "underline",
                                          textDecorationColor: "#398CD1",
                                        }}
                                        mt={1}
                                      >
                                        View More
                                      </Typography>
                                    </Link>
                                  </>
                                )}
                              </Box>

                              <Box className="button-list">
                                {serviceVal?.skillData?.map(
                                  (skillVal, index) => (
                                    <Button key={index}>
                                      {skillVal.skill_name}
                                    </Button>
                                  )
                                )}
                              </Box>
                              <Box className="see-more-btn">
                                <Button
                                  variant="contained"
                                  startIcon={<AddIcon />}
                                  onClick={() => handleSeeMore(serviceVal)}
                                >
                                  See more
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    "No Data Found"
                  )}
                </CustomTabPanel>

                {categoryData &&
                  categoryData?.map((categoryVal, index) => (
                    <CustomTabPanel value={value} index={index + 1} key={index}>
                      {filteredData?.length ? (
                        <Grid container spacing={2}>
                          {filteredData?.map((serviceVal, ind) => (
                            <Grid item xs={6} key={ind}>
                              <Box className="card-box">
                                <Box className="top-header">
                                  <Box className="top-left">
                                    <Box className="pc-chip-wrep-browse">
                                      <Chip className="pc-chip" label={"New"} />
                                    </Box>
                                    <Box className="top-sub-left">
                                      <Typography className="font-16">
                                        {serviceVal?.project_title}
                                      </Typography>
                                      <Typography className="font-12">
                                        Fixed-price | Posted 21 hours ago
                                      </Typography>
                                    </Box>
                                  </Box>
                                  {serviceVal && serviceVal?.status ? (
                                    <Box className="top-right">
                                      <Bookmarks
                                        className="bookmark-icon"
                                        onClick={() =>
                                          handleClickBookmark(serviceVal)
                                        }
                                      />
                                    </Box>
                                  ) : (
                                    <Box className="top-right">
                                      <BookmarkBorderIcon
                                        className="bookmark-icon"
                                        onClick={() =>
                                          handleClickBookmark(serviceVal)
                                        }
                                      />
                                    </Box>
                                  )}
                                </Box>
                                <Box className="card-body">
                                  <Box className="card-body-top">
                                    <Box className="left-section">
                                      <Typography className="title font-12">
                                        {serviceVal?.budget === "Project Budget"
                                          ? "Fixed-price"
                                          : serviceVal?.budget}
                                      </Typography>
                                      <Typography
                                        component="p"
                                        className="sub-title font-16"
                                      >
                                        ${serviceVal?.budget_from}
                                      </Typography>
                                    </Box>
                                    <Box className="right-section">
                                      <Typography
                                        component="h4"
                                        className="title font-12"
                                      >
                                        Experience Level
                                      </Typography>
                                      <Typography
                                        component="p"
                                        className="sub-title font-16"
                                      >
                                        {serviceVal?.level_of_experience}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box className="card-body-discription">
                                    <Typography
                                      component="p"
                                      className="title font-14"
                                    >
                                      {serviceVal?.describe_services}
                                    </Typography>
                                  </Box>
                                  <Box className="button-list">
                                    {serviceVal?.skillData?.map(
                                      (skillVal, index) => (
                                        <Button key={index}>
                                          {skillVal.skill_name}
                                        </Button>
                                      )
                                    )}
                                  </Box>
                                  <Box className="see-more-btn">
                                    <Button
                                      variant="contained"
                                      startIcon={<AddIcon />}
                                      onClick={() => handleSeeMore(serviceVal)}
                                    >
                                      See more
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Grid container spacing={2}>
                          <Grid md={12}>
                            <Typography ml={3}>No Data Found</Typography>
                          </Grid>
                        </Grid>
                      )}
                    </CustomTabPanel>
                  ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
    // </Box>
  );
};

export default BrowserRequest;
