import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Box, Chip, Grid, Typography } from "@mui/material";
import { BorderAll, KeyboardReturn, MonetizationOn, PhotoSizeSelectLarge, Search } from "@mui/icons-material";
import { HomeImage, howItWorks, mobileImage } from "../../Components/Images/Images";
import CategoryCard from "../Components/CategoryCard/CategoryCard";
import ServiceCard from "../Components/ServiceCard/ServiceCard";
import ServicesSearch from "../Components/ServicesSearch/ServicesSearch";
import SearchCompo from "../Components/SearchCompo/SearchCompo";
import "./ServiceCatalog.css";
import { GetApi } from "../../Api/Api";

const ServiceCatalog = () => {

  const [categoryData, setCategoryData] = useState(null);
  const [servicesData, setServicesData] = useState([])

  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/services/spCategory");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }

    // Api to get categoryData
    async function getServicesData() {
      try {
        const res = await GetApi("/services/getServices");
        setServicesData(res?.data?.data?.length ? res.data.data : []);
      } catch (error) {
        console.log(error);
      }
    }
    getServicesData();
    getCategoryData()
  }, [])

  const categories = [
    { Icon: BorderAll, Heading: 'Technology Developers & Software Services', SubHeading: '200 skills', Url: 'technology-software' },
    { Icon: BorderAll, Heading: 'Accounting & Auditing', SubHeading: '200 skills', Url: 'accounting-auditing' },
    { Icon: BorderAll, Heading: 'Legal & Tax', SubHeading: '200 skills', Url: 'research' },
    { Icon: KeyboardReturn, Heading: 'Insurance', SubHeading: '200 skills', Url: 'insurance' },
    { Icon: PhotoSizeSelectLarge, Heading: 'Escrow & Custodian', SubHeading: '200 skills', Url: 'escrow-custodian' },
    { Icon: PhotoSizeSelectLarge, Heading: 'Research', SubHeading: '200 skills', Url: 'research' },
    { Icon: PhotoSizeSelectLarge, Heading: 'Copywriting', SubHeading: '200 skills', Url: 'copywriting' },
    { Icon: MonetizationOn, Heading: 'Marketing & Digital Services', SubHeading: '200 skills', Url: 'marketing-digital' },
  ];

  const serviceData = [
    { title: 'Social Media Strategy', image: HomeImage },
    { title: 'Social Media Ad Setup & Management', image: HomeImage },
    { title: 'Social Media Design', image: HomeImage },
    { title: 'Social Media Ad Setup & Management', image: HomeImage },
  ]

  const chipData = [
    { label: 'Real Estate Insurance' },
    { label: 'Auditing' },
    { label: 'Digital Services' },
    { label: 'Custodian' },
    // Add more items as needed
  ];
  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  })
  return (
    <Box>
      <Box className="home-exploreBanner-section ">
        <Box className="container">
          <Box mb={3} px={'10px'}>
            <ServicesSearch pageTitle={'Service Catalog'} categoryData={categoryData} />
          </Box>
        </Box>

        {/* Expert Services */}
        <Box className='service-marketplace'>
          <Box className="container" my={8} >
            <Box className='expert-services-bg'>
              <Grid container spacing={3}>
                <Grid item md={5} sm={12} xs={12}>
                  <Box className="left-img" justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end', lg: 'flex-end' }}>
                    <Avatar variant="square" alt="Image" src={mobileImage} sx={{ width: 'auto', maxWidth: { md: '412px', lg: '412px', xs: '280px' }, height: 'auto' }}></Avatar>
                  </Box>
                </Grid>
                <Grid item md={7} sm={12} xs={12}>
                  <Box className='expert-services'>
                    <Typography className="font-18">Service Catalog</Typography>
                    <Typography className="font-45" paddingRight={'20%'}>Clear scope.<br />
                      Secure transactions. Quality vendors.</Typography>
                    <Typography className="font-22" paddingRight={'20%'} minHeight={'112px'} mb={1}>Need services for your project? We got you. Browse and search for service providers in just a few clicks.</Typography>
                    <Box paddingRight={'20%'} width={'100%'}>
                      <SearchCompo pageTitle={"Service Catalog"} />
                    </Box>
                    <Box paddingRight={'10%'} mt={1} display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                      <Typography className="font-14" style={{ fontWeight: '500' }}>Popular:</Typography>
                      {chipData.map((chip, index) => (
                        <Box key={index} className='popular-chip' ml={1} my={'3px'} style={{ cursor: 'pointer' }}>
                          <Chip label={chip.label} icon={<Search />} />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        {/* Browse Services by Category */}
        <Box className='categoryCard' py={8}>
          <Box className="container">
            <Box>
              <Typography className="font-45 text-white" padding={'24px'}>Browse by category</Typography>
            </Box>
            <Grid container spacing={2}>
              {categoryData?.map((category, index) => (
                <Grid item key={index} md={3} sm={6} xs={12}>
                  <CategoryCard
                    key={index} // It's good practice to provide a unique key for each element in the array
                    Icon={category.categoryIcon.path}
                    Heading={category.title}
                    SubHeading={`${category.totalSkill} Skill`}
                    url={category.id}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        {/*How it works*/}
        <Box className="rc-easyProcess-section" pt={{ xs: 4, md: 8 }} pb={{ xs: 6, md: 14 }}>
          <Box className="container">
            <Box className="section-heading" textAlign={"center"} pb={7} maxWidth={800} margin={'0 auto'}>
              <Typography className="title text-white" variant="h3">How it works</Typography>
              <Typography className="subtitle  text-white">We created a streamlined, user-friendly process that makes running an investment project easy and affordable.</Typography>
            </Box>
            <Box className="rc-easyProcess-wrap">
              <Avatar variant="square" alt="Image" src={howItWorks} sx={{ width: '100%', height: 'auto', maxWidth: '1000px', margin: '0 auto' }}></Avatar>
            </Box>
          </Box>
        </Box>

        {/* List of service provider */}
        <Box className='service-marketplace' mt={2}>
          <Box className="container">
            <Typography className="font-45 text-white" padding={'24px'}>In-Demand Services</Typography>
            <Grid container spacing={1}>
              {servicesData?.map((val, ind) => {
                return (
                  <Grid key={ind} item xs={12} sm={6} md={3} lg={3}>
                    <ServiceCard image={val?.coverphoto?.path} title={val?.services_title} />
                  </Grid>
                )
              })}
            </Grid >
          </Box>
        </Box>

        {/* List of service provider */}
        <Box className='service-marketplace' mt={4}>
          <Box className="container">
            <Typography className="font-45 text-white" padding={'24px'}>Newly Listed Service Providers</Typography>
            <Grid container spacing={1}>
              {servicesData?.map((val, ind) => {
                return (
                  <Grid key={ind} item xs={12} sm={6} md={3} lg={3}>
                    <ServiceCard image={val?.coverphoto?.path} title={val?.services_title} />
                  </Grid>
                )
              })}
            </Grid >
          </Box>
        </Box>

        {/* List of service provider */}
        <Box className='service-marketplace' mt={4}>
          <Box className="container">
            <Typography className="font-45 text-white" >Browse all categories</Typography>
            <ServicesSearch pageTitle={'Service Catalog'} categoryData={categoryData} footer={"Catalog Footer"} />
          </Box>
        </Box>
      </Box>
    </Box >
  );
};
export default ServiceCatalog;
