import React from 'react'
import '../ServicesSearch/ServicesSearch.css'
import { Box, IconButton, Stack, TextField, Typography, styled } from '@mui/material'
import { Search } from '@mui/icons-material';
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router';

const SearchCompo = ({ url , pageTitle }) => {
    const navigate = useNavigate()
    const SearchTextField = styled(TextField)({
        maxWidth: '662px',
        height: '48px',
        '& .MuiOutlinedInput-root': {
            '& input': {
                padding: '12px 24px',
            },
            '& fieldset': {
                borderRadius: '48px',
            },
            backgroundColor: '#ffffff',
            color:"#ffffff",
            borderRadius: '48px',
            'MuiInputBase-input' :{
                color: "#fff",
              }
        },
    });

    const SearchButton = styled(IconButton)({
        '& .MuiIconButton-root': {
            padding: '10px',
            backgroundColor: '#398CD1',
            borderRadius: '48px',
            '& .MuiSvgIcon-root': {
                color: '#ffffff',
                height: '12px',
                width: '12px',
            }
        },

        // disable hover
        '&:hover': {
            backgroundColor: '#398CD1',
            '& .MuiSvgIcon-root': {
                color: '#ffffff',
            }
        }


    });
    const handleClick = () => {
        if (url)
            navigate(url)
    }
    return (
        <Box className= {pageTitle === "Service Catalog" ? "service-search-bar Service Catalog " : "service-search-bar find-service-provider" } width={'100%'}>
            <SearchTextField variant='outlined' placeholder="Search for services"
                InputProps={{
                    endAdornment: (
                        <SearchButton sx={{ p: '10px' }} className='bg-golden' aria-label="search" onClick={handleClick} >
                            <Search sx={{ color: '#000', height: '12px', width: '12px' }} />
                        </SearchButton>
                    ),
                }}
            />
        </Box>
    )
}

export default SearchCompo