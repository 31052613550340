import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, IconButton, } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import SliderCardProser from "../../../Components/Common/SliderCardProser";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import "./Components/Questionnaire.css";
import { CATEGORY_TYPE_ID } from "../../../constants";
import { useSnackbar } from "../../../Contexts/SnackbarContext";

export default function SetSaleDetails(props) {
  const { showSnackbar } = useSnackbar()

  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // Get current proposal and progress data from the parent component
  let propData = getProposalData();
  let currentProgress = getProgress();

  // Initialize state for the sale price with a default value of 0
  const [salePrice, setSalePrice] = useState(propData?.sale_price ? propData?.sale_price : 0);
  const [estimatedError, setEstimatedError] = useState(propData?.sale_price ? propData?.sale_price : 0);

  // state to hold max limit for project price
  const [isMaxLimitExceeded, setIsMaxLimitExceeded] = useState(false);
  const [maxLimit, setMaxLimit] = useState(0);

  /**
   * Function to handle change in sale price.
   * @param {string} type - Type of change, either 'inc' for increment or 'dec' for decrement.
   */
  const changeRaise = (type) => {
    if (type === "inc") {
      // Increase sale price by $500
      setSalePrice((prevCount) => prevCount ? (parseFloat(prevCount) + 500) : (500));
    } else {
      // Decrease sale price by $500, but ensure it doesn't go below 0
      setSalePrice((prevCount) => parseFloat(prevCount) >= 500 && prevCount ? parseFloat(prevCount) - 500 : prevCount);
    }
  };

  /**
   * Function to handle the click event for the "Next" button.
   * Updates 'propData' with the sale price and proceeds to the next step.
   */
  const handleClick = () => {
    propData.sale_price = salePrice;
    updateProposalData(propData);
    handleNext();
  };

  /**
   * Function to handle the click event for the "Save and Exit" button.
   * Updates 'propData' with the sale price and saves the proposal.
   */
  const SaveAndExit = () => {
    propData.sale_price = salePrice;
    updateProposalData(propData);
    saveProposal();
  };

  useEffect(() => {
    if (salePrice > 0 && salePrice > parseFloat(propData?.watchInformation?.watch_estimated) && propData?.category_id === CATEGORY_TYPE_ID.WATCH) {
      setEstimatedError(true)
    } else {
      setEstimatedError(false);
    }
  }, [salePrice])

  useEffect(() => {
    if (salePrice > 0 && !estimatedError) {
      // checking for max range
      let checkMaxLimitExceeded = true, checkMaxLimit = 0;
      let stackAmtData = propData?.stackAmtData;
      let projectAmt = parseFloat(salePrice)
      if (stackAmtData?.length) {
        stackAmtData?.forEach((dataItem) => {
          checkMaxLimit = dataItem?.max_amount
          if (dataItem?.min_amount <= projectAmt && projectAmt <= dataItem?.max_amount) {
            checkMaxLimitExceeded = false
          }
        })
        setIsMaxLimitExceeded(checkMaxLimitExceeded)
        setMaxLimit(checkMaxLimit)
      }
      // check for max range exceeded
      if (checkMaxLimitExceeded && checkMaxLimit > 0) {
        showSnackbar(`You cannot create a project that exceeds the set range configuration $${checkMaxLimit}`, 'error');
      } else if (checkMaxLimitExceeded && checkMaxLimit == 0) {
        showSnackbar(`Range configuration is not set for the asset type`, 'error');
      }
    }
  }, [salePrice])

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left">
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  {propData?.selectedListingType === "loan"
                    ? "Now, set your loan details"
                    : "Now, set your price"}
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right  quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button
                    onClick={SaveAndExit}
                    className="btn-rounded btn-text-white btn-blue-600"
                    disabled={
                      (salePrice != "" && Boolean(salePrice > 0) && !isMaxLimitExceeded) ? false : true
                    }
                  >
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box sx={{ padding: "inherit" }} className="qsn-middle-content">
                <Box className="questionnaire5" sx={{ pt: 4, mb: 5 }}>
                  <Box className="CategoryBox">
                    <Grid container direction="row">
                      {propData?.selectedListingType === "loan" ? <>
                        <Grid item xs={1} sm={1} md={2} lg={2.5}></Grid>
                        <Grid item xs={10} sm={10} md={8} lg={7} className="priceDetailsImage">
                          <SliderCardProser
                            height={"100%"}
                            image={(propData?.asset_coverphoto_view?.startsWith("proposer/uploads/")) ? `${process.env.REACT_APP_IMAGE_URL}${propData?.asset_coverphoto_view}` : propData?.asset_coverphoto_view}
                            title={propData?.asset_title}
                          />
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2.5}></Grid></>
                        :
                        <Grid item xs={12} sm={12} md={12} className="priceDetailsImage">
                          <SliderCardProser
                            height={"80.76%"}
                            image={
                              propData?.selectedCategory === "nft" ||
                                propData?.asset_coverphoto_view?.startsWith(
                                  "proposer/uploads/"
                                )
                                ? `${process.env.REACT_APP_IMAGE_URL}${propData?.asset_coverphoto_view}`
                                : propData?.asset_coverphoto_view
                            }
                            title={propData?.asset_title}
                          />
                        </Grid>
                      }
                    </Grid>
                  </Box>
                </Box>
                <Box className="NS-sale-details">
                  <Box className="NS-sale-details-icon">
                    <IconButton
                      onClick={() => {
                        changeRaise("dec");
                      }}
                      className="NS-sale-details-icon-btn"
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    <TextField
                      id="outlined-number"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={salePrice}
                      size="small"
                      helperText={"Sale Price"}
                      onChange={(e) => setSalePrice(parseFloat(e.target.value))}
                      InputProps={{
                        inputComponent: NumericFormatCustomDollar,
                      }}
                      onKeyDown={(event) => {
                        if (event?.key === '-' || event?.key === '+') {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Box>
                  <Box className="NS-sale-details-icon">
                    <IconButton
                      onClick={() => {
                        changeRaise("inc");
                      }}
                      className="NS-sale-details-icon-btn"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center', paddingTop: '10px' }}>{Boolean(salePrice === '') ? "Please enter sale price." : estimatedError ? "Sale price can not be greater than estimated value." : isMaxLimitExceeded ? `You cannot create the project above $${maxLimit}` : ""}
                </Typography>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  mt={3}
                >
                  <Box textAlign={"left"} className="quests6-btn-box">
                    <Button
                      className="btn-rounded back-btn"
                      onClick={handleback}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className="quests-btn-box">
                    <Button
                      disabled={
                        (salePrice != "" && Boolean(salePrice > 0) && !isMaxLimitExceeded) ? false : true
                      }
                      onClick={handleClick}
                      endIcon={<KeyboardArrowRightIcon />}
                      className="btn-rounded btn-text-white btn-blue-600"
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
