// getMinAssetApproved.js

import { readContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { INVESTMENT_TYPE_ID } from "../../constants";

/**
 * Fetch user wallet balance
 * @param {Number} listing_id asset listing id
 * @param {Number} investment_type_id asset investment type id
 * @returns user wallet balance
 */
async function getRepaymentConfigDetail(listing_id, investment_type_id) {
    try {
        let contract_address = CONTRACTS.WATCH_REPAYMENT_ADDRESS
        if (investment_type_id === INVESTMENT_TYPE_ID.FRACTION) {
            contract_address = CONTRACTS.SPV_DEBT_REPAYMENT_ADDRESS;
        }
        const repaymentConfigDetail = await readContract({
            address: contract_address,
            abi: CONTRACT_ABI[contract_address],
            functionName: "repaymentConfigDetail",
            chainId: 6805,
            args: [
                Number(listing_id) // listing id
            ]
        });
        return repaymentConfigDetail;
    } catch (error) {
        console.error('Error in fetching repayment config:', error);
        throw error; // propagate the error
    }
}

export { getRepaymentConfigDetail };
