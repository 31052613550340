import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useDisconnect } from "wagmi";
import { NavLink, useNavigate } from "react-router-dom";
import { Typography, Box, styled, Drawer, IconButton, List, Divider, Tooltip, Avatar, } from "@mui/material";
import { ChevronLeft, ChevronRight, AttachMoney, Message, Settings, HelpCenter, Logout, } from "@mui/icons-material";
import { Profile } from "../../Components/Images/Images";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DescriptionIcon from '@mui/icons-material/Description';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { logout } from "../../features/auth/authSlice";
import "./Sidebar.css";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  padding: "12px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  padding: "12px",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  [theme.breakpoints.up("xs")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    marginTop: "64px",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: "#032744",
      borderRadius: "0px 16px 16px 0px",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "#032744",
      borderRadius: "0px 16px 16px 0px",
    },
  }),
}));

const Sidebar = ({ handleSidebarChange }) => {
  const navigate = useNavigate();
  const { totalUnreadCount } = useSelector((state) => state.chat);
  let userData = localStorage.getItem('user_data')
  userData = JSON.parse(userData);

  const [open, setOpen] = useState(false);

  // Get the dispatch function from the Redux store
  const dispatch = useDispatch();
  // Get the account and disconnect function from the useAccount and useDisconnect custom hooks
  const account = useAccount();
  const { disconnect } = useDisconnect();

  const handleDrawerOpen = () => {
    setOpen(true);
    handleSidebarChange(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleSidebarChange(false);
  };

  const handleClick = () => {
    navigate("/dummy");
  };

  /**
  * function to handle logout functionality
  */
  const handleLogout = () => {
    if (account.isConnected) {
      //disconnect wallet if connected
      disconnect();
    }
    dispatch(logout());
    navigate("/");

    // Reload the page after a short delay (e.g., 500 milliseconds)
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  // Function to get the first letter of a string
  const getFirstLetter = (str) => {
    return str.charAt(0);
  };

  return (
    <CustomDrawer className="left-sidebar" variant="permanent" open={open}>
      {open ? (
        <Box className="leftSidebar-toggle-wrap">
          <IconButton
            className="leftSidebar-toggle"
            onClick={handleDrawerClose}
          >
            <ChevronLeft />
          </IconButton>
        </Box>
      ) : (
        <Box className="leftSidebar-toggle-wrap">
          <IconButton className="leftSidebar-toggle" onClick={handleDrawerOpen}>
            <ChevronRight />
          </IconButton>
        </Box>
      )}
      <Box className="sidebar-head-div">
        {open ? (
          <>
            {/* <Button
              className="helpCenter-btn btn-rounded btn-yellow-60 btn-large"
              startIcon={<HelpCenter />}
              onClick={handleClick}
            >
              {"Help Center"}
            </Button> */}
            <div className="profile-div">
              {/* <Avatar alt="Remy Sharp" src={Profile} /> */}
              {userData?.persona_picture ?
                <Avatar
                  alt="Token icon"
                  src={
                    userData?.persona_picture
                      ? userData?.persona_picture
                      : null
                  }
                  sx={{ width: 40, height: 40 }}
                /> :
                <Avatar
                  className={"avatar"}
                  alt="Remy Sharp"
                >
                  {getFirstLetter(userData?.first_name ? userData?.first_name : "")}
                  {getFirstLetter(userData?.last_name ? userData?.last_name : "")}
                </Avatar>
              }

              <div className="right-profile">
                <h4>Hi {userData?.first_name ? userData?.first_name : ""} {userData?.last_name ? userData?.last_name : ""} </h4>
                <p>
                  Member ID: {userData?.member_id ? userData?.member_id : ""}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* <Box
              className="helpCenter-btn-closed"
              onClick={handleClick}
              sx={{
                padding: "10px",
                marginTop: "10px",
                width: "40px !important",
              }}
            >
              <HelpCenter />
            </Box> */}
            {userData?.persona_picture ?
              <Avatar
                alt="Token icon"
                src={
                  userData?.persona_picture
                    ? userData?.persona_picture
                    : null
                }
                sx={{ width: 40, height: 40 }}
              /> :
              <Avatar
                className={"avatar"}
                alt="Remy Sharp"
                style={{ backgroundColor: 'white', color: 'black' }}
              >
                {getFirstLetter(userData?.first_name ? userData?.first_name : "")}
                {getFirstLetter(userData?.last_name ? userData?.last_name :
                  "")}
              </Avatar>}
          </>
        )}
      </Box>
      <List>
        <Box className="sidebar-menu">
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="portfolio" exact="true" className={`nav-link`}>
              <Tooltip
                title={open === false ? "My Portfolio" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <ShowChartIcon style={{ marginRight: open ? "10px" : "0px" }} />
                </Box>
              </Tooltip>
              {open && <span>{" "}My Portfolio</span>}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="transactions" exact="true" className={`nav-link`}>
              <Tooltip
                title={open === false ? "My Transactions" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <AttachMoney style={{ marginRight: open ? "10px" : "0px" }} />
                </Box>
              </Tooltip>
              {open && <span>{" "}My Transactions</span>}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="documents" exact="true" className={`nav-link`}>
              <Tooltip
                title={open === false ? "Documents" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <DescriptionIcon style={{ marginRight: open ? "10px" : "0px" }} />
                </Box>
              </Tooltip>
              {open && <span>{" "}Documents</span>}
            </NavLink>
          </Box>

          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="projects" exact="true" className={`nav-link`}>
              <Tooltip
                title={open === false ? "My Projects" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <BookmarksIcon style={{ marginRight: open ? "10px" : "0px" }} />
                </Box>
              </Tooltip>
              {open && <span>{" "}My Projects</span>}
            </NavLink>
          </Box>

          {/* <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="saved-deals" exact="true"className={`nav-link`}>
              <Tooltip
                title={open === false ? "Saved Deals" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <Bookmarks style={{ marginRight: open ? "10px" : "0px" }} />
                </Box>
              </Tooltip>
              {open && "Saved Deals"}
            </NavLink>
          </Box> */}

          {/* <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="chats" exact="true"className={`nav-link`} style={{}}>
              <Tooltip
                title={open === false ? "Messages" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon" style={{}}>
                  <Badge
                    badgeContent={open ? 0 : totalUnreadCount}
                    color="error"
                  >
                    <Message style={{ marginRight: open ? "10px" : "0px" }} />
                  </Badge>
                </Box>
              </Tooltip>
              {open && "Messages"}
              <Badge
                badgeContent={open ? totalUnreadCount : 0}
                color="error"
                sx={{
                  transition: "opacity 0.5s ease-in",
                  opacity: open ? 1 : 0,
                  float: "right",
                  mt: "10px",
                }}
              />
            </NavLink>
          </Box> */}

          <Box className="sidebar-head-div">
            <Divider className="divider" />
            {open && <Box >
              <Typography className="account-text">Account</Typography>
            </Box>}
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="settings" exact="true" className={`nav-link`}>
              <Tooltip
                title={open === false ? "Settings" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <Settings style={{ marginRight: open ? "10px" : "0px" }} />
                </Box>
              </Tooltip>
              {open && <span>{" "}Settings</span>}
            </NavLink>
          </Box>

          <Box className={open ? "menu-item" : "menu-item menu-item-close"} onClick={handleLogout}>
            <NavLink to="/" className={`nav-link`}>
              <Tooltip
                title={open === false ? "Logout" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                  <Logout style={{ marginRight: open ? "10px" : "0px" }} />
                </Box>
              </Tooltip>
              {open && <span>{" "}Logout</span>}
            </NavLink>
          </Box>
        </Box>
      </List>
    </CustomDrawer>
  );
};

export default Sidebar;
