import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Grid, Stack, Typography } from "@mui/material";
import { IconButton, TextField, styled } from "@mui/material";
import "./ViewSingleRequest.css";
import ServicesSearch from "../Components/ServicesSearch/ServicesSearch";
import { GetApi, GetApiParam } from "../../Api/Api";
import { Search } from "@mui/icons-material";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import SkillChip from "../Components/Chips/SkillChip/SkillChip";
import AddIcon from "@mui/icons-material/Add";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RightProjectProfileSection from "../CompanyProfilePage/RightProjectProfileSection";
import { Link, useLocation } from "react-router-dom";



const ViewSingleRequest = () => {
  const [categoryData, setCategoryData] = useState(null);
  const [serviceData, setServicesData] = useState([]);
  const [serviceProvider, getServiceProvider] = useState([]);
  const [previousStepData, setPreviousStepData] = useState([]);
  const [similerProjectType, setSimilerProjectType] = useState([]);
  const [expandedStates, setExpandedStates] = useState([]);

  const location = useLocation();

  const SearchTextField = styled(TextField)({
    maxWidth: "662px",
    height: "48px",
    "& .MuiOutlinedInput-root": {
      "& input": {
        padding: "12px 24px",
      },
      "& fieldset": {
        borderRadius: "48px",
      },
      backgroundColor: "#ffffff",
      color: "#ffffff",
      borderRadius: "48px",
      "MuiInputBase-input": {
        color: "#fff",
      },
    },
  });

  const SearchButton = styled(IconButton)({
    "& .MuiIconButton-root": {
      padding: "10px",
      backgroundColor: "#398CD1",
      borderRadius: "48px",
      "& .MuiSvgIcon-root": {
        color: "#ffffff",
        height: "12px",
        width: "12px",
      },
    },

    // disable hover
    "&:hover": {
      backgroundColor: "#398CD1",
      "& .MuiSvgIcon-root": {
        color: "#ffffff",
      },
    },
  });

  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/services/spCategory");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }

    // Api to get categoryData
    async function getServicesData() {
      try {
        const res = await GetApi("/services/getServices");
        setServicesData(res?.data?.data?.length ? res.data.data : []);
      } catch (error) {
        console.log(error);
      }
    }

    // Api to get categoryData
    async function getServiceProviders() {
      try {
        const res = await GetApi("/services/getServiceProviders");
        getServiceProvider(res?.data?.data?.length ? res.data.data : []);
      } catch (error) {
        console.log(error);
      }
    }

    getServicesData();
    getCategoryData();
    getServiceProviders();
  }, []);

  useEffect(() => {
    async function getSimilerProjects() {
      const payload = {
        sp_category_id: previousStepData.sp_category_id,
        user_id: previousStepData.user_id,
      };
      try {
        const res = await GetApiParam("/services/getSimilarProjects", payload);
        setSimilerProjectType(res?.data?.data?.length ? res.data.data : []);
        // Initialize expanded state for each card
        setExpandedStates(new Array(res?.data?.data?.length).fill(false));
      } catch (error) {
        console.log(error);
      }
    }

    if (previousStepData) {
      getSimilerProjects();
    }
  }, [previousStepData]);

  useEffect(() => {
    if (location.state) {
      setPreviousStepData(location.state.prevStepDetails);
    }
  }, [location]);

  const handleViewMore = (ind) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = true;
      return newStates;
    });
  };

  const handleViewLess = (ind) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = false;
      return newStates;
    });
  };

  return (
    <Box className=" container view-single-request-main">
      <Box className="service-search">
        <Box mt={4} sx={{marginTop:"95px"}}>
          <ServicesSearch categoryData={categoryData} pageTitle={""} />
        </Box>
      </Box>
      <Box className="service-search-item" mt={3}>
        <SearchTextField
          className="service-search-item-black "
          variant="outlined"
          placeholder="Search for services"
          InputProps={{
            endAdornment: (
              <SearchButton
                className="search-button bg-golden"
                aria-label="search"
              >
                <Search
                  style={{ color: "black" }}
                  sx={{ color: "black", height: "12px", width: "12px" }}
                />
              </SearchButton>
            ),
          }}
        />
      </Box>
      <Box className="view-single-request-conatiner">
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Box className="left-container">
              <Box className="top-view">
                <Box className="left-section">
                  <Typography className="title font-28" component="h4">
                    {previousStepData && previousStepData?.project_title}
                  </Typography>
                  <Typography className="sub-title font-14" component="p">
                    Search more Auditing service requests | Posted 1 hours ago
                  </Typography>
                </Box>
                <Box className="book-mark-btn">
                  <BookmarkBorderOutlinedIcon />
                </Box>
              </Box>
              <Box className="disc-box">
                <Typography className="title font-14" component="p">
                  {previousStepData && previousStepData?.describe_services}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box className="categoryCard" mt={3}>
                <Grid container spacing={2}>
                  <Grid item md={4} sm={6} xs={4}>
                    <Box className="category-card">
                      {previousStepData.length && (
                        <img
                          className="Icon"
                          src={`${process.env.REACT_APP_IMAGE_URL}${
                            previousStepData &&
                            previousStepData?.sp_media_tables[0]?.path
                          }`}
                        />
                      )}
                      <Box pt={3}>
                        <Typography className="Heading font-24">
                          ${previousStepData && previousStepData?.budget_to}
                        </Typography>
                        <Typography className="SubHeading  font-18">
                          Fixed-prices
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4} sm={6} xs={4}>
                    <Box className="category-card">
                      {previousStepData.length && (
                        <img
                          className="Icon"
                          src={`${process.env.REACT_APP_IMAGE_URL}${
                            previousStepData &&
                            previousStepData?.sp_media_tables[0]?.path
                          }`}
                        />
                      )}
                      <Box pt={3}>
                        <Typography className="Heading font-24">
                          {previousStepData &&
                            previousStepData?.level_of_experience}
                        </Typography>
                        <Typography className="SubHeading  font-18">
                          Experience Level
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4} sm={6} xs={4}>
                    <Box className="category-card">
                      {previousStepData.length && (
                        <img
                          className="Icon"
                          src={`${process.env.REACT_APP_IMAGE_URL}${
                            previousStepData &&
                            previousStepData?.sp_media_tables[0]?.path
                          }`}
                        />
                      )}
                      <Box pt={3}>
                        <Typography className="Heading font-24">
                          Ongoing
                        </Typography>
                        <Typography className="SubHeading  font-18">
                          Project Type
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className="comapany-profile-skills" mt={3}>
              <Typography component="h4" className="title font-18">
                Skills & Expertise
              </Typography>

              <Box mt={2}>
                <Box className="skill-section">
                  <Grid container spacing={2} mt={1}>
                    {previousStepData?.skillData?.map((item, index) => {
                      return (
                        <Grid xs="auto" ml={2}>
                          {" "}
                          <SkillChip key={index} label={item.skill_name} />{" "}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box className="activity-job-container">
              <Typography component="h4" className="title font-18">
                Activity on this job
              </Typography>
              <Box className="activity-main">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box className="card">
                      <Typography className="title font-22" component="h4">
                        0 to 1
                      </Typography>
                      <Typography className="sub-title font-12" component="p">
                        Applications
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="card">
                      <Typography className="title font-22" component="h4">
                        0 hours ago
                      </Typography>
                      <Typography className="sub-title font-12" component="p">
                        Last viewed by client
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="card">
                      <Typography className="title font-22" component="h4">
                        0
                      </Typography>
                      <Typography className="sub-title font-12" component="p">
                        Interviewing
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className="activity-job-container">
              <Typography component="h4" className="title">
                About the Client
              </Typography>
              <Box className="activity-main">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box className="card">
                      <Typography className="title font-22" component="h4">
                        Singapore
                      </Typography>
                      <Typography className="sub-title font-12" component="p">
                        Singapore, 7.15PM
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="card">
                      <Typography className="title font-22" component="h4">
                        8 jobs posted
                      </Typography>
                      <Typography className="sub-title font-12" component="p">
                        99% hire rate, 2 open jobs
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className="card">
                      <Typography className="title font-22" component="h4">
                        $1,000 total spent
                      </Typography>
                      <Typography className="sub-title font-12" component="p">
                        3 hires, 2 active
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* Explore similer project section */}
            <Box className="explore-job-container" mt={3}>
              <Typography component="h4" className="heading font-28">
                Explore similar jobs
              </Typography>
              <Box mt={3}>
                <Grid container spacing={2}>
                  {similerProjectType &&
                    similerProjectType?.slice(0, 2)?.map((serviceVal, ind) => {
                      return (
                        <Grid item key={ind} xs={4} md={6} sm={6}>
                          <Box className="card-box">
                            <Box className="top-header">
                              <Box className="top-left">
                                <Box className="pc-chip-wrep-browse">
                                  <Chip className="pc-chip" label={"New"} />
                                </Box>
                                <Box className="top-sub-left">
                                  <Typography
                                    component="h4"
                                    className="font-16 title"
                                  >
                                    {serviceVal.title}
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="font-12 sub-title"
                                  >
                                    Fixed-price | Posted 21 hours ago
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="top-right">
                                <BookmarkBorderIcon className="bookmark-icon" />
                              </Box>
                            </Box>
                            <Box className="card-body">
                              <Box className="top-view">
                                <Box className="left-section">
                                  <Typography className="title font-12">
                                    {serviceVal?.budget === "Project Budget"
                                      ? "Fixed-price"
                                      : serviceVal?.budget}
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="sub-title font-16"
                                  >
                                    ${serviceVal?.budget_from}
                                  </Typography>
                                </Box>
                                <Box className="right-section">
                                  <Typography
                                    component="h4"
                                    className="title font-12"
                                  >
                                    Experience Level
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="sub-title font-16"
                                  >
                                    {serviceVal?.level_of_experience}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box className="card-body-discription">
                                {expandedStates[ind] ? (
                                  <>
                                    <Typography
                                      component="p"
                                      className="title font-14"
                                    >
                                      {serviceVal?.describe_services}
                                    </Typography>
                                    <Link onClick={() => handleViewLess(ind)}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color: "#398CD1",
                                          fontSize: "14px",
                                          fontWeight: 700,
                                          textDecoration: "underline",
                                          textDecorationColor: "#398CD1",
                                        }}
                                        mt={1}
                                      >
                                        View Less
                                      </Typography>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      component="p"
                                      className="title font-14"
                                      sx={{
                                        mt: "12px",
                                        color: "#FFFFFF",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "2",
                                        WebkitBoxOrient: "vertical",
                                      }}
                                    >
                                      {serviceVal?.describe_services}
                                    </Typography>
                                    <Link onClick={() => handleViewMore(ind)}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color: "#398CD1",
                                          fontSize: "14px",
                                          fontWeight: 700,
                                          textDecoration: "underline",
                                          textDecorationColor: "#398CD1",
                                        }}
                                        mt={1}
                                      >
                                        View More
                                      </Typography>
                                    </Link>
                                  </>
                                )}
                              </Box>
                              <Box className="button-list">
                                {serviceVal?.skillData?.map(
                                  (skillVal, index) => (
                                    <Button key={index}>
                                      {skillVal.skill_name}
                                    </Button>
                                  )
                                )}
                              </Box>
                              <Box className="see-more-btn">
                                <Button
                                  variant="contained"
                                  startIcon={<AddIcon />}
                                >
                                  See more
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className="submit-appli-rqs-btn">
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  startIcon={<ArrowForwardIcon />}
                  className="font-14"
                >
                  Submit application for this request
                </Button>
              </Stack>
            </Box>
            <Box mt={2}>
              <RightProjectProfileSection />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewSingleRequest;
