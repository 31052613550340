// proposerStake.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";
import convertToDecimal from "../Common/convertToDecimal";
import convertToSixDecimal from "../Common/convertToSixDecimal";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * pay proposer stake amount for creating a project
 * @param {String} address user wallet address
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function proposerStake(address, user_id, asset_id, blockchain_category_id, blockchain_investment_type_id, projectAmt, stakeAmt, showSnackbar, handleActiveStep, handleModalClose, handleSuccess, assetData) {
    try {
        // set active step to 2
        handleActiveStep(2);
        let argsData = {}, functionName = '';
        if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            argsData = {
                projectId: asset_id, // Asset id / Project id (Backend)
                assetId: blockchain_category_id, // Asset category id (Asset category id of blockchain)
                investmentId: blockchain_investment_type_id, // asset investment type id (Asset investment id of blockchain)
                salePrice: convertToDecimal(projectAmt), // Amount of the project 
                depositAmount: convertToDecimal(assetData?.sale_deposit_amount), // Deposit amount
            }
            functionName = "createSaleProposal"
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
            argsData = {
                projectId: asset_id, // Asset id / Project id (Backend)
                assetId: blockchain_category_id, // Asset category id (Asset category id of blockchain)
                investmentId: blockchain_investment_type_id, // asset investment type id (Asset investment id of blockchain)
                saleAmount: convertToDecimal(projectAmt), // Amount of the project 
                noOfParcel: Number(assetData?.number_of_parcel), // Total number of parcel
                minInvestment: convertToDecimal(assetData?.minimun_investment_per_parcel),
                claimedPercentage: Number(75 * 100), // Claim Percentage
            }

            functionName = "createParcelProposal"
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            argsData = {
                projectId: asset_id, // Asset id / Project id (Backend)
                assetId: blockchain_category_id, // Asset category id (Asset category id of blockchain)
                investmentId: blockchain_investment_type_id, // asset investment type id (Asset investment id of blockchain)
                tokenName: 'Debt Token',
                tokenSymbol: 'D',
                minAmount: convertToDecimal(assetData?.loan_minimum_investment),
                maxAmount: convertToDecimal(assetData?.loan_amount),
                minInvestment: convertToDecimal(assetData?.loan_minimum_investment),
                minPercentage: Number(1 * 100),
                maxPercentage: Number(Math.round(parseFloat(assetData?.loan_roi) * 100)),
                minDuration: Number(assetData?.loan_iop + 1),
                maxDuration: Number(1 * assetData?.loan_duration_month),
                interestOnlyPeriod: Number(assetData?.loan_iop),
                paymentDate: Number(assetData?.loan_repay_day),
                claimedPercentage: Number(75 * 100)
            }
            functionName = "createLoanProposal"
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            let senior = assetData?.capitalTypeDetails?.filter((items) => Object.keys(items)[0] === 'Senior Debt');
            let junior = assetData?.capitalTypeDetails?.filter((items) => Object.keys(items)[0] === 'Junior Debt');
            let equity = assetData?.capitalTypeDetails?.filter((items) => Object.keys(items)[0] === 'Equity');

            let debtToken = [], equityToken = {}, totalDebt = 0;
            if (senior?.length && junior?.length) {
                let seniorObj = senior[0]['Senior Debt']
                let juniorObj = junior[0]['Junior Debt']

                totalDebt = parseFloat(seniorObj?.total_raise) + parseFloat(juniorObj?.total_raise)
                debtToken = [
                    {
                        tokenName: "Senior Debt",
                        tokenSymbol: 'S',
                        totalRaise: convertToDecimal(seniorObj?.total_raise),
                        minInvestment: convertToDecimal(seniorObj?.minimum_investment),
                        interestOnlyPeriod: seniorObj?.iop_senior,
                        percentage: Math.round(parseFloat(seniorObj?.rate_of_return) * 100),
                        duration: seniorObj?.senior_duration * 12,
                        // paymentDate: Number(assetData?.loan_repay_day)
                    },
                    {
                        tokenName: "Junior Debt",
                        tokenSymbol: 'J',
                        totalRaise: convertToDecimal(juniorObj?.total_raise),
                        minInvestment: convertToDecimal(juniorObj?.minimum_investment),
                        interestOnlyPeriod: juniorObj?.iop_junior,
                        percentage: (Math.round(parseFloat(juniorObj?.rate_of_return) * 100)),
                        duration: juniorObj?.junior_duration * 12,
                        // paymentDate: Number(assetData?.loan_repay_day)
                    }
                ]
            } else if (senior?.length) {
                let seniorObj = senior[0]['Senior Debt']

                totalDebt = parseFloat(seniorObj?.total_raise)
                debtToken = [
                    {
                        tokenName: "Senior Debt",
                        tokenSymbol: 'S',
                        totalRaise: convertToDecimal(seniorObj?.total_raise),
                        minInvestment: convertToDecimal(seniorObj?.minimum_investment),
                        interestOnlyPeriod: seniorObj?.iop_senior,
                        percentage: (Math.round(parseFloat(seniorObj?.rate_of_return) * 100)),
                        duration: seniorObj?.senior_duration * 12,
                        // paymentDate: Number(assetData?.loan_repay_day)
                    }
                ]
            } else if (junior?.length) {
                let juniorObj = junior[0]['Junior Debt']

                totalDebt = parseFloat(juniorObj?.total_raise)
                debtToken = [
                    {
                        tokenName: "Junior Debt",
                        tokenSymbol: 'J',
                        totalRaise: convertToDecimal(juniorObj?.total_raise),
                        minInvestment: convertToDecimal(juniorObj?.minimum_investment),
                        interestOnlyPeriod: juniorObj?.iop_junior,
                        percentage: (Math.round(parseFloat(juniorObj?.rate_of_return) * 100)),
                        duration: juniorObj?.junior_duration * 12,
                        // paymentDate: Number(assetData?.loan_repay_day)
                    }
                ]
            }

            if (equity?.length) {
                let equityObj = equity[0]['Equity']

                equityToken =
                {
                    tokenName: "Limited Partner",
                    tokenSymbol: 'L',
                    totalRaise: convertToDecimal(equityObj?.total_raise),
                    minInvestment: convertToDecimal(equityObj?.minimum_investment),
                    equityPercentage: Number(Math.round(parseFloat(equityObj?.equity) * 100)),
                    cashOnYield: 24
                }
            }

            let HurdleRate = {
                isHurdleRate: true,
                isResetHurdle: false,
                minIRRPercent: [0, 700, 1200],
                maxIRRPercent: [700, 1200, 10000],
                partners: [
                    [10000, 0],
                    [7500, 2500],
                    [6000, 4000]
                ],
                carriedPercentage: 3500,
                gpPercentage: 2000,
            };

            argsData = {
                projectId: asset_id, // Asset id / Project id (Backend)
                assetId: blockchain_category_id, // Asset category id (Asset category id of blockchain)
                investmentId: blockchain_investment_type_id, // asset investment type id (Asset investment id of blockchain)
                totalCapital: convertToDecimal(assetData?.fractionalize_project_size), // project size 
                totalRaise: convertToDecimal(assetData?.fractionalize_total_price), // total raise amount
                totalDebtAmt: convertToDecimal(totalDebt),
                totalEquityAmt: convertToDecimal(equity?.length && equity[0]?.total_raise ? equity[0]?.total_raise : 0),
                proposerPercentage: Number(100 - equity?.length && equity[0]?.equity ? equity[0]?.equity : 0) * 100,
                equityPercentage: Number(Math.round(parseFloat(equity?.length && equity[0]?.equity ? equity[0]?.equity : 0) * 100)),
                paymentDate: Number(assetData?.loan_repay_day),
                claimedPercentage: Number(75 * 100),
                projectDuration: Number(assetData?.fractionalize_duration_of_project * 12), // duration of loan amount,
                debtToken,
                equityToken,
                hurdleParam: HurdleRate
            }

            functionName = "createSPVProposal"
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            let classA = '', classB = '';

            let HurdleRate = {
                isHurdleRate: true,
                prefferdPercentage: [],
                LPPercentage: [],
                GPPercentage: [],
            };
            assetData?.shared_data?.forEach((capitals, index) => {

                if (index == 0) {
                    HurdleRate?.prefferdPercentage.push(Number(Math.round(parseFloat(capitals?.hurdle_rate * 100))));
                    HurdleRate?.LPPercentage.push(6000);
                    HurdleRate?.GPPercentage.push(4000);
                    // HurdleRate.push({
                    //     minIRRPercent: [0, 700, 1200],
                    //     maxIRRPercent: [700, 1200, 10000],
                    //     partners: [
                    //         [10000, 0],
                    //         [7500, 2500],
                    //         [6000, 4000]
                    //     ],
                    //     carriedPercentage: 3500,
                    //     gpPercentage: 2000,
                    //     isHurdleRate: true,
                    //     isResetHurdle: false
                    // })
                    classA = {
                        tokenName: "Class A",
                        tokenSymbol: 'A',
                        totalRaise: convertToDecimal(capitals?.total_raise),
                        minInvestment: convertToDecimal(capitals?.minimum_investment),
                        // equityPercentage: Number(capitals?.hurdle_rate * 100),
                    }
                } else if (index == 1) {
                    HurdleRate?.prefferdPercentage.push(Number(Math.round(parseFloat(capitals?.hurdle_rate * 100))));
                    HurdleRate?.LPPercentage.push(7000);
                    HurdleRate?.GPPercentage.push(3000);
                    // HurdleRate.push({
                    //     minIRRPercent: [0, 700, 1200],
                    //     maxIRRPercent: [700, 1200, 10000],
                    //     partners: [
                    //         [10000, 0],
                    //         [7500, 2500],
                    //         [6000, 4000]
                    //     ],
                    //     carriedPercentage: 3500,
                    //     gpPercentage: 2000,
                    //     isHurdleRate: true,
                    //     isResetHurdle: false
                    // })
                    classB = {
                        tokenSymbol: 'B',
                        tokenName: "Class B",
                        totalRaise: convertToDecimal(capitals?.total_raise),
                        minInvestment: convertToDecimal(capitals?.minimum_investment),
                        // equityPercentage: Number(capitals?.hurdle_rate * 100),
                    }
                    // }
                }
            })
            let equityParam = [];
            if (classA && classB) {
                equityParam = [classA, classB]
            } else if (classA) {
                equityParam = [classA]
            } else if (classA) {
                equityParam = [classB]
            }

            argsData = {
                projectId: asset_id, // Asset id / Project id (Backend)
                assetId: blockchain_category_id, // Asset category id (Asset category id of blockchain)
                investmentId: blockchain_investment_type_id, // asset investment type id (Asset investment id of blockchain)
                totalRaise: convertToDecimal(assetData.fractionalize_total_price), // totalRaise
                duration: Number(assetData?.fractionalize_duration_of_project) * 12, // 
                claimedPercentage: Number(75 * 100),
                fundType: equityParam, // classTypeParam
                hurdleParam: HurdleRate
            }
            functionName = "createFundProposal"
        }
        let contract_address = CONTRACTS.CREATE_PROPOSAL
        if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            contract_address = CONTRACTS.FUND_CREATE_PROPOSAL
        }

        const { request: createProposal } = await prepareWriteContract({
            address: contract_address,
            abi: CONTRACT_ABI[contract_address],
            account: address,
            chainId: 6805,
            functionName: functionName,
            args: [
                argsData,
                // assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN ? getCurrencyTypeAddress(assetData?.feeTokenType) : CONTRACTS.TOKEN,// _feeToken
                getCurrencyTypeAddress(assetData?.feeTokenType), // _feeToken
                // (assetData?.feeTokenType === 'USDT' || assetData?.feeTokenType === 'USDC') ? convertToSixDecimal(stakeAmt) : convertToDecimal(stakeAmt), // Stake amount
                getCurrencyTypeDecimal(assetData?.feeTokenType, stakeAmt), // Stake amount
            ]
        })

        const { hash } = await writeContract(createProposal)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data, stakeAmt, asset_id, user_id)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error fetching balance:', error);
        throw error; // propagate the error
    }
}

export { proposerStake };