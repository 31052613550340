import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles"
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Chip, Box, Paper, TableRow, TableHead, TableContainer, Table, TableBody, Stack, Grid, Modal, IconButton, Backdrop, CircularProgress } from '@mui/material';
import { ImageOutlined, RemoveRedEye } from '@mui/icons-material';
import { DOCUMENT_SIGN_STATUS, OFFER_STATUS } from '../../constants.js'
import { GetApi, GetApiParam } from '../../Api/Api.js';
import { useNavigate } from 'react-router-dom';
import '../Transactions/Transactions.css'

// Styles for table columns
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

// Styles for table rows
const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));

// modal styles
const styleModal = { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

// Creating chips according to document status
const DOCUMENT_SIGN_STATUS_TEXT = {
    [DOCUMENT_SIGN_STATUS.PENDING]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: "#13202D", bgcolor: "#F6CFB7", borderRadius: "8px", }} label={'Pending Signature'} />,
    [DOCUMENT_SIGN_STATUS.SIGNED]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: "#13202D", bgcolor: "#34D399", borderRadius: "8px", }} label={'Completed'} />,
    [DOCUMENT_SIGN_STATUS.REJECTED]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: "#13202D", bgcolor: "#FF0000", borderRadius: "8px", }} label={'Rejected'} />,
}

const PendingDocumentsPanel = () => {
    // Initialize states
    const [documentsData, SetDocumentsData] = useState([]);
    const [backupDocumentsData, SetBackupDocumentsData] = useState([]);
    const [categoryData, setCategoryData] = useState(); //category data
    const [filterCategoryId, setFilterCategoryId] = useState(0);
    const [view, setView] = useState(false);
    const [file, setFile] = useState("");
    const [type, setType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    /**
     * function to get category type name
     */
    const capitalTypeName = (id) => {
        var capital_type_name = ''
        switch (id) {
            case 1:
                capital_type_name = 'Real Estate';
                break;
            case 2:
                capital_type_name = 'Art';
                break;
            case 9:
                capital_type_name = 'Funds';
                break;
            case 7:
                capital_type_name = 'Gold';
                break;
            case 8:
                capital_type_name = 'Watch';
                break;
            case 47:
                capital_type_name = 'Asset';
                break;
            default:
                capital_type_name = '';
                break;
        }
        return capital_type_name
    }

    /**
     * format date
     */
    const formatDate = (inputDateString) => {
        // Parse the input date string
        const inputDate = new Date(inputDateString);

        // Format the date to your desired output
        const formattedDate = `${inputDate.getDate()}.${inputDate.getMonth() + 1}.${inputDate.getFullYear()}, ${inputDate.getHours()}:${inputDate.getMinutes() < 10 ? '0' : ''}${inputDate.getMinutes()}`;
        return formattedDate
    }

    // Here we are getting signed documents list
    useEffect(() => {
        let u = localStorage.getItem('user_data');
        let User = JSON.parse(u);
        const getSigningDocumentData = async () => {
            try {
                let res = await GetApi(`/docusign/getPendingSigningDocument?investor_id=${User.id}`);
                if (res?.data?.data && res.data?.data?.length) {
                    const resDataFilter = res.data.data?.filter((data) => {
                        if (data?.offerData && data?.offerData?.status === OFFER_STATUS.COMPLETED) {
                            return true;
                        } else if (!data?.offerData && data?.direct_buy) {
                            return true;
                        } else if (!data?.offerData && data?.status === "completed") {
                            return true;
                        }
                        return false;
                    });
                    const newData = resDataFilter?.map(item => ({
                        ...item,
                        // Change the field names as needed
                        d_name: item?.asset_data?.investment_type?.id === 2 ? 'Loan Agreement' : 'Sale Agreement',
                        p_name: item?.asset_data?.asset_title,
                        category: capitalTypeName(item?.asset_data?.category_id),
                        date: formatDate(item?.updatedAt),
                        status: item.status === 'sent' ? 1 : 2,
                        action: "Cancel Offer",
                        images: {
                            "path": "",
                        },
                    }));

                    // Update the state with the modified data
                    SetDocumentsData(newData);
                    SetBackupDocumentsData(newData);
                }

            } catch (error) {
                console.log('error', error)
            }
        }
        if (User.id) {
            getSigningDocumentData();
        }
        // calling to get all category type data
        getCategoryData();
    }, [])

    /**
    * Api to get Category Type Data
    */
    async function getCategoryData() {
        try {
            const res = await GetApi("/proposer/getCategory/");
            setCategoryData(res?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * open modal to display doc content
     * @param {Array} documentdata 
     * @returns {void}
     */
    const viewDoc = async (documentdata, mode, index) => {
        setLoading(true)

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/docusign/viewDocument?envelopeId=${documentdata?.envelope_id}`);
            const pdfBuffer = await response.arrayBuffer();
            const pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setFile(pdfUrl);
            setType('pdf');

            setOpenModal(true);
            setView(true);
            setLoading(false);
        }
        catch (error) {
            setLoading(false)
            console.log('error', error)
        }

    };

    /**
     * handle modal close
     */
    const handleClose = () => { setOpenModal(false); setView(false) };

    // Whenever category id changed, we are filter data according to category id selected 
    useEffect(() => {
        if (filterCategoryId) {
            SetDocumentsData(backupDocumentsData.filter(o => o.asset_data?.category_id === filterCategoryId))
        } else {
            SetDocumentsData(backupDocumentsData)
        }
    }, [filterCategoryId])

    return (
        <>
            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell>Document Name</StyledTableCell>
                            <StyledTableCell align="left">Project Name</StyledTableCell>
                            <StyledTableCell align="center">Category</StyledTableCell>
                            <StyledTableCell align="center">Date</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center" style={{ width: '120px' }}>Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentsData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.asset_data?.asset_title}${i}`}>
                                <StyledTableCell component="th" scope="row">{row.d_name}</StyledTableCell>
                                <StyledTableCell align="left">{row.p_name}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Chip
                                        sx={{ marginTop: 2, marginBottom: 2, color: "#FFFFFF", bgcolor: "#13202D", fontSize: "12px", borderRadius: "8px", }}
                                        label={row.category}
                                        icon={<ImageOutlined sx={{ color: "#FFFFFF !important" }} />}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.date}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {DOCUMENT_SIGN_STATUS_TEXT[row.status]}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="baseline"
                                        spacing={2}
                                    >
                                        <IconButton style={{ padding: 0 }} onClick={() => viewDoc(row, 'view-only', i)}>
                                            <RemoveRedEye style={{ color: "#FFFFFF" }} />
                                        </IconButton>
                                    </Stack>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* modal code start from here */}
            <Grid>
                {
                    loading &&
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="primary" />
                    </Backdrop>
                }
                {(view && !loading && type && file) && (
                    <Modal
                        className="projectDocumentsModal"
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        fullWidth={true}
                    >
                        <Box sx={styleModal}>
                            {/* <iframe
                title="PDF Viewer"
                src={file}
                style={{ width: "100%", height: "100%" }}
                className="pdf-viewer-modal"
              ></iframe> */}
                            <object
                                data={file}
                                type="application/pdf"
                                style={{ width: '100%', height: '100%' }}
                            >
                                <p style={{ textAlign: 'center' }}>
                                    Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                                </p>
                            </object>
                        </Box>
                    </Modal>
                )}
            </Grid >
            {/* modal code end from here */}
        </>
    )
}

export default PendingDocumentsPanel