import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, IconButton } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

// Import custom components for this page
import { NewLogo } from '../../../Components/Images/Images'
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import NumericFormatCustom from "../../../Components/Common/NumericFormatCustom";
import "./Components/Questionnaire.css"

export default function FundsFractionDetails(props) {
  const { showSnackbar } = useSnackbar()

  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // Get current proposal and progress data from the parent component
  var propData = getProposalData();
  let currentProgress = getProgress();

  const [names, setNames] = useState(propData?.capital_type_list?.length ? propData?.capital_type_list : [])

  // state to hold max limit for project price
  const [isMaxLimitExceeded, setIsMaxLimitExceeded] = useState(false);
  const [maxLimit, setMaxLimit] = useState(0);

  // Initialize state variables with default values from 'propData' or fallback values
  const [raise, setRaise] = useState(propData?.fractionalize_total_price ? propData?.fractionalize_total_price : 0)
  const [minimum_investment, setMinimumInvestment] = useState(propData?.fractionalize_minimum_investment ? propData?.fractionalize_minimum_investment : 0)
  const [projectDuration, setProjectDuration] = useState(propData?.fractionalize_duration_of_project ? propData?.fractionalize_duration_of_project : 0)
  const [sharedClasses, setSharedClasses] = useState(propData?.number_of_shared ? propData?.number_of_shared : 0)

  /**
   * Function to increment or decrement values by $500
   * @param {String} type 
   * @param {Function} setFunction 
   */
  const handleChangeAmount = (type, setFunction) => {
    if (type === 'inc') {
      setFunction(prevCount => prevCount ? (parseFloat(prevCount) + 500) : 500);
    } else {
      setFunction(prevCount => (parseFloat(prevCount) >= 500 ? parseFloat(prevCount) - 500 : prevCount));
    }
  }

  /**
   * Function to increment or decrement 'projectDuration' by 1 
   * @param {String} type 
   */
  const handleProjectDuration = (type) => {
    if (type === 'inc') {
      setProjectDuration(prevCount => prevCount ? (parseInt(prevCount) + 1) : 1)
    } else {
      setProjectDuration(prevCount => (parseInt(prevCount) >= 1 ? parseInt(prevCount) - 1 : prevCount))
    }
  }

  /**
  * Function to increment or decrement 'sharedClasses' by 1
  * @param {String} type 
  */
  const handleSharedClasses = (type) => {
    if (type === 'inc') {
      setSharedClasses(prevCount => prevCount ? (parseInt(prevCount) + 1) : 1)
    } else {
      setSharedClasses(prevCount => (parseInt(prevCount) >= 1 ? parseInt(prevCount) - 1 : prevCount))
    }
  }

  /**
   * Handle "Next" button click 
   */
  const handleClick = () => {
    if (propData?.shared_data?.length > sharedClasses && propData?.shared_data?.length > 0) {
      propData.shared_data = propData?.shared_data?.slice(0, sharedClasses);
    }
    // Update properties in 'propData' based on state variables
    propData.fractionalize_total_price = raise;
    propData.fractionalize_minimum_investment = minimum_investment;
    propData.fractionalize_duration_of_project = projectDuration;
    propData.number_of_shared = parseInt(sharedClasses);
    propData.capital_type_list = names;

    handleNext()

    // Call 'updateProposalData' function with 'propData'
    updateProposalData(propData);
  }

  /**
   *  Handle "Save and Exit" button click 
   */
  const SaveAndExit = () => {
    if (propData?.shared_data?.length > sharedClasses && propData?.shared_data?.length > 0) {
      propData.shared_data = propData?.shared_data?.slice(0, sharedClasses);
    }
    // Update properties in 'propData' based on state variables
    propData.fractionalize_total_price = raise;
    propData.fractionalize_minimum_investment = minimum_investment;
    propData.fractionalize_duration_of_project = projectDuration;
    propData.number_of_shared = sharedClasses;
    propData.capital_type_list = names;

    // Call 'updateProposalData' function with 'propData'
    saveProposal();
    updateProposalData(propData);
  }

  // updating capital type list on propData change
  useEffect(() => {
    if (propData?.capital_type_list?.length) {
      setNames(propData?.capital_type_list)
    }
  }, [propData])

  useEffect(() => {
    if (raise) {
      // checking for max range
      let checkMaxLimitExceeded = true, checkMaxLimit = 0;
      let stackAmtData = propData?.stackAmtData;
      let projectAmt = parseFloat(raise)
      if (stackAmtData?.length) {
        stackAmtData?.forEach((dataItem) => {
          checkMaxLimit = dataItem?.max_amount
          if (dataItem?.min_amount <= projectAmt && projectAmt <= dataItem?.max_amount) {
            checkMaxLimitExceeded = false
          }
        })
        setIsMaxLimitExceeded(checkMaxLimitExceeded)
        setMaxLimit(checkMaxLimit)
      }
      // check for max range exceeded
      if (checkMaxLimitExceeded && checkMaxLimit > 0) {
        showSnackbar(`You cannot create a project that exceeds the set range configuration $${checkMaxLimit}`, 'error');
      } else if (checkMaxLimitExceeded && checkMaxLimit == 0) {
        showSnackbar(`Range configuration is not set for the asset type`, 'error');
      }
    }
  }, [raise])

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Now, set your price
                </Typography>
              </Box>
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right  quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit}
                    disabled={
                      // Boolean(parseFloat(minimum_investment) <= parseFloat(raise)) && minimum_investment !== '' && minimum_investment > 0 && 
                      raise !== "" && projectDuration !== "" && sharedClasses != "" && raise > 0 && projectDuration > 0 && sharedClasses < 3 && sharedClasses > 0 && !isMaxLimitExceeded ? false : true
                    }
                    className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box>
                  <Grid container spacing={{ xs: 1, md: 1, sm: 1 }} my={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography className="inputFieldTitle">Total Raise</Typography>
                      <Box className='NS-sale-details'>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleChangeAmount('dec', setRaise) }} className="NS-sale-details-icon-btn">
                            <RemoveIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustomDollar,
                            }}
                            size="small"
                            helperText={"Total Raise"}
                            value={raise}
                            onChange={(e) => setRaise(e.target.value)}
                            onKeyDown={(event) => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleChangeAmount('inc', setRaise) }} className="NS-sale-details-icon-btn">
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      {/* <Typography className="inputFieldTitle">Minimum Investment</Typography>
                      <Box className='NS-sale-details'>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleChangeAmount('dec', setMinimumInvestment) }} className="NS-sale-details-icon-btn">
                            <RemoveIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustomDollar,
                            }}
                            value={minimum_investment}
                            size="small"
                            helperText={"Minimum Investment"}
                            onChange={(e) => setMinimumInvestment(e.target.value)}
                            onKeyDown={(event) => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleChangeAmount('inc', setMinimumInvestment) }} className="NS-sale-details-icon-btn">
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Box> */}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography className="inputFieldTitle" sx={{ marginBottom: '8px !important' }}>Duration of Project</Typography>
                      <Box className='NS-sale-details'>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleProjectDuration('dec') }} className="NS-sale-details-icon-btn">
                            <RemoveIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={projectDuration}
                            size="small"
                            helperText={projectDuration > 1 ? "Years" : 'Year'}
                            onChange={(e) => setProjectDuration(e.target.value)}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            onKeyDown={(event) => {
                              if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleProjectDuration('inc') }} className="NS-sale-details-icon-btn">
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Grid item xs={12} md={12} lg={12}>
                  <Typography className="inputFieldTitle" sx={{ marginBottom: '8px !important' }}>No. of Shared Classes</Typography>
                  <Box className='NS-sale-details'>
                    <Box className='NS-sale-details-icon'>
                      <IconButton disabled={sharedClasses === 0 ? true : false} onClick={() => { handleSharedClasses('dec') }} className="NS-sale-details-icon-btn">
                        <RemoveIcon />
                      </IconButton>
                    </Box>
                    <Box>
                      <TextField
                        id="outlined-number"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={sharedClasses}
                        disabled
                        size="small"
                        // onChange={(e) => setSharedClasses(e.target.value)}
                        // InputProps={{
                        //   inputComponent: NumericFormatCustom,
                        // }}
                        onKeyDown={(event) => {
                          if (event?.key === '-' || event?.key === '+' || event?.key === ".") {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Box>
                    <Box className='NS-sale-details-icon'>
                      <IconButton disabled={sharedClasses > 1 ? true : false} onClick={() => { handleSharedClasses('inc') }} className="NS-sale-details-icon-btn">
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
                <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center', marginTop: 2.5 }}>
                  {
                    // Boolean(parseFloat(minimum_investment) > parseFloat(raise)) ? "Minimum investment can not be greater than total raise." : 
                    parseInt(sharedClasses) > 2 ? "No. of shared classes can not be more than two" : isMaxLimitExceeded ? `You cannot create the project above $${maxLimit}` : ""}
                </Typography>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleback}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className='quests-btn-box'>
                    <Button
                      disabled={
                        // Boolean(parseFloat(minimum_investment) <= parseFloat(raise)) && minimum_investment !== '' && minimum_investment > 0 && 
                        raise !== "" && projectDuration !== "" && sharedClasses != "" && raise > 0 && projectDuration > 0 && sharedClasses < 3 && sharedClasses > 0 && !isMaxLimitExceeded ? false : true
                      }
                      onClick={() => handleClick()} endIcon={<KeyboardArrowRightIcon />} className='btn-rounded btn-text-white btn-blue-600'>
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}




